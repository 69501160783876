// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metadata-title {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid black;
  font-size: var(--medium-font-size) !important;
  color: var(--dark-red) !important;
}

.edit-video-away-player-grid svg, .edit-video-home-player-grid svg, .edit-video-away-player-select svg, .edit-video-home-player-select svg {
  height: var(--small-font-size);
}`, "",{"version":3,"sources":["webpack://./src/videoPages/EditVideo/VideoMetaDataForm.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,2BAAA;EACA,8BAAA;EACA,6CAAA;EACA,iCAAA;AAAJ;;AAMI;EACI,8BAAA;AAHR","sourcesContent":["\n.metadata-title{\n    display: flex;\n    justify-content: flex-start;\n    border-bottom: 1px solid black;\n    font-size: var(--medium-font-size) !important;\n    color: var(--dark-red) !important;\n}\n\n\n.edit-video-away-player-grid, .edit-video-home-player-grid, .edit-video-away-player-select, .edit-video-home-player-select\n{\n    svg{\n        height: var(--small-font-size);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
