import React, { useRef, useState, useEffect, SyntheticEvent } from 'react';
import { MatchData } from 'types/videoTypes';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import { Button, Slider, Stack, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import './DpInterface.scss';

const DpInterface: React.FC<{ matchData: MatchData }> = ({ matchData }) => {
  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const mainVideoContainerRef = useRef<HTMLDivElement>(null);
  const secondaryVideoContainerRef = useRef<HTMLDivElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);

  const handleVideo1Load = () => {
    setIsVideoLoaded(true);
  };

  const handleVideoError = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
    console.error('Error loading video:', e);
  };

  const handleVideo1TimeUpdate = () => {
    if (video1Ref.current && video2Ref.current) {
      video2Ref.current.currentTime = video1Ref.current.currentTime;
    }
  };

  useEffect(() => {
    const video1 = video1Ref.current;
    const video2 = video2Ref.current;

    if (video1 && video2) {
      const loadedMetadataHandler = () => {
        setIsVideoLoaded(true);
      };

      video1.addEventListener('loadedmetadata', loadedMetadataHandler);
      video2.addEventListener('loadedmetadata', loadedMetadataHandler);

      const playHandler = () => {
        video2.play().catch((e) => console.error('Error playing video 2:', e));
        setIsPlaying(true);
      };
      const pauseHandler = () => {
        video2.pause();
        setIsPlaying(false);
      };
      const seekedHandler = () => {
        video2.currentTime = video1.currentTime;
      };

      video1.addEventListener('play', playHandler);
      video1.addEventListener('pause', pauseHandler);
      video1.addEventListener('seeked', seekedHandler);

      return () => {
        video1.removeEventListener('play', playHandler);
        video1.removeEventListener('pause', pauseHandler);
        video1.removeEventListener('seeked', seekedHandler);
        video1.removeEventListener('loadedmetadata', loadedMetadataHandler);
        video2.removeEventListener('loadedmetadata', loadedMetadataHandler);
      };
    }
  }, []);

  const togglePlay = () => {
    if (video1Ref.current) {
      if (isPlaying) {
        video1Ref.current.pause();
      } else {
        video1Ref.current.play();
      }
    }
  };

  const skipTime = (seconds: number) => {
    if (video1Ref.current) {
      video1Ref.current.currentTime += seconds;
    }
  };

  const skipFrame = (forward: boolean) => {
    if (video1Ref.current) {
      const frameTime = 1 / 30; // Assuming 30 fps
      video1Ref.current.currentTime += forward ? frameTime : -frameTime;
    }
  };

  const handleSpeedChange = (event: Event, newValue: number | number[]) => {
    const speed = Array.isArray(newValue) ? newValue[0] : newValue;
    setPlaybackRate(speed);
    if (video1Ref.current && video2Ref.current) {
      video1Ref.current.playbackRate = speed;
      video2Ref.current.playbackRate = speed;
    }
  };

  return (
    <div className="dp-interface">
      <div className="video-container main-video" ref={mainVideoContainerRef}>
        <TransformWrapper
          initialScale={2.5}
          disablePadding={true}
          centerOnInit={true}
          limitToBounds={true}
        >
          <TransformComponent>
            <video
              ref={video1Ref}
              onLoadedData={handleVideo1Load}
              onError={handleVideoError}
              onTimeUpdate={handleVideo1TimeUpdate}
            >
              <source src={`${matchData.fileUrl}#t=0.001`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </TransformComponent>
        </TransformWrapper>
      </div>
      <div
        className="video-container secondary-video"
        ref={secondaryVideoContainerRef}
      >
        <Stack direction="column" spacing={2} className="video-controls">
          <Button onClick={togglePlay} variant="contained" fullWidth>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </Button>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Button onClick={() => skipTime(-5)} variant="contained">
              <FastRewindIcon />
            </Button>
            <Button onClick={() => skipTime(5)} variant="contained">
              <FastForwardIcon />
            </Button>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Button onClick={() => skipFrame(false)} variant="contained">
              <SkipPreviousIcon />
            </Button>
            <Button onClick={() => skipFrame(true)} variant="contained">
              <SkipNextIcon />
            </Button>
          </Stack>
          <Slider
            value={playbackRate}
            min={0.1}
            max={2}
            step={0.1}
            onChange={handleSpeedChange}
            orientation="vertical"
            sx={{ height: 150 }}
          />
          <Typography variant="body2" align="center">
            {playbackRate.toFixed(1)}x
          </Typography>
        </Stack>

        <video ref={video2Ref} onError={handleVideoError}>
          <source src={`${matchData.fileUrl}#t=0.001`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {!isVideoLoaded && <div className="loading">Loading video...</div>}
    </div>
  );
};

export default DpInterface;
