import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Cookies from 'js-cookie';
import axios from 'axios';

import BallDetailsBar from './InputPanel/BallDetailsBar';
import EditDpPanel from './InputPanel/EditDpPanel';
import EditRoundPanel from './EditRoundPanel';
import { formatTime, getLastScore } from 'src/utils/utils';

import {
  roundEndTimeIndex,
  roundNumberIndex,
  roundStartTimeIndex,
  ballDataIndex,
  roundIdIndex,
  roundDataIndex,
} from 'src/constants';

import './ManualInput.scss';

interface UserInfo {
  isCoach: boolean;
  admin: boolean;
}

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
}

interface ManualInputProps {
  userInfo: UserInfo;
  videoData: VideoData;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
  matchId: number;
  roundResults: any[];
  videoRef: React.RefObject<HTMLVideoElement>;
}

const ManualInput: React.FC<ManualInputProps> = ({
  userInfo,
  videoData,
  setVideoData,
  matchId,
  roundResults,
  videoRef,
}) => {
  const token = Cookies.get('token') || '';
  const [fixedRound, setFixedRound] = useState(-1);
  const [ballData, setBallData] = useState<any[]>([]);
  const [roundData, setRoundData] = useState<any[]>([]);

  useEffect(() => {
    if (
      fixedRound === -1 &&
      roundResults &&
      roundResults[videoData.currentRound]
    ) {
      setBallData(roundResults[videoData.currentRound][ballDataIndex]);
      setRoundData(roundResults[videoData.currentRound]);
    } else if (fixedRound !== -1 && roundResults && roundResults[fixedRound]) {
      setBallData(roundResults[fixedRound][ballDataIndex]);
      setRoundData(roundResults[fixedRound]);
    } else {
      setBallData([]);
      setRoundData([]);
    }
  }, [roundResults, videoData.currentRound, fixedRound]);

  const handleInsertBall = () => {
    axios
      .put(
        `/api/editballdata`,
        {
          new_row: [
            -1,
            roundData[0][roundIdIndex],
            roundData[0][roundStartTimeIndex],
            roundData[0][roundEndTimeIndex],
            0,
            0,
          ],
        },
        {
          params: {
            match_id: matchId,
            primary_attribute: `video edited (id) : ${matchId}`,
            secondary_attribute: `new ball insert to round : ${roundData[0][roundNumberIndex]} ${roundData[0][roundStartTimeIndex]}s to ${roundData[0][roundEndTimeIndex]}s`,
          },
          headers: { Authorization: token },
        },
      )
      .then(() => {
        setVideoData((prevState) => ({
          ...prevState,
          refresh: true,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const roundNumbers = Array.from(
    { length: videoData.numberOfRound },
    (_, rIndex) => rIndex + 1,
  );

  const permission = userInfo.isCoach || userInfo.admin;

  return (
    <>
      {permission && (
        <div className="manual-input-main-container">
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              aria-controls="edit-round-panel-content"
              id="edit-round-panel-header"
            >
              {roundResults[videoData.currentRound] ? (
                <div className="current-round-info-panel">
                  <span className="round-number">
                    回合{' '}
                    <span className="number">{videoData.currentRound + 1}</span>
                  </span>
                  <span className="time-info">
                    開始:{' '}
                    <span className="start-time">
                      {formatTime(
                        roundResults[videoData.currentRound][roundDataIndex][
                          roundStartTimeIndex
                        ],
                      )}
                    </span>{' '}
                    結束:{' '}
                    <span className="end-time">
                      {formatTime(
                        roundResults[videoData.currentRound][roundDataIndex][
                          roundEndTimeIndex
                        ],
                      )}
                    </span>
                  </span>
                  <span className="score-info">
                    比分:{' '}
                    <span className="score">
                      {getLastScore(roundResults, videoData.currentRound)}
                    </span>
                  </span>
                </div>
              ) : (
                <p
                  style={{ color: 'white', textAlign: 'center', width: '100%' }}
                >
                  沒有回合
                </p>
              )}
            </AccordionSummary>
            <AccordionDetails className="round-summary-container">
              <EditRoundPanel
                currentRound={videoData.currentRound}
                roundNumbers={roundNumbers}
                roundResults={roundResults}
                matchId={matchId}
                token={token}
                setVideoData={setVideoData}
                videoRef={videoRef}
              />
            </AccordionDetails>
          </Accordion>
          {roundResults && (
            <BallDetailsBar
              videoData={videoData}
              roundResults={roundResults}
              videoRef={videoRef}
              token={token}
              matchId={matchId}
              setVideoData={setVideoData}
            />
          )}
          {ballData[videoData.currentBall] && (
            <div className="ball-input-main-panel">
              <EditDpPanel
                matchId={matchId}
                currentRound={videoData.currentBall}
                dpIndex={1}
                setVideoData={setVideoData}
                ballData={ballData[videoData.currentBall]}
                videoRef={videoRef}
              />
            </div>
          )}
          <div className="padding"></div>
        </div>
      )}
    </>
  );
};

export default ManualInput;
