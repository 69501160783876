import CloseIcon from '@mui/icons-material/Close';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
} from '@mui/material';

import './VideoUploadOptions.scss';

const VideoUploadOptions = ({
  matchData,
  file,
  index,
  handleRemoveFile,
  handleModifyMatchData,
  playlists,
  selectedPlaylists,
  handlePlaylistSelect,
}) => {
  return (
    <li className="video-upload-block" key={index}>
      <div className="video-upload-content">
        <span>{file.name}</span>
        <Button
          onClick={() => handleRemoveFile(index)}
          aria-label="Remove file"
        >
          <CloseIcon />
        </Button>
      </div>
      <div className="video-upload-info">
        <TextField
          className="video-name-input"
          value={matchData.videoName[index]}
          onChange={(e) => handleModifyMatchData(index, e.target.value)}
          label="影片名"
          variant="outlined"
          fullWidth
        />
        <FormControl className="playlist-select">
          <Select
            multiple
            value={selectedPlaylists[file.name] || []}
            onChange={(event) => handlePlaylistSelect(event, file.name)}
            renderValue={(selected) => selected.join(', ')}
          >
            {!playlists.message &&
              playlists.map((playlist) => (
                <MenuItem
                  key={playlist.playlist_id}
                  value={playlist.playlist_name}
                >
                  <Checkbox
                    checked={selectedPlaylists[file.name]?.includes(
                      playlist.playlist_name,
                    )}
                  />
                  <ListItemText primary={playlist.playlist_name} />
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>播放清單</FormHelperText>
        </FormControl>
      </div>
    </li>
  );
};

export default VideoUploadOptions;
