import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chart } from 'react-google-charts';
import Heatmap from 'src/playerPages/HeatMap/HeatMap';
import { formatTime } from 'src/utils/utils';
import CustomTooltip from './customToolTip';
import './VideoAnalysisPanel.scss';

const VideoAnalysisPanel = ({
  dpResults,
  dpIndex,
  ttPlotHeight,
  ttPlotWidth,
  homePlayer,
  awayPlayer,
  matchId,
  matchData,
  isAdmin,
  topPlayer,
}) => {
  const heatmapClass = 'main-hm-plot-container';
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const dpIndexRef = useRef(dpIndex);

  useEffect(() => {
    dpIndexRef.current = dpIndex;
  }, [dpIndex]);

  const options = useMemo(
    () => ({
      width: ttPlotWidth,
      height: ttPlotHeight,
      legend: 'none',
      seriesType: 'line',
      chartArea: {
        width: '100%',
        height: '100%',
        backgroundColor: {
          fill: '#00ff00',
          fillOpacity: 0.55,
        },
      },
      backgroundColor: '#161616',
      series: {
        1: { type: 'scatter', pointSize: 5 },
        2: { type: 'scatter', pointSize: 8 },
      },
      vAxis: {
        viewWindow: {
          max: 1280,
          min: 0,
        },
        ticks: [0, 320, 640, 960, 1280],
        textPosition: 'none',
        textStyle: { color: '#ffffff' },
        baselineColor: '#ffffff',
        gridlineColor: '#ffffff',
      },
      hAxis: {
        viewWindow: {
          max: 800,
          min: 0,
        },
        ticks: [0, 266, 533, 800],
        textPosition: 'none',
        textStyle: { color: '#ffffff' },
        baselineColor: '#ffffff',
        gridlineColor: '#ffffff',
      },
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      colors: ['#000000', '#ffffff', '#E50914'],
      tooltip: { trigger: 'none' }, // Disable default tooltip
    }),
    [ttPlotWidth, ttPlotHeight],
  );

  const handleSelect = useCallback(
    ({ chartWrapper }) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
      if (selection.length === 1) {
        const [selectedItem] = selection;
        const dataTable = chartWrapper.getDataTable();
        const { row, column } = selectedItem;

        const currentDpIndex = dpIndexRef.current;

        const botPlayer =
          (topPlayer === awayPlayer ? homePlayer : awayPlayer) || '';

        const playerName =
          (dpResults[currentDpIndex][3][row - 2] === 'top'
            ? topPlayer
            : botPlayer) || '';
        const truncatedPlayerName =
          playerName.length > 15 ? playerName.slice(0, 15) + '...' : playerName;
        let trajectory;

        if (row > 2) {
          trajectory = dpResults[currentDpIndex][4][row - 3] ? '斜線' : '直線';
        } else {
          trajectory = '斜線/直線';
        }

        const newInfo = {
          numBall: row - 1,
          coordinates: [
            Math.floor(dataTable.getValue(row, 0)),
            Math.floor(dataTable.getValue(row, column)),
          ],
          time: formatTime(dpResults[currentDpIndex][2][row - 2][0]),
          player: truncatedPlayerName,
          balltraj: trajectory,
        };

        setTooltipInfo(newInfo);

        // Get the position of the selected point
        const bounds = chart
          .getChartLayoutInterface()
          .getBoundingBox('chartarea');
        const x = chart
          .getChartLayoutInterface()
          .getXLocation(dataTable.getValue(row, 0));
        const y = chart
          .getChartLayoutInterface()
          .getYLocation(dataTable.getValue(row, column));

        setTooltipPosition({ x: bounds.left + x, y: bounds.top + y });
      } else {
        setTooltipInfo(null);
      }
    },
    [dpResults, homePlayer, awayPlayer],
  );

  const handleCloseTooltip = useCallback(() => {
    setTooltipInfo(null);
  }, []);

  const chartEvents = useMemo(
    () => [
      {
        eventName: 'select',
        callback: handleSelect,
      },
    ],
    [handleSelect],
  );

  const memoizedChart = useMemo(() => {
    if (dpResults && dpResults[dpIndex] && dpResults[dpIndex][1]) {
      return (
        <>
          <span style={{ color: 'white' }}>top/right</span>
          <div style={{ position: 'relative' }}>
            <Chart
              chartType="ComboChart"
              data={dpResults[dpIndex][1]}
              options={options}
              chartEvents={chartEvents}
              width={ttPlotWidth}
              height={ttPlotHeight}
            />
            {tooltipInfo && (
              <CustomTooltip info={tooltipInfo} onClose={handleCloseTooltip} />
            )}
          </div>
          <span style={{ color: 'white' }}>bottom/left</span>
        </>
      );
    } else {
      return <p className="missing-dp">無法檢測到落點。</p>;
    }
  }, [
    dpIndex,
    dpResults,
    ttPlotHeight,
    ttPlotWidth,
    tooltipInfo,
    tooltipPosition,
    options,
    chartEvents,
  ]);

  return (
    <div className="video-analysis-main-container">
      {matchData.progress >= 0 ? (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>軌跡圖</Typography>
            </AccordionSummary>
            <div className="trajectory-plot-container">{memoizedChart}</div>
          </Accordion>
          {isAdmin && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>落點熱度圖</Typography>
              </AccordionSummary>
              <div className="video-heatmap-container">
                <div className="video-heatmap-player-name">
                  <p>{homePlayer.slice(0, 15)}</p>
                  <p>{awayPlayer.slice(0, 15)}</p>
                </div>
                <div className="analysis-result-panel">
                  <Heatmap
                    videoId={matchId}
                    heatmapClass={heatmapClass}
                    disableClose={true}
                    homePlayer={homePlayer}
                    awayPlayer={awayPlayer}
                  />
                </div>
              </div>
            </Accordion>
          )}
        </>
      ) : (
        <p>無落點</p>
      )}
      <p className="padding"></p>
    </div>
  );
};

export default VideoAnalysisPanel;
