import { JSX, useState } from 'react';
import Title from './Title';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Theme, useTheme } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Cookies from 'js-cookie';

import './ChartOption.scss';

interface ChartOptionProps {
  DBFilterCriteria: {
    userName: string[];
    dataDate: string;
    metrics: string[];
  };
  setDBFilterCriteria: React.Dispatch<
    React.SetStateAction<{
      userName: string[];
      dataDate: string;
      metrics: string[];
    }>
  >;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const metricNames = ['Video watched', 'Log in', 'Video uploaded'];
const metricNamesDict: { [key: string]: string } = {
  'Video watched': 'update video views',
  'Log in': 'log in',
  'Video uploaded': 'upload video',
};

export default function ChartOption({
  DBFilterCriteria,
  setDBFilterCriteria,
}: ChartOptionProps): JSX.Element {
  const token = Cookies.get('token') || '';
  const UserNames: string[] = sessionStorage.getItem('userList')
    ? JSON.parse(sessionStorage.getItem('userList')!)
    : [];

  const handleChange = (
    event: SelectChangeEvent<string[]>,
    filterType: string,
  ) => {
    const { value } = event.target;

    if (filterType === 'users') {
      setDBFilterCriteria((prevFilterCriteria) => ({
        ...prevFilterCriteria,
        userName: typeof value === 'string' ? value.split(',') : value,
      }));
    } else if (filterType === 'metrics') {
      setDBFilterCriteria((prevFilterCriteria) => ({
        ...prevFilterCriteria,
        metrics: typeof value === 'string' ? value.split(',') : value,
      }));
    } else if (filterType === 'dataDate') {
      setDBFilterCriteria((prevFilterCriteria) => ({
        ...prevFilterCriteria,
        dataDate: value.toString(),
      }));
    }
  };

  return (
    <div className="admin-chart-option">
      <Title>Chart Option </Title>
      <InputLabel id="demo-multiple-name-label">Users</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={DBFilterCriteria.userName}
        onChange={(event) => handleChange(event, 'users')}
        MenuProps={MenuProps}
      >
        {UserNames &&
          UserNames.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
      </Select>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={DBFilterCriteria.dataDate}
        name="radio-buttons-group"
        row
        onChange={(event) => handleChange(event, 'dataDate')}
      >
        <FormControlLabel
          value="CMonth"
          control={<Radio />}
          label="This Month"
        />
        <FormControlLabel value="CYear" control={<Radio />} label="This Year" />
        <FormControlLabel
          value="90D"
          control={<Radio />}
          label="Last 90 Days"
        />
      </RadioGroup>
      <InputLabel id="demo-multiple-name-label">Activity</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={DBFilterCriteria.metrics}
        onChange={(event) => handleChange(event, 'metrics')}
        MenuProps={MenuProps}
      >
        {metricNames.map((metric) => (
          <MenuItem key={metric} value={metricNamesDict[metric]}>
            {metric}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
