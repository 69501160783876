import React, { useState } from 'react';
import { Snackbar, SnackbarContent, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import { formatTime } from 'src/utils/utils';

import {
  ballAwayPlayerScoreIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballStartTimeIndex,
} from 'src/constants';

import './InputPanel.scss';

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
}

interface InputPanelProps {
  ballData: any[][];
  videoRef: React.RefObject<HTMLVideoElement>;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
}

const InputPanel: React.FC<InputPanelProps> = ({
  ballData,
  videoRef,
  setVideoData,
}) => {
  const token = Cookies.get('token');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleEditToggle = () => {
    setVideoData((prevState) => ({
      ...prevState,
      refresh: true,
    }));
  };

  const handleScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    scoreIndex: number,
  ) => {};

  const handleTimeUpdate = (isStartTime: boolean) => {
    if (!videoRef.current) return;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="input-ball-edit-container">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="h-custom-snackbar"
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          className="h-custom-snackbar"
          message={snackbarMessage}
        />
      </Snackbar>
      <div className="ball-score-edit-panel">
        <TextField
          type="number"
          value={ballData[ballHomePlayerScoreIndex]}
          onChange={(e) =>
            handleScoreChange(
              e as React.ChangeEvent<HTMLInputElement>,
              ballHomePlayerScoreIndex,
            )
          }
          inputProps={{ min: 0 }}
          sx={{ marginRight: '4px' }}
        />
        <span> : </span>
        <TextField
          type="number"
          value={ballData[ballAwayPlayerScoreIndex]}
          onChange={(e) =>
            handleScoreChange(
              e as React.ChangeEvent<HTMLInputElement>,
              ballAwayPlayerScoreIndex,
            )
          }
          inputProps={{ min: 0 }}
          sx={{ marginLeft: '4px' }}
        />
      </div>
      <div className="ball-info-edit-panel">
        <button
          onClick={() => handleTimeUpdate(true)}
          title="將當前視頻時間設定為起始時間"
          style={{ marginRight: '4px' }}
        >
          {formatTime(ballData[ballStartTimeIndex])}
        </button>
        <span>:</span>
        <button
          onClick={() => handleTimeUpdate(false)}
          title="將當前視頻時間設定為結束時間"
          style={{ marginLeft: '4px' }}
        >
          {formatTime(ballData[ballEndTimeIndex])}
        </button>
      </div>
    </div>
  );
};

export default InputPanel;
