import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import VideoFileUploadForm from './VideoFileUploadForm';
import { UploadStatus } from './UploadStatus';

import { fetchPlaylistDataAPI } from 'src/api/videoService';
import Cookies from 'js-cookie';

import './VideoUploadForm.scss';

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        乒乓球
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const steps = ['选择视频'];

export const VideoUploadForm = ({ userInfo }) => {
  const token = Cookies.get('token');

  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [matchData, setMatchData] = useState({
    videoName: 'MissingData',
    videoDescription: '_',
    stage: '_',
    matchDate: '2022-03-14',
    location: '_',
    HomePlayer: '_',
    AwayPlayer: '_',
    fileUrls: '',
    thumbnail_urls: 'defaultTestThumbnail.png',
    accountType: String(userInfo.accountType),
    uploadUser: userInfo.identifier,
  });

  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState({});

  const handlePlaylistSelect = (event, index) => {
    setSelectedPlaylists((prevState) => {
      return {
        ...prevState,
        [index]: event.target.value,
      };
    });
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // load data
  useEffect(() => {
    const fetchPlaylistData = async () => {
      try {
        const responseData = await fetchPlaylistDataAPI(
          {
            accountType: userInfo.accountType,
          },
          token,
        );
        setPlaylists(responseData);
      } catch (error) {
        // setError(error.message);
      }
    };

    fetchPlaylistData();
  }, []);

  return (
    <div className="main-upload">
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, mx: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h2" variant="h4" align="center">
          視頻上傳
        </Typography>
        {activeStep === steps.length ? (
          <UploadStatus
            userInfo={userInfo}
            files={files}
            matchData={matchData}
            selectedPlaylists={selectedPlaylists}
          />
        ) : (
          <>
            <VideoFileUploadForm
              setFiles={setFiles}
              files={files}
              matchData={matchData}
              setMatchData={setMatchData}
              playlists={playlists}
              selectedPlaylists={selectedPlaylists}
              handlePlaylistSelect={handlePlaylistSelect}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={() => handleBack()} sx={{ mt: 3, ml: 1 }}>
                  返回
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => handleNext()}
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === steps.length - 1 ? '確認并上傳' : '下一步'}
              </Button>
            </Box>
          </>
        )}
      </Paper>
      <Copyright />
    </div>
  );
};
