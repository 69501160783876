import React from 'react';
import SearchBar from 'src/components/SearchBar/SearchBar';
import HomeFilterOption from 'src/components/FilterPanel/HomeFilterOption';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const HomeNavBar = ({
  setToken,
  setFilterCriteria,
  appliedFilterCriteria,
  CustomTagList,
  userInfo,
  filterStats,
  searchName,
}) => {

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="8px" gap="8px">
      <SearchBar
        player={false}
        setFilterCriteria={setFilterCriteria}
        searchName={searchName}
        style={{ flexGrow: 1 }}
      />

      <HomeFilterOption
        setFilterCriteria={setFilterCriteria}
        appliedFilterCriteria={appliedFilterCriteria}
        CustomTagList={CustomTagList}
        userInfo={userInfo}
        filterStats={filterStats}
        style={{ marginLeft: 'auto' }}
      />
    </Box>
  );
};

export default HomeNavBar;
