import { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import OCRManagement from './OCRMangement';
import DPManagement from './DPManagement';

import './File.scss';

function HomePage(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Back to '}
      <Link color="inherit" href="/">
        Home Page
      </Link>{' '}
      {'.'}
    </Typography>
  );
}

const Files = ({ userInfo }) => {
  const token = Cookies.get('token');
  const [value, setValue] = useState('one');
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [refreshData, setRefreshData] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabContent = () => {
    switch (value) {
      case 'one':
        return <OCRManagement userInfo={userInfo} />;
      case 'two':
        return <DPManagement userInfo={userInfo} />;
      default:
        return null;
    }
  };

  // get player data
  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`/api/usersData`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;
        setUserData(responseData);
        // Extracting an array of usernames from the response data
        const usernames = responseData.map((user) => user.username);
        setUserList(usernames);
      } catch (error) {
        console.log(error);
      }
    };

    if (refreshData) {
      fetchUserList();
      setRefreshData(false);
    }
  }, [refreshData]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab value="one" label="OCR Management" />
        <Tab value="two" label="DP Management" />
      </Tabs>
      <Container
        className="files-management-table"
        sx={{ mt: 4, mb: 4, maxWidth: '100% !important' }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              {renderTabContent()}
            </Paper>
          </Grid>
        </Grid>
        <HomePage sx={{ pt: 4 }} />
      </Container>
    </Box>
  );
};

export default Files;
