// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-snackbar {
  top: 20vh !important;
}
.delete-snackbar .MuiPaper-root {
  height: 30vh;
  width: 30vw;
  background-color: var(--apple-grey);
}
.delete-snackbar .MuiPaper-root div {
  font-size: var(--medium-font-size);
}
.delete-snackbar .MuiPaper-root svg {
  font-size: var(--large-button-size);
}

.edit-other-option-panel {
  display: grid;
  grid-template-columns: 50% 50%;
}
.edit-other-option-panel button {
  font-size: var(--medium-font-size);
}
.edit-other-option-panel button svg {
  font-size: var(--medium-button-size) !important;
}`, "",{"version":3,"sources":["webpack://./src/videoPages/EditVideo/VideoDelete.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;EACA,mCAAA;AAEJ;AADI;EACE,kCAAA;AAGN;AADI;EACE,mCAAA;AAGN;;AACA;EACE,aAAA;EACA,8BAAA;AAEF;AADE;EACE,kCAAA;AAGJ;AAFI;EACE,+CAAA;AAIN","sourcesContent":[".delete-snackbar {\n  top: 20vh !important;\n  .MuiPaper-root {\n    height: 30vh;\n    width: 30vw;\n    background-color: var(--apple-grey);\n    div {\n      font-size: var(--medium-font-size);\n    }\n    svg {\n      font-size: var(--large-button-size);\n    }\n  }\n}\n.edit-other-option-panel {\n  display: grid;\n  grid-template-columns: 50% 50%;\n  button {\n    font-size: var(--medium-font-size);\n    svg {\n      font-size: var(--medium-button-size) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
