import { useState, useEffect, useRef, useMemo } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';

import VideoControlPanel from 'src/components/VideoControlPanel/VideoControlPanel';
import {
  handlePlayPause,
  handleSeekBackward,
  handleSeekForward,
  handleSeekBackwardFrame,
  handleSeekForwardFrame,
  handleSeekBackwardBall,
  handleSeekForwardBall,
} from 'src/utils/videoControl';

import './VideoPlayer.scss';

import {
  roundDataIndex,
  roundStartTimeIndex,
  roundEndTimeIndex,
  highlightDataIndex,
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  transformationXIndex,
  transformationYIndex,
  transformationScaleIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  ballDataIndex,
  deuceIndex,
  criticalPointIndex,
} from 'src/constants';

const tabs = [
  'settingPanel',
  'infoPanel',
  'scorePanel',
  'analysisPanel',
  'highlightPanel',
  'playlistPanel',
];

const VideoPlayer = ({
  source,
  videoRef,
  roundResults,
  isMobile,
  replayData,
  commentaryTimes,
  videoData,
  setVideoData,
  userInfo,
  setCurrentTransformation,
}) => {
  const [showConversionBlock, setShowConversionBlock] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [transformation, setTransformation] = useState({
    positionX: 0,
    positionY: 0,
    scale: 1,
  });
  const [scale, setScale] = useState(1);

  const [videoStatus, setVideoStatus] = useState({
    currentBallStartTime: 0,
    currentBallEndTime: 0,
    currentHLStartTime: 0,
    currentHLEndTime: 0,
    currentBallPlayedTime: 1,
    currentHighlightPlayedTime: 1,
    isHighlight: false,
  });

  const [roundHighlight, setRoundHighlight] = useState([]);

  const zoomPanRef = useRef(null);

  const [highlightNotice, setHighlightNotice] = useState(true);

  const handleTransform = () => {
    if (videoRef.current && zoomPanRef.current) {
      const { setTransform } = zoomPanRef.current;
      // Use setTransform function to set the transformation
      const xValue = transformation.positionX * videoRef.current.clientWidth;
      const yValue =
        transformation.positionY *
        (videoRef.current.clientWidth > videoRef.current.clientHeight
          ? videoRef.current.clientWidth
          : videoRef.current.clientHeight);
      const scaleValue = transformation.scale;
      const animationTime = 1;
      const animationName = 'easeOut';

      setScale(scaleValue);

      setTransform(xValue, yValue, scaleValue, animationTime, animationName);
    }
  };

  const handleVideoLoadedMetadata = () => {
    setIsVideoLoaded(true);
  };

  // keep track of video time
  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    handleTimeBarUpdate();
  };

  // progress bar go to time
  const handleSeek = (event) => {
    const progressBar = document.getElementById('progress-bar');
    const seekTime =
      (event.nativeEvent.offsetX / progressBar.offsetWidth) *
      videoRef.current.duration;
    videoRef.current.currentTime = seekTime;
  };

  // get value for progress bar
  const handleTimeBarUpdate = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      const progressBar = document.getElementById('progress-bar');
      if (progressBar) {
        progressBar.value = (currentTime / duration) * 100;
      }
    }
  };

  const renderOverlay = () => {
    return (
      <div className="button-overlay">
        {userInfo && userInfo.accountType !== 'm' && (
          <>
            <IconButton
              className="overlay-button"
              title="上一个球"
              onClick={() =>
                handleSeekBackwardBall(
                  videoRef,
                  commentaryTimes,
                  roundResults,
                  videoData.currentRound,
                  videoData.currentBall,
                  setVideoData,
                )
              }
            >
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              className="overlay-button"
              title="按帧快退"
              onClick={() => handleSeekBackwardFrame(videoRef, setVideoData)}
            >
              <FastRewindIcon />
            </IconButton>
          </>
        )}
        <IconButton
          className="overlay-button"
          title="退5秒"
          onClick={() => handleSeekBackward(videoRef, setVideoData)}
        >
          <Replay5Icon />
        </IconButton>
        <IconButton
          className="overlay-button"
          onClick={() => handlePlayPause(videoRef, videoData, setVideoData)}
        >
          {videoData.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton
          className="overlay-button"
          title="进5秒"
          onClick={() => handleSeekForward(videoRef, setVideoData)}
        >
          <Forward5Icon />
        </IconButton>
        {userInfo && userInfo.accountType !== 'm' && (
          <>
            <IconButton
              className="overlay-button"
              title="按帧快进"
              onClick={() => handleSeekForwardFrame(videoRef, setVideoData)}
            >
              <FastForwardIcon />
            </IconButton>
            <IconButton
              className="overlay-button"
              title="下一个球"
              onClick={() =>
                handleSeekForwardBall(
                  videoRef,
                  commentaryTimes,
                  roundResults,
                  videoData.currentRound,
                  videoData.currentBall,
                  setVideoData,
                )
              }
            >
              <SkipNextIcon />
            </IconButton>
          </>
        )}
      </div>
    );
  };

  const renderVideoControl = () => {
    return (
      <div className="video-control">
        <VideoControlPanel
          videoRef={videoRef}
          barTime={currentTime}
          handleSeek={handleSeek}
          tab={tabs[videoData.tabIndex]}
          roundResults={roundResults}
          videoData={videoData}
          setVideoData={setVideoData}
          commentaryTimes={commentaryTimes}
          scale={scale}
          setScale={setScale}
          onZoomChange={(newScale) => {
            if (zoomPanRef.current) {
              zoomPanRef.current.setTransform(0, 0, newScale);
            }
          }}
        />
      </div>
    );
  };

  const renderHighlightNotice = () => {
    const handleDisablePov = () => {
      setVideoData((prevState) => ({
        ...prevState,
        isCoachPOV: false,
      }));
      setHighlightNotice(false);
    };

    const shouldRender = () => {
      for (const timePair of roundHighlight) {
        const startTime = timePair[0];
        const timeDiff = (startTime - currentTime).toFixed(1);

        if (timeDiff > 0 && timeDiff < 10) {
          return (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={highlightNotice}
            >
              <Alert
                severity="success"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {timeDiff}秒後，教練亮點。
                <Button onClick={handleDisablePov}>停用教練亮點</Button>
              </Alert>
            </Snackbar>
          );
        }
      }

      return null;
    };

    return shouldRender();
  };

  useEffect(() => {
    let interval;
    const timer = setTimeout(() => {
      if (!isVideoLoaded) {
        setShowConversionBlock(true);
      }
    }, 10000);

    if (isVideoLoaded) {
      interval = setInterval(handleTimeUpdate, 50);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [isVideoLoaded]);

  // get current ball
  useEffect(() => {
    let matchedRoundIndex;
    var shouldUpdate = false;
    var shouldLoopBall = false;
    var shouldLoopHL = false;
    const videoUpdateInterval = 0.15;

    const updateRoundHighlightTime = (matchedRoundIndex) => {
      let allRoundHighlight = [];
      for (
        let hIndex = 0;
        hIndex < roundResults[matchedRoundIndex][highlightDataIndex].length;
        hIndex++
      ) {
        const highlightStartTime =
          roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
            highlightStartTimeIndex
          ];
        const highlightEndTime =
          roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
            highlightEndTimeIndex
          ];
        allRoundHighlight.push([highlightStartTime, highlightEndTime]);
      }
      setRoundHighlight(allRoundHighlight);
    };

    //set video first played
    if (videoData.played === false && currentTime > 0.001) {
      setVideoData((prevData) => ({
        ...prevData,
        played: true,
      }));
    }

    if (
      currentTime > videoStatus.currentBallEndTime ||
      currentTime < videoStatus.currentHLStartTime ||
      currentTime > videoStatus.currentHLEndTime ||
      currentTime < videoStatus.currentBallStartTime
    ) {
      shouldUpdate = true;
    }

    if (
      videoStatus.currentBallEndTime !== 0 &&
      replayData.ballReplayTimes != 1 &&
      videoStatus.currentBallEndTime - videoRef.current.currentTime >= 0 &&
      videoStatus.currentBallEndTime - videoRef.current.currentTime <=
        videoUpdateInterval &&
      videoStatus.isHighlight === false
    ) {
      shouldLoopBall = true;
    }

    if (
      videoStatus.isHighlight === true &&
      replayData.highlightReplayTimes != 1 &&
      videoStatus.currentHLEndTime - videoRef.current.currentTime >= 0 &&
      videoStatus.currentHLEndTime - videoRef.current.currentTime <=
        videoUpdateInterval
    ) {
      shouldLoopHL = true;
    }

    if (videoData.skipped === true) {
      setVideoData((prevState) => ({
        ...prevState,
        skipped: false,
      }));
      setVideoStatus((prevStatus) => ({
        ...prevStatus,
        currentBallPlayedTime: 1,
      }));
    }

    const updateRound = () => {
      return new Promise((resolve, reject) => {
        if (roundResults && roundResults.length > 0) {
          if (
            currentTime <= roundResults[0][roundDataIndex][roundStartTimeIndex]
          ) {
            matchedRoundIndex = 0;
          } else {
            for (
              let roundIndex = 0;
              roundIndex < roundResults.length;
              roundIndex++
            ) {
              const roundStartTime =
                roundResults[roundIndex][roundDataIndex][roundStartTimeIndex];
              const roundEndTime =
                roundResults[roundIndex][roundDataIndex][roundEndTimeIndex];
              if (currentTime >= roundStartTime) {
                matchedRoundIndex = roundIndex;
                if (currentTime <= roundEndTime) {
                  break;
                }
              }
            }
          }
        }

        if (matchedRoundIndex !== -1) {
          resolve(matchedRoundIndex);
        } else {
          reject(new Error('No matching round found.'));
        }
      });
    };

    const updateHighlight = (matchedRoundIndex) => {
      return new Promise((resolve, reject) => {
        if (roundResults[matchedRoundIndex] && matchedRoundIndex !== -1) {
          let matchHighlightIndex = -1;

          for (
            let hIndex = 0;
            hIndex < roundResults[matchedRoundIndex][highlightDataIndex].length;
            hIndex++
          ) {
            const highlightStartTime =
              roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
                highlightStartTimeIndex
              ];
            const highlightEndTime =
              roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
                highlightEndTimeIndex
              ];
            if (
              currentTime >= highlightStartTime &&
              currentTime <= highlightEndTime
            ) {
              matchHighlightIndex = hIndex;

              const x =
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][1][0][transformationXIndex];
              const y =
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][1][0][transformationYIndex];
              const scale =
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][1][0][transformationScaleIndex];
              setTransformation({ positionX: x, positionY: y, scale: scale });
            }
          }
          if (
            matchHighlightIndex === -1 &&
            !(
              transformation.positionX === 0 &&
              transformation.positionY === 0 &&
              transformation.scale === 1
            )
          ) {
            setTransformation({ positionX: 0, positionY: 0, scale: 1 });
          } else if (
            matchHighlightIndex === -1 &&
            videoStatus.isHighlight === true
          ) {
            setVideoStatus((prevStatus) => ({
              ...prevStatus,
              isHighlight: false,
            }));
          } else if (matchHighlightIndex !== -1) {
            setVideoStatus((prevStatus) => ({
              ...prevStatus,
              isHighlight: true,
              currentHLStartTime:
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][0][highlightStartTimeIndex],
              currentHLEndTime:
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][0][highlightEndTimeIndex],
            }));
          }

          resolve();
        } else {
          reject(new Error('No matching highlight found.'));
        }
      });
    };

    const updateBall = (matchedRoundIndex) => {
      return new Promise((resolve, reject) => {
        const updateRoundBall = () => {
          let matchBallIndex = -1;
          for (
            let ballIndex = 0;
            ballIndex < roundResults[matchedRoundIndex][ballDataIndex].length;
            ballIndex++
          ) {
            const ballStartTime =
              roundResults[matchedRoundIndex][ballDataIndex][ballIndex][
                ballStartTimeIndex
              ];
            const ballEndTime =
              roundResults[matchedRoundIndex][ballDataIndex][ballIndex][
                ballEndTimeIndex
              ];
            if (currentTime >= ballStartTime) {
              matchBallIndex = ballIndex;
              if (currentTime < ballEndTime) {
                break;
              }
            }
          }
          if (videoData.currentRound !== matchedRoundIndex) {
            setVideoData((prevState) => ({
              ...prevState,
              currentRound: matchedRoundIndex,
            }));
          }
          if (videoData.currentBall !== matchBallIndex) {
            setVideoData((prevState) => ({
              ...prevState,
              currentBall: matchBallIndex,
            }));

            if (
              roundResults[matchedRoundIndex][ballDataIndex][matchBallIndex]
            ) {
              setVideoStatus((prevStatus) => ({
                ...prevStatus,
                currentBallStartTime:
                  roundResults[matchedRoundIndex][ballDataIndex][
                    matchBallIndex
                  ][ballStartTimeIndex],
                currentBallEndTime:
                  roundResults[matchedRoundIndex][ballDataIndex][
                    matchBallIndex
                  ][ballEndTimeIndex],
              }));
            }
          }
        };

        if (matchedRoundIndex !== -1) {
          if (
            shouldUpdate === true &&
            shouldLoopBall === false &&
            shouldLoopHL === false
          ) {
            updateRoundBall();
          } else {
            if (shouldLoopHL === true) {
              // if user skip to 1s after end or 1s before start or loop times finished
              if (
                videoStatus.currentHighlightPlayedTime ==
                  replayData.highlightReplayTimes &&
                replayData != 1
              ) {
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentHighlightPlayedTime: 1,
                }));
                videoRef.current.currentTime =
                  videoStatus.currentHLEndTime + 0.01;
              } else {
                videoRef.current.currentTime =
                  videoStatus.currentHLStartTime + 0.01;
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentHighlightPlayedTime:
                    videoStatus.currentHighlightPlayedTime + 1,
                }));
              }
            } else if (shouldLoopBall === true) {
              // check if current ball us deuce or critical point
              const matchedDeuce =
                roundResults[videoData.currentRound]?.[ballDataIndex]?.[
                  videoData.currentBall
                ]?.[deuceIndex] || false;
              const matchedCP =
                roundResults[videoData.currentRound]?.[ballDataIndex]?.[
                  videoData.currentBall
                ]?.[criticalPointIndex] || false;

              if (
                videoStatus.currentBallPlayedTime == replayData.ballReplayTimes
              ) {
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentBallPlayedTime: 1,
                }));
                videoRef.current.currentTime =
                  videoStatus.currentBallEndTime + 0.01;
              } else if (
                (replayData.replayDeuce && matchedDeuce) ||
                (replayData.replayCriticalPoint && matchedCP) ||
                (!replayData.replayDeuce && !replayData.replayCriticalPoint)
              ) {
                videoRef.current.currentTime =
                  videoStatus.currentBallStartTime + 0.01;
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentBallPlayedTime: videoStatus.currentBallPlayedTime + 1,
                }));
              }
            }
          }
          resolve();
        } else {
          reject(new Error('No matching ball found.'));
        }
      });
    };

    updateRound()
      .then(() => {
        updateRoundHighlightTime(matchedRoundIndex);
        return updateHighlight(matchedRoundIndex);
      })
      .then(() => {
        return updateBall(matchedRoundIndex);
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle any errors that occurred during the update process
      });
  }, [currentTime]);

  useEffect(() => {
    if (videoData.isCoachPOV === true) {
      handleTransform();
    }
  }, [transformation]);

  useMemo(() => {
    if (videoData.isCoachPOV === false && zoomPanRef.current) {
      const animationTime = 1;
      const animationName = 'easeOut';
      const { setTransform } = zoomPanRef.current;

      setTransform(0, 0, 1, animationTime, animationName);
    }
  }, [videoData.isCoachPOV]);

  const handleZoom = (event) => {
    if (zoomPanRef.current && event.state) {
      setScale(event.state.scale);
    }
  };

  return (
    <div
      className={
        videoData.isFs
          ? 'video-panel fs'
          : `video-panel ${tabs[videoData.tabIndex]}`
      }
    >
      <div className="pov-video-player-container">
        {!isVideoLoaded &&
          (showConversionBlock ? (
            // <ConversionBlock />
            <div className="convert-button">
              <p>無法下載視頻...</p>
            </div>
          ) : (
            <div className="convert-button">
              <p>視頻下載中...</p>
              <CircularProgress />
            </div>
          ))}
        <TransformWrapper
          onZoom={handleZoom}
          disablePadding={true}
          ref={zoomPanRef}
        >
          <TransformComponent>
            <video
              ref={videoRef}
              className="video-player"
              preload="auto"
              {...(isMobile && { playsInline: true })}
              onLoadedMetadata={handleVideoLoadedMetadata}
            >
              <source src={`${source}#t=0.001`} type="video/mp4" />
            </video>
          </TransformComponent>
        </TransformWrapper>
        {videoData.isCoachPOV && renderHighlightNotice()}
        {renderOverlay()}
        {renderVideoControl()}
      </div>
    </div>
  );
};

export default VideoPlayer;
