import { JSX } from 'react';
import { LineChart } from '@mui/x-charts';

import Title from './Title';

import './Chart.scss';

export default function activitiesChart({
  xAxis,
  series,
  chartHeight,
  chartWidth,
  DBFilterCriteria,
}: {
  xAxis: Array<string>;
  series: Array<{ [key: string]: [number] }>;
  chartHeight: number;
  chartWidth: number;
  DBFilterCriteria: {
    userName: string[];
    dataDate: string;
    metrics: string[];
  };
}): JSX.Element {
  const convertedxAxis =
    xAxis && xAxis.map((dateString: string) => new Date(dateString));

  const colors: { [key: string]: string } = {
    views: '#1f77b4',
    'log in': '#ff7f0e',
    upload: '#2ca02c',
  };

  const minStep =
    DBFilterCriteria && DBFilterCriteria.dataDate === 'CYear'
      ? 30 * 3600 * 1000 * 24
      : 3600 * 1000 * 24;

  return (
    <div className="chart-container">
      <Title>Usage</Title>
      <div className="chart-panel">
        {xAxis && series && xAxis.length > 0 ? (
          <LineChart
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'top', horizontal: 'right' },
                padding: 0,
                itemMarkWidth: 13,
                itemMarkHeight: 5,
                markGap: 5,
                itemGap: 4,
              },
            }}
            xAxis={[
              {
                scaleType: 'time',
                data: convertedxAxis,
                tickMinStep: minStep, // min step: 24h
                min: convertedxAxis[0],
                max: convertedxAxis[convertedxAxis.length - 1],
              },
            ]}
            yAxis={[{ label: 'count' }]}
            series={series.map((data, index) => ({
              data: Object.values(data)[0],
              key: `Series ${index + 1}`,
              label: Object.keys(data)[0],
              color: colors[Object.keys(data)[0]],
              showMark: false,
            }))}
            width={chartWidth}
            height={chartHeight}
            margin={{ top: 20, bottom: 50, right: 100, left: 50 }}
            sx={{
              '& .MuiLineElement-root': {
                strokeWidth: 1.5,
              },
              '& .MuiAreaElement-series-Germany': {
                fill: "url('#myGradient')",
              },
            }}
          />
        ) : (
          <p>No data</p>
        )}
      </div>
    </div>
  );
}
