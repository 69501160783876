import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import VisGraph, { GraphEvents, Options } from 'react-vis-graph-wrapper';
import {
  Box,
  Tab,
  Tabs,
  Paper,
  CircularProgress,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MenuIcon from '@mui/icons-material/Menu';
import useFetchVideoData from '../../api/useFetchVideoData';
import DrawerComponent from '../../components/PlayerGraph/DrawerComponent';
import MenuComponent from '../../components/PlayerGraph/MenuComponent';
import FilterComponent from '../../components/PlayerGraph/FilterComponent';
import {
  GraphData as VisGraphData,
  CompetitionData,
  PlayerData,
  Node,
  Edge,
  GraphEdges,
  GraphComponentProps,
  VideoData,
} from '../../types/GraphTypes';

const GraphComponent: React.FC<GraphComponentProps> = ({
  userInfo,
  setToken,
}) => {
  const [data, setData] = useState<CompetitionData | undefined>();
  const [multiGraphData, setMultiGraphData] = useState<VisGraphData>({
    nodes: [],
    edges: [],
  });
  const [singleGraphData, setSingleGraphData] = useState<VisGraphData>({
    nodes: [],
    edges: [],
  });
  const [maleSingleGraphData, setMaleSingleGraphData] = useState<VisGraphData>({
    nodes: [],
    edges: [],
  });
  const [femaleSingleGraphData, setFemaleSingleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });
  const [maleDoubleGraphData, setMaleDoubleGraphData] = useState<VisGraphData>({
    nodes: [],
    edges: [],
  });
  const [femaleDoubleGraphData, setFemaleDoubleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });
  const [mixedDoubleGraphData, setMixedDoubleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });

  const [initMultiGraphData, setInitMultiGraphData] = useState<VisGraphData>({
    nodes: [],
    edges: [],
  });
  const [initSingleGraphData, setInitSingleGraphData] = useState<VisGraphData>({
    nodes: [],
    edges: [],
  });
  const [initMaleSingleGraphData, setInitMaleSingleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });
  const [initFemaleSingleGraphData, setInitFemaleSingleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });
  const [initMaleDoubleGraphData, setInitMaleDoubleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });
  const [initFemaleDoubleGraphData, setInitFemaleDoubleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });
  const [initMixedDoubleGraphData, setInitMixedDoubleGraphData] =
    useState<VisGraphData>({ nodes: [], edges: [] });

  const [loading, setLoading] = useState<boolean>(true);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [videoData, setVideoData] = useState<VideoData[] | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | {
    mouseX: number;
    mouseY: number;
  }>(null);
  const [selectedNodeData, setSelectedNodeData] = useState<any>(null);
  const [selectedDepth, setSelectedDepth] = useState<number>(-1); // -1 represents 'all'
  const [customDepth, setCustomDepth] = useState<string>('');
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<number[]>([]);
  const [localselectedNode, setLocalSelectedNode] = useState<any>(null); // Selected node id

  const highlightNames_M = [
    'WONG CHUN TING',
    'HO KWAN KIT',
    'LAM SIU HANG',
    'CHAN BALDWIN',
  ];
  const highlightNames_F = [
    'LAM YEE LOK',
    'DOO HOI KEM',
    'ZHU CHENGZHU',
    'LEE HO CHING',
    'NG WING LAM',
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isHorizontalMobile = useMediaQuery(
    '(max-width: 767px) and (orientation: landscape)',
  );

  const token = Cookies.get('token');

  useFetchVideoData(setVideoData, userInfo);

  useEffect(() => {
    const fetchData = async () => {
      // "COM_FSingle": COM_FSingle,
      // "COM_FDouble": COM_FDouble,
      // "COM_MSingle": COM_MSingle,
      // "COM_MDouble": COM_MDouble,
      // "COM_MixedDouble": COM_MixedDouble,
      try {
        const response = await axios.get('/api/videoGraph', {
          headers: {
            Authorization: `${token}`,
          },
        });
        const jsonData = response.data;
        if (
          !jsonData.name_lists ||
          !jsonData.COM_multi_player ||
          !jsonData.COM_single_player
        ) {
          console.error('Data is missing expected fields');
          return;
        }
        if (
          jsonData.COM_FSingle &&
          jsonData.COM_FDouble &&
          jsonData.COM_MSingle &&
          jsonData.COM_MDouble &&
          jsonData.COM_MixedDouble
        ) {
          console.log('extend data');
        }

        setData(jsonData);
        transformDataToGraphs(jsonData);
        setLoading(false);
      } catch (error) {
        if (
          (error as any).response &&
          ((error as any).response.status === 404 ||
            (error as any).response.status === 500)
        ) {
          fetch('/BEData.json')
            .then((response) => {
              if (!response.ok)
                throw new Error(`HTTP error! status: ${response.status}`);
              return response.json();
            })
            .then((jsonData) => {
              if (
                !jsonData.name_lists ||
                !jsonData.COM_multi_player ||
                !jsonData.COM_single_player
              ) {
                console.error('Data is missing expected fields');
                return;
              }
              if (
                jsonData.COM_FSingle &&
                jsonData.COM_FDouble &&
                jsonData.COM_MSingle &&
                jsonData.COM_MDouble &&
                jsonData.COM_MixedDouble
              ) {
                console.log('extend data');
              }
              setData(jsonData);
              transformDataToGraphs(jsonData);
              setLoading(false);
            })
            .catch((e) => {
              console.error('Error loading data:', e);
              setLoading(false);
            });
        } else {
          console.error('Error loading data:', error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [token]);

  const getColor = (
    name: string,
    maleHighlightNames: string[],
    femaleHighlightNames: string[],
  ): string => {
    if (
      maleHighlightNames.some((substring) => name.includes(substring)) &&
      femaleHighlightNames.some((substring) => name.includes(substring))
    ) {
      return '#FFD700'; // Gold color for mixed doubles
    } else if (
      maleHighlightNames.some((substring) => name.includes(substring))
    ) {
      return '#79acf2'; // Blue color for male players in the highlight list
    } else if (
      femaleHighlightNames.some((substring) => name.includes(substring))
    ) {
      return 'red'; // Red color for female players in the highlight list
    }
    return '#D2E5FF'; // Default color for other players
  };

  const createEdges = (graphData: PlayerData, nodes: Node[]): GraphEdges => {
    const edges: Edge[] = [];
    const connectedNodeIds: Set<string> = new Set();
    const playerIds: string[] = Object.keys(graphData);
    const edgeMap: Map<string, number> = new Map();

    for (let i = 0; i < playerIds.length; i++) {
      for (let j = i + 1; j < playerIds.length; j++) {
        const sharedCompetitions: number[] = graphData[playerIds[i]].filter(
          (id: number) => graphData[playerIds[j]].includes(id),
        );
        if (sharedCompetitions.length > 0) {
          const edgeKey = `${playerIds[i]}-${playerIds[j]}-${sharedCompetitions}`;
          edgeMap.set(
            edgeKey,
            (edgeMap.get(edgeKey) || 0) + sharedCompetitions.length,
          );
          connectedNodeIds.add(playerIds[i]);
          connectedNodeIds.add(playerIds[j]);
        }
      }
    }

    edgeMap.forEach((value, key) => {
      const [from, to, competitions] = key.split('-');
      const width = (Math.ceil(Math.min(value, 20)) / 20) * 8; //  value  Math.min(value, 25) / 25

      edges.push({
        from,
        to,
        length: 200,
        width,
        label: value === 1 ? '' : value.toString(),
        videoId: competitions,
      });
    });

    const connectedNodes: Node[] = nodes.filter((node: Node) =>
      connectedNodeIds.has(String(node.id)),
    );
    return { edges, connectedNodes };
  };

  const createAndSetGraphData = (
    compDataKey: PlayerData,
    allNodes: Node[],
    setGraphData: React.Dispatch<React.SetStateAction<VisGraphData>>,
    setInitGraphData: React.Dispatch<React.SetStateAction<VisGraphData>>,
    initGraphData: VisGraphData,
  ) => {
    const edges = createEdges(compDataKey, allNodes);
    setGraphData({ nodes: edges.connectedNodes, edges: edges.edges });
    if (!initGraphData.nodes.length) {
      setInitGraphData({ nodes: edges.connectedNodes, edges: edges.edges });
    }
  };

  const transformDataToGraphs = (compData: CompetitionData) => {
    const allNodes = Object.keys(compData.name_lists).map((key) => ({
      id: key,
      label: compData.name_lists[key],
      title: `Tooltip for ${compData.name_lists[key]}`,
      color: getColor(
        compData.name_lists[key],
        highlightNames_M,
        highlightNames_F,
      ),
      initColor: getColor(
        compData.name_lists[key],
        highlightNames_M,
        highlightNames_F,
      ),
    }));

    createAndSetGraphData(
      compData.COM_multi_player,
      allNodes,
      setMultiGraphData,
      setInitMultiGraphData,
      initMultiGraphData,
    );
    createAndSetGraphData(
      compData.COM_single_player,
      allNodes,
      setSingleGraphData,
      setInitSingleGraphData,
      initSingleGraphData,
    );
    createAndSetGraphData(
      compData.COM_MSingle,
      allNodes,
      setMaleSingleGraphData,
      setInitMaleSingleGraphData,
      initMaleSingleGraphData,
    );
    createAndSetGraphData(
      compData.COM_FSingle,
      allNodes,
      setFemaleSingleGraphData,
      setInitFemaleSingleGraphData,
      initFemaleSingleGraphData,
    );
    createAndSetGraphData(
      compData.COM_MDouble,
      allNodes,
      setMaleDoubleGraphData,
      setInitMaleDoubleGraphData,
      initMaleDoubleGraphData,
    );
    createAndSetGraphData(
      compData.COM_FDouble,
      allNodes,
      setFemaleDoubleGraphData,
      setInitFemaleDoubleGraphData,
      initFemaleDoubleGraphData,
    );
    createAndSetGraphData(
      compData.COM_MixedDouble,
      allNodes,
      setMixedDoubleGraphData,
      setInitMixedDoubleGraphData,
      initMixedDoubleGraphData,
    );
  };

  const findNeighborsAtDepth = (
    graph: VisGraphData,
    nodeId: string,
    maxDepth: number,
  ): { nodes: string[]; edges: Edge[]; details: any[] } => {
    const visitedNodes = new Set<string>();
    const visitedEdges = new Set<Edge>();
    const resultNodes: string[] = [];
    const resultEdges: Edge[] = [];
    const details: any[] = [];
    const queue = [{ node: nodeId, depth: 0 }];

    while (queue.length > 0) {
      const { node, depth: currentDepth } = queue.shift()!;

      if (currentDepth > maxDepth) continue;

      if (!visitedNodes.has(node)) {
        visitedNodes.add(node);
        resultNodes.push(node);

        const neighbors = graph.edges
          .filter((edge) => {
            if (
              (edge.from === node || edge.to === node) &&
              currentDepth < maxDepth
            ) {
              visitedEdges.add(edge);
              const neighbor = edge.from === node ? edge.to : edge.from;
              details.push({
                from: node,
                to: neighbor,
                depth: currentDepth + 1,
                videoId: edge.videoId,
              });
              return !visitedNodes.has(String(neighbor));
            }
            return false;
          })
          .map((edge) => (edge.from === node ? edge.to : edge.from));

        neighbors.forEach((neighbor) =>
          queue.push({ node: String(neighbor), depth: currentDepth + 1 }),
        );
      }
    }

    return { nodes: resultNodes, edges: Array.from(visitedEdges), details };
  };

  const handleNodeClick = useCallback(
    (event: any) => {
      const { nodes } = event;
      console.log('--------------------------------');
      console.log('Selected nodes:', nodes);

      if (nodes.length > 0) {
        const selectedNode = nodes[0];
        setLocalSelectedNode(nodes);
        console.log('localselectedNode node:', localselectedNode);
        if (checkSelectedOtherNode(selectedNode)) {
          console.log('changed node!!!');
          handleReset();
        }

        const depth =
          selectedDepth === -1 ? Number.MAX_SAFE_INTEGER : selectedDepth; // Handle 'all' case
        const graphData = getGraphDataByTab(activeTab);

        const {
          nodes: neighborNodes,
          edges: neighborEdges,
          details,
        } = findNeighborsAtDepth(graphData, selectedNode, depth);

        const selectedNodeDetails = {
          nodeId: selectedNode,
          nodeName: data?.name_lists[selectedNode] || selectedNode,
          neighbors: neighborNodes,
          details,
        };

        console.log(
          'Selected node details with neighbors:',
          selectedNodeDetails,
        );

        setSelectedNodeData(selectedNodeDetails);
        setMenuAnchor({
          mouseX: event.event.center.x,
          mouseY: event.event.center.y,
        });

        const updatedGraphData = {
          nodes: graphData.nodes.map((node) => ({
            ...node,
            color: neighborNodes.includes(String(node.id))
              ? node.initColor
              : 'rgba(211,211,211,0.5)', // Set to transparent if not in neighbors
          })),
          edges: graphData.edges.map((edge) => ({
            ...edge,
            color: neighborEdges.includes(edge)
              ? '#000000'
              : 'rgba(211,211,211,0.5)', // Set to transparent if not in neighbors
          })),
        };

        setGraphDataByTab(activeTab, updatedGraphData);
      }
      console.log('--------------------------------');
    },
    [
      activeTab,
      data,
      multiGraphData,
      singleGraphData,
      maleSingleGraphData,
      femaleSingleGraphData,
      maleDoubleGraphData,
      femaleDoubleGraphData,
      mixedDoubleGraphData,
      selectedDepth,
    ],
  );

  const updateGraphTransparency = useCallback(
    (
      depth: number,
      selectedNode?: string,
      selectedGraphPlayerIds?: number[],
    ) => {
      const graphData = activeTab === 0 ? multiGraphData : singleGraphData;
      console.log(
        'Updating graph transparency with depth: updateGraphTransparency',
      );

      if (
        !selectedNode &&
        (!selectedGraphPlayerIds || selectedGraphPlayerIds.length === 0)
      )
        return;

      const { nodes: neighborNodes } = findNeighborsAtDepth(
        graphData,
        selectedNode || '',
        depth,
      );
      const updatedGraphData = {
        nodes: graphData.nodes.map((node) => ({
          ...node,
          color:
            (selectedGraphPlayerIds &&
              selectedGraphPlayerIds.includes(parseInt(String(node.id)))) ||
            neighborNodes.includes(String(node.id))
              ? node.initColor
              : 'rgba(211,211,211,0.5)', // Set to transparent if not in neighbors
        })),
        edges: graphData.edges.map((edge) => ({
          ...edge,
          color:
            (selectedGraphPlayerIds &&
              selectedGraphPlayerIds.includes(parseInt(String(edge.from))) &&
              selectedGraphPlayerIds.includes(parseInt(String(edge.to)))) ||
            (neighborNodes.includes(String(edge.from)) &&
              neighborNodes.includes(String(edge.to)))
              ? '#000000'
              : 'rgba(211,211,211,0.5)', // Set to transparent if not in neighbors
        })),
      };

      if (activeTab === 0) {
        setMultiGraphData(updatedGraphData);
      } else {
        setSingleGraphData(updatedGraphData);
      }
    },
    [multiGraphData, singleGraphData, activeTab],
  );

  const handleCustomDepthSubmit = useCallback(() => {
    if (!isNaN(parseInt(customDepth))) {
      setSelectedDepth(parseInt(customDepth));
      updateGraphTransparency(parseInt(customDepth), localselectedNode);
    }
  }, [customDepth, updateGraphTransparency, localselectedNode]);

  const handleResetColor = useCallback(() => {
    console.log('Resetting handleResetColor colors');
    transformDataToGraphs(data!);
  }, [data]);

  const handleReset = useCallback(() => {
    console.log('Resetting graph colors');
    transformDataToGraphs(data!);
  }, [data]);

  const checkSelectedOtherNode = (curr_node: string) => {
    if (localselectedNode) {
      if (localselectedNode[0] != curr_node) {
        return true;
      }
    }
    return false;
  };

  const handleSelectedPlayersChange = useCallback(
    (selectedPlayers: number[]) => {
      console.log('selectedPlayers', selectedPlayers);
      setSelectedPlayerIds(selectedPlayers);
      updateGraphTransparency(selectedDepth, undefined, selectedPlayers);
    },
    [selectedDepth, updateGraphTransparency],
  );

  const events: GraphEvents = {
    select: handleNodeClick,
    deselectNode: handleResetColor, // Add this to handle clicking on the white area
  };

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
      handleDrawerClose();
    },
    [],
  );

  const toggleFilter = useCallback(() => {
    setFilterOpen(!filterOpen);
  }, [filterOpen]);

  const toggleFullScreen = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const options: Options = {
    layout: { hierarchical: false },
    edges: {
      color: '#000000',
      font: {
        size: 16,
        align: 'top',
        strokeWidth: 3,
        strokeColor: '#ffffff',
        background: '#f4f4f4',
      },
      arrows: {
        to: { enabled: false, scaleFactor: 1.2 },
        from: { enabled: false, scaleFactor: 1.2 },
      },
      smooth: { enabled: true, type: 'dynamic', roundness: 0.5 },
    },
    nodes: {
      shape: 'dot',
      size: 20,
      color: { border: '#2B7CE9', background: '#97C2FC' },
      font: { size: 15, color: '#343434' },
    },
    height: '750px',
  };

  const setGraphDataByTab = (
    tabIndex: number,
    updatedGraphData: VisGraphData,
  ) => {
    switch (tabIndex) {
      case 0:
        setMultiGraphData(updatedGraphData);
        break;
      case 1:
        setSingleGraphData(updatedGraphData);
        break;
      case 2:
        setMaleSingleGraphData(updatedGraphData);
        break;
      case 3:
        setFemaleSingleGraphData(updatedGraphData);
        break;
      case 4:
        setMaleDoubleGraphData(updatedGraphData);
        break;
      case 5:
        setFemaleDoubleGraphData(updatedGraphData);
        break;
      case 6:
        setMixedDoubleGraphData(updatedGraphData);
        break;
      default:
        setMultiGraphData(updatedGraphData);
        break;
    }
  };

  const getGraphDataByTab = (tabIndex: number): VisGraphData => {
    switch (tabIndex) {
      case 0:
        return multiGraphData;
      case 1:
        return singleGraphData;
      case 2:
        return maleSingleGraphData;
      case 3:
        return femaleSingleGraphData;
      case 4:
        return maleDoubleGraphData;
      case 5:
        return femaleDoubleGraphData;
      case 6:
        return mixedDoubleGraphData;
      default:
        return multiGraphData;
    }
  };

  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1', mt: 0 }}>
        <Paper
          elevation={3}
          sx={{
            width: '100vw',
            position: 'relative',
            ...(fullScreen && {
              width: '100vw',
              height: '100vh',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1200,
            }),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 0,
            }}
          >
            {isMobile || isHorizontalMobile ? (
              <IconButton onClick={handleDrawerOpen} color="primary">
                <MenuIcon />
              </IconButton>
            ) : (
              <Box>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  centered
                  variant="fullWidth"
                  sx={{ '.MuiTab-root': { minWidth: 0, flex: 1 } }}
                >
                  {[
                    '全部雙打',
                    '全部單打',
                    '男單打',
                    '女單打',
                    '男雙打',
                    '女雙打',
                    '混合雙打',
                  ].map((text, index) => (
                    <Tab
                      key={text}
                      label={
                        <Typography
                          variant={isSmallScreen ? 'subtitle2' : 'h6'}
                          noWrap
                        >
                          {text}
                        </Typography>
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            )}
            <Box>
              <IconButton onClick={toggleFullScreen} color="primary">
                {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
            </Box>
          </Box>
          <FilterComponent
            selectedDepth={selectedDepth}
            setSelectedDepth={setSelectedDepth}
            customDepth={customDepth}
            setCustomDepth={setCustomDepth}
            handleCustomDepthSubmit={handleCustomDepthSubmit}
            handleReset={handleReset}
            onSelectedPlayersChange={handleSelectedPlayersChange}
            userInfo={userInfo}
            setToken={setToken}
          />
          <DrawerComponent
            drawerOpen={drawerOpen}
            handleDrawerClose={handleDrawerClose}
            handleTabChange={handleTabChange}
          />
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <VisGraph
              graph={getGraphDataByTab(activeTab)}
              options={options}
              events={events}
              getNetwork={(network: any) =>
                console.log(
                  `Network ${activeTab === 0 ? 'Multi' : 'Single'}:`,
                  network,
                )
              }
            />
          )}
        </Paper>
      </Box>

      <MenuComponent
        menuAnchor={menuAnchor}
        handleMenuClose={handleMenuClose}
        selectedNodeData={selectedNodeData}
        videoData={videoData}
        jsonData={data}
      />
    </div>
  );
};

export default GraphComponent;
