import {
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';

const getLastScore = (roundResults, roundIndex) => {
  const round = roundResults[roundIndex];
  if (round && round[1] && round[1].length > 0) {
    const mappedBallData = round[1];
    const lastBall = mappedBallData[mappedBallData.length - 1];
    return `${lastBall[ballHomePlayerScoreIndex]}:${lastBall[ballAwayPlayerScoreIndex]}`;
  }
  return 'N/A';
};

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export { formatTime, getLastScore };
