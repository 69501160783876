// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-home-searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
}
.player-home-searchbar div {
  padding: var(--xxsmall-font-size);
}
.player-home-searchbar .player-sort-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
}
.player-home-searchbar .player-sort-container .player-sort-panel {
  background-color: var(--apple-lightgrey);
}
.player-home-searchbar .player-sort-container .player-sort-panel svg {
  color: black;
}

@media (orientation: portrait) {
  .player-home-searchbar {
    display: flex;
    justify-content: space-around;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PlayerSearchBar/PlayerSearchBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,WAAA;AACF;AACE;EACE,iCAAA;AACJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,wBAAA;EAAA,mBAAA;AAAJ;AAEI;EACE,wCAAA;AAAN;AAEM;EACE,YAAA;AAAR;;AAMA;EACE;IACE,aAAA;IACA,6BAAA;EAHF;AACF","sourcesContent":[".player-home-searchbar {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  justify-items: center;\n  width: 100%;\n\n  div {\n    padding: var(--xxsmall-font-size);\n  }\n\n  .player-sort-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: fit-content;\n\n    .player-sort-panel {\n      background-color: var(--apple-lightgrey);\n\n      svg {\n        color: black;\n      }\n    }\n  }\n}\n\n@media (orientation: portrait) {\n  .player-home-searchbar {\n    display: flex;\n    justify-content: space-around;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
