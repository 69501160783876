// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-match-container {
  display: flex;
  flex-direction: column;
}
.highlight-match-container .MuiSvgIcon-root {
  font-size: var(--large-font-size);
}
.highlight-match-container .players-info {
  color: var(--dark-red);
  font-size: var(--medium-font-size);
  text-align: center;
}
.highlight-match-container .edit-highlight-video-data {
  width: 98%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}
.highlight-match-container .edit-highlight-video-data .match-info, .highlight-match-container .edit-highlight-video-data .date-location {
  font-size: var(--small-font-size);
}

.edit-h-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 2%;
  overflow-y: auto;
  font-size: var(--medium-font-size);
}
.edit-h-block .MuiFormControl-root {
  width: 96%;
  margin-bottom: 5%;
}
.edit-h-block .edit-h-round {
  background-color: var(--grey);
  margin-top: 0;
  width: 98%;
  margin-bottom: 5%;
  padding-bottom: 2%;
  text-align: center;
  font-weight: 400;
  color: black;
  font-size: var(--medium-font-size);
  cursor: pointer;
}
.edit-h-block .edit-h-round.selected {
  background-color: var(--apple-green);
}`, "",{"version":3,"sources":["webpack://./src/videoPages/HighlightEditor/EditHighlightDisplay/RoundContent.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;AAAJ;AACI;EACE,iCAAA;AACN;AAEI;EACI,sBAAA;EACA,kCAAA;EACA,kBAAA;AAAR;AAGI;EACA,UAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,qBAAA;EACA,8BAAA;AADJ;AAKI;EACI,iCAAA;AAHR;;AAQE;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,kCAAA;AALJ;AAMI;EACE,UAAA;EACA,iBAAA;AAJN;AAMI;EACE,6BAAA;EACA,aAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,kCAAA;EACA,eAAA;AAJN;AAKM;EAEE,oCAAA;AAJR","sourcesContent":["\n.highlight-match-container{\n    display: flex;\n    flex-direction: column;\n    .MuiSvgIcon-root{\n      font-size: var(--large-font-size);\n    }\n\n    .players-info{\n        color: var(--dark-red);\n        font-size: var(--medium-font-size);\n        text-align: center;\n    }\n      \n    .edit-highlight-video-data{\n    width: 98%;\n    display: grid;\n    grid-template-columns: 50% 50%;\n    align-items: center;\n    justify-items: center;\n    justify-content: space-between;\n\n\n\n    .match-info, .date-location{\n        font-size: var(--small-font-size);\n    }\n    }\n  }\n  \n  .edit-h-block{\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    margin-left: 2%;\n    overflow-y: auto;\n    font-size: var(--medium-font-size);\n    .MuiFormControl-root{\n      width:96%;\n      margin-bottom: 5%;\n  }\n    .edit-h-round{\n      background-color: var(--grey); \n      margin-top: 0;\n      width:98%;\n      margin-bottom: 5%;\n      padding-bottom: 2%;\n      text-align: center;\n      font-weight: 400;\n      color: black;\n      font-size: var(--medium-font-size);\n      cursor: pointer;\n      &.selected {\n        // Styles for the selected edit-round element\n        background-color: var(--apple-green);\n      }\n    }\n  }\n\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
