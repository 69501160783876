// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-highlight {
  display: flex;
  flex-direction: column;
}
.edit-highlight .css-jm3i9k {
  width: 100%;
}
.edit-highlight .css-d0uhtl {
  font-size: var(--small-font-size);
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/videoPages/HighlightEditor/EditHighlightDisplay/HighlightContent.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;AAAJ;AACI;EACI,WAAA;AACR;AACI;EACI,iCAAA;EACA,SAAA;AACR","sourcesContent":["\n.edit-highlight{\n    display: flex;\n    flex-direction: column;\n    .css-jm3i9k{\n        width: 100%;\n    }\n    .css-d0uhtl{\n        font-size: var(--small-font-size);\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
