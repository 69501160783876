import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputVideoPanel from './InputVideoPanel';

import './FileUploadForm.scss';

export default function FileUploadForm({
  setMatchData,
  setReportFile,
  matchData,
  setOCRFile,
  setDpFile,
}) {
  const handleReportFileChange = (event) => {
    const file = event.target.files[0];
    setReportFile(file);
    setMatchData((prevData) => ({ ...prevData, reportUrl: file.name }));
  };

  const handleOCRFileChange = (event) => {
    const file = event.target.files[0];
    setOCRFile(file);
    setMatchData((prevData) => ({ ...prevData, OCRFilename: file.name }));
  };

  const handleDpFileChange = (event) => {
    const file = event.target.files[0];
    setDpFile(file);
    setMatchData((prevData) => ({ ...prevData, dpFilename: file.name }));
  };

  return (
    <div className="file-upload-main-container">
      <div className="data-input-options">
        <InputVideoPanel matchData={matchData} />
      </div>
      <Typography variant="h6" gutterBottom>
        上傳
      </Typography>
      <div className="file-upload-container">
        <div className="manual-data-upload">
          <TextField
            required
            id="ocrData"
            name="ocrData"
            label="球數據文件（csv）"
            fullWidth
            variant="standard"
            type="file"
            accept=".csv"
            onChange={handleOCRFileChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { paddingRight: '36px' },
            }}
          />
          <TextField
            required
            id="dpRawData"
            name="dpRawData"
            label="Drop Point Raw Data"
            fullWidth
            variant="standard"
            type="file"
            accept=".csv"
            onChange={handleDpFileChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            required
            id="reportFile"
            name="reportFile"
            label="報告文件（PDF）"
            fullWidth
            variant="standard"
            type="file"
            accept=".pdf"
            onChange={handleReportFileChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { paddingRight: '36px' },
            }}
          />
        </div>
      </div>
    </div>
  );
}
