import { useState, useEffect } from 'react';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SourceIcon from '@mui/icons-material/Source';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from '@mui/material';

import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import VideoAnalysisProgress from './Progress/VideoAnalysisProgress';

import Cookies from 'js-cookie';

import './VideoSettingPanel.scss';

import {
  uploadPlaylistDataAPI,
  fetchPlaylistDataAPI,
} from 'src/api/videoService';

interface Props {
  userInfo: any;
  matchData: any;
  setReplayData: any;
  replayData: any;
  videoData: any;
  setVideoData: any;
  setMatchData: any;
}

const VideoSettingPanel: React.FC<Props> = ({
  userInfo,
  matchData,
  setReplayData,
  replayData,
  videoData,
  setVideoData,
  setMatchData,
}) => {
  const token = Cookies.get('token') || '';

  const [isPin, setIsPin] = useState(false);

  const fetchPlaylists = async (accountType: string) => {
    try {
      const params = { accountType: accountType };
      const data = await fetchPlaylistDataAPI(params, token);
      setIsPin(false);
      for (const playlist of data) {
        for (const match_id of playlist.ispin) {
          if (match_id === matchData.matchId) {
            setIsPin(true);
            break;
          }
        }
      }

      console.log('Fetched playlist data:', data);
    } catch (error) {
      console.error('Error fetching playlist data:', error);
    }
  };

  useEffect(() => {
    fetchPlaylists(userInfo.accountType);
  }, []);

  const handleBallReplayChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => {
    const target = event.target as HTMLInputElement;
    setReplayData((prevData: any) => ({
      ...prevData,
      ballReplayTimes: target.value,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handleHighlightReplayChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => {
    const target = event.target as HTMLInputElement;
    setReplayData((prevData: any) => ({
      ...prevData,
      highlightReplayTimes: target.value,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handleDeuceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplayData((prevData: any) => ({
      ...prevData,
      replayDeuce: event.target.checked,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handleCriticalPointChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setReplayData((prevData: any) => ({
      ...prevData,
      replayCriticalPoint: event.target.checked,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handlePOVChange = () => {
    setVideoData((prevState: any) => ({
      ...prevState,
      isCoachPOV: !videoData.isCoachPOV,
    }));
  };

  const handlePin = async () => {
    const params = {
      custom_tags: [],
      ispin: isPin === true ? [matchData.matchId] : [],
      match_ids: [matchData.matchId],
      mental: userInfo.accountType === 'm',
      physical: userInfo.accountType === 'p',
      playlist_id: -1,
      playlist_name: '重點',
    };

    try {
      await uploadPlaylistDataAPI(params, token);
      fetchPlaylists(userInfo.accountType);
    } catch (error) {
      console.error('Error updating playlist data:', error);
    }
  };

  const renderSetting = () => (
    <div className="other-setting-container">
      <button
        className={`coachPOV ${videoData.isCoachPOV ? 'on' : ''}`}
        onClick={handlePOVChange}
        title="教練視角"
      >
        <VisibilityIcon />
        教练視角
      </button>
      <div className="ball-replay-option-panel">
        <span className="ball-replay-span">球重播次數</span>
        <ToggleButtonGroup
          color="primary"
          value={replayData.ballReplayTimes}
          exclusive
          onChange={handleBallReplayChange}
          aria-label="Platform"
          title="重播每一球"
        >
          <ToggleButton
            value="1"
            className={replayData.ballReplayTimes == 1 ? 'blink-red' : ''}
          >
            0
          </ToggleButton>
          <ToggleButton
            value="2"
            className={replayData.ballReplayTimes == 2 ? 'blink-red' : ''}
          >
            2
          </ToggleButton>
          <ToggleButton
            value="5"
            className={replayData.ballReplayTimes == 5 ? 'blink-red' : ''}
          >
            5
          </ToggleButton>
          <ToggleButton
            value="10"
            className={replayData.ballReplayTimes == 10 ? 'blink-red' : ''}
          >
            10
          </ToggleButton>
          <ToggleButton
            value="99999"
            className={replayData.ballReplayTimes == 99999 ? 'blink-red' : ''}
          >
            無限
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="ball-replay-options">
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={replayData.replayDeuce}
                color="secondary"
                onChange={handleDeuceChange}
                title="重播僅平分球"
              />
            }
            label="Deuce"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={replayData.replayCriticalPoint}
                color="warning"
                onChange={handleCriticalPointChange}
                title="重播僅關鍵分"
              />
            }
            label="關鍵分"
          />
        </div>
      </div>
      <div className="highlight-replay-option-panel">
        <span className="highlight-replay-span">亮點重播次數</span>
        <ToggleButtonGroup
          color="primary"
          value={replayData.highlightReplayTimes}
          exclusive
          onChange={handleHighlightReplayChange}
          aria-label="Platform"
          title="重播每個亮點"
        >
          <ToggleButton
            value="1"
            className={replayData.highlightReplayTimes == 1 ? 'blink-red' : ''}
          >
            0
          </ToggleButton>
          <ToggleButton
            value="2"
            className={replayData.highlightReplayTimes == 2 ? 'blink-red' : ''}
          >
            2
          </ToggleButton>
          <ToggleButton
            value="5"
            className={replayData.highlightReplayTimes == 5 ? 'blink-red' : ''}
          >
            5
          </ToggleButton>
          <ToggleButton
            value="10"
            className={replayData.highlightReplayTimes == 10 ? 'blink-red' : ''}
          >
            10
          </ToggleButton>
          <ToggleButton
            value="99999"
            className={
              replayData.highlightReplayTimes == 99999 ? 'blink-red' : ''
            }
          >
            無限
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="download-options">
        {userInfo.isCoach === true && (
          <a className="video-download" href="/csReport" title="直/曲球分析">
            <DownloadIcon />
            接發球分析
          </a>
        )}
        {userInfo.isCoach === true && (
          <a
            className="video-download"
            href={matchData.fileUrl}
            download
            title="下載視頻"
          >
            <DownloadIcon />
            下载视频
          </a>
        )}

        {matchData.reportUrl !== '/reports/null' ? (
          <a
            className="report-download"
            href={matchData.reportUrl}
            download
            title="下載報告"
          >
            <DownloadIcon />
            下载報告
          </a>
        ) : (
          <span className="no-report" title="没有報告">
            <NotInterestedIcon />
            没有報告
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className="video-setting-main">
      <VideoAnalysisProgress
        currentProgress={matchData.progress}
        matchData={matchData}
        setMatchData={setMatchData}
        setVideoData={setVideoData}
      />
      <div className="video-prop-setting-panel">
        <FlagVideo
          matchData={matchData}
          setMatchData={setMatchData}
          defaultOpen={false}
        />
        {userInfo.isCoach === true && (
          <Link
            className="editVideo"
            to={`/edit-metadata/${matchData.matchId}/0`}
            title="管理視頻數據 "
          >
            <SourceIcon /> 編輯數據
          </Link>
        )}
        {userInfo.isCoach === true && (
          <Button onClick={handlePin}>
            <PushPinIcon sx={{ color: isPin === true ? 'red' : 'black' }} />
          </Button>
        )}
      </div>

      {renderSetting()}
      <p className="padding"></p>
    </div>
  );
};

export default VideoSettingPanel;
