import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link } from 'react-router-dom';

import axios from 'axios';
import { Snackbar, SnackbarContent} from '@mui/material';

import Cookies from 'js-cookie';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        TableTennis
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {

  const token = Cookies.get('token')
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [tempAccountType, setTempAccountType] = useState('a'); // a - able, m - mental, p - physical

  const handleChange = (event) => {
    setTempAccountType(event.target.value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const goBack = () => {
    window.history.back();
  };



  const handleSubmit = (event) => {
    event.preventDefault();
   
    const data = new FormData(event.currentTarget);

    axios.post('/api/signup', data, {
      params: {
          primary_attribute: 'new username: ' + data.get('userName'),
          secondary_attribute: 'new user email: ' + data.get('email')
      },
      headers: {
        Authorization: token
      }
    })
    .then(response => {
        setSnackbarMessage('Account created');
        setSnackbarOpen(true);
    })
    .catch(error => {
        if (error.response) {
            setSnackbarMessage(error.response.data.error); // Assuming the error message is returned as "error" in the response
        } else {
            setSnackbarMessage('An error occurred during signup'); // Set a generic error message if no response is available
        }
        setSnackbarOpen(true);
        console.error('Error occurred during signup:', error);
    });

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user-name"
                  name="userName"
                  required
                  fullWidth
                  id="userName"
                  label="Username"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="accesscode"
                  label="Access Code"
                  type="accesscode"
                  id="accesscode"
                  autoComplete="access-code"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                  control={<Checkbox value="coach" color="primary" />}
                  name='coach'
                  label="I am a Coach"
                />
            </Grid>
            <Grid item xs={12}>
              <FormControl required sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>Account type</InputLabel>
                  <Select
                    name = 'accountType'
                    value={tempAccountType}
                    label="Account type *"
                    onChange={handleChange}
                  >
                    <MenuItem value={'a'}>Able</MenuItem>
                    <MenuItem value={'m'}>Mental</MenuItem>
                    <MenuItem value={'p'}>Physical</MenuItem>
                  </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Grid> 
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              <Link onClick={goBack} variant="body2">
                Go Back
              </Link>
              </Grid>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
             <SnackbarContent className="POV-custom-snackbar" message={snackbarMessage} />
          </Snackbar>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
