// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--apple-grey);
  min-height: 100vh;
  width: 100vw;
}
.main-upload .MuiPaper-outlined {
  background-color: var(--grey);
  color: var(--apple-lightgrey2);
}
.main-upload label {
  color: var(--main-text-color);
  font-size: var(--medium-font-size);
}
.main-upload .MuiBox-root button {
  color: var(--main-text-color);
  background-color: #e50914;
}
.main-upload .MuiLinearProgress-bar {
  background-color: #e50914;
}
.main-upload .MuiBackdrop-root button {
  color: #e50914;
  background-color: transparent;
}

@media screen and (orientation: portrait) {
  .main-upload .upload-container {
    max-width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/videoPages/UploadVideo/VideoUploadForm.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mCAAA;EACA,iBAAA;EACA,YAAA;AACF;AACE;EACE,6BAAA;EACA,8BAAA;AACJ;AACE;EACE,6BAAA;EACA,kCAAA;AACJ;AAGI;EACE,6BAAA;EACA,yBAAA;AADN;AAIE;EACE,yBAAA;AAFJ;AAKI;EACE,cAAA;EACA,6BAAA;AAHN;;AAQA;EAEI;IACE,cAAA;EANJ;AACF","sourcesContent":[".main-upload {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: var(--apple-grey);\n  min-height: 100vh;\n  width: 100vw;\n\n  .MuiPaper-outlined {\n    background-color: var(--grey);\n    color: var(--apple-lightgrey2);\n  }\n  label {\n    color: var(--main-text-color);\n    font-size: var(--medium-font-size);\n  }\n\n  .MuiBox-root {\n    button {\n      color: var(--main-text-color);\n      background-color: #e50914;\n    }\n  }\n  .MuiLinearProgress-bar {\n    background-color: #e50914;\n  }\n  .MuiBackdrop-root {\n    button {\n      color: #e50914;\n      background-color: transparent;\n    }\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .main-upload {\n    .upload-container {\n      max-width: 90%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
