import React from 'react';
import { Typography, Box, Link } from '@mui/material';
import { CheckCircle, Edit } from '@mui/icons-material';
import { MatchData } from 'types/videoTypes';

const UploadMatchDataPanel: React.FC<{ matchData: MatchData }> = ({
  matchData,
}) => {
  const isValidField = (field: string) => {
    return field !== ' ' && field !== null && field.length >= 2;
  };

  return (
    <Box className="match-data-panel">
      <Box className="data-row">
        <Typography>主場球員：</Typography>
        <Typography className="progress-player-name">
          {matchData.homePlayer}
        </Typography>
        <CheckCircle
          color={isValidField(matchData.homePlayer) ? 'success' : 'disabled'}
        />
      </Box>
      <Box className="data-row">
        <Typography>客場球員：</Typography>
        <Typography className="progress-player-name">
          {matchData.awayPlayer}
        </Typography>
        <CheckCircle
          color={isValidField(matchData.awayPlayer) ? 'success' : 'disabled'}
        />
      </Box>
      <Box className="data-row">
        <Typography>類別：</Typography>
        <Typography>{matchData.matchCategory}</Typography>
        <CheckCircle
          color={isValidField(matchData.matchCategory) ? 'success' : 'disabled'}
        />
      </Box>
      <Link
        href={`/edit-metadata/${matchData.matchId}/0`}
        className="edit-link"
        underline="none"
      >
        <Edit fontSize="small" />
        <Typography>編輯資料</Typography>
      </Link>
    </Box>
  );
};

export default UploadMatchDataPanel;
