import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchDropPointsAPI } from 'src/api/videoService';

import VideoPlayer from 'src/components/VideoPlayer/VideoPlayer';
import VideoDynamicPanel from './VideoDynamicPanel/VideoDynamicPanel';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import detectDevice from 'src/utils/utils';

import './VideoInterface.scss';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  dateIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  parentEventIndex,
  categoryIndex,
  viewCountIndex,
  uploadUserIndex,
  ballDataIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  homePlayerIdIndex,
  awayPlayerIdIndex,
  roundDataIndex,
  roundEndTimeIndex,
  FlagCommentIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  tableCoorIndex,
  scoreCoorIndex,
  startingPlayerIndex,
  analysisProgressIndex,
} from 'src/constants';

import { fetchIndMatchDataAPI } from 'src/api/videoService';

const VideoInterface = ({ setToken, userInfo }) => {
  const [error, setError] = useState('');
  const [isMobile, setIsMobile] = useState(true);
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const videoRef = useRef(null);
  const token = Cookies.get('token');
  const { videoId } = useParams();

  const [matchData, setMatchData] = useState({
    matchId: '',
    videoName: '',
    videoDescription: '',
    stage: '',
    matchDate: '',
    location: '',
    homePlayer: '',
    awayPlayer: '',
    homePlayerId: '',
    awayPlayerId: '',
    fileUrl: '',
    thumbnailUrl: '',
    startEndTimeUrl: '',
    reportUrl: '',
    matchEvent: '',
    matchParentEvent: '',
    matchCategory: '',
    viewCount: '',
    uploadUser: '',
    flagComment: '',
    homePlayerScore: 0,
    awayPlayerScore: 0,
  });
  const [roundResults, setRoundResults] = useState(null);
  const [rawDpResults, setRawDpResults] = useState(null);
  const [dpResults, setDpResults] = useState(null);
  const [topPlayerId, setTopPlayerId] = useState('');
  const [topPlayer, setTopPlayer] = useState('');

  const [videoData, setVideoData] = useState({
    filename: '',
    currentRound: 0,
    currentBall: 0,
    isPlaying: false,
    isCoachPOV: true,
    isFs: false,
    tabIndex: 0,
    isHovered: false,
    skipped: false,
    refresh: true,
    numberOfRound: 0,
    played: false,
  });

  const [commentaryTimes, setCommentaryTimes] = useState({
    firstCommentaryStartTime: 0,
    lastCommentaryStartTime: 0,
    firstCommentaryEndTime: 0,
    lastCommentaryEndTime: 0,
  });

  const [replayData, setReplayData] = useState({
    ballReplayTimes: 1,
    highlightReplayTimes: 1,
    replayDeuce: false,
    replayCriticalPoint: false,
  });

  const [currentTransformation, setCurrentTransformation] = useState({
    positionX: 0,
    positionY: 0,
    scale: 1,
  });

  const updateVideoViews = async () => {
    const response = await axios.put(`/api/updateVideoViews/${videoId}`, null, {
      params: {
        primary_attribute: 'watched video : ' + matchData.videoName,
      },
      headers: {
        Authorization: `${token}`,
      },
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const { checkedMobileBrowser, checkedMobile } = detectDevice();
      setIsMobileBrowser(checkedMobileBrowser);
      setIsMobile(checkedMobile);
    };

    // Initial check on mount
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    if (videoData.played === true) {
      // 5 minutes
      setTimeout(function () {
        updateVideoViews();
      }, 30000);
    }
  }, [videoData.played]);

  useEffect(() => {
    if (
      matchData.homePlayerId !== '' &&
      matchData.awayPlayerId !== '' &&
      topPlayerId !== ''
    ) {
      if (topPlayerId === matchData.homePlayerId) {
        setTopPlayer(matchData.homePlayer);
      } else {
        setTopPlayer(matchData.awayPlayer);
      }
    }
  }, [matchData.homePlayerId, matchData.awayPlayerId, topPlayerId]);

  // load data
  useEffect(() => {
    const transformResultsToArray = (matchDp) => {
      const transformedArray = [];

      for (const ballDp of matchDp) {
        const [ball_num, drop_points, startingPlayer] = ballDp;
        let alternatingPlayer = startingPlayer;
        const newData = [['x', '球的路徑 ', '落點', '最后落點', 'middleLine']];
        const timeData = [];
        const middleLine = 1280 / 2;
        const trajectory = [];
        const ballPlayers = [];
        let previousX = null;

        newData.push([0, null, null, null, middleLine]);
        newData.push([800, null, null, null, middleLine]);

        if (!drop_points) {
          newData.push([9999, 9999, 9999, 9999, null]);
          newData.push([9999, 9999, 9999, 9999, null]);
          timeData.push([0]);
          timeData.push([0]);
        } else {
          for (let i = 0; i < drop_points.length; i++) {
            const x = 800 * drop_points[i][0];
            const y = 1280 - 1280 * drop_points[i][1];
            const time = drop_points[i][2];

            const yScatterValue = i === drop_points.length - 1 ? null : y;
            const yLastValue = i === drop_points.length - 1 ? y : null;

            if (x < 0 && y < 0) {
              newData.push([9999, 9999, yScatterValue, yLastValue, null]);
              timeData.push([0]);
            } else {
              newData.push([x, y, yScatterValue, yLastValue, null]);
              timeData.push([time]);
              ballPlayers.push(alternatingPlayer);
              // Alternate the starting player for the next ball
              alternatingPlayer =
                alternatingPlayer === 'top' ? 'bottom' : 'top';

              if (previousX !== null) {
                if (
                  (previousX <= 400 && x > 400) ||
                  (previousX >= 400 && x < 400)
                ) {
                  trajectory.push(1);
                } else {
                  trajectory.push(0);
                }
              }
              previousX = x;
            }
          }
        }

        if (newData.length === 4) {
          const secondArray = [
            newData[3][0],
            newData[3][1],
            newData[3][3],
            newData[3][2],
            null,
          ];
          newData.push(secondArray);
          timeData.push([0]);
        }

        const resultArray = [
          ball_num,
          newData,
          timeData,
          ballPlayers,
          trajectory,
        ];
        transformedArray.push(resultArray);
      }

      return transformedArray;
    };

    const fetchVideoData = async () => {
      try {
        const responseData = await fetchIndMatchDataAPI(videoId, token);
        // Destructure the response data
        const [MatchData, rawRoundResults] = responseData;

        // change date and url
        let transformedList = MatchData.map((videoData) => {
          const fileUrl = videoData[urlIndex];
          setVideoData((prevState) => ({
            ...prevState,
            filename: fileUrl,
          }));
          const urlWithoutSuffix = fileUrl.substring(
            0,
            fileUrl.lastIndexOf('.'),
          );
          const reportUrl = videoData[reportIndex];
          const url = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
          const publicReportUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/report/${reportUrl}`;
          const fetchedMatchDate = videoData[dateIndex];
          const dateWithoutTime = fetchedMatchDate.replace(
            /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
            '',
          );

          return {
            matchId: videoData[matchIdIndex],
            videoName: videoData[videoNameIndex],
            videoDescription: videoData[matchDescriptionIndex],
            stage: videoData[stageIndex],
            matchDate: dateWithoutTime,
            location: videoData[locationIndex],
            homePlayer: videoData[homePlayerIndex],
            awayPlayer: videoData[awayPlayerIndex],
            homePlayerId: videoData[homePlayerIdIndex],
            awayPlayerId: videoData[awayPlayerIdIndex],
            fileUrl: url,
            thumbnailUrl: videoData[thumbnailIndex],
            reportUrl: publicReportUrl,
            matchEvent: videoData[subEventIndex],
            matchParentEvent: videoData[parentEventIndex],
            matchCategory: videoData[categoryIndex],
            viewCount: videoData[viewCountIndex],
            uploadUser: videoData[uploadUserIndex],
            flagComment: videoData[FlagCommentIndex],
            homePlayerScore: videoData[homePlayerMatchScoreIndex],
            awayPlayerScore: videoData[awayPlayerMatchScoreIndex],
            tableCoor: videoData[tableCoorIndex],
            scoreCoor: videoData[scoreCoorIndex],
            startingPlayer: videoData[startingPlayerIndex],
            progress: videoData[analysisProgressIndex],
          };
        });

        // if there is round result
        if (
          rawRoundResults[0] &&
          rawRoundResults[0][roundDataIndex][roundEndTimeIndex] !== 9999
        ) {
          const firstBall = rawRoundResults[0][ballDataIndex][0];
          const lastBallArray =
            rawRoundResults[rawRoundResults.length - 1][ballDataIndex];
          const lastBall = lastBallArray[lastBallArray.length - 1];
          setCommentaryTimes((prevTimes) => ({
            ...prevTimes,
            firstCommentaryStartTime:
              !firstBall || firstBall.length === 0
                ? 0
                : firstBall[ballStartTimeIndex],
            firstCommentaryEndTime:
              !firstBall || firstBall.length === 0
                ? 9999
                : firstBall[ballEndTimeIndex],
            lastCommentaryStartTime:
              !lastBall || lastBall.length === 0
                ? 99999
                : lastBall[ballStartTimeIndex],
            lastCommentaryEndTime:
              !lastBall || lastBall.length === 0
                ? 99999
                : lastBall[ballEndTimeIndex],
          }));
        }
        // no ocr data
        else {
          setCommentaryTimes((prevTimes) => ({
            ...prevTimes,
            firstCommentaryStartTime: 0,
            firstCommentaryEndTime: 9999,
            lastCommentaryStartTime: 99999,
            lastCommentaryEndTime: 99999,
          }));
        }

        setMatchData(transformedList[0]);
        setRoundResults(rawRoundResults);
        setVideoData((prevState) => ({
          ...prevState,
          numberOfRound: rawRoundResults.length,
        }));
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchDpData = async () => {
      try {
        const responseData = await fetchDropPointsAPI(
          {
            videoId: videoId,
          },
          token,
        );

        setTopPlayerId(responseData[1]);
        setRawDpResults(responseData[0]);
        const transformedData = transformResultsToArray(responseData[0]);
        setDpResults(transformedData);
      } catch (error) {
        setError(error.message);
      }
    };

    setVideoData((prevData) => ({
      ...prevData,
      isMobile: isMobile,
    }));

    if (videoData.refresh === true) {
      fetchVideoData();
      fetchDpData();
      setVideoData((prevState) => ({
        ...prevState,
        refresh: false,
      }));
    }
  }, [videoData.refresh]);

  return (
    <>
      {matchData.fileUrl && videoRef ? (
        <div className="video-interface-main">
          <div
            className={
              isMobile === true ? 'video-Minterface' : 'video-interface'
            }
          >
            <VideoPlayer
              source={matchData.fileUrl}
              videoRef={videoRef}
              replayData={replayData}
              commentaryTimes={commentaryTimes}
              videoData={videoData}
              setVideoData={setVideoData}
              roundResults={roundResults}
              isMobile={isMobile}
              isMobileBrowser={isMobileBrowser}
              userInfo={userInfo}
              currentTransformation={currentTransformation}
              setCurrentTransformation={setCurrentTransformation}
              matchData={matchData}
              setMatchData={setMatchData}
            />
            <VideoDynamicPanel
              roundResults={roundResults}
              dpResults={dpResults}
              rawDpResults={rawDpResults}
              videoRef={videoRef}
              token={token}
              userInfo={userInfo}
              matchData={matchData}
              videoData={videoData}
              setVideoData={setVideoData}
              currentTransformation={currentTransformation}
              replayData={replayData}
              setReplayData={setReplayData}
              setMatchData={setMatchData}
              topPlayer={topPlayer}
            />
          </div>
        </div>
      ) : (
        <div className="loading-video">
          <CircularProgress color="secondary" />
          <p>加载中....</p>
        </div>
      )}
    </>
  );
};

export default VideoInterface;
