import { useState, useEffect } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MatchScoreBoard from 'src/components/MatchScoreBoard/MatchScoreBoard';
import RoundScorePanel from './RoundScorePanel';
import ScoreChart from './ScoreChart';

import './ScorePanel.scss';

import { ballDataIndex } from 'src/constants';

const ScorePanel = ({
  roundResults,
  matchData,
  videoRef,
  score1,
  score2,
  xAxis,
  yAxis,
  chartHeight,
  chartWidth,
  videoData,
  setVideoData,
}) => {
  const [roundData, setRoundData] = useState([]);

  //update scoreboard data
  useEffect(() => {
    if (roundResults && roundResults[videoData.currentRound]) {
      setRoundData(roundResults[videoData.currentRound][ballDataIndex]);
    }
  }, [roundResults, videoData.currentRound]);

  return (
    <div className="score-main-container">
      {score1 !== null && score2 !== null && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>比賽得分</Typography>
            </AccordionSummary>
            <MatchScoreBoard
              score1={score1}
              score2={score2}
              HomePlayer={matchData.homePlayer}
              AwayPlayer={matchData.awayPlayer}
              videoData={videoData}
              videoRef={videoRef}
              roundResults={roundResults}
              setVideoData={setVideoData}
            />
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>回合得分</Typography>
            </AccordionSummary>
            <RoundScorePanel
              matchData={matchData}
              roundData={roundData}
              videoData={videoData}
              setVideoData={setVideoData}
              videoRef={videoRef}
              roundResults={roundResults}
            />
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography>回合得分圖表 </Typography>
            </AccordionSummary>
            <ScoreChart
              score1={score1}
              score2={score2}
              matchData={matchData}
              currentRound={videoData.currentRound}
              xAxis={xAxis}
              yAxis={yAxis}
              chartHeight={chartHeight}
              chartWidth={chartWidth}
              videoRef={videoRef}
              roundResults={roundResults}
              setVideoData={setVideoData}
            />
          </Accordion>
        </>
      )}
      <p className="padding"></p>
    </div>
  );
};

export default ScorePanel;
