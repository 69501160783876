import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Snackbar, SnackbarContent } from '@mui/material';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import './Login.scss';

const defaultTheme = createTheme();

export default function LoginForm({ setToken }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const updateAuthContext = (token) => {
    setToken(token);
    const decodedToken = jwt_decode(token);

    const expirationTimeInSeconds = decodedToken.exp;
    const expirationDate = new Date(expirationTimeInSeconds * 1000);

    // Store values in cookies with secure and expires attributes
    Cookies.set('token', token, {
      expires: expirationDate,
      secure: true,
      sameSite: 'strict',
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    axios
      .post('/api/login', data)
      .then((response) => {
        if (response.data.message === 'Invalid credentials') {
          setSnackbarMessage('登入失敗：請檢查您的憑證');
          setSnackbarOpen(true);
          setLoginError(true);
        } else {
          const newToken = response.data;
          updateAuthContext(newToken);
          setSnackbarMessage('Logged in');
          navigate('/'); // Navigates back to the home page
        }
      })
      .catch((error) => {
        setSnackbarMessage('登入失敗：請檢查您的憑證');
        setSnackbarOpen(true);
        setLoginError(true);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        className={`main-login ${loginError ? 'error' : ''}`}
        container
        component="main"
        sx={{ height: '100vh' }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className="login-background"
          sx={{
            backgroundImage:
              'url(https://i.etsystatic.com/45803373/r/il/03d92d/5454890829/il_fullxfull.5454890829_a87w.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'dark'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          className="login-panel"
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar className="login-avatar">
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className="login-title">
              登入系統
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              className="login-form"
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="identifier"
                label="電郵/用户"
                name="identifier"
                autoComplete="identifier"
                autoFocus
                className="login-input"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="密碼"
                type="password"
                id="password"
                autoComplete="current-password"
                className="login-input"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="login-button"
              >
                進入系統
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="login-custom-snackbar"
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent message={snackbarMessage} />
      </Snackbar>
    </ThemeProvider>
  );
}
