import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Cookies from 'js-cookie';

import { updatePlaylistDataAPI } from 'src/api/videoService';

export const UploadStatus = ({
  userInfo,
  files,
  matchData,
  selectedPlaylists,
}) => {
  const token = Cookies.get('token');

  const [progressArray, setProgressArray] = useState([]);
  const [totalProgress, setTotalProgress] = useState(0);
  const [backdropError, setBackdropError] = useState(false);
  const [error, setError] = useState('');

  // Function to update progress values
  const updateProgress = (index, value) => {
    setProgressArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = value;
      return newArray;
    });
  };

  const handleCloseBackdrop = () => {
    setBackdropError(false); // Reset the error state to close the backdrop
  };

  const UploadErrorBackdrop = ({ onClose }) => {
    return (
      <Backdrop open={true} style={{ zIndex: 9999 }}>
        <div>
          <Typography variant="h6" component="div" color="error">
            失败，请重试。
            {backdropError.message}
          </Typography>
          <IconButton color="primary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Backdrop>
    );
  };

  // update multiple meta data
  const uploadMetaData = ({ filename, videoName }) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('new_results', JSON.stringify(matchData));
      formData.append('filename', filename);
      formData.append('videoName', videoName);

      axios
        .put('/api/uploadMetaData', formData, {
          params: {
            primary_attribute: 'video metadata updated: ' + filename,
          },
          headers: {
            Authorization: token, // Assuming `token` variable is defined
          },
        })
        .then((response) => {
          console.log('Metadata uploaded successfully!');
          resolve(response.data.match_id);
        })
        .catch((error) => {
          console.error('Error occurred during metadata upload:', error);
          reject('Error occurred during metadata upload.');
        });
    });
  };

  // merge 1 file
  const mergeFileChunks = async (file) => {
    const formData = new FormData();
    formData.append('filename', file.name);
    axios
      .post('/api/mergeFile', formData, {
        params: {
          primary_attribute: 'filename : ' + file.name,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        setError('Error occurred during file merge.');
      });
  };

  // upload 1 file
  const uploadFile = (file, index, currentChunk) => {
    const megabytes = 4; // cur chunk size is 4mb
    const bytesPerMegabyte = 1048576;
    const chunkSize = megabytes * bytesPerMegabyte;
    const totalChunks = Math.ceil(file.size / chunkSize);

    // upload chunks of video
    const uploadChunk = (start, end, index, currentChunk) => {
      return new Promise((resolve, reject) => {
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('videoFile', chunk, file.name);
        formData.append('currentChunk', currentChunk);

        axios
          .put(`/api/uploadVideoChunk`, formData, {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                ((currentChunk * chunkSize + progressEvent.loaded) * 100) /
                  file.size,
              );
              const fileProgress = percentCompleted;
              updateProgress(index, fileProgress);
            },
            timeout: 2 * 60 * 60 * 1000, // 2 hours timeout
            headers: {
              Authorization: `${token}`,
            },
          })
          .then(() => {
            currentChunk++;
            if (currentChunk < totalChunks) {
              const start = currentChunk * chunkSize;
              const end = Math.min(start + chunkSize, file.size);
              uploadChunk(start, end, index, currentChunk)
                .then(resolve)
                .catch(reject);
            } else {
              // All file chunks uploaded, now merge them
              mergeFileChunks(file);
              resolve();
            }
          })
          .catch((error) => {
            if (error.code === 'ECONNABORTED') {
              // Handle timeout error
              setError('File upload timed out.');
            } else {
              setError('Error occurred during file upload.');
            }
            reject(error);
            setBackdropError(error);
          });
      });
    };

    // chunks already exist
    if (currentChunk !== 0) {
      const resume_start = currentChunk * chunkSize;
      const resume_end = Math.min(resume_start + chunkSize, file.size);
      return uploadChunk(resume_start, resume_end, index, currentChunk);
    } else {
      return uploadChunk(0, chunkSize, index, currentChunk);
    }
  };

  const processFile = (file, totalFiles, count, index) => {
    return new Promise((resolve) => {
      // Function to check file existence and get currentChunk
      const checkFile = () => {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `/api/checkFileExistence`,
              { filename: file.name },
              {
                headers: {
                  Authorization: `${token}`,
                },
              },
            )
            .then((response) => {
              const currentChunk = response.data.currentChunk;
              resolve(currentChunk); // Resolve the promise with the currentChunk value
            })
            .catch((error) => {
              reject(error); // Reject the promise with the error
            });
        });
      };

      // Call checkFile to get the currentChunk value
      checkFile()
        .then((currentChunk) => {
          // currentChunk value is not -1, upload the file
          if (currentChunk === -1) {
            // Move on to the next file
            resolve(count + 1); // Resolve the promise with incremented count
          } else {
            uploadFile(file, index, currentChunk)
              .then(() => {
                resolve(count + 1); // Resolve the promise with incremented count
              })
              .catch((error) => {
                resolve(count + 1);
              });
          }
        })
        .catch((error) => {
          resolve(count + 1);
        });
    });
  };

  const uploadPlaylist = async ({ match_id }) => {
    try {
      const responseData = await updatePlaylistDataAPI(
        {
          playlists: selectedPlaylists,
          match_id: match_id,
          accountType: userInfo.accountType,
        },
        token,
      );
    } catch (error) {
      console.log('error updating playlist');
    }
  };

  useEffect(() => {
    const processFiles = async (files) => {
      const totalFiles = files.length;
      let count = 0;
      let match_id;

      for (const [index, file] of files.entries()) {
        count = await processFile(file, totalFiles, count, index);
        match_id = await uploadMetaData({
          filename: file.name,
          videoName: matchData['videoName'][index],
        });
        uploadPlaylist({
          match_id: match_id,
        });
        const curProgress = Math.round((count / totalFiles) * 100);
        setTotalProgress(curProgress);
      }

      // Set the state after all files are processed
      setTotalProgress(101);
    };

    if (files && files.length > 0) {
      processFiles(files);
    }
  }, [files]);

  if (totalProgress > 100) {
    return (
      <React.Fragment>
        {error !== '' ? (
          <p>Error: {error}</p>
        ) : (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              视频上傳成功!
            </Typography>
            <Typography variant="subtitle1">
              您的视频已成功上傳，结果将很快在平台上可用。
            </Typography>
            <Link href="/" variant="body2">
              返回主頁。
            </Link>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {backdropError && <UploadErrorBackdrop onClose={handleCloseBackdrop} />}
        <Typography variant="h5" gutterBottom>
          视频上傳中...
        </Typography>
        <Typography variant="subtitle1">您的影片正在處理中.</Typography>
        <div>
          {progressArray.map((progress, index) => (
            <div key={index}>
              <p>
                檔案上傳進度 {files[index].name}: {progress}%
              </p>
              <LinearProgress
                variant="determinate"
                color="secondary"
                value={progress}
              />
            </div>
          ))}
        </div>
        <div>
          <p>整體上傳進度: {totalProgress}%</p>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={totalProgress}
          />
        </div>
        <p>不要刷新頁面!! 請稍等 .........</p>
      </React.Fragment>
    );
  }
};
