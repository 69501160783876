import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import VideoUploadOptions from './VideoUploadOptions';

import './VideoFileUploadForm.scss';

const VideoFileUploadForm = ({
  setFiles,
  files,
  matchData,
  setMatchData,
  playlists,
  selectedPlaylists,
  handlePlaylistSelect,
}) => {
  // get video name and thumbnail url from video url
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(Array.from(selectedFiles));
    setMatchData((prevData) => {
      const videoName = Array.from(selectedFiles).map((file) =>
        file.name.replace('.MP4', ''),
      );
      return {
        ...prevData,
        videoName,
        fileUrls: Array.from(selectedFiles).map((file) => file.name),
        thumbnail_urls: Array.from(selectedFiles).map((file) =>
          file.name.replace('.MP4', '.jpg'),
        ),
      };
    });
  };

  // remove both file and match data
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    setMatchData((prevData) => {
      const updatedFileUrls = [...prevData.fileUrls];
      updatedFileUrls.splice(index, 1);
      const updatedVideoName = [...prevData.videoName];
      updatedVideoName.splice(index, 1);
      const updatedThumbnailUrl = [...prevData.thumbnail_urls];
      updatedThumbnailUrl.splice(index, 1);
      return {
        ...prevData,
        fileUrls: updatedFileUrls,
        videoName: updatedVideoName,
        thumbnail_url: updatedThumbnailUrl,
      };
    });
    // Reset the value of the file input field
    const fileInput = document.getElementById('videoFiles');
    fileInput.value = null;
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleModifyMatchData = (index, newValue) => {
    setMatchData((prevData) => {
      const updatedMatchData = { ...prevData };
      updatedMatchData.videoName[index] = newValue;
      return {
        ...prevData,
        videoName: updatedMatchData.videoName,
      };
    });
  };

  return (
    <div className="main-upload-container">
      <Grid item xs={12} sm={6} className="main-upload-panel">
        <TextField
          required
          id="videoFiles"
          name="videoFiles"
          label="视频文件（MP4）"
          fullWidth
          autoComplete="videoFiles"
          variant="standard"
          type="file"
          accept="video/mp4"
          onChange={handleFileChange}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            style: {
              color: 'white',
            },
            multiple: true,
          }}
        />
      </Grid>
      <Grid className="main-upload-panel">
        <IconButton onClick={refreshPage} aria-label="Refresh">
          <RefreshIcon />
        </IconButton>
        <span>如果之前的上傳被中断, 請刷新以停止之前的上傳，避免文件損壞</span>
      </Grid>
      {files.length > 0 && (
        <div className="file-info-container">
          <Typography variant="subtitle1">選定的檔案:</Typography>
          <ul>
            {files.map((file, index) => (
              <VideoUploadOptions
                matchData={matchData}
                file={file}
                index={index}
                handleRemoveFile={handleRemoveFile}
                handleModifyMatchData={handleModifyMatchData}
                playlists={playlists}
                selectedPlaylists={selectedPlaylists}
                handlePlaylistSelect={handlePlaylistSelect}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default VideoFileUploadForm;
