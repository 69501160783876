import { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'js-cookie';

import './OCRManagementPopUp.scss';

const OCRManagementPopUp = ({
  videoId,
  fileName,
  ocrList,
  currentOCR,
  setRefresh,
}) => {
  const token = Cookies.get('token') || '';
  const [OCRFile, setOCRFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleOCRFileChange = (event) => {
    const file = event.target.files[0];
    setOCRFile(file);
  };

  const handleDownloadScore = async (ocrFileName, fileName) => {
    try {
      const response = await axios.get('/api/ocrFileManagement', {
        responseType: 'blob',
        params: {
          primary_attribute: 'downloaded ocr file: ' + ocrFileName,
          secondary_attribute: 'video : ' + fileName,
          ocr_filename: ocrFileName,
          video_filename: fileName,
        },
        headers: {
          Authorization: token,
        },
      });

      // Create a temporary URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', ocrFileName); // Set the desired filename for the downloaded file
      document.body.appendChild(link);

      // Trigger the click event on the link to initiate the file download
      link.click();

      // Clean up the temporary link and URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteScore = async (matchId, ocrFileName, fileName) => {
    try {
      await axios.delete('/api/ocrFileManagement', {
        params: {
          primary_attribute: 'deleted ocr file: ' + ocrFileName,
          secondary_attribute: 'video : ' + fileName,
          match_id: matchId,
          ocr_filename: ocrFileName,
          video_filename: fileName,
        },
        headers: {
          Authorization: token,
        },
      });

      setRefresh(true);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetScore = async (matchId, ocrFileName, fileName) => {
    try {
      await axios.put(
        '/api/ocrFileManagement',
        {
          match_id: matchId,
          ocr_filename: ocrFileName,
        },
        {
          params: {
            primary_attribute: 'set ocr file: ' + ocrFileName,
            secondary_attribute: 'video : ' + fileName,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      setRefresh(true);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadOCR = async (matchId, fileName) => {
    const formData = new FormData();

    formData.append('ocrData', OCRFile);
    formData.append('match_id', matchId);

    await axios
      .post(`/api/ocrFileManagement`, formData, {
        timeout: 600000,
        params: {
          primary_attribute: 'upload ocr file: ' + OCRFile.name,
          secondary_attribute: 'video : ' + fileName,
        },
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        console.log('File uploaded successfully!');
        setRefresh(true);
        setIsOpen(false);
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          // Handle timeout error
          console.log('File upload timed out.');
        } else {
          console.error('Error occurred during file upload.', error);
        }
      });
  };

  return (
    <div className="ocr-popup-main">
      <button className="popup-button" onClick={() => openPopup()}>
        <FolderOutlinedIcon />
      </button>
      <Popup
        open={isOpen}
        closeOnDocumentClick={false}
        onClose={closePopup}
        className="ocr-popup-main-container"
      >
        <div className="ocr-popup-main-content">
          <div className="ocr-popup-header">
            <span>{fileName}</span>
            <button className="close-button" onClick={() => closePopup()}>
              <CloseIcon />
            </button>
          </div>
          <div className="ocr-popup-upload-panel">
            <div className="ocr-popup-upload-option">
              <span>Upload new ocr data</span>
              <TextField
                required
                id="ocrData"
                name="ocrData"
                label="球數據文件（csv）"
                fullWidth
                variant="standard"
                type="file"
                accept=".csv"
                onChange={handleOCRFileChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: { paddingRight: '36px' },
                }}
              />
            </div>
            <div className="OCR-edit-buttons">
              <Link
                className="OCR-edit-player"
                to={`/edit-metadata/${videoId}`}
                title="添加球员"
                target="_blank"
              >
                <EmojiPeopleOutlinedIcon /> 添加球员
              </Link>
              <button
                className="OCR-submit-button"
                onClick={() => handleUploadOCR(videoId, fileName)}
              >
                / 確認
              </button>
            </div>
          </div>
          <div className="ocr-popup-files-panel">
            {ocrList.map((csvFile, index) => (
              <div className="ocr-popup-file-panel">
                {csvFile === currentOCR ? (
                  <div className="ocr-popup-current-file">
                    <span title={csvFile}>生效中 : {csvFile}</span>
                  </div>
                ) : (
                  <span title={csvFile}>{csvFile}</span>
                )}
                <div className="ocr-popup-file-option">
                  <button>
                    <DoneOutlinedIcon
                      onClick={() => handleSetScore(videoId, csvFile, fileName)}
                    />
                  </button>
                  <button>
                    <DownloadOutlinedIcon
                      onClick={() => handleDownloadScore(csvFile, fileName)}
                    />
                  </button>
                  <button>
                    <CloseIcon
                      onClick={() =>
                        handleDeleteScore(videoId, csvFile, fileName)
                      }
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default OCRManagementPopUp;
