import SearchBar from 'src/components/SearchBar/SearchBar';
import PlayerHomeFilterOption from 'src/components/FilterPanel/PlayerHomeFilter';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import './PlayerSearchBar.scss';

const PlayerSearchBar = ({
  accountType,
  setFilterCriteria,
  appliedFilterCriteria,
  setSortCriteria,
  appliedSortCriteria,
  searchName,
}) => {
  return (
    <div className="player-home-searchbar">
      <SearchBar
        player={true}
        accountType={accountType}
        setFilterCriteria={setFilterCriteria}
        searchName={searchName}
      />
      <PlayerHomeFilterOption
        setFilterCriteria={setFilterCriteria}
        appliedFilterCriteria={appliedFilterCriteria}
      />
      <div className="player-sort-container">
        <Select
          className="player-sort-panel"
          sx={{ width: '100%' }}
          displayEmpty
          onChange={(event) => setSortCriteria(event.target.value)}
          value={appliedSortCriteria}
          renderValue={(value) => {
            if (value) {
              if (value === 1) {
                return 'Z - A';
              } else if (value === 2) {
                return 'A - Z';
              } else if (value === 3) {
                return '影片數';
              }
            }
            return '选择排序方式';
          }}
        >
          <MenuItem value="" disabled>
            选择排序方式
          </MenuItem>
          <MenuItem value={1}>Z - A</MenuItem>
          <MenuItem value={2}>A - Z</MenuItem>
          <MenuItem value={3}>影片數</MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default PlayerSearchBar;
