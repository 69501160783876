import { useState } from 'react';

import VerticalTable from './VerticalTable';
import './RoundScorePanel.scss';
import { ballDataIndex } from 'src/constants';

const RoundScorePanel = ({
  matchData,
  roundData,
  roundResults,
  videoRef,
  videoData,
  setVideoData,
}) => {
  const [showDeuce, setShowDeuce] = useState(false);
  const [showCP, setShowCP] = useState(false);

  const handleDeuceChange = () => {
    setShowDeuce((prev) => !prev);
  };

  const handleCriticalPointChange = () => {
    setShowCP((prev) => !prev);
  };

  return (
    <div className="roundscore-container">
      <div className="roundscore-legend">
        <div className="legend1">
          <span className="green-square"></span> : 亮點
        </div>
        <div className="legend2">
          <span className="pink-square"></span> : 現在球
        </div>
        <div className="legend3">
          <span className="blue-square"></span> : 現在亮點
        </div>
        <div className="option1">
          <button
            className={showDeuce === true ? 'selected' : 'notSelected'}
            onClick={handleDeuceChange}
          >
            Deuce
          </button>
        </div>
        <div className="option2">
          <button
            className={showCP === true ? 'selected' : 'notSelected'}
            onClick={handleCriticalPointChange}
          >
            關鍵分
          </button>
        </div>
      </div>
      {showCP === true ? (
        <div className="multi-scoreboard">
          {roundResults.map((round, index) => (
            <VerticalTable
              key={index}
              matchData={matchData}
              roundData={round[ballDataIndex]}
              roundIndex={index}
              videoRef={videoRef}
              currentBall={index}
              setVideoData={setVideoData}
              cp={true}
            />
          ))}
        </div>
      ) : showDeuce === true ? (
        <div className="multi-scoreboard">
          {roundResults.map((round, index) => (
            <VerticalTable
              key={index}
              matchData={matchData}
              roundData={round[ballDataIndex]}
              roundIndex={index}
              videoRef={videoRef}
              currentBall={index}
              setVideoData={setVideoData}
              deuce={true}
            />
          ))}
        </div>
      ) : (
        <VerticalTable
          matchData={matchData}
          roundData={roundData}
          roundIndex={videoData.currentRound}
          videoRef={videoRef}
          currentBall={videoData.currentBall}
          setVideoData={setVideoData}
        />
      )}
    </div>
  );
};

export default RoundScorePanel;
