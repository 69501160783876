import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import HomeNavBar from 'src/components/NavBar/HomeNavBar';
import HomeSidePanel from 'src/components/HomeSidePanel/HomeSidePanel';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { Tabs, Tab, IconButton } from '@mui/material';
import { fetchMatchDataAPI } from 'src/api/videoService';
import VideoList from './VideoSelectBlock/VideoList';
import { useTheme, useMediaQuery } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditVideoDialog from './EditVideoDialog/EditVideoDialog';
import EditIcon from '@mui/icons-material/Edit';
import { fetchPlaylistDataAPI } from '../../api/videoService';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  dateIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  customTagIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  eventIndex,
  categoryIndex,
  isDoubleIndex,
  viewCountIndex,
  uploadUserIndex,
  parentEventIndex,
  dpFilenameIndex,
  FlagCommentIndex,
} from 'src/constants';

const categories = ['全部', '本地', '國際', '參考', '重點']; // , '已分析' remove

const translationDict = {
  Final: '決賽',
  'Semi-Final': '半決賽',
  'Quarter-Final': '四強',
  R16: '十六強',
  R32: '三十二強',
  R64: '六十四強',
  R128: '一百二十八強',
  MSingles: '男單打',
  MDouble: '男雙打',
  FSingle: '女單打',
  FDouble: '女雙打',
  MixedDouble: '混合雙打',
  '-1': '不使用OCR',
  1: '僅使用OCR',
};

const translationRDict = {
  決賽: 'Final',
  半決賽: 'Semi-Final',
  四強: 'Quarter-Final',
  十六強: 'R16',
  三十二強: 'R32',
  六十四強: 'R64',
  一百二十八強: 'R128',
  男單打: 'MSingles',
  男雙打: 'MDouble',
  女單打: 'FSingle',
  女雙打: 'FDouble',
  混合雙打: 'MixedDouble',
};

const Home = ({ setToken, userInfo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const token = Cookies.get('token');
  const [videoList, setVideoList] = useState([]);
  const [error, setError] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const videoHomeRef = useRef(null);
  const videoFilterPanelRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [enabledTabs, setEnabledTabs] = useState({
    本地: false,
    國際: false,
    參考: false,
    重點: false,
    // 已分析: false,
  });
  const [dataFetched, setDataFetched] = useState(false);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    // Add any additional logic if needed when the tab changes
  };

  // Rest of the Home component code

  const handleEditVideoClick = (video) => {
    setSelectedVideo(video);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    // Delay 5 seconds then close
    setTimeout(() => {
      setEditDialogOpen(false);
      setSelectedVideo(null);
    }, 1000);
  };

  const handleEditVideoSave = (updatedVideoList) => {
    setVideoList(updatedVideoList);
    handleEditDialogClose();
  };

  const [filterCriteria, setFilterCriteria] = useState(() => {
    const cachedCriteria = localStorage.getItem('filterCriteria');
    return cachedCriteria
      ? JSON.parse(cachedCriteria)
      : {
          matchName: '',
          matchId: [],
          matchDate: '',
          stages: [],
          player: '',
          location: '',
          events: [],
          categories: [],
          startDate: '',
          endDate: '',
          customTags: [],
          ocrOnly: '0',
          flaggedReason: '',
          manualUpdate: '0',
        };
  });

  const [sortCriteria, setSortCriteria] = useState(() => {
    const cachedSortCriteria = localStorage.getItem('sortCriteria');
    return cachedSortCriteria ? JSON.parse(cachedSortCriteria) : 1;
  });

  const [pageNumber, setPageNumber] = useState(() => {
    const cachedPageNumber = sessionStorage.getItem('pageNumber');
    return cachedPageNumber ? parseInt(cachedPageNumber) : 1;
  });
  const [CustomTagList, setCustomTagList] = useState([]);
  const [videosPerPage, setVideosPerPage] = useState(() => {
    const cachedVideosPerPage = sessionStorage.getItem('videosPerPage');
    return cachedVideosPerPage ? parseInt(cachedVideosPerPage) : 10;
  });

  const [filterStats, setFilterStats] = useState({
    stageCounts: {},
    subEventCounts: {},
    customTagCounts: {},
    categoryCounts: {},
  });

  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const observerRef = useRef();

  const handleSortChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSortCriteria(value); // Update sort criteria state for all values

    let sortedVideos = [...videoList]; // Prepare a copy for sorting

    if (value === 2) {
      // From A to Z
      sortedVideos.sort((a, b) => {
        // Custom sort function to handle numerical and alphabetical sorting
        return a.videoName.localeCompare(b.videoName, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
      setVideoList(sortedVideos); // Update the video list state with sorted videos
    } else if (value === 3) {
      // From Z to A
      sortedVideos.sort((a, b) => {
        // Custom sort function to handle numerical and alphabetical sorting
        return b.videoName.localeCompare(a.videoName, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
      setVideoList(sortedVideos); // Update the video list state with sorted videos
    } else if (value === 4) {
      // Custom sort (not implemented)
      // Add custom sort logic here 自定義(未完成)
      console.log('Custom sort not implemented 自定義(未完成)');
      return null;
    }
  };

  const handleClearFilter = (key, value) => {
    setFilterCriteria((prevCriteria) => {
      const updatedCriteria = { ...prevCriteria };
      if (Array.isArray(updatedCriteria[key])) {
        updatedCriteria[key] = updatedCriteria[key].filter(
          (item) => item !== value,
        );
      } else {
        updatedCriteria[key] = '';
      }
      return updatedCriteria;
    });
  };

  const handleClearAllFilter = () => {
    const updatedFilterCriteria = {
      matchName: '',
      matchId: [],
      matchDate: '',
      stages: [],
      player: '',
      location: '',
      events: [],
      categories: [],
      startDate: '',
      endDate: '',
      customTags: [],
      ocrOnly: '0',
      flaggedReason: '',
      manualUpdate: '0',
    };
    setFilterCriteria(updatedFilterCriteria);
  };

  useEffect(() => {
    const fetchAndMergeData = async () => {
      const {
        matchName,
        matchId,
        matchDate,
        stages,
        player,
        location,
        events,
        categories,
        startDate,
        endDate,
        customTags,
        ocrOnly,
        flaggedReason,
        manualUpdate,
      } = filterCriteria;

      localStorage.setItem('filterCriteria', JSON.stringify(filterCriteria));
      localStorage.setItem('sortCriteria', JSON.stringify(sortCriteria));

      if (sortCriteria === 1 || sortCriteria === -1) {
        try {
          // Fetch match data
          const matchResponse = await fetchMatchDataAPI(
            {
              match_name: matchName,
              match_id: matchId.join(','),
              match_date: matchDate,
              location: location,
              stage: stages.join(','),
              player: player,
              current_page: pageNumber,
              accountType: userInfo.accountType,
              events: events.join(','),
              categories: categories.join(','),
              startDate: startDate,
              endDate: endDate,
              sortCriteria: sortCriteria,
              customTags: customTags.join(','),
              admin: userInfo.admin,
              ocrOnly: ocrOnly,
              flaggedReason: flaggedReason,
              manualUpdate: manualUpdate,
            },
            token,
          );

          const responseData = matchResponse.videoData;
          setFilterStats({
            stageCounts: matchResponse.stageCounts,
            subEventCounts: matchResponse.subEventCounts,
            customTagCounts: matchResponse.customTagCounts,
            categoryCounts: matchResponse.categoryCounts,
          });

          // Fetch playlist data
          const playlistData = await fetchPlaylistDataAPI(
            { accountType: userInfo.accountType },
            token,
          );

          // Transform and merge data
          const mergedData = responseData.map((videoData, index) => {
            const fileUrl = videoData[urlIndex];
            const urlWithoutSuffix = fileUrl.substring(
              0,
              fileUrl.lastIndexOf('.'),
            );
            const TNUrl = videoData[thumbnailIndex];
            const newFileUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
            const newTNUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${TNUrl}`;

            const fetchedMatchDate = videoData[dateIndex];
            const dateWithoutTime = fetchedMatchDate.replace(
              /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
              '',
            );

            const videoCategories = playlistData
              .filter((playlist) =>
                playlist.match_ids.includes(videoData[matchIdIndex]),
              )
              .map((playlist) => playlist.playlist_name);

            const top = playlistData.some(
              (playlist) =>
                playlist.match_ids.includes(videoData[matchIdIndex]) &&
                playlist.ispin.includes(videoData[matchIdIndex]),
            );

            return {
              count: index + 1,
              matchId: videoData[matchIdIndex],
              videoName: videoData[videoNameIndex],
              matchDescription: videoData[matchDescriptionIndex],
              stage: videoData[stageIndex],
              date: dateWithoutTime,
              location: videoData[locationIndex],
              homePlayer: videoData[homePlayerIndex],
              awayPlayer: videoData[awayPlayerIndex],
              fileUrl: newFileUrl,
              TNUrl: newTNUrl,
              report: videoData[reportIndex],
              parentEvent: videoData[parentEventIndex],
              event: videoData[eventIndex],
              subEvent: videoData[subEventIndex],
              category: videoData[categoryIndex],
              isDouble: videoData[isDoubleIndex],
              viewCount: videoData[viewCountIndex],
              uploadUser: videoData[uploadUserIndex],
              customTag: videoData[customTagIndex],
              homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
              awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],
              dp: videoData[dpFilenameIndex],
              flaggedReason: videoData[FlagCommentIndex],
              video_category:
                videoCategories.length > 0 ? videoCategories : ['全部'],
              top: top,
            };
          });

          setVideoList(mergedData);

          // Enable tabs based on the video categories present
          const newEnabledTabs = {
            本地: mergedData.some((video) =>
              video.video_category.includes('本地'),
            ),
            國際: mergedData.some((video) =>
              video.video_category.includes('國際'),
            ),
            參考: mergedData.some((video) =>
              video.video_category.includes('參考'),
            ),
            重點: mergedData.some((video) =>
              video.video_category.includes('重點'),
            ),
          };

          setEnabledTabs(newEnabledTabs);
          setDataFetched(true);
        } catch (error) {
          console.error('Error fetching and merging data:', error);
          setError(error.message);
        }
      }
    };

    fetchAndMergeData();
  }, [filterCriteria, sortCriteria, tabIndex]);

  useEffect(() => {
    const containerElement = videoHomeRef.current;
    const containerElement2 = videoFilterPanelRef.current;

    if (containerElement) {
      const containerClassList = containerElement.classList;
      if (!isCollapsed) {
        containerClassList.add('mini');
      } else {
        containerClassList.remove('mini');
      }
    }

    if (containerElement2) {
      const containerClassList2 = containerElement2.classList;
      if (!isCollapsed) {
        containerClassList2.add('mini');
      } else {
        containerClassList2.remove('mini');
      }
    }
  }, [isCollapsed]);

  useEffect(() => {
    const handleResize = () => {
      const containerElement = videoHomeRef.current;
      if (containerElement) {
        const containerClassList = containerElement.classList;
        const isMobile = window.innerWidth <= 1200; // Adjust the breakpoint as needed

        if (isMobile) {
          containerClassList.add('mobile');
        } else {
          containerClassList.remove('mobile');
        }
      }
    };
    const fetchCustomTagList = async () => {
      try {
        const response = await axios.get('/api/customTagList', {
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;
        setCustomTagList(responseData);
      } catch (error) {
        //console.log(error);
      }
    };

    handleResize();
    fetchCustomTagList();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (loading) return;
    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMoreData) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }, 800);
      }
    };

    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    observerRef.current = new IntersectionObserver(handleObserver, option);

    if (observerRef.current && observerRef.current.unobserve) {
      observerRef.current.unobserve(document.querySelector('#bottom-element'));
    }

    observerRef.current.observe(document.querySelector('#bottom-element'));

    return () => observerRef.current.disconnect();
  }, [loading, hasMoreData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pageNumber * videosPerPage < videoList.length) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }, 800);
      } else {
        setHasMoreData(false);
      }
    }, 800);

    return () => clearInterval(interval);
  }, [pageNumber, videosPerPage, videoList.length]);

  const renderVideoList = () => {
    const filteredAndSortedVideos = videoList
      .filter(
        (video) =>
          tabIndex === 0 ||
          (video &&
            video.video_category &&
            video.video_category.includes(categories[tabIndex])),
      )
      .sort((a, b) => (b.top ? 1 : 0) - (a.top ? 1 : 0))
      .slice(0, pageNumber * videosPerPage);
    return (
      <>
        {userInfo.accountType &&
          filteredAndSortedVideos.map((videoData) => (
            <VideoList
              videoData={videoData}
              key={videoData.matchId}
              isPin={videoData.top}
            />
          ))}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        minWidth: '100%',
        flexGrow: 1,
        overflow: 'auto', // Changed from hidden to auto
        maxWidth: 'calc(100vw)', // Adjusted this line to calculate the width dynamically
      }}
    >
      {!isMobile && (
        <HomeSidePanel
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          isCoach={userInfo.isCoach}
          accountType={userInfo.accountType}
        />
      )}
      <Paper
        sx={{
          flexGrow: 1,
          transition: 'margin-left 0.3s',
          ml: !isMobile && isCollapsed ? '40px' : '0px',
          maxWidth: !isMobile
            ? 'calc(100vw - 40px)' // Added spaces around the '-' operator
            : isCollapsed
            ? '100vw'
            : 'calc(100vw - 240px)', // Added spaces around the '-' operator
          mb: 2,
        }}
      >
        <HomeNavBar
          setFilterCriteria={setFilterCriteria}
          setToken={setToken}
          userInfo={userInfo}
          appliedFilterCriteria={filterCriteria}
          CustomTagList={CustomTagList}
          filterStats={filterStats}
          searchName={filterCriteria.matchName}
        />
        <Box ref={videoFilterPanelRef}>
          <Box>
            <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
              {Object.entries(filterCriteria).map(([key, value]) => {
                if (Array.isArray(value) && value.length === 0) return null;
                if (
                  value === '' ||
                  value === '0' ||
                  value === null ||
                  value === undefined
                )
                  return null;

                const stringValue = String(value);
                const translatedValues = Array.isArray(value)
                  ? value.map((v) => translationDict[v] || v)
                  : [translationDict[stringValue] || stringValue];

                return translatedValues.map((translatedValue) => (
                  <Button
                    variant="contained"
                    endIcon={<CloseOutlinedIcon />}
                    onClick={() =>
                      handleClearFilter(
                        key,
                        translationRDict[translatedValue] || translatedValue,
                      )
                    }
                    key={translatedValue}
                    sx={{ borderRadius: '50px', ml: 1, mb: 1 }} // Makes the buttons look like pills
                  >
                    {translatedValue}
                  </Button>
                ));
              })}
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              justifyContent: 'flex-end',
              mr: 2,
            }}
          >
            <Button
              variant="outlined"
              endIcon={<CloseOutlinedIcon />}
              onClick={handleClearAllFilter}
              sx={{ flex: 1, maxWidth: '100px', height: '40px' }} // Adjust width according to preference
            >
              清除
            </Button>

            <Select
              labelId="sort-simple-select-label"
              id="sort-simple-select"
              value={sortCriteria}
              onChange={handleSortChange}
              sx={{ flex: 1, maxWidth: '100px', height: '40px' }}
              displayEmpty
              renderValue={(value) => {
                switch (value) {
                  case 1:
                    return '最早';
                  case -1:
                    return '最遲';
                  case 2:
                    return '從A到Z';
                  case 3:
                    return '從Z到A';
                  case 4:
                    return '自定義(未完成)';
                  default:
                    return '選擇排序方式';
                }
              }}
            >
              <MenuItem value={1}>最早</MenuItem>
              <MenuItem value={-1}>最遲</MenuItem>
              <MenuItem value={2}>從A到Z</MenuItem>
              <MenuItem value={3}>從Z到A</MenuItem>
              {/* <MenuItem value={4}>自定義(未完成)</MenuItem> */}
            </Select>
          </Box>
        </Box>

        {userInfo.accountType && (
          <Paper sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="search tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {categories.map((category, index) => (
                  <Tab
                    label={category}
                    key={index}
                    // disabled={category !== '全部' && !enabledTabs[category]}
                  />
                ))}
              </Tabs>
              {userInfo.isCoach && (
                <IconButton
                  onClick={() => handleEditVideoClick(editDialogOpen)}
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
          </Paper>
        )}

        <EditVideoDialog
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          videoData={videoList}
          onSave={handleEditVideoSave}
          setToken={token}
          accountType={userInfo.accountType}
        />

        <Box ref={videoHomeRef}>
          <Paper
            elevation={3}
            sx={{ p: 2, maxWidth: '100vw', overflow: 'auto' }}
          >
            <Box ref={videoHomeRef}>
              <Paper
                elevation={3}
                sx={{ p: 2, maxWidth: '100vw', overflow: 'auto' }}
              >
                <Box>
                  {renderVideoList()}
                  <div id="bottom-element"></div>
                </Box>
                {loading && (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                )}
                {!hasMoreData && !loading && (
                  <Typography align="center" color="textSecondary" mt={2}>
                    已顯示所有結果。
                    <br />
                    {videoList.length === 0 && '請嘗試更改搜尋條件。'}
                    <br />
                    {videoList.length === 0 &&
                      !(sortCriteria === 1 || sortCriteria === -1) &&
                      '請更改排序方式為"最早"或"最遲"。'}
                  </Typography>
                )}
              </Paper>
            </Box>

            <Button
              href="/"
              variant="contained"
              color="primary"
              sx={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                borderRadius: '50%',
                minWidth: '50px',
                minHeight: '50px',
              }}
            >
              <ArrowUpwardIcon />
            </Button>
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
};

export default Home;
