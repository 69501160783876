import { useState } from 'react';
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ player, setFilterCriteria, searchName }) => {
  const [searchQuery, setSearchQuery] = useState(searchName ? searchName : '');
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearch = () => {
    if (player) {
      setFilterCriteria((prevFilterCriteria) => ({
        ...prevFilterCriteria,
        playerName: searchQuery,
      }));
    } else {
      setFilterCriteria((prevFilterCriteria) => ({
        ...prevFilterCriteria,
        matchName: searchQuery,
      }));
    }
    if (isMobile) {
      setOpen(false); // Close the dialog after search on mobile
    }
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box sx={{ width:"80%"}}>
      <OutlinedInput
        fullWidth
        value={searchQuery}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        placeholder={player ? '尋找選手' : '搜索視頻...'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleSearch} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        sx={{
          borderRadius: 2,
          backgroundColor: 'background.paper',
          boxShadow: 1,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& .MuiInputBase-input': {
            padding: '10px 12px',
          },
        }}
      />
    </Box>
  );
};

export default SearchBar;
