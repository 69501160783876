import { useState, useEffect } from 'react';
import { fetchIndMatchDataAPI, fetchMatchDataAPI } from 'src/api/videoService';
import Cookies from 'js-cookie';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  dateIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  roundDataIndex,
  ballDataIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
  awayPlayerIdIndex,
  homePlayerIdIndex,
} from 'src/constants';

import PlayerVideoBlock from './PlayerVideoBlock';

import './PlayerVideos.scss';

const PlayerVideos = ({ playerData, accountType }) => {
  const token = Cookies.get('token');

  const [error, setError] = useState('');
  const [videoList, setVideoList] = useState([]);

  // get list of video
  useEffect(() => {
    const fetchIndMatchData = async (videoId) => {
      try {
        const responseData = await fetchIndMatchDataAPI(videoId, token);
        const [MatchData, rawRoundResults] = responseData;
        const scores = [];
        for (const roundResult of rawRoundResults) {
          const ballData = roundResult[ballDataIndex];
          if (ballData && ballData.length > 0) {
            const lastBallData = ballData[ballData.length - 1];
            const homePlayerRoundScore = lastBallData[ballHomePlayerScoreIndex];
            const awayPlayerRoundScore = lastBallData[ballAwayPlayerScoreIndex];
            scores.push({
              homeScore: homePlayerRoundScore,
              awayScore: awayPlayerRoundScore,
            });
          }
        }
        return scores;
      } catch (error) {
        setError(error.message);
        return [{ homeScore: 0, awayScore: 0 }];
      }
    };

    const fetchMatchData = async () => {
      try {
        const response = await fetchMatchDataAPI(
          {
            player: playerData.player_id,
            playerName: playerData.player_name,
            accountType: accountType,
            admin: true,
          },
          token,
        );

        const responseData = response.videoData;
        const responseOpponentsData = response.opponentsData;

        // transform data and url
        let transformedList;
        if (!responseData) {
          transformedList = [];
        } else {
          transformedList = await Promise.all(
            responseData.map(async (videoData, index) => {
              const indMatchData = await fetchIndMatchData(videoData[0]);

              const fileUrl = videoData[urlIndex];
              const urlWithoutSuffix = fileUrl.substring(
                0,
                fileUrl.lastIndexOf('.'),
              );
              const TNUrl = videoData[thumbnailIndex];
              const newFileUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
              const newTNUrl = `${process.env.PUBLIC_URL}/thumbnails/${TNUrl}`;
              const fetchedMatchDate = videoData[dateIndex];
              const dateWithoutTime = fetchedMatchDate.replace(
                /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
                '',
              );
              const opponentData =
                responseOpponentsData[index].filter((potentialOpponent) => {
                  const playerId = potentialOpponent['player_id'];
                  const awayPlayerId = videoData[awayPlayerIdIndex];
                  const homePlayerId = videoData[homePlayerIdIndex];

                  return playerId === awayPlayerId || playerId === homePlayerId;
                })[0] ?? [];

              let opponent_head_shot_path;
              let opponent_player2_head_shot_path;
              const opponent_head_shot = opponentData['head_shot'];
              const opponent_player2_head_shot =
                opponentData['player2_head_shot'];

              const last_updated_date = new Date(
                opponentData.last_updated,
              ).toLocaleDateString('en-GB');

              const currentDate = new Date();
              const birthDate = opponentData['dob']
                ? new Date(opponentData['dob'])
                : new Date('2000-01-01');

              let actual_age =
                currentDate.getFullYear() - birthDate.getFullYear();

              const currentMonth = currentDate.getMonth();
              const birthMonth = birthDate.getMonth();
              const currentDay = currentDate.getDate();
              const birthDay = birthDate.getDate();

              if (
                currentMonth < birthMonth ||
                (currentMonth === birthMonth && currentDay < birthDay)
              ) {
                actual_age--;
              }

              if (
                opponent_head_shot &&
                !opponent_head_shot.startsWith('https')
              ) {
                opponent_head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${opponent_head_shot}`;
              } else {
                opponent_head_shot_path = opponent_head_shot;
              }

              if (
                opponent_player2_head_shot &&
                !opponent_player2_head_shot.startsWith('https')
              ) {
                opponent_player2_head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${opponent_player2_head_shot}`;
              } else {
                opponent_player2_head_shot_path = opponent_player2_head_shot;
              }

              const homePlayer = videoData[homePlayerIndex];
              const awayPlayer = videoData[awayPlayerIndex];

              let playerScore;
              let opponentScore;
              let updatedIndMatchData;

              // change home-away to player-opponent
              if (homePlayer === playerData.player_name) {
                playerScore = videoData[homePlayerMatchScoreIndex];
                opponentScore = videoData[awayPlayerMatchScoreIndex];
              } else {
                playerScore = videoData[awayPlayerMatchScoreIndex];
                opponentScore = videoData[homePlayerMatchScoreIndex];
              }
              updatedIndMatchData = indMatchData.map(
                ({ homeScore, awayScore }) => ({
                  [homePlayer]: homeScore,
                  [awayPlayer]: awayScore,
                }),
              );

              return {
                matchId: videoData[matchIdIndex],
                videoName: videoData[videoNameIndex],
                matchDescription: videoData[matchDescriptionIndex],
                stage: videoData[stageIndex],
                transformedMatchDate: dateWithoutTime,
                location: videoData[locationIndex],
                transformedFileURL: newFileUrl,
                transformedTNUrl: newTNUrl,
                report: videoData[reportIndex],
                subEvent: videoData[subEventIndex],
                opponentGender: opponentData['gender'],
                opponentId: opponentData['player_id'] ?? 0,
                opponentName: opponentData['player_name'] ?? '_',
                opponentCountry: opponentData['country'],
                opponentCountryCode: opponentData['country_code'],
                opponentAge: actual_age,
                opponentLastUpdated: last_updated_date,
                opponentHand: opponentData['handedness'],
                opponentStyle: opponentData['grip'],
                opponentImg: opponent_head_shot_path,
                opponentPlayer2Img: opponent_player2_head_shot_path,
                opponentScore: opponentScore,
                playerScore: playerScore,
                roundData:
                  updatedIndMatchData.length > 0
                    ? updatedIndMatchData
                    : [{ [homePlayer]: 0, [awayPlayer]: 0 }],
              };
            }),
          );
        }

        setVideoList(transformedList);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMatchData();
  }, []);

  return (
    <div className="analysis-video-table-container">
      {videoList &&
        videoList.map((row) => (
          <PlayerVideoBlock
            matchDetails={row}
            playerData={playerData}
            accountType={accountType}
            videoPage={false}
          />
        ))}
      <p className="padding"></p>
    </div>
  );
};
export default PlayerVideos;
