import { useParams } from 'react-router-dom';
import './PlayerMatchAnalysis.scss';
import { useState, useEffect } from 'react';
import { fetchDropPointsAPI } from 'src/api/videoService';
import SimpleHeatmap from 'src/components/Heatmap/SimpleHeatmap';

import Cookies from 'js-cookie';

const hmOptions = [
  'all',
  '1',
  '2',
  '3',
  'winning',
  'losing',
  'all',
  '1',
  '2',
  '3',
  'winning',
  'losing',
];

export default function PlayerMatchAnalysis(): React.JSX.Element {
  const { videoId } = useParams();
  const [indDpHmResults, setIndDpHmResults] = useState(null);
  const heatmapClass = 'main-hm-plot-container';

  const token = Cookies.get('token');

  // useEffect(() => {
  //   const fetchDpData = async () => {
  //     try {
  //       const responseData = await fetchDropPointsAPI(
  //         {
  //           video_hm: true,
  //           videoId: Number(videoId),
  //           player_name: null,
  //           HM_option: null,
  //           Split_RoundBall: null,
  //           match_option: null,
  //           opponent_option: null,
  //         },
  //         String(token),
  //       );
  //       // last ball will have null receive
  //       setIndDpHmResults(responseData);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchDpData();
  // }, []);

  return (
    <div className="match-analysis-main-container">
      {/* {indDpHmResults &&
        indDpHmResults[0] &&
        Object.keys(hmOptions).map((option) => (
          <SimpleHeatmap
            key={option}
            dpData={indDpHmResults[option]}
            playerName="no"
            hmOptions={hmOptions[parseInt(option)]}
            heatmapClass={heatmapClass}
            giant={false}
          />
        ))} */}
    </div>
  );
}
