import { useState } from 'react';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import UserActivities from './UserActivities';
import UserManagement from './UserManagement';

function HomePage(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Back to '}
      <Link color="inherit" href="/">
        Home Page
      </Link>{' '}
      {'.'}
    </Typography>
  );
}

const Users = ({ userData, setRefreshData }) => {
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabContent = () => {
    switch (value) {
      case 'one':
        return (
          <UserManagement userData={userData} setRefreshData={setRefreshData} />
        );
      case 'two':
        return <UserActivities />;
      default:
        return null;
    }
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab value="one" label="User Management" />
        <Tab value="two" label="User Activities (fake data)" />
      </Tabs>
      <Container sx={{ mt: 4, mb: 4, maxWidth: '100% !important' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              {renderTabContent()}
            </Paper>
          </Grid>
        </Grid>
        <HomePage sx={{ pt: 4 }} />
      </Container>
    </Box>
  );
};

export default Users;
