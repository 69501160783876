// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-summary-panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--small-font-size);
}
.round-summary-panel .round-input-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e4e7d8;
  border-radius: var(--small-font-size);
}
.round-summary-panel .round-input-panel.flashing {
  animation: flash 2.5s linear infinite;
}
.round-summary-panel .round-input-panel button {
  background-color: transparent;
  border: 0;
  color: red;
  cursor: pointer;
}
@keyframes flash {
  0%, 100% {
    background-color: #fac7c7;
  }
  50% {
    background-color: #e4e7d8;
  }
}`, "",{"version":3,"sources":["webpack://./src/videoPages/VideoInterface/VideoDynamicPanel/ManualInput/EditRoundPanel.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,qCAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,qCAAA;AAEJ;AADI;EACE,qCAAA;AAGN;AADI;EACE,6BAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;AAGN;AAAI;EACE;IAEE,yBAAA;EACN;EACI;IACE,yBAAA;EACN;AACF","sourcesContent":[".round-summary-panel {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: var(--small-font-size);\n  .round-input-panel {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    background-color: #e4e7d8;\n    border-radius: var(--small-font-size);\n    &.flashing {\n      animation: flash 2.5s linear infinite;\n    }\n    button {\n      background-color: transparent;\n      border: 0;\n      color: red;\n      cursor: pointer;\n    }\n\n    @keyframes flash {\n      0%,\n      100% {\n        background-color: #fac7c7;\n      }\n      50% {\n        background-color: #e4e7d8;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
