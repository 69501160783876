// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-activities-container {
  max-height: 80vh;
  overflow: auto;
}
.recent-activities-container button {
  color: var(--apple-grey);
  border: 1px solid var(--apple-green);
  border-radius: 14px;
  background-color: var(--apple-green);
  padding: var(--xsmall-font-size);
  margin: var(--xsmall-font-size);
  font-size: var(--small-font-size);
  float: inline-end;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/DashBoard/RecentActivities.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;AACF;AAAE;EACE,wBAAA;EACA,oCAAA;EACA,mBAAA;EACA,oCAAA;EACA,gCAAA;EACA,+BAAA;EACA,iCAAA;EACA,iBAAA;EACA,eAAA;AAEJ","sourcesContent":[".recent-activities-container {\n  max-height: 80vh;\n  overflow: auto;\n  button {\n    color: var(--apple-grey);\n    border: 1px solid var(--apple-green);\n    border-radius: 14px;\n    background-color: var(--apple-green);\n    padding: var(--xsmall-font-size);\n    margin: var(--xsmall-font-size);\n    font-size: var(--small-font-size);\n    float: inline-end;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
