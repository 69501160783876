import { useState, useMemo, useRef } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Heatmap from 'src/playerPages/HeatMap/HeatMap';

import { formatTime } from 'src/utils/utils';

import './VideoAnalysisPanel.scss';

import { Chart } from 'react-google-charts';
import {
  ballDataIndex,
  ballEndTimeIndex,
  ballStartTimeIndex,
} from 'src/constants';

import Cookies from 'js-cookie';

import { updateDPDataAPI } from 'src/api/videoService';

const VideoAnalysisPanel = ({
  dpResults,
  dpIndex,
  ttPlotHeight,
  ttPlotWidth,
  homePlayer,
  awayPlayer,
  matchId,
  videoData,
  matchData,
  roundResults,
  setVideoData,
  isAdmin,
}) => {
  const token = Cookies.get('token');

  const chartRef = useRef(null);
  const heatmapClass = 'main-hm-plot-container';
  const [clickPositions, setClickPositions] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const currentBall = videoData.currentBall !== -1 ? videoData.currentBall : 0;
  const currentRound = videoData.currentRound;

  const toggleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };
  // chart options for trajectory plot
  const options = {
    width: ttPlotWidth,
    height: ttPlotHeight,
    legend: 'none',
    seriesType: 'line',
    chartArea: {
      width: '100%',
      height: '100%',
      backgroundColor: {
        fill: '#00ff00',
        fillOpacity: 0.55,
      },
    },
    backgroundColor: '#161616',
    series: {
      1: { type: 'scatter', pointSize: 5 },
      2: { type: 'scatter', pointSize: 8 },
    },
    vAxis: {
      viewWindow: {
        max: 1280,
        min: 0,
      },
      ticks: [0, 320, 640, 960, 1280],
      textPosition: 'none',
      textStyle: { color: '#ffffff' },
      baselineColor: '#ffffff',
      gridlineColor: '#ffffff',
    },
    hAxis: {
      viewWindow: {
        max: 800,
        min: 0,
      },
      ticks: [0, 266, 533, 800],
      textPosition: 'none',
      textStyle: { color: '#ffffff' },
      baselineColor: '#ffffff',
      gridlineColor: '#ffffff',
    },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
    colors: ['#000000', '#ffffff', '#E50914'],
  };

  const memoizedChart = useMemo(() => {
    console.log(dpResults);
    if (
      dpResults &&
      dpResults[dpIndex] &&
      dpResults[dpIndex][1] &&
      dpResults[dpIndex][1][3]
    ) {
      // Extract the subarrays starting from index 3 and only keep elements at index 0 and 1
      const extractedArray = dpResults[dpIndex][1].slice(3).map((subArr) => ({
        x: (subArr[0] / 800) * 100 + '%',
        y: 100 - (subArr[1] / 1280) * 100 + '%',
      }));

      setClickPositions(extractedArray);

      return (
        <>
          <span style={{ color: 'white' }}>top/right</span>
          <Chart
            chartType="ComboChart"
            data={dpResults[dpIndex][1]}
            options={options}
          />
          <span style={{ color: 'white' }}>bottom/left</span>
        </>
      );
    } else {
      return <p className="missing-dp">無法檢測到落點。</p>;
    }
  }, [dpIndex, isEdit, ttPlotHeight, ttPlotWidth]);

  const editDpPanel = () => {
    const handleChartClick = (indexToDelete) => {
      // Delete the position at the specified index
      setClickPositions((prevPositions) =>
        prevPositions.filter((_, idx) => idx !== indexToDelete),
      );
    };

    const handleClearAll = () => {
      setClickPositions([]);
    };

    const handleSaveDp = () => {
      const save = async () => {
        const convertedArray = clickPositions.map((pos) => [
          Math.round((parseFloat(pos.x) / 100) * 800), // Reversing x calculation
          Math.round((parseFloat(pos.y) / 100) * 1280), // Reversing y calculation
        ]);

        try {
          const responseData = await updateDPDataAPI(
            {
              match_id: matchId,
              round_number: currentRound,
              dp_index: dpIndex,
              dp: JSON.stringify(convertedArray),
            },
            token,
          );
          setVideoData((prevState) => ({
            ...prevState,
            refresh: true,
          }));
          // Set a timeout to toggle isEdit to false after 0.5 seconds
          setTimeout(() => {
            setIsEdit(false);
          }, 500); // 500 milliseconds = 0.5 seconds
        } catch (error) {
          console.log(error);
        }
      };

      save();
    };

    const handleAddClick = (event) => {
      // Get the position of the Chart component relative to the document
      const chartRect = chartRef.current.getBoundingClientRect();

      // Calculate the click position relative to the Chart component
      const xPos = `${
        ((event.clientX - chartRect.left) / chartRect.width) * 100
      }%`;
      const yPos = `${
        ((event.clientY - chartRect.top) / chartRect.height) * 100
      }%`;

      // Update the positions array by adding a new position object
      setClickPositions((prevPositions) => [
        ...prevPositions,
        { x: xPos, y: yPos },
      ]);
    };

    return (
      <div className="edit-dp-container">
        {roundResults[currentRound] &&
        roundResults[currentRound][ballDataIndex] &&
        roundResults[currentRound][ballDataIndex][currentBall] ? (
          <>
            <div className="edit-dp-options-container">
              <p>
                <span style={{ color: 'brown' }}> 第{currentRound + 1}輪 </span>
                <span style={{ color: 'red' }}>第{currentBall + 1}球</span> 從 ({' '}
                {formatTime(
                  roundResults[currentRound][ballDataIndex][currentBall][
                    ballStartTimeIndex
                  ],
                )}{' '}
                :{' '}
                {formatTime(
                  roundResults[currentRound][ballDataIndex][currentBall][
                    ballEndTimeIndex
                  ],
                )}
                )
              </p>
              <div className="button-panel">
                <button
                  style={{ color: 'white', cursor: 'pointer' }}
                  onClick={handleSaveDp}
                >
                  儲存
                </button>
                <button
                  style={{ backgroundColor: 'orange', cursor: 'pointer' }}
                  onClick={toggleEdit}
                >
                  返回
                </button>
                <button
                  style={{ backgroundColor: 'red', cursor: 'pointer' }}
                  onClick={handleClearAll}
                >
                  清除所有
                </button>
              </div>
            </div>
            <span style={{ color: 'white' }}>top/right</span>
            <div
              class="table-tennis-table"
              ref={chartRef}
              onClick={(event) => {
                handleAddClick(event);
              }}
            >
              <div class="middle-line"></div>
              <div class="table-row">
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell"></div>
              </div>
              <div class="table-row">
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell"></div>
              </div>
              <div class="table-row">
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell"></div>
              </div>
              <div class="table-row">
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell"></div>
              </div>

              {clickPositions.map((position, index) => (
                <div
                  key={index}
                  className="rttttt"
                  style={{
                    position: 'absolute',
                    top: position.y,
                    left: position.x,
                    transform: 'translate(0%, -50%)', // Center the dot
                  }}
                >
                  <div
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'transparent',
                      color: 'orange',
                      fontSize: '2rem',
                      borderRadius: '50%',
                      transform: 'translate(-50%, 0%)',
                    }}
                  >
                    •
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'black',
                      color: 'white',
                      padding: '4px',
                      borderRadius: '4px',
                      marginLeft: '4px',
                    }}
                  >
                    {index + 1}
                    <span
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                        marginLeft: '4px',
                      }}
                      onClick={(event) => {
                        event.stopPropagation(); // Stop event propagation
                        handleChartClick(index);
                      }}
                    >
                      &times;
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <span style={{ color: 'white' }}>bottom/left</span>
          </>
        ) : (
          <p>no rounds</p>
        )}
      </div>
    );
  };

  return (
    <div className="video-analysis-main-container">
      {matchData.progress >= 90 ? (
        <div>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>軌跡圖</Typography>
            </AccordionSummary>
            {isEdit ? (
              <>{editDpPanel()}</>
            ) : (
              <div className="trajectory-plot-container">
                {' '}
                {roundResults[currentRound][ballDataIndex][currentBall] && (
                  <button onClick={toggleEdit}>
                    <span style={{ color: 'white', cursor: 'pointer' }}>
                      編輯
                      <span style={{ color: 'black' }}>
                        {' '}
                        第{currentRound + 1}輪{' '}
                      </span>
                      <span style={{ color: 'orange' }}>
                        第{currentBall + 1}球
                      </span>{' '}
                      從 ({' '}
                      {formatTime(
                        roundResults[currentRound][ballDataIndex][currentBall][
                          ballStartTimeIndex
                        ],
                      )}{' '}
                      :{' '}
                      {formatTime(
                        roundResults[currentRound][ballDataIndex][currentBall][
                          ballEndTimeIndex
                        ],
                      )}
                      )
                    </span>
                  </button>
                )}{' '}
                {memoizedChart}
              </div>
            )}
          </Accordion>
          {isAdmin && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>落點熱度圖</Typography>
              </AccordionSummary>
              <div className="video-heatmap-container">
                <div className="video-heatmap-player-name">
                  <p>{homePlayer.slice(0, 15)}</p>
                  <p>{awayPlayer.slice(0, 15)}</p>
                </div>

                <div className="analysis-result-panel">
                  <Heatmap
                    videoId={matchId}
                    heatmapClass={heatmapClass}
                    disableClose={true}
                    homePlayer={homePlayer}
                    awayPlayer={awayPlayer}
                  />
                </div>
              </div>
            </Accordion>
          )}
        </div>
      ) : (
        <p>無落點</p>
      )}
      <p className="padding"></p>
    </div>
  );
};

export default VideoAnalysisPanel;
