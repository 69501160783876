// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  height: -moz-fit-content;
  height: fit-content;
}
.chart-container .chart-panel {
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
}
@media (orientation: landscape) and (max-width: 932px) {
  .chart-container .chart-panel tspan {
    font-size: var(--xsmall-font-size);
  }
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/DashBoard/Chart.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EAAA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,wBAAA;EAAA,mBAAA;AAEJ;AADI;EACE;IACE,kCAAA;EAGN;AACF","sourcesContent":[".chart-container {\n  height: fit-content;\n  .chart-panel {\n    display: flex;\n    height: fit-content;\n    @media (orientation: landscape) and (max-width: 932px) {\n      tspan {\n        font-size: var(--xsmall-font-size);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
