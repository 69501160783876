// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.match-score-table {
  max-width: 95%;
  display: flex;
  justify-content: center;
}
.match-score-table tbody {
  border: 1px solid white;
  font-size: var(--small-font-size);
}
.match-score-table tbody th {
  font-weight: 300;
  width: 10%;
  border-right: 1px solid white;
}
.match-score-table tbody tr:first-child {
  background-color: transparent;
  color: white;
}
.match-score-table tbody tr:not(:last-child) th {
  border-bottom: 1px solid white;
}
.match-score-table tbody tr:not(:last-child) td {
  border-bottom: 1px solid white;
}
.match-score-table tbody td:not(:last-child) {
  border-right: 1px solid white;
}
.match-score-table td {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: calc(var(--scorePanel-width) / 2 - 10);
  text-align: center;
}
.match-score-table .HomePlayer-score {
  cursor: pointer;
}
.match-score-table .AwayPlayer-score {
  cursor: pointer;
}

@media screen and (orientation: portrait) {
  .match-score-table td {
    min-width: 30vw;
    max-width: 30vw;
  }
  .match-score-table tbody {
    font-size: var(--medium-font-size);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MatchScoreBoard/MatchScoreBoard.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,uBAAA;AACF;AACE;EACE,uBAAA;EACA,iCAAA;AACJ;AACI;EACE,gBAAA;EACA,UAAA;EACA,6BAAA;AACN;AAEI;EACE,6BAAA;EACA,YAAA;AAAN;AAIM;EACE,8BAAA;AAFR;AAIM;EACE,8BAAA;AAFR;AAKI;EACE,6BAAA;AAHN;AAOE;EACE,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,6CAAA;EACA,kBAAA;AALJ;AAQE;EACE,eAAA;AANJ;AAQE;EACE,eAAA;AANJ;;AAUA;EAEI;IACE,eAAA;IACA,eAAA;EARJ;EAUE;IACE,kCAAA;EARJ;AACF","sourcesContent":[".match-score-table {\n  max-width: 95%;\n  display: flex;\n  justify-content: center;\n\n  tbody {\n    border: 1px solid white;\n    font-size: var(--small-font-size);\n\n    th {\n      font-weight: 300;\n      width: 10%;\n      border-right: 1px solid white;\n    }\n\n    tr:first-child {\n      background-color: transparent;\n      color: white;\n    }\n\n    tr:not(:last-child) {\n      th {\n        border-bottom: 1px solid white;\n      }\n      td {\n        border-bottom: 1px solid white;\n      }\n    }\n    td:not(:last-child) {\n      border-right: 1px solid white;\n    }\n  }\n\n  td {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    text-align: left;\n    width: calc(var(--scorePanel-width) / 2 - 10);\n    text-align: center;\n  }\n\n  .HomePlayer-score {\n    cursor: pointer;\n  }\n  .AwayPlayer-score {\n    cursor: pointer;\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .match-score-table {\n    td {\n      min-width: 30vw;\n      max-width: 30vw;\n    }\n    tbody {\n      font-size: var(--medium-font-size);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
