import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

import './PlayerHomeFilter.scss';

import { countries } from 'src/constants';

const PlayerHomeFilterOption = ({
  setFilterCriteria,
  appliedFilterCriteria,
}) => {
  // temp replicate of parent , dont want to update while selecting
  const [selectedCountries, setSelectedCountries] = useState(() => {
    const cachedCountries = localStorage.getItem('playerFilterCriteria');
    return cachedCountries ? JSON.parse(cachedCountries).country : countries;
  });

  // if close without clicking apply, reset
  const handlePopupClose = (byApplyFilter) => {
    if (!byApplyFilter) {
      // reset by reapplying the original
      // setSelectedFilterOptions(appliedFilterCriteria);
    }
  };

  // update the criteria
  const handleApplyOptions = () => {
    const updatedFilterCriteria = {
      ...appliedFilterCriteria,
      country: selectedCountries,
    };
    setFilterCriteria(updatedFilterCriteria);
  };

  const handleFilterChange = (event, filterType) => {
    const value = event.target.value;

    const toggleValueInArray = (value, array) => {
      const index = array.indexOf(value);
      if (index > -1) {
        // Value is already in the array, so remove it
        array.splice(index, 1);
      } else {
        // Value is not in the array, so append it
        array.push(value);
      }
      // Return the updated array
      return [...array]; // Create a new array to trigger state update
    };

    if (filterType === 'countries') {
      setSelectedCountries((prevSelectedCountries) =>
        toggleValueInArray(value, [...prevSelectedCountries]),
      );
    }
  };

  const handleSelectAll = () => {
    setSelectedCountries([...countries]);
  };

  const handleClearAll = () => {
    setSelectedCountries([]);
  };

  useEffect(() => {
    setSelectedCountries(appliedFilterCriteria['country']);
  }, [appliedFilterCriteria]);

  return (
    <div className="filter-player-home-panel">
      <Popup
        trigger={
          <button className="popup-button">
            <FilterListIcon /> 篩選器
          </button>
        }
        modal
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="player-home-filter-popup-main-content">
            <button
              className="close-button"
              onClick={() => {
                close();
                handlePopupClose(false);
              }}
            >
              <CloseIcon />
            </button>
            <FormControl>
              <Grid container>
                <Accordion className="countries-filter-container">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>选择國家/地區</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <div className="countries-options">
                          <button
                            className="select-all-button"
                            onClick={() => handleSelectAll()}
                          >
                            Select All
                          </button>
                          <button
                            className="clear-all-button"
                            onClick={() => handleClearAll()}
                          >
                            Clear All
                          </button>
                        </div>
                        {countries.map((country) => (
                          <FormControlLabel
                            key={country}
                            control={
                              <Checkbox
                                checked={
                                  selectedCountries.indexOf(country) > -1
                                }
                                onChange={(event) =>
                                  handleFilterChange(event, 'countries')
                                }
                                value={country}
                              />
                            }
                            label={country}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </FormControl>
            <button
              className="apply-filter"
              onClick={() => {
                handleApplyOptions();
                close();
                handlePopupClose(true);
              }}
            >
              確認
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default PlayerHomeFilterOption;
