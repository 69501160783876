// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-chart-option {
  width: 100%;
}
.admin-chart-option .MuiInputBase-root {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/DashBoard/ChartOption.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;AAEI;EACI,WAAA;AAAR","sourcesContent":["\n\n.admin-chart-option{\n    width: 100%;\n    .MuiInputBase-root{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
