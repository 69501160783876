import React, { useRef, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  TextField,
  IconButton,
  Chip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PushPinIcon from '@mui/icons-material/PushPin';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const categories = ['本地', '國際', '參考', '重點']; // , '已分析' removed

const MiniVideoCard = ({
  videoData,
  handleCardClick,
  onCategoryChange,
  onPinToTop,
}) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeoutRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeoutRef.current);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
    hoverTimeoutRef.current = setTimeout(() => {
      if (videoRef.current && videoRef.current.play) {
        videoRef.current.play().catch((error) => {
          console.error('Failed to play video:', error);
        });
      }
    }, 2000);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    clearTimeout(hoverTimeoutRef.current);
    if (videoRef.current && videoRef.current.pause) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const getCategoryValue = (video_category) => {
    return Array.isArray(video_category) && video_category.length > 0
      ? video_category
      : [];
  };

  //  disable pin feature
  const handlePinClick = () => {
    if (getCategoryValue(videoData.video_category).length === 0) {
      setOpenSnackbar(true);
    } else {
      console.log(`Pin status changed for video ${videoData.videoName} to ${!videoData.top}`);
      onPinToTop(videoData.matchId);
    }
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column', // Change to column for better mobile layout
          marginBottom: 2,
          width: '100%',
          height: 'auto', // Let height be determined by content
          cursor: 'pointer',
          '@media (min-width: 600px)': {
            flexDirection: 'row', // Change back to row for larger screens
            height: '150px', // Set fixed height for larger screens
          },
        }}
      >
        <Box
          onClick={() => handleCardClick(videoData)}
          sx={{
            display: 'flex',
            flexDirection: 'column', // Change to column for better mobile layout
            alignItems: 'center',
            width: '100%',
            padding: '8px',
            '@media (min-width: 600px)': {
              flexDirection: 'row', // Change back to row for larger screens
            },
          }}
        >
          <CardMedia
            component={isHovered ? 'video' : 'img'}
            ref={videoRef}
            image={
              !isHovered &&
              videoData.TNUrl &&
              videoData.TNUrl !== 'none' &&
              videoData.TNUrl !== ''
                ? videoData.TNUrl
                : undefined
            }
            src={isHovered ? videoData.fileUrl : undefined}
            alt="Video preview"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            muted={isHovered}
            autoPlay={isHovered}
            preload="none"
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              '@media (min-width: 600px)': {
                width: '200px',
                height: '100%',
              },
            }}
          />
          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {videoData.videoName}
            </Typography>
            <Typography variant="body2">By: {videoData.uploadUser}</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {getCategoryValue(videoData.video_category).map(
                (category, index) => (
                  <Chip key={index} label={category} />
                ),
              )}
            </Box>
            <Autocomplete
              multiple
              options={categories}
              value={getCategoryValue(videoData.video_category)}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, newValue) => {
                console.log(
                  `Category changed for video ${videoData.videoName} to ${newValue}`,
                );
                onCategoryChange(videoData.matchId, newValue);
                if (newValue.length === 0 && videoData.top) {
                  onPinToTop(videoData.matchId);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Categories" variant="standard" />
              )}
              size="small"
              sx={{ marginTop: 1, minWidth: 120 }}
            />
          </CardContent>
          {/* disable pin feature */}
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
            <IconButton onClick={handlePinClick}>
              <PushPinIcon sx={{ color: videoData.top ? 'blue' : 'inherit' }} />
            </IconButton>
          </Box>
        </Box>
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          你需要添加類別然後才能釘選
        </Alert>
      </Snackbar>
    </>
  );
};

export default MiniVideoCard;



// drag function did not fix
// ---------------------------------------------------
// import React, { useRef, useState, useEffect } from 'react';
// import {
//   Card,
//   CardContent,
//   CardMedia,
//   Typography,
//   Box,
//   TextField,
//   IconButton,
//   Chip,
// } from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';
// import PushPinIcon from '@mui/icons-material/PushPin';
// import Alert from '@mui/material/Alert';
// import Snackbar from '@mui/material/Snackbar';
// import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// import { Draggable } from 'react-beautiful-dnd';

// const categories = ['本地', '國際', '參考', '重點']; // , '已分析' removed

// const MiniVideoCard = ({
//   videoData,
//   handleCardClick,
//   onCategoryChange,
//   onPinToTop,
//   index,
// }) => {
//   const videoRef = useRef(null);
//   const [isHovered, setIsHovered] = useState(false);
//   const hoverTimeoutRef = useRef(null);
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   useEffect(() => {
//     return () => {
//       clearTimeout(hoverTimeoutRef.current);
//     };
//   }, []);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//     hoverTimeoutRef.current = setTimeout(() => {
//       if (videoRef.current && videoRef.current.play) {
//         videoRef.current.play().catch((error) => {
//           console.error('Failed to play video:', error);
//         });
//       }
//     }, 2000);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//     clearTimeout(hoverTimeoutRef.current);
//     if (videoRef.current && videoRef.current.pause) {
//       videoRef.current.pause();
//       videoRef.current.currentTime = 0;
//     }
//   };

//   const getCategoryValue = (video_category) => {
//     return Array.isArray(video_category) && video_category.length > 0
//       ? video_category
//       : [];
//   };

//   const handlePinClick = () => {
//     if (getCategoryValue(videoData.video_category).length === 0) {
//       setOpenSnackbar(true);
//     } else {
//       console.log(`Pin status changed for video ${videoData.videoName} to ${!videoData.top}`);
//       onPinToTop(videoData.matchId);
//     }
//   };

//   return (
//     <Draggable draggableId={videoData.matchId} index={index}>
//       {(provided) => (
//         <Card
//           ref={provided.innerRef}
//           {...provided.draggableProps}
//           {...provided.dragHandleProps}
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             marginBottom: 2,
//             width: '100%',
//             height: 'auto',
//             cursor: 'pointer',
//             '@media (min-width: 600px)': {
//               flexDirection: 'row',
//               height: '150px',
//             },
//           }}
//         >
//           <Box
//             onClick={() => handleCardClick(videoData)}
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               width: '100%',
//               padding: '8px',
//               '@media (min-width: 600px)': {
//                 flexDirection: 'row',
//               },
//             }}
//           >
//             <CardMedia
//               component={isHovered ? 'video' : 'img'}
//               ref={videoRef}
//               image={
//                 !isHovered &&
//                 videoData.TNUrl &&
//                 videoData.TNUrl !== 'none' &&
//                 videoData.TNUrl !== ''
//                   ? videoData.TNUrl
//                   : undefined
//               }
//               src={isHovered ? videoData.fileUrl : undefined}
//               alt="Video preview"
//               onMouseEnter={handleMouseEnter}
//               onMouseLeave={handleMouseLeave}
//               muted={isHovered}
//               autoPlay={isHovered}
//               preload="none"
//               sx={{
//                 width: '100%',
//                 height: 'auto',
//                 objectFit: 'cover',
//                 '@media (min-width: 600px)': {
//                   width: '200px',
//                   height: '100%',
//                 },
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 width: '100%',
//               }}
//             >
//               <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
//                 {videoData.videoName}
//               </Typography>
//               <Typography variant="body2">By: {videoData.uploadUser}</Typography>
//               <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                 {getCategoryValue(videoData.video_category).map(
//                   (category, index) => (
//                     <Chip key={index} label={category} />
//                   ),
//                 )}
//               </Box>
//               <Autocomplete
//                 multiple
//                 options={categories}
//                 value={getCategoryValue(videoData.video_category)}
//                 isOptionEqualToValue={(option, value) => option === value}
//                 onChange={(event, newValue) => {
//                   console.log(
//                     `Category changed for video ${videoData.videoName} to ${newValue}`,
//                   );
//                   onCategoryChange(videoData.matchId, newValue);
//                   if (newValue.length === 0 && videoData.top) {
//                     onPinToTop(videoData.matchId);
//                   }
//                 }}
//                 renderInput={(params) => (
//                   <TextField {...params} label="Categories" variant="standard" />
//                 )}
//                 size="small"
//                 sx={{ marginTop: 1, minWidth: 120 }}
//               />
//             </CardContent>
//             <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
//               <IconButton onClick={handlePinClick}>
//                 <PushPinIcon sx={{ color: videoData.top ? 'blue' : 'inherit' }} />
//               </IconButton>
//             </Box>
//             <Box {...provided.dragHandleProps} sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
//               <DragIndicatorIcon />
//             </Box>
//           </Box>
//         </Card>
//       )}
//     </Draggable>
//   );
// };

// export default MiniVideoCard;
