import { useState } from 'react';

import Rating from '@mui/material/Rating';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import StarIcon from '@mui/icons-material/Star';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { formatTime } from 'src/utils/utils';
import VideoHighlightEditingPanel from './VideoHighlightEditingPanel';
import { deleteHighlightDataAPI } from 'src/api/videoService';

import Cookies from 'js-cookie';

import './VideoHighlightCommentary.scss';

import {
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  highlightDataIndex,
  highlightRatingIndex,
  highlightCommentIndex,
  highlightOwnerIndex,
  highlightIdIndex,
  roundDataIndex,
  roundIdIndex,
  roundMatchIdIndex,
} from 'src/constants';

const VideoHighlightCommentary = ({
  userInfo,
  roundData,
  ballStartTime,
  ballEndTime,
  videoRef,
  currentTransformation,
  setVideoData,
}) => {
  const token = Cookies.get('token');

  const currentTime =
    videoRef && videoRef.current ? videoRef.current.currentTime : ballStartTime;

  const [expanded, setExpanded] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [highlightIndexToEdit, setHighlightIndexToEdit] = useState(-1);

  const permission = userInfo.isCoach || userInfo.admin;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleDelete = async (highlight_id) => {
    try {
      const response = await deleteHighlightDataAPI(
        {
          highlight_id: highlight_id,
        },
        token,
      );
      setIsAddNew(false);
      setHighlightIndexToEdit(-1);
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddHightlight = () => {
    setIsAddNew(!isAddNew);
  };

  const handleEditHightlight = (highlightIndex) => {
    setHighlightIndexToEdit(highlightIndex);
  };

  return (
    <div className="video-highlight-main-container">
      {permission && (
        <div className="highlight-menu-panel">
          {isAddNew ? (
            <div className="new-editing-panel">
              <button
                className="edit-highlight-back-button"
                title="新增亮點"
                onClick={() => handleAddHightlight()}
              >
                <ArrowBackOutlinedIcon />
              </button>
              <VideoHighlightEditingPanel
                highlight_id={-1}
                round_id={roundData[roundDataIndex][roundIdIndex]}
                match_id={roundData[roundDataIndex][roundMatchIdIndex]}
                videoRef={videoRef}
                owner={userInfo.identifier}
                start_time={0}
                end_time={0}
                description=""
                hl_rating={0}
                transformation={currentTransformation}
                setVideoData={setVideoData}
                setHighlightIndexToEdit={setHighlightIndexToEdit}
                setIsAddNew={setIsAddNew}
              />
            </div>
          ) : (
            <div className="highlight-button-panel">
              <span>新增亮點</span>
              <button title="新增亮點" onClick={() => handleAddHightlight()}>
                <AddOutlinedIcon />
              </button>
            </div>
          )}
        </div>
      )}
      {roundData && roundData[2]
        ? roundData[highlightDataIndex].map(
            (highlightResult, highlightIndex) => (
              <div
                className="highlight-commentary-ind-container"
                key={highlightIndex}
              >
                {highlightIndexToEdit === -1 && permission && (
                  <div className="highlight-button-panel">
                    <button
                      className="edit-highlight-button"
                      title="修亮點"
                      onClick={() => handleEditHightlight(highlightIndex)}
                    >
                      <EditOutlinedIcon />
                    </button>
                    <button
                      className="delete-highlight-button"
                      title="刪除亮點"
                      onClick={() =>
                        handleDelete(highlightResult[0][highlightIdIndex])
                      }
                    >
                      <DeleteForeverOutlinedIcon />
                    </button>
                  </div>
                )}

                {highlightIndex === highlightIndexToEdit && permission ? (
                  <div className="editing-panel">
                    <button
                      className="edit-highlight-back-button"
                      title="新增亮點"
                      onClick={() => handleEditHightlight(-1)}
                    >
                      <ArrowBackOutlinedIcon />
                    </button>
                    <VideoHighlightEditingPanel
                      highlight_id={highlightResult[0][highlightIdIndex]}
                      round_id={roundData[roundDataIndex][roundIdIndex]}
                      match_id={roundData[roundDataIndex][roundMatchIdIndex]}
                      videoRef={videoRef}
                      owner={userInfo.identifier}
                      start_time={highlightResult[0][highlightStartTimeIndex]}
                      end_time={highlightResult[0][highlightEndTimeIndex]}
                      description={highlightResult[0][highlightCommentIndex]}
                      hl_rating={highlightResult[0][highlightRatingIndex]}
                      transformation={currentTransformation}
                      setVideoData={setVideoData}
                      setHighlightIndexToEdit={setHighlightIndexToEdit}
                      setIsAddNew={setIsAddNew}
                    />
                  </div>
                ) : (
                  <Accordion
                    expanded={expanded === `panel${highlightIndex}`}
                    onChange={handleChange(`panel${highlightIndex}`)}
                    sx={{ width: '100%' }}
                  >
                    <AccordionSummary
                      aria-controls={`panel${highlightIndex}bh-content`}
                      id={`panel${highlightIndex}bh-header`}
                      sx={{ width: '100%' }}
                    >
                      <div className="rating">
                        <Rating
                          className="stars"
                          name="read-only"
                          readOnly
                          value={parseInt(
                            highlightResult[0][highlightRatingIndex],
                          )}
                          precision={0.5}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        {currentTime >=
                          highlightResult[0][highlightStartTimeIndex] &&
                          currentTime <=
                            highlightResult[0][highlightEndTimeIndex] && (
                            <span className="current-highlight">
                              <ReportGmailerrorredIcon className="is-playing" />
                            </span>
                          )}
                        <span className="highlight-owner">
                          {highlightResult[0][highlightOwnerIndex]}
                        </span>
                      </div>
                      <div className="highlight-time">
                        <span>
                          开始:{' '}
                          {formatTime(
                            highlightResult[0][highlightStartTimeIndex],
                          )}
                        </span>
                        <span>
                          结束:{' '}
                          {formatTime(
                            highlightResult[0][highlightEndTimeIndex],
                          )}
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="comment">
                        {highlightResult[0][highlightCommentIndex]}
                      </p>
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            ),
          )
        : null}
    </div>
  );
};

export default VideoHighlightCommentary;
