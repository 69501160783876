// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recent-upload .upload-user {
  padding-bottom: var(--medium-font-size);
}
.recent-upload button {
  color: var(--apple-grey);
  border: 1px solid var(--apple-blue);
  border-radius: 14px;
  background-color: var(--apple-blue);
  padding: var(--xsmall-font-size);
  margin: var(--xsmall-font-size);
  cursor: pointer;
}

@media (orientation: portrait) {
  .recent-upload p {
    font-size: var(--medium-button-size);
  }
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/DashBoard/RecentUpload.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,uCAAA;AAEJ;AAAE;EACE,wBAAA;EACA,mCAAA;EACA,mBAAA;EACA,mCAAA;EACA,gCAAA;EACA,+BAAA;EACA,eAAA;AAEJ;;AAEA;EAEI;IACE,oCAAA;EAAJ;AACF","sourcesContent":[".recent-upload {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  .upload-user {\n    padding-bottom: var(--medium-font-size);\n  }\n  button {\n    color: var(--apple-grey);\n    border: 1px solid var(--apple-blue);\n    border-radius: 14px;\n    background-color: var(--apple-blue);\n    padding: var(--xsmall-font-size);\n    margin: var(--xsmall-font-size);\n    cursor: pointer;\n  }\n}\n\n@media (orientation: portrait) {\n  .recent-upload {\n    p {\n      font-size: var(--medium-button-size);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
