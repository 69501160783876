import {
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';

const getLastScore = (roundResults, roundIndex) => {
  const round = roundResults[roundIndex];
  if (round && round[1] && round[1].length > 0) {
    const mappedBallData = round[1];
    const lastBall = mappedBallData[mappedBallData.length - 1];
    return `${lastBall[ballHomePlayerScoreIndex]}:${lastBall[ballAwayPlayerScoreIndex]}`;
  }
  return 'N/A';
};

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

const detectDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const { outerWidth, outerHeight } = window;
  const aspectRatio = outerWidth / outerHeight;

  // Regular expression for mobile devices
  const mobileRegex =
    /iphone|ipod|android|iemobile|blackberry|webos|kindle|opera mini/i;

  // Regular expression for tablets
  const tabletRegex = /ipad|tablet|playbook|silk|\b(tablet)\b/i;

  // Check if the device is an iPad
  const isIPad =
    /ipad/i.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  // Check if it's a large screen device (potentially a tablet)
  const isLargeScreen = Math.min(outerWidth, outerHeight) >= 768;

  // Determine if it's a mobile device
  const isMobile = mobileRegex.test(userAgent);

  // Determine if it's a tablet
  const isTablet =
    tabletRegex.test(userAgent) ||
    isIPad ||
    (isLargeScreen && aspectRatio > 0.6 && aspectRatio < 1.6);

  return {
    checkedMobileBrowser: isMobile || isTablet,
    checkedMobile: isMobile,
  };
};

export default detectDevice;

export { formatTime, getLastScore, detectDevice };
