import Flag from 'react-world-flags';
import { Link } from 'react-router-dom';

import './PlayerCard.scss';

const translationDict = {
  M: '男單',
  MM: '男雙',
  F: '女單',
  FF: '女雙',
  MF: '混雙',
};

const PlayerCard = ({ playerData }) => {
  const code = playerData.countryCode;

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={`/player-analysis/${playerData.player_id}`}
      className="player-card-main-container"
    >
      <div className="player-card-container">
        <div className="player-card-top-container">
          {playerData.countryCode === 'TPE' ? (
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
              className="player-smallflag"
            />
          ) : code ? (
            <Flag code={code} className="player-smallflag" />
          ) : (
            <img
              src="https://t4.ftcdn.net/jpg/05/38/56/33/360_F_538563343_1Yb2J773QHkZSdWnIVhVxW4xjfOB8Rhi.jpg"
              alt="No image"
              className="player-smallflag"
            />
          )}

          <div className="player-photo-container">
            {playerData.head_shot === '' ||
            playerData.head_shot === null ||
            playerData.head_shot === 'None' ? (
              <div className="player-img-panel">
                <img
                  src="https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg"
                  alt="No image"
                  className="player-img"
                />
              </div>
            ) : (
              <div className="player-img-panel">
                {playerData.gender === 'MM' ||
                playerData.gender === 'FF' ||
                playerData.gender === 'MF' ? (
                  <div className="double-img-container">
                    <img
                      className="player1-img"
                      src={playerData.head_shot}
                      alt="Player Headshot"
                    />
                    <img
                      className="player2-img"
                      src={playerData.player2_head_shot}
                      alt="Player Headshot"
                    />
                  </div>
                ) : (
                  <img
                    className="player-img"
                    src={playerData.head_shot}
                    alt="Player Headshot"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="player-card-bot-container">
          <span className="player-card-name">{playerData.player_name}</span>
          <div className="player-info-container">
            <p className="country" style={{ color: '#99582a' }}>
              {playerData.country}
            </p>
            {playerData.gender !== null && (
              <>
                {(playerData.gender === 'F' || playerData.gender === 'M') && (
                  <p className="age">
                    年齡:{' '}
                    <span style={{ color: '#003049' }}>
                      {playerData.actual_age}
                    </span>
                  </p>
                )}
                <p className="gender" style={{ color: '#c1121f' }}>
                  {translationDict[playerData.gender]}
                </p>
              </>
            )}
            <p className="video-number">
              影片:{' '}
              <span style={{ color: '#669bbc' }}>
                {playerData.number_of_videos}{' '}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Link>
    // <Link
    //   style={{ textDecoration: 'none' }}
    //   to={`/player-analysis/${playerData.player_id}`}
    //   className="player-card-main-container"
    // >
    //   <span
    //     className="player-card-name-container"
    //     title={playerData.player_name}
    //   >
    //     <span className="player-card-name">{playerData.player_name}</span>
    //     {playerData.countryCode === 'TPE' && (
    //       <img
    //         src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
    //         className="player-smallflag"
    //       />
    //     )}
    //     {Flag ? <Flag className="player-smallflag" /> : null}
    //   </span>
    //   <div className="player-card-container">
    //     <div className="player-card-left-container">
    //       {playerData.head_shot === '' ||
    //       playerData.head_shot === null ||
    //       playerData.head_shot === 'None' ? (
    //         <div className="player-card-left-normal-panel">
    //           {Flag ? (
    //             <Flag className="player-Bigflag" />
    //           ) : playerData.countryCode === 'TPE' ? (
    //             <img
    //               src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
    //               className="player-Bigflag"
    //             />
    //           ) : (
    //             <img
    //               src="https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg"
    //               alt="No image"
    //               className="player-Bigflag"
    //             />
    //           )}
    //         </div>
    //       ) : (
    //         <div className="player-card-left-img-panel">
    //           {playerData.gender === 'MM' ||
    //           playerData.gender === 'FF' ||
    //           playerData.gender === 'MF' ? (
    //             <div className="double-img-container">
    //               <img
    //                 className="player1-img"
    //                 src={playerData.head_shot}
    //                 alt="Player Headshot"
    //               />
    //               <img
    //                 className="player2-img"
    //                 src={playerData.player2_head_shot}
    //                 alt="Player Headshot"
    //               />
    //             </div>
    //           ) : (
    //             <img
    //               className="player-img"
    //               src={playerData.head_shot}
    //               alt="Player Headshot"
    //             />
    //           )}
    //         </div>
    //       )}
    //     </div>
    //     <div className="player-card-right-container">
    //       <p className="country">{playerData.country}</p>
    //       {playerData.gender !== null && (
    //         <>
    //           {(playerData.gender === 'F' || playerData.gender === 'M') && (
    //             <p className="age">年齡: {playerData.actual_age}</p>
    //           )}
    //           <p className="gender">{translationDict[playerData.gender]}</p>
    //         </>
    //       )}
    //       <p className="video-number">影片數 : {playerData.number_of_videos}</p>
    //     </div>
    //   </div>
    // </Link>
  );
};

export default PlayerCard;
