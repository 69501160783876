import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { MatchData } from 'types/videoTypes';

const OCRCompletionPanel: React.FC<{ matchData: MatchData }> = ({
  matchData,
}) => {
  useEffect(() => {
    window.open(
      `/edit-metadata/${matchData.matchId}/2`,
      '_blank',
      'noopener,noreferrer',
    );
  }, [matchData.matchId]);

  const handleOpenEditMetadata = () => {
    window.open(
      `/edit-metadata/${matchData.matchId}/2`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<OpenInNew />}
      onClick={handleOpenEditMetadata}
    >
      重新打開編輯頁面
    </Button>
  );
};

export default OCRCompletionPanel;
