import { useState, Fragment } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';

import axios from 'axios';
import Cookies from 'js-cookie';

import './EditHighlightDisplay.scss';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';

import RoundContentComponent from './RoundContent';
import HighlightListComponent from './HighlightContent';

import {
  ballStartTimeIndex,
  highlightIdIndex,
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  highlightDataIndex,
  roundDataIndex,
  roundStartTimeIndex,
  roundDescriptionIndex,
  ballDataIndex,
  ballEndTimeIndex,
  ballHighlightIndex,
  highlightRatingIndex,
  highlightCommentIndex,
} from 'src/constants';

const EditHighlightDisplay = ({
  matchData,
  tempResults,
  videoRef,
  fetchVideoData,
  setClickedIndex,
  setCurrentRound,
  setTempResults,
}) => {
  const token = Cookies.get('token');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedRoundIndex, setSelectedRoundIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['回合资料', '亮點资料'];

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleButtonClick = () => {
    if (activeStep === steps.length - 1) {
      // Show the popup warning
      //setOpenPopup(true);
    } else {
      // Proceed to the next step
      handleNext();
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RoundContentComponent
            matchData={matchData}
            tempResults={tempResults}
            selectedRoundIndex={selectedRoundIndex}
            handleGoRound={handleGoRound}
            handleRoundDescriptionChange={handleRoundDescriptionChange}
          />
        );
      case 1:
        return (
          <HighlightListComponent
            tempResults={tempResults}
            handleDeleteClick={handleDeleteClick}
            handleGoRound={handleGoRound}
            selectedRoundIndex={selectedRoundIndex}
            handleRatingChange={handleRatingChange}
            handleCommentChange={handleCommentChange}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  const handleSubmitClick = () => {
    axios
      .put(
        `/api/editHighlight`,
        { new_results: tempResults },
        {
          params: {
            primary_attribute: `video edited : ${matchData.videoName}`,
          },
          headers: {
            Authorization: `${token}`,
          },
        },
      )
      .then((response) => {
        //console.log(response.data);
        fetchVideoData();
        setSnackbarMessage('數據更新成功');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // refresh to undo change
  const handleUndoClick = () => {
    window.location.reload();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // jump to clicked round
  const handleGoRound = (roundIndex) => {
    videoRef.current.currentTime =
      tempResults[roundIndex][roundDataIndex][roundStartTimeIndex];
    setSelectedRoundIndex(roundIndex);
    setCurrentRound(roundIndex);
    setClickedIndex(null);
  };

  // this function is also needed here because of delete so we need to updated ball ishighlight again
  const addBallHighlight = (tempResults) => {
    for (let roundIndex = 0; roundIndex < tempResults.length; roundIndex++) {
      for (
        let ballIndex = 0;
        ballIndex < tempResults[roundIndex][ballDataIndex].length;
        ballIndex++
      ) {
        const ballStartTime =
          tempResults[roundIndex][ballDataIndex][ballIndex][ballStartTimeIndex];
        const ballEndTime =
          tempResults[roundIndex][ballDataIndex][ballIndex][ballEndTimeIndex];

        let fallsWithinRange = false; // Initialize to false

        // Check if the ball falls within any of the highlights
        for (
          let highlightIndex = 0;
          highlightIndex < tempResults[roundIndex][highlightDataIndex].length;
          highlightIndex++
        ) {
          const startTime =
            tempResults[roundIndex][highlightDataIndex][highlightIndex][0][
              highlightStartTimeIndex
            ];
          const endTime =
            tempResults[roundIndex][highlightDataIndex][highlightIndex][0][
              highlightEndTimeIndex
            ];

          if (startTime <= ballStartTime && endTime >= ballEndTime) {
            fallsWithinRange = true;
            break; // Exit the loop if the ball falls within any highlight
          }
        }
        // Update ballHighlightIndex based on fallsWithinRange
        tempResults[roundIndex][ballDataIndex][ballIndex][ballHighlightIndex] =
          fallsWithinRange;
      }
    }
  };

  // delete highlight
  const handleDeleteClick = (roundIndex, highlightIndex) => {
    setClickedIndex(null);
    const highlightId =
      tempResults[roundIndex][highlightDataIndex][highlightIndex][0][
        highlightIdIndex
      ];
    tempResults[roundIndex][highlightDataIndex].splice(highlightIndex, 1);
    addBallHighlight(tempResults);

    axios
      .delete(`/api/editHighlight`, {
        params: {
          delete_highlight_id: highlightId,
          primary_attribute: `video edited : ${matchData.videoName}`,
        },
        data: { new_results: tempResults },
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        // Handle the successful response
        fetchVideoData();
        setSnackbarMessage('數據已删除!');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  // edit round description
  const handleRoundDescriptionChange = (roundIndex, newValue) => {
    setTempResults((prevTempResults) => {
      const updatedTempResults = [...prevTempResults];
      updatedTempResults[roundIndex][roundDataIndex][roundDescriptionIndex] =
        newValue;
      return updatedTempResults;
    });
  };

  const handleRatingChange = (roundIndex, highlightIndex, newValue) => {
    setTempResults((prevTempResults) => {
      const updatedTempResults = [...prevTempResults];
      updatedTempResults[roundIndex][highlightDataIndex][highlightIndex][0][
        highlightRatingIndex
      ] = newValue;
      return updatedTempResults;
    });
  };

  const handleCommentChange = (roundIndex, highlightIndex, newValue) => {
    setTempResults((prevTempResults) => {
      const updatedTempResults = [...prevTempResults];

      updatedTempResults[roundIndex][highlightDataIndex][highlightIndex][0][
        highlightCommentIndex
      ] = newValue;
      return updatedTempResults;
    });
  };

  return (
    <div className="edit-h-container">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="h-custom-snackbar"
        open={snackbarOpen}
        autoHideDuration={1000000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          className="h-custom-snackbar"
          message={snackbarMessage}
        />
      </Snackbar>
      <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 1 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Fragment>
        {getStepContent(activeStep)}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {activeStep !== 0 ? (
            <Button
              variant="contained"
              onClick={() => handleBack()}
              sx={{ mt: 3, ml: 1 }}
            >
              回合资料
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleButtonClick()}
              sx={{ mt: 3, ml: 1 }}
            >
              設置亮點
            </Button>
          )}
        </Box>
      </Fragment>
      <div className="edit-h-buttons">
        <button className="save-button" onClick={handleSubmitClick}>
          保存
        </button>
        <button className="delete-button" onClick={handleUndoClick}>
          撤销
        </button>
        <Link to={`/video-player/${matchData.matchId}`}>返回视频</Link>
        <Link to={`/set-coach-pov/${matchData.matchId}`}>設置教练視角</Link>
      </div>
    </div>
  );
};

export default EditHighlightDisplay;
