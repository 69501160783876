import { BallData } from 'types/videoTypes';

export const convertToBallData = (
  data: number[],
  isNew: boolean,
  nextBall: boolean,
  isRoundChange: boolean,
): BallData => {
  const currentStartTime = data[2];
  const currentEndTime = data[3];
  let newStartTime: number;
  let newEndTime: number;

  if (!nextBall && !isRoundChange) {
    newStartTime = Math.max(0, currentStartTime - 5);
    newEndTime = currentStartTime;
  } else if (nextBall && !isRoundChange) {
    newStartTime = currentEndTime;
    newEndTime = currentEndTime + 5;
  } else if (!nextBall && isRoundChange) {
    newStartTime = Math.max(0, currentStartTime - 20);
    newEndTime = currentStartTime;
  } else {
    newStartTime = currentEndTime;
    newEndTime = currentEndTime + 20;
  }

  if (newEndTime <= newStartTime) {
    newEndTime = newStartTime + 1;
  }

  return {
    ball_id: isNew ? -1 : data[0],
    round_id: data[1],
    start_time: newStartTime,
    end_time: newEndTime,
    home_player_score: data[4],
    away_player_score: data[5],
    highlight: data[6] === null ? false : true,
    critical_point: Boolean(data[7]),
    deuce: Boolean(data[8]),
  };
};
