import { Typography, Grid, TextField } from '@mui/material';
import VideoUploadOptions from './VideoUploadOptions';

import './VideoFileUploadForm.scss';

const allowedVideoFormats = ['mp4', 'avi', 'mov', 'mkv', 'webm'];

const VideoFileUploadForm = ({
  setFiles,
  files,
  matchData,
  setMatchData,
  playlists,
  selectedPlaylists,
  handlePlaylistSelect,
  uploaded,
}) => {
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedVideoFormats.includes(extension);
    });

    if (validFiles.length !== selectedFiles.length) {
      alert(
        '警告：部分文件未被添加！\n\n僅支持以下視頻格式：' +
          allowedVideoFormats.join('、') +
          '。\n\n請仔細檢查您的文件，確保它們符合要求的格式。不符合要求的文件將被自動過濾。',
      );
      // Clear the input field
      event.target.value = '';

      // If no valid files, exit the function
      if (validFiles.length === 0) {
        return;
      }
    }

    setFiles(validFiles);
    setMatchData((prevData) => ({
      ...prevData,
      videoName: validFiles.map((file) => file.name.replace(/\.[^/.]+$/, '')),
      fileUrls: validFiles.map((file) => file.name),
      thumbnail_urls: validFiles.map((file) =>
        file.name.replace(/\.[^/.]+$/, '.jpg'),
      ),
    }));
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setMatchData((prevData) => {
      const { fileUrls, videoName, thumbnail_urls } = prevData;
      return {
        ...prevData,
        fileUrls: fileUrls.filter((_, i) => i !== index),
        videoName: videoName.filter((_, i) => i !== index),
        thumbnail_urls: thumbnail_urls.filter((_, i) => i !== index),
      };
    });
    document.getElementById('videoFiles').value = null;
  };

  const handleModifyMatchData = (index, newValue) => {
    setMatchData((prevData) => ({
      ...prevData,
      videoName: prevData.videoName.map((name, i) =>
        i === index ? newValue : name,
      ),
    }));
  };

  const incompleteUploads = uploaded.uploads
    ? uploaded.uploads.filter((upload) => !upload.upload_completed)
    : [];

  return (
    <div className="main-upload-container">
      <Grid container spacing={2}>
        <Grid item xs={12} className="main-upload-panel">
          <TextField
            required
            id="videoFiles"
            name="videoFiles"
            label="视频文件"
            fullWidth
            autoComplete="videoFiles"
            variant="standard"
            type="file"
            accept={allowedVideoFormats.map((format) => `.${format}`).join(',')}
            onChange={handleFileChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { color: 'white' },
              multiple: true,
            }}
          />
        </Grid>
      </Grid>
      <div className="past-upload-info-container">
        {incompleteUploads.length > 0 && (
          <Typography variant="h6" className="upload-header">
            由你暫停的上傳（如無法上傳，請檢查影片格式，必須為mp4）：
          </Typography>
        )}
        {incompleteUploads.map((upload, index) => (
          <div className="past-upload-info" key={index}>
            <span className="file-name">檔案名稱：{upload.video_name}</span>
            <span className="upload-date">
              於 {upload.upload_date.slice(0, 10)}
            </span>
          </div>
        ))}
      </div>
      {files.length > 0 && (
        <div className="file-info-container">
          <Typography variant="h6">選定的檔案:</Typography>
          <ul>
            {files.map((file, index) => (
              <VideoUploadOptions
                key={index}
                matchData={matchData}
                file={file}
                index={index}
                handleRemoveFile={handleRemoveFile}
                handleModifyMatchData={handleModifyMatchData}
                playlists={playlists}
                selectedPlaylists={selectedPlaylists}
                handlePlaylistSelect={handlePlaylistSelect}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default VideoFileUploadForm;
