import { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import HighlightEditorPlayer from './HighlightEditorPlayer/HighlightEditorPlayer';
import EditHighlightDisplay from './EditHighlightDisplay/EditHighlightDisplay';
import HighlightControlPanel from './HighlightControlPanel/HighlightControlPanel';
import ScoreBoard from 'src/components/ScoreBoard/ScoreBoard';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import './HighlightEditor.scss';

import {
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  dateIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  roundDataIndex,
  ballDataIndex,
  roundStartTimeIndex,
  roundEndTimeIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  highlightDataIndex,
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  ballHighlightIndex,
  matchIdIndex,
  homePlayerIdIndex,
  awayPlayerIdIndex,
} from 'src/constants';

const HighlightEditor = () => {
  const token = Cookies.get('token');
  const { videoId } = useParams();
  const [error, setError] = useState('');
  const videoRef = useRef(null);

  const [matchData, setMatchData] = useState({
    videoName: '',
    videoDescription: '',
    stage: '',
    matchDate: '',
    location: '',
    homePlayer: '',
    awayPlayer: '',
    homePlayerId: '',
    awayPlayerId: '',
    fileUrl: '',
    thumbnailUrl: '',
    startEndTimeUrl: '',
    reportUrl: '',
    dpUrl: '',
    matchEvent: '',
    matchParentEvent: '',
    matchCategory: '',
  });
  const [roundResults, setRoundResults] = useState(null);

  const [currentRound, setCurrentRound] = useState(0);
  const [currentBall, setCurrentBall] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [roundStartTime, setRoundStartTime] = useState(0);
  const [roundEndTime, setRoundEndTime] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [highlightIndex, setHighlightIndex] = useState(0);
  const [tempResults, setTempResults] = useState([]);

  const [progressBarValue, setProgressBarValue] = useState(0);

  const fetchVideoData = async () => {
    try {
      const response = await axios.get(`/api/videodata/${videoId}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      const responseData = response.data;
      // Destructure the response data
      const [matchData, roundResults] = responseData;
      // change date and url
      let transformedList;
      transformedList = matchData.map((videoData) => {
        const fileUrl = videoData[urlIndex];
        const urlWithoutSuffix = fileUrl.substring(0, fileUrl.lastIndexOf('.'));
        const url = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
        const fetchedMatchDate = videoData[dateIndex];
        const dateWithoutTime = fetchedMatchDate.replace(
          /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
          '',
        );

        return {
          videoName: videoData[videoNameIndex],
          videoDescription: videoData[matchDescriptionIndex],
          stage: videoData[stageIndex],
          matchDate: dateWithoutTime,
          location: videoData[locationIndex],
          homePlayer: videoData[homePlayerIndex],
          awayPlayer: videoData[awayPlayerIndex],
          fileUrl: url,
          matchId: videoData[matchIdIndex],
          homePlayerId: videoData[homePlayerIdIndex],
          awayPlayerId: videoData[awayPlayerIdIndex],
        };
      });
      setMatchData(transformedList[0]);
      setRoundResults(roundResults);
      setTempResults(roundResults);
    } catch (error) {
      setError(error.message);
    }
  };

  // change ishighlight of ball data to true if ball falls between the range
  const addBallHighlight = (tempResults) => {
    for (let roundIndex = 0; roundIndex < tempResults.length; roundIndex++) {
      for (
        let ballIndex = 0;
        ballIndex < tempResults[roundIndex][ballDataIndex].length;
        ballIndex++
      ) {
        const ballStartTime =
          tempResults[roundIndex][ballDataIndex][ballIndex][ballStartTimeIndex];
        const ballEndTime =
          tempResults[roundIndex][ballDataIndex][ballIndex][ballEndTimeIndex];

        let fallsWithinRange = false; // Initialize to false

        // Check if the ball falls within any of the highlights
        for (
          let highlightIndex = 0;
          highlightIndex < tempResults[roundIndex][highlightDataIndex].length;
          highlightIndex++
        ) {
          const startTime =
            tempResults[roundIndex][highlightDataIndex][highlightIndex][0][
              highlightStartTimeIndex
            ];
          const endTime =
            tempResults[roundIndex][highlightDataIndex][highlightIndex][0][
              highlightEndTimeIndex
            ];

          if (startTime <= ballStartTime && endTime >= ballEndTime) {
            fallsWithinRange = true;
            break; // Exit the loop if the ball falls within any highlight
          }
        }
        // Update ballHighlightIndex based on fallsWithinRange
        tempResults[roundIndex][ballDataIndex][ballIndex][ballHighlightIndex] =
          fallsWithinRange;
      }
    }
  };

  // get value of progress bar per round
  const handleTimeBarUpdate = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      const relativeTime =
        currentTime -
        roundResults[currentRound][roundDataIndex][roundStartTimeIndex];

      let roundDuration;

      if (
        roundResults[currentRound][roundDataIndex][roundEndTimeIndex] === 9999
      ) {
        if (videoRef.current && !isNaN(videoRef.current.duration)) {
          roundDuration =
            videoRef.current.duration -
            roundResults[currentRound][roundDataIndex][roundStartTimeIndex];
        }
      } else {
        roundDuration =
          roundResults[currentRound][roundDataIndex][roundEndTimeIndex] -
          roundResults[currentRound][roundDataIndex][roundStartTimeIndex];
      }

      if (isFinite(duration)) {
        setProgressBarValue((relativeTime / roundDuration) * 100);
      } else {
        setProgressBarValue(0);
      }
    }
  };

  const getRoundStartEndTime = () => {
    const currentVideoRef = videoRef.current;
    if (
      roundResults &&
      roundResults[currentRound][roundDataIndex][roundEndTimeIndex] === 9999 &&
      currentVideoRef
    ) {
      setRoundStartTime(0);
      setRoundEndTime(currentVideoRef.duration);
    } else if (roundResults) {
      setRoundStartTime(
        roundResults[currentRound][roundDataIndex][roundStartTimeIndex],
      );
      setRoundEndTime(
        roundResults[currentRound][roundDataIndex][roundEndTimeIndex],
      );
    }
  };

  // get current ball
  useEffect(() => {
    const updateRoundBall = () => {
      // find round once data is available
      if (roundResults && roundResults.length > 0) {
        let matchBallIndex = -1;
        for (
          let ballIndex = 0;
          ballIndex < roundResults[currentRound][ballDataIndex].length;
          ballIndex++
        ) {
          const ballStartTime =
            roundResults[currentRound][ballDataIndex][ballIndex][
              ballStartTimeIndex
            ];
          const ballEndTime =
            roundResults[currentRound][ballDataIndex][ballIndex][
              ballEndTimeIndex
            ];
          if (currentTime >= ballStartTime) {
            matchBallIndex = ballIndex;
            if (currentTime <= ballEndTime) {
              break;
            }
          }
        }
        if (currentBall !== matchBallIndex) {
          setCurrentBall(matchBallIndex);
        }
      } else {
        console.log('roundResults is null or empty.');
      }
    };

    updateRoundBall();
    handleTimeBarUpdate();
  }, [currentTime]);

  useEffect(() => {
    fetchVideoData();
  }, []);

  useEffect(() => {
    getRoundStartEndTime();
  }, [currentRound]);

  useEffect(() => {
    if (tempResults) {
      addBallHighlight(tempResults);
      getRoundStartEndTime();
    }
  }, [tempResults]);

  return (
    <div>
      <div className="main-highlight-edit">
        <div className="highlight-scoreboard">
          <ScoreBoard
            roundResults={tempResults}
            matchData={matchData}
            currentRound={currentRound}
            videoRef={videoRef}
          />
        </div>
        {error && <p>Error: {error}</p>}
        {tempResults.length > 0 ? (
          <div className="edit-highlight-video-interface">
            <div className="edit-highlight-interface-container">
              <HighlightEditorPlayer
                source={matchData.fileUrl}
                videoRef={videoRef}
                currentRound={currentRound}
                roundStartTime={roundStartTime}
                roundEndTime={roundEndTime}
                tempResults={tempResults}
                highlightIndex={highlightIndex}
                setIsPlaying={setIsPlaying}
                clickedIndex={clickedIndex}
                setCurrentTime={setCurrentTime}
                isPlaying={isPlaying}
              />
              <div className="highlight-control-container">
                <LinearProgress
                  variant="determinate"
                  value={progressBarValue}
                />
                <HighlightControlPanel
                  roundEndTime={roundEndTime}
                  videoRef={videoRef}
                  matchData={matchData}
                  clickedIndex={clickedIndex}
                  setClickedIndex={setClickedIndex}
                  tempResults={tempResults}
                  currentRound={currentRound}
                  highlightIndex={highlightIndex}
                  setHighlightIndex={setHighlightIndex}
                  setTempResults={setTempResults}
                />
              </div>
            </div>
            <EditHighlightDisplay
              matchData={matchData}
              tempResults={tempResults}
              videoRef={videoRef}
              setTempResults={setTempResults}
              fetchVideoData={fetchVideoData}
              setCurrentRound={setCurrentRound}
              setClickedIndex={setClickedIndex}
            />
          </div>
        ) : (
          <div className="loading-highlight">
            <CircularProgress color="primary" />
            <p className="error">
              <Link to={`/video-player/${videoId}`}>
                未找到球數據.......返回视频
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HighlightEditor;
