// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scoreChart-title {
  font-size: var(--medium-font-size);
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/videoPages/VideoInterface/VideoDynamicPanel/ScorePanel/ScoreChart.scss"],"names":[],"mappings":"AAEA;EACI,kCAAA;EACA,aAAA;EACA,uBAAA;AADJ","sourcesContent":["\n\n.scoreChart-title{\n    font-size: var(--medium-font-size);\n    display: flex;\n    justify-content: center;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
