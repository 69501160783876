import axios from 'axios';
import { userLogParams } from 'types/userTypes';
import { HandleAPIError } from './auth';

export const fetchUserLogAPI = async (
  params: userLogParams,
  token: string,
): Promise<any> => {
  try {
    const { userName, dataDate, metrics } = params;

    const joinedUserName = userName ? userName.join(',') : null;
    const joinedMetrics = metrics ? metrics.join(',') : null;

    const response = await axios.get('/api/usersLog', {
      params: {
        userName: joinedUserName,
        dataDate,
        metrics: joinedMetrics,
      },
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const fetchUserListAPI = async (token: string): Promise<any> => {
  try {
    const response = await axios.get(`/api/usersData`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};
