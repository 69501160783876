import React, { RefObject, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextField from '@mui/material/TextField';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import { updateVideoDataAPI } from 'src/api/videoService';

import Cookies from 'js-cookie';

import './MatchDescriptionPanel.scss';

interface CommentProps {
  userComment: string;
  videoRef: RefObject<HTMLVideoElement>;
  permission: boolean;
  match_id: number;
  setVideoData: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
}

const MatchDescriptionPanel: React.FC<CommentProps> = ({
  userComment,
  videoRef,
  permission,
  match_id,
  setVideoData,
}) => {
  const token = Cookies.get('token');

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [modifiedDescription, setModifiedDescription] = useState(userComment);

  const handleCommentClick = (timeInSeconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = timeInSeconds;
    }
  };

  const toggleEditing = () => {
    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  const convertToSeconds = (minutes: string, seconds: string): number => {
    const mins = parseInt(minutes, 10);
    const secs = parseInt(seconds, 10);
    if (isNaN(mins) || isNaN(secs)) {
      return -1; // Invalid time format
    }
    return mins * 60 + secs;
  };

  const handleSubmit = async () => {
    try {
      const response = await updateVideoDataAPI(
        {
          match_id: match_id,
          match_description: modifiedDescription,
        },
        token as string,
      );
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
      }));
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const renderEditingPanel = () => {
    return (
      <div className="coach-comment-edit-panel">
        <div className="legend">
          <p>
            Tag time by using the following form (@MM:SS) where : can be any
            non-integer. For example, 12:30 or 12.30 is fine.
          </p>
          <p className="translation">
            使用以下形式（@MM:SS）標記時間，其中 : 可以是任何非整數。例如，12:30
            或 12.30 都可以。
          </p>
        </div>
        <div className="text-container">
          <TextField
            id="outlined-textarea"
            label="教練評論"
            placeholder="教練評論"
            multiline
            value={modifiedDescription}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setModifiedDescription(event.target.value);
            }}
          />
          <button onClick={handleSubmit}>
            <CheckOutlinedIcon />
          </button>
        </div>
      </div>
    );
  };

  const renderComment = (comment: string) => {
    const lines = comment.split(/\r?\n/);

    const renderedLines = lines.map((line, lineIndex) => {
      if (line.trim() === '') {
        return (
          <div key={`line-${lineIndex}`} className="comment-line empty-line">
            &nbsp;
          </div>
        );
      }

      const commentParts = line.split(/(@\d+[^\d\s]\d+)/);
      const renderedParts: JSX.Element[] = [];

      commentParts.forEach((part, index) => {
        if (part.startsWith('@')) {
          const timeMatch = part.match(/@(\d+)([^\d\s])(\d+)/);
          if (timeMatch) {
            const [, minutes, separator, seconds] = timeMatch;
            const timeInSeconds = convertToSeconds(minutes, seconds);

            renderedParts.push(
              <span
                className="comment-time"
                key={`${lineIndex}-time-${index}`}
                onClick={() => handleCommentClick(timeInSeconds)}
              >
                {part}
              </span>,
            );
          } else {
            renderedParts.push(
              <span className="comment-text" key={`${lineIndex}-text-${index}`}>
                {part}
              </span>,
            );
          }
        } else if (part.trim() !== '') {
          renderedParts.push(
            <span className="comment-text" key={`${lineIndex}-text-${index}`}>
              {part}
            </span>,
          );
        }
      });

      return (
        <div key={`line-${lineIndex}`} className="comment-line">
          {renderedParts}
        </div>
      );
    });

    return <div className="comment-container">{renderedLines}</div>;
  };

  return (
    <div className="coach-comment-container">
      {permission && (
        <button onClick={toggleEditing}>
          {isEditing ? <ArrowBackOutlinedIcon /> : <EditOutlinedIcon />}
        </button>
      )}
      {isEditing ? (
        renderEditingPanel()
      ) : (
        <div className="coach-comment-view-panel">
          {renderComment(userComment)}
        </div>
      )}
    </div>
  );
};

export default MatchDescriptionPanel;
