import { useState } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import Stack from '@mui/material/Stack';

import Cookies from 'js-cookie';

function SimpleDialog(props) {
  const { onClose, setToken, admin, subadmin, open, setOpen, setUserInfo } =
    props;

  const handleLogout = () => {
    Cookies.remove('token');
    setToken(null);
    setUserInfo({
      identifier: null,
      isCoach: null,
      accountType: null,
      admin: null,
      subadmin: null,
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <IconButton
          sx={{ fontSize: '1.5em', borderRadius: '0', flexDirection: 'row' }}
          onClick={handleLogout}
          color="inherit"
        >
          <LogoutIcon sx={{ fontSize: '1.5em' }} />
          登出
        </IconButton>
        {subadmin === true || admin === true ? (
          <Link
            style={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.5em',
            }}
            to="/admin-page"
            onClick={handleClose}
          >
            <AdminPanelSettingsOutlinedIcon sx={{ fontSize: '1.5em' }} />
            管理頁面
          </Link>
        ) : null}
        <Button variant="contained" color="inherit" onClick={handleClose}>
          關閉
        </Button>
      </Stack>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function UserOption({ setToken, userInfo, setUserInfo }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // chunk the name to ...
  const chunkName = (name) => {
    if (name.length > 8) {
      return name.slice(0, 8) + '...';
    }
    return name;
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        title="帳號"
        startIcon={<ManageAccountsOutlinedIcon />}
        color="inherit"
      >
        {/* {chunkName(userInfo.identifier)} */}
      </Button>
      <SimpleDialog
        setToken={setToken}
        admin={userInfo.admin}
        subadmin={userInfo.subadmin}
        open={open}
        onClose={handleClose}
        setOpen={setOpen}
        setUserInfo={setUserInfo}
      />
    </div>
  );
}
