// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ball-commentary-edit-panel {
  max-height: 30vh;
  overflow-y: auto;
  font-size: var(--small-font-size);
}
.ball-commentary-edit-panel .comment-rating {
  display: flex;
}
.ball-commentary-edit-panel .comment-rating .stars svg {
  color: #faaf00 !important;
}
.ball-commentary-edit-panel .edit-comment-panel {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/videoPages/VideoInterface/VideoDynamicPanel/VideoHighlightPanel/utils/CommentEdit.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,gBAAA;EACA,iCAAA;AADJ;AAGI;EACI,aAAA;AADR;AAGY;EACI,yBAAA;AADhB;AAKI;EACI,aAAA;EACA,qBAAA;EACA,sBAAA;AAHR","sourcesContent":["\n\n.ball-commentary-edit-panel{\n    max-height: 30vh;\n    overflow-y: auto;\n    font-size: var(--small-font-size);\n\n    .comment-rating{\n        display: flex;\n        .stars{\n            svg{\n                color: #faaf00 !important;\n            }\n        }\n    }\n    .edit-comment-panel{\n        display: flex;\n        align-items: flex-end;\n        flex-direction: column;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
