// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-upload-block {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.video-upload-block .video-upload-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.video-upload-block .video-upload-content span {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #1976d2;
}
.video-upload-block .video-upload-content button {
  min-width: auto;
  padding: 6px;
}
.video-upload-block .video-upload-content button svg {
  font-size: 20px;
  color: #d32f2f;
}
.video-upload-block .video-upload-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.video-upload-block .video-upload-info .video-name-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4caf50;
}
.video-upload-block .video-upload-info .playlist-select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4caf50;
}
.video-upload-block .video-upload-info .playlist-select .MuiSelect-select {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.video-upload-block .video-upload-info .playlist-select .MuiMenuItem-root {
  display: flex;
  align-items: center;
}
.video-upload-block .video-upload-info .playlist-select .MuiMenuItem-root .MuiCheckbox-root {
  padding: 0 8px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/videoPages/UploadVideo/VideoUploadOptions.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACE,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AACN;AAEI;EACE,eAAA;EACA,YAAA;AAAN;AAEM;EACE,eAAA;EACA,cAAA;AAAR;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ;AAOQ;EACE,qBAAA;AALV;AAYQ;EACE,qBAAA;AAVV;AAcM;EACE,aAAA;EACA,eAAA;EACA,QAAA;AAZR;AAeM;EACE,aAAA;EACA,mBAAA;AAbR;AAeQ;EACE,kBAAA;AAbV","sourcesContent":[".video-upload-block {\n  margin-bottom: 16px;\n  padding: 16px;\n  background-color: #f5f5f5;\n  border-radius: 4px;\n\n  .video-upload-content {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 16px;\n\n    span {\n      max-width: 80%;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n      font-size: 16px;\n      color: #1976d2;\n    }\n\n    button {\n      min-width: auto;\n      padding: 6px;\n\n      svg {\n        font-size: 20px;\n        color: #d32f2f;\n      }\n    }\n  }\n\n  .video-upload-info {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n\n    .video-name-input {\n      .MuiOutlinedInput-root {\n        &.Mui-focused .MuiOutlinedInput-notchedOutline {\n          border-color: #4caf50;\n        }\n      }\n    }\n\n    .playlist-select {\n      .MuiOutlinedInput-root {\n        &.Mui-focused .MuiOutlinedInput-notchedOutline {\n          border-color: #4caf50;\n        }\n      }\n\n      .MuiSelect-select {\n        display: flex;\n        flex-wrap: wrap;\n        gap: 4px;\n      }\n\n      .MuiMenuItem-root {\n        display: flex;\n        align-items: center;\n\n        .MuiCheckbox-root {\n          padding: 0 8px 0 0;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
