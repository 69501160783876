import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

import './RecentActivities.scss';

export default function RecentActivities({
  usersActivities,
}: {
  usersActivities: Array<Record<string, any>>;
}): React.JSX.Element {
  const [activitiesNumber, setActivitiesNumber] = useState(10);
  const handleMoreActivities = (): void => {
    setActivitiesNumber((prevActivitiesNumber) => prevActivitiesNumber + 100);
  };
  return (
    <div className="recent-activities-container">
      <Title>Recent Activities</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Index</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Activity</TableCell>
            <TableCell>Info</TableCell>
            <TableCell>Additional info</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersActivities &&
            usersActivities
              .slice(0, activitiesNumber)
              .map((usersActivity, index) => (
                <TableRow key={usersActivity.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{usersActivity.activity_date}+8</TableCell>
                  <TableCell>{usersActivity.activity_user}</TableCell>
                  <TableCell>{usersActivity.activity_location}</TableCell>
                  <TableCell>{usersActivity.activity_name}</TableCell>
                  <TableCell title={usersActivity.primary_attribute}>
                    {usersActivity.primary_attribute.length > 50
                      ? `${usersActivity.primary_attribute.slice(0, 50)}...`
                      : usersActivity.primary_attribute}
                  </TableCell>
                  <TableCell title={usersActivity.secondary_attribute}>
                    {usersActivity.secondary_attribute.length > 50
                      ? `${usersActivity.secondary_attribute.slice(0, 50)}...`
                      : usersActivity.secondary_attribute}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <button onClick={() => handleMoreActivities()}>
        See more activities
      </button>
    </div>
  );
}
