import React, { useState } from 'react';
import {
  CheckCircle,
  ExpandMore,
  ExpandLess,
  Close,
  Refresh,
} from '@mui/icons-material';
import {
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
} from '@mui/material';

import UploadMatchDataPanel from './UploadMatchDataPanel';
import UploadAnalysisDataPanel from './UploadAnalysisDataPanel';
import OCRCompletionPanel from './OCRCompletionPanel';
import HumanVerificationPanel from './HumanVerificationPanel';
import DropPointAnalysisPanel from './DropPointAnalysisPanel';
import { MatchData } from 'types/videoTypes';

import './VideoAnalysisProgress.scss';

interface videoData {
  refresh: boolean;
}

interface ProgressStep {
  label: string;
  percentage: number;
  component: React.ComponentType<{
    matchData: MatchData;
    setMatchData: React.Dispatch<React.SetStateAction<MatchData>>;
  }>;
}

interface VideoAnalysisProgressProps {
  currentProgress: number;
  matchData: MatchData;
  setMatchData: React.Dispatch<React.SetStateAction<MatchData>>;
  setVideoData: React.Dispatch<React.SetStateAction<videoData>>;
}

const progressSteps: ProgressStep[] = [
  {
    label: '上傳比賽數據',
    percentage: 20,
    component: UploadMatchDataPanel,
  },
  {
    label: '上傳分析數據',
    percentage: 40,
    component: UploadAnalysisDataPanel,
  },
  {
    label: '完成 OCR',
    percentage: 60,
    component: OCRCompletionPanel,
  },
  {
    label: '人工驗證',
    percentage: 70,
    component: HumanVerificationPanel,
  },
  {
    label: '完成落點分析',
    percentage: 90,
    component: DropPointAnalysisPanel,
  },
  {
    label: '人工最終驗證',
    percentage: 100,
    component: HumanVerificationPanel,
  },
];

const VideoAnalysisProgress: React.FC<VideoAnalysisProgressProps> = ({
  currentProgress,
  matchData,
  setMatchData,
  setVideoData,
}) => {
  const [open, setOpen] = useState(false);
  const [activePanel, setActivePanel] = useState<number | null>(null);

  const currentStepIndex = progressSteps.findIndex(
    (step) => currentProgress <= step.percentage,
  );
  const currentStep =
    currentStepIndex === -1 ? progressSteps.length - 1 : currentStepIndex;
  const nextStep = Math.min(currentStep + 1, progressSteps.length - 1);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActivePanel(null);
  };

  const handleToggleEditPanel = (index: number) => {
    setActivePanel(index);
    setOpen(true);
  };

  const handleRefresh = () => {
    setVideoData((prevState: videoData) => ({
      ...prevState,
      refresh: true,
    }));
  };
  const renderStep = (step: ProgressStep, index: number) => {
    const isCompleted = currentProgress >= step.percentage;
    const isNextIncomplete = index === currentStep + 1;
    const isButtonEnabled =
      isCompleted || isNextIncomplete || (currentProgress === 0 && index === 0);

    return (
      <Box key={index} className="step-container">
        <CheckCircle className={isCompleted ? 'completed' : 'incomplete'} />
        <Typography variant="body1" className={isCompleted ? 'completed' : ''}>
          {step.label}
        </Typography>
        <Button
          onClick={() => handleToggleEditPanel(index)}
          size="small"
          color="primary"
          disabled={!isButtonEnabled}
        >
          查看詳情
        </Button>
      </Box>
    );
  };

  return (
    <div className="video-analysis-progress">
      <Typography variant="h6" className="title">
        視頻分析進度
        <IconButton onClick={handleRefresh} size="small" aria-label="refresh">
          <Refresh />
        </IconButton>
      </Typography>
      <LinearProgress
        variant="determinate"
        value={currentProgress}
        className="progress-bar"
      />
      <Box className="current-steps">
        {renderStep(progressSteps[currentStep], currentStep)}
        {nextStep !== currentStep &&
          renderStep(progressSteps[nextStep], nextStep)}
      </Box>
      <Button
        onClick={handleClickOpen}
        fullWidth
        variant="outlined"
        startIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        {open ? '關閉進度面板' : '開啟進度面板'}
      </Button>
      <Dialog
        className="video-analysis-progress-dialog"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>
          {activePanel !== null
            ? progressSteps[activePanel].label
            : '完整視頻分析進度'}
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="video-analysis-progress-dialog-content">
          {activePanel !== null
            ? React.createElement(progressSteps[activePanel].component, {
                matchData,
                setMatchData,
              })
            : progressSteps.map((step, index) => renderStep(step, index))}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VideoAnalysisProgress;
