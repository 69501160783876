import { useState, useEffect } from 'react';
import { Box, Paper, Button, Link, Typography } from '@mui/material';
import VideoFileUploadForm from './VideoFileUploadForm';
import { UploadStatus } from './UploadStatus';
import { fetchPlaylistDataAPI } from 'src/api/videoService';
import { getUserUploadStatusAPI } from 'src/api/vqService';
import Cookies from 'js-cookie';

import './VideoUploadForm.scss';

const Copyright = (props) => (
  <Typography variant="body2" color="white" align="center" {...props}>
    {'Copyright © '}
    <Link color="inherit" href="/">
      乒乓球
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

const steps = ['选择视频'];

export const VideoUploadForm = ({ userInfo }) => {
  const token = Cookies.get('token');
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [matchData, setMatchData] = useState({
    videoName: 'MissingData',
    videoDescription: '_',
    stage: '_',
    matchDate: '2022-03-14',
    location: '_',
    HomePlayer: '_',
    AwayPlayer: '_',
    fileUrls: '',
    thumbnail_urls: 'defaultTestThumbnail.png',
    accountType: String(userInfo.accountType),
    uploadUser: userInfo.identifier,
  });
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchPlaylistDataAPI(
          { accountType: userInfo.accountType },
          token,
        );
        setPlaylists(responseData);
      } catch (error) {
        console.error('Failed to fetch playlist data:', error);
      }
      try {
        const responseData = await getUserUploadStatusAPI(
          { username: userInfo.identifier },
          token,
        );
        setUploaded(responseData);
      } catch (error) {
        console.error('Failed to fetch upload data:', error);
      }
    };

    fetchData();
  }, [userInfo.accountType, token]);

  const handlePlaylistSelect = (event, index) => {
    setSelectedPlaylists((prevState) => ({
      ...prevState,
      [index]: event.target.value,
    }));
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <div className="main-upload">
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, mx: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h2" variant="h4" align="center">
          視頻上傳
        </Typography>
        {activeStep === steps.length ? (
          <UploadStatus
            userInfo={userInfo}
            files={files}
            matchData={matchData}
            selectedPlaylists={selectedPlaylists}
          />
        ) : (
          <>
            <VideoFileUploadForm
              setFiles={setFiles}
              files={files}
              matchData={matchData}
              setMatchData={setMatchData}
              playlists={playlists}
              selectedPlaylists={selectedPlaylists}
              handlePlaylistSelect={handlePlaylistSelect}
              uploaded={uploaded}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  返回
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === steps.length - 1 ? '確認并上傳' : '下一步'}
              </Button>
            </Box>
          </>
        )}
      </Paper>
      <Copyright />
    </div>
  );
};
