import { useState } from 'react';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { IconButton } from '@mui/material';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { formatTime } from 'src/utils/utils';

import {
  handlePlayPause,
  handleSeekBackward,
  handleSeekForward,
  handleSeekBackwardFrame,
  handleSeekForwardFrame,
  handleSeekBackwardBall,
  handleSeekForwardBall,
} from 'src/utils/videoControl';

import './VideoControlPanel.scss';

const options = [0.25, 0.5, 0.75, 1];

const VideoControlPanel = ({
  videoRef,
  barTime,
  handleSeek,
  tab,
  roundResults,
  videoData,
  setVideoData,
  commentaryTimes,
  onZoomChange,
  scale,
  setScale,
}) => {
  const [videoSpeed, setVideoSpeed] = useState(1);

  const handleSpeedChange = (value) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = value;
      setVideoSpeed(value);
    }
  };

  const handleZoomChange = (newScale) => {
    const clampedScale = Math.max(1, Math.min(newScale, 10));
    setScale(clampedScale);
    onZoomChange(clampedScale);
  };

  return (
    <>
      {videoData.isFs !== null ? (
        <div
          className={
            videoData.isFs ? 'control-panel fs' : `control-panel ${tab}`
          }
        >
          <progress
            id="progress-bar"
            max="100"
            value="0"
            onClick={handleSeek}
          />
          <div className="options">
            <div className="left-options">
              <IconButton
                title="上一个球"
                onClick={() =>
                  handleSeekBackwardBall(
                    videoRef,
                    commentaryTimes,
                    roundResults,
                    videoData.currentRound,
                    videoData.currentBall,
                    setVideoData,
                  )
                }
              >
                <SkipPreviousIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="前幀"
                onClick={() => handleSeekBackwardFrame(videoRef, setVideoData)}
              >
                <FastRewindIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="前5秒"
                onClick={() => handleSeekBackward(videoRef, setVideoData)}
              >
                <Replay5Icon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                onClick={() =>
                  handlePlayPause(videoRef, videoData, setVideoData)
                }
              >
                {videoData.isPlaying ? (
                  <PauseIcon sx={{ color: 'white' }} />
                ) : (
                  <PlayArrowIcon sx={{ color: 'white' }} />
                )}
              </IconButton>
              <IconButton
                title="下5秒"
                onClick={() => handleSeekForward(videoRef, setVideoData)}
              >
                <Forward5Icon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下帧"
                onClick={() => handleSeekForwardFrame(videoRef, setVideoData)}
              >
                <FastForwardIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下一个球"
                onClick={() =>
                  handleSeekForwardBall(
                    videoRef,
                    commentaryTimes,
                    roundResults,
                    videoData.currentRound,
                    videoData.currentBall,
                    setVideoData,
                  )
                }
              >
                <SkipNextIcon sx={{ color: 'white' }} />
              </IconButton>
              <span className="video-time">
                {videoRef.current ? (
                  <>
                    {` ${formatTime(barTime)}`}
                    {`/${formatTime(videoRef.current.duration)}`}
                  </>
                ) : (
                  <>
                    {` ${formatTime('00')}`}
                    {`/${formatTime('00')}`}
                  </>
                )}
              </span>
            </div>
            <div className="others-menu">
              <div className="zoom-controls">
                <IconButton onClick={() => handleZoomChange(scale - 0.5)}>
                  <RemoveIcon className="zoom-icon" />
                </IconButton>
                <span
                  className="zoom-scale"
                  onClick={() => handleZoomChange(1)}
                >
                  {scale.toFixed(1)}x
                </span>
                <IconButton onClick={() => handleZoomChange(scale + 0.5)}>
                  <AddIcon className="zoom-icon" />
                </IconButton>
              </div>
              <div className="speed-menu">
                <span>速度:</span>
                {options.map((option) => (
                  <button
                    key={option}
                    onClick={() => handleSpeedChange(option)}
                  >
                    <span
                      className={
                        videoSpeed === option
                          ? 'speed-button-current'
                          : 'speed-button'
                      }
                    >
                      {option}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default VideoControlPanel;
