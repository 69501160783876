import axios from 'axios';
import { PlayerParams } from 'types/playerTypes';
import { HandleAPIError } from './auth';

export const fetchPlayerDataAPI = async (
  params: PlayerParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/playersData`, {
      params: params,
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

// export const addPlayer = async (playerData: PlayerData) => {
//   try {
//     console.log('playerData', playerData);
//     const response = await axios.post(`${API_URL}/players`, playerData);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

// export const getPlayers = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/players`); // Use the correct URL for your API
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

// export const deletePlayer = async (playerID: string) => {
//   try {
//     // Replace the URL with your API endpoint.
//     const response = await axios.delete(`${API_URL}/players/${playerID}`);

//     if (response.status !== 200) {
//       throw new Error(`Error: ${response.status}`);
//     }

//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };
