import { useEffect } from 'react';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './HighlightEditorPlayer.scss';
import { handlePlayPause,handleSeekBackward,handleSeekForward } from 'src/utils/videoControl';
import {
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  highlightDataIndex,
} from 'src/constants';

const HighlightEditorPlayer = ({source, videoRef, currentRound, roundStartTime, roundEndTime, tempResults, highlightIndex, isPlaying, setIsPlaying, clickedIndex, setCurrentTime}) => {


  // start from beginning of round
  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.currentTime = roundStartTime ; // Set the initial start time
  }, [videoRef, roundStartTime]);


  // loop highlight
  const handleTimeUpdate = () => {
    // If click on new highlight, loop highlight
    if(clickedIndex !== null){
      const highlightStartTime = tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightStartTimeIndex];
      const highlightEndTime = tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightEndTimeIndex];
      if (videoRef.current.currentTime < highlightStartTime){
        videoRef.current.currentTime = highlightStartTime;
      }
      else if (videoRef.current.currentTime > highlightEndTime) {
        videoRef.current.currentTime = highlightEndTime;
      };
  }
  // Not clicked on any, loop round
  else if (tempResults[currentRound]){
    if (videoRef.current.currentTime < roundStartTime || videoRef.current.currentTime > roundEndTime || (roundEndTime === 9999 && videoRef.current.currentTime > videoRef.current.duration) ) {
      videoRef.current.currentTime = roundStartTime;
    }
  }
    setCurrentTime(videoRef.current.currentTime);
  };


  return (
      <div className="highlight-edit-container">
        <video
          ref={videoRef}
          playsInline
          preload='metadata'
          className="highlight-edit-player"
          onTimeUpdate={() => handleTimeUpdate()}
        >
          <source src={source + '#t=1.001'} type="video/mp4" />
        </video>
          <div className="highlight-button-overlay">
            <IconButton className="overlay-button" onClick={() => handleSeekBackward(videoRef)}>
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton className="overlay-button" onClick={() => handlePlayPause(videoRef, isPlaying, setIsPlaying)}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <IconButton className="overlay-button" onClick={() => handleSeekForward(videoRef)}>
              <NavigateNextIcon />
            </IconButton>
          </div>
      </div>
  );
};
  
  export default HighlightEditorPlayer;