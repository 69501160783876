import { useState, useEffect } from 'react';
import { VideoEditForm } from './VideoEditForm';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import {
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  dateIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  reportIndex,
  subEventIndex,
  parentEventIndex,
  categoryIndex,
  isDoubleIndex,
  customTagIndex,
  matchIdIndex,
  dpFilenameIndex,
  dpAnalyzedTimeIndex,
  LastOCRIndex,
  OCRFilenameIndex,
  tableCoorIndex,
  scoreCoorIndex,
  startingPlayerIndex,
} from 'src/constants';

import './EditVideoData.scss';

const EditVideoData = ({ userInfo }) => {
  const token = Cookies.get('token');
  const { videoId, step } = useParams();
  const [matchData, setMatchData] = useState({
    videoId: '',
    videoName: '',
    videoDescription: '',
    stage: '',
    matchDate: '',
    location: '',
    HomePlayer: '',
    AwayPlayer: '',
    fileUrl: '',
    videoUrl: '',
    reportUrl: '',
    matchEvent: '',
    matchParentEvent: '',
    matchCategory: '',
    isDouble: false,
    accountType: userInfo.accountType,
    customTag: '',
    dpFilename: '',
    dpAnalyzedTime: '',
    LastOCR: '',
    OCRFilename: '',
  });

  const formatDate = (dateString) => {
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };

    const [dayOfWeek, day, monthAbbreviation, year] = dateString.split(' ');
    const month = months[monthAbbreviation];
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  // load data
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`/api/videodata/${videoId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;
        // Destructure the response data
        const [matchResults, roundResults] = responseData;
        // change date and url
        let transformedList;
        transformedList = matchResults.map((videoData) => {
          const fetchedMatchDate = videoData[dateIndex];
          const dateWithoutTime = fetchedMatchDate.replace(
            /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
            '',
          );
          const formattedDate = formatDate(dateWithoutTime);

          const fileUrl = videoData[urlIndex];
          const urlWithoutSuffix = fileUrl.substring(
            0,
            fileUrl.lastIndexOf('.'),
          );
          const url = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;

          console.log(videoData);

          setMatchData((prevMatchData) => ({
            ...prevMatchData,
            matchId: videoData[matchIdIndex],
            videoName: videoData[videoNameIndex],
            videoDescription: videoData[matchDescriptionIndex],
            stage: videoData[stageIndex],
            matchDate: formattedDate,
            location: videoData[locationIndex],
            HomePlayer: videoData[homePlayerIndex] || '',
            AwayPlayer: videoData[awayPlayerIndex] || '',
            fileUrl: videoData[urlIndex],
            videoUrl: url,
            reportUrl: videoData[reportIndex],
            matchEvent: videoData[subEventIndex] || '',
            matchParentEvent: videoData[parentEventIndex],
            matchCategory: videoData[categoryIndex],
            isDouble: videoData[isDoubleIndex] || false,
            accountType: userInfo.accountType,
            customTag: videoData[customTagIndex] || '',
            dpFilename: videoData[dpFilenameIndex] || '',
            dpAnalyzedTime: videoData[dpAnalyzedTimeIndex] || '',
            LastOCR: videoData[LastOCRIndex] || '',
            OCRFilename: videoData[OCRFilenameIndex] || '',
            tableCoor: videoData[tableCoorIndex]
              ? videoData[tableCoorIndex].map((coor) => ({
                  x: coor[0] / 1000,
                  y: coor[1] / 1000,
                }))
              : [],
            scoreCoor: videoData[scoreCoorIndex]
              ? videoData[scoreCoorIndex].map((coor) => ({
                  x: coor[0] / 1000,
                  y: coor[1] / 1000,
                }))
              : [],
            startingPlayer: videoData[startingPlayerIndex] || 'top',
          }));
        });
      } catch (error) {}
    };
    fetchVideoData();
  }, []);

  if (!matchData) {
    return null;
  }

  return (
    <div className="edit-videoData-main-container">
      <VideoEditForm
        matchData={matchData}
        setMatchData={setMatchData}
        userInfo={userInfo}
        token={token}
        defaultStep={step}
      />
      <a href={`/video-player/${videoId}`} className="edit-video-back-button">
        返回視頻
        <ArrowBackOutlinedIcon />
      </a>
    </div>
  );
};

export default EditVideoData;
