// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-player-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: var(--small-font-size);
  background-color: #f3f3f3;
  border-radius: var(--small-font-size);
  font-size: 1.5rem;
  width: 90%;
}
.edit-player-info .player-profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.edit-player-info .delete-player-button {
  color: #c20d13;
  font-weight: 600;
  font-size: 1.5rem;
}
.edit-player-info .edit-player-button {
  color: #151799;
  font-weight: 600;
  font-size: 1.5rem;
}
.edit-player-info svg {
  font-size: 1.5rem !important;
  padding: 0;
}
.edit-player-info span {
  margin: 0;
}

.edit-player-main-container .edit-player-main-panel {
  padding: var(--large-button-size);
}`, "",{"version":3,"sources":["webpack://./src/playerPages/AnalysisMainPanel/EditPlayerDialog.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,8BAAA;EACA,yBAAA;EACA,qCAAA;EACA,iBAAA;EACA,UAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AACE;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACE;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACE;EACE,4BAAA;EACA,UAAA;AACJ;AACE;EACE,SAAA;AACJ;;AAIE;EACE,iCAAA;AADJ","sourcesContent":[".edit-player-info {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  margin: var(--small-font-size);\n  background-color: #f3f3f3;\n  border-radius: var(--small-font-size);\n  font-size: 1.5rem;\n  width: 90%;\n\n  .player-profile-header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n  }\n  .delete-player-button {\n    color: #c20d13;\n    font-weight: 600;\n    font-size: 1.5rem;\n  }\n  .edit-player-button {\n    color: #151799;\n    font-weight: 600;\n    font-size: 1.5rem;\n  }\n  svg {\n    font-size: 1.5rem !important;\n    padding: 0;\n  }\n  span {\n    margin: 0;\n  }\n}\n\n.edit-player-main-container {\n  .edit-player-main-panel {\n    padding: var(--large-button-size);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
