// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis-video-table-container {
  min-width: 100%;
  max-width: 100%;
  max-height: 90vh;
  overflow: scroll;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  justify-content: flex-start;
  align-items: center;
  gap: 1vw;
  padding-right: var(--small-font-size);
  background-color: transparent;
}
@media (orientation: landscape) and (max-width: 1500px) and (min-aspect-ratio: 16/9) {
  .analysis-video-table-container {
    flex-direction: row;
    scroll-snap-type: x mandatory;
  }
}
.analysis-video-table-container .padding {
  min-height: 20vh;
  min-width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/playerPages/PlayerVideos/PlayerVideos.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;EAEA,aAAA;EACA,sBAAA;EACA,6BAAA;EAMA,2BAAA;EACA,mBAAA;EACA,QAAA;EAEA,qCAAA;EACA,6BAAA;AANF;AAHE;EAXF;IAYI,mBAAA;IACA,6BAAA;EAMF;AACF;AACE;EACE,gBAAA;EACA,cAAA;AACJ","sourcesContent":[".analysis-video-table-container {\n  min-width: 100%;\n  max-width: 100%;\n  max-height: 90vh;\n  overflow: scroll;\n  flex: 1;\n\n  display: flex;\n  flex-direction: column;\n  scroll-snap-type: y mandatory;\n\n  @media (orientation: landscape) and (max-width: 1500px) and (min-aspect-ratio: 16/9) {\n    flex-direction: row;\n    scroll-snap-type: x mandatory;\n  }\n  justify-content: flex-start;\n  align-items: center;\n  gap: 1vw;\n\n  padding-right: var(--small-font-size);\n  background-color: transparent;\n  .padding {\n    min-height: 20vh;\n    min-width: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
