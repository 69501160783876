

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import {IconButton} from '@mui/material';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import Avatar from '@mui/material/Avatar';


import {
  commentOwnerIndex,
  ballCommentIndex,
  commentDateIndex,
  commentRatingIndex,
  commentIdIndex
} from 'src/constants';

import axios from 'axios';

import Cookies from 'js-cookie';

import './CommentDisplay.scss';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const trimmedName = name.trim();
  const nameParts = trimmedName.split(' ');
  let initials = '';

  if (nameParts.length >= 2) {
    initials = `${nameParts[0][0]}${nameParts[1][0]}`;
  } else if (nameParts.length === 1) {
    initials = nameParts[0].slice(0, 2);
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${initials}`,
  };
}

const CommentDisplay = ( {setIsEditing, setCommentIndex, setEditingBallIndex, currentBallIndex, commentData, setCommentData,userInfo,token, setVideoData})  => {
    

  const { identifier: commentOwner, isCoach, admin: isAdmin, subadmin: isSubadmin } = userInfo;

  const permission = isCoach ===true || isAdmin === true || isSubadmin ===true ? true : false;



      const handleEditChange = (index) => {
          setIsEditing(true);
          setCommentIndex(index);
          setEditingBallIndex(currentBallIndex);
        };


      const deleteCommentData = (index, comment, comment_owner, comment_date) => {
        const commentId = commentData[currentBallIndex][index][commentIdIndex];
        axios
          .delete(`/api/editballcomment?comment_id=${commentId}`,{
            params: {
              primary_attribute: `deleted comment: ${comment}`,
              secondary_attribute: `details: comment by ${comment_owner}/${comment_date}`
            },
            headers: {
            Authorization: `${token}`
          }})
          .then(response => {
            console.log('Comment deleted successfully');
            setVideoData(prevState => ({
              ...prevState,
              refresh: true
            }));
          })
          .catch(error => {
            console.error('Error deleting comment:');
          });
      };

      const handleDelete = (index, comment, comment_owner, comment_date) => {
        const deleteComments = [...commentData];
        deleteComments[currentBallIndex] = deleteComments[currentBallIndex].filter((_, i) => i !== index);
        deleteCommentData(index, comment, comment_owner, comment_date);
        setCommentData(deleteComments);
      };

      const labels = {
        0: '極差',
        0.5: '非常差',
        1: '差',
        1.5: '稍差',
        2: '尚可',
        2.5: '良好',
        3: '相當好',
        3.5: '優秀',
        4: '非常優秀',
        4.5: '卓越',
        5: '極卓越',
      };
    
      function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
      }
  
    return (
  
    <div className='ball-commentary-display-container'>
      {commentData[currentBallIndex] && (
        <div>
            {commentData[currentBallIndex].map((row, index) => (
                <div className='video-ind-commentary-display'>
                  <div className='comment-rating'>
                    <Rating
                        className='stars'
                        name="read-only"
                        readOnly
                        value={parseInt(row[commentRatingIndex])}
                        precision={0.5}
                        getLabelText={getLabelText}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                      <Box sx={{ ml: 2 }}>{labels[parseInt(row[commentRatingIndex])]}</Box>
                    </div>
                    <p className="comment" key={index}> 
                    <span className='avatar-owner-span'>
                      <Avatar {...stringAvatar(String(row[commentOwnerIndex]))} />
                      {row[commentOwnerIndex]}
                    </span>
                     {row[ballCommentIndex]}
                    </p>
                    <p className="comment-date">{row[commentDateIndex]}</p>
                    {row[commentOwnerIndex].trim() === commentOwner.trim() && (
                      <div>
                        <IconButton className="video-h-edit-button" onClick={() => handleEditChange(index)}>
                          <EditIcon /> 
                        </IconButton>
                        <IconButton className="video-h-delete-button" onClick={() => handleDelete(index, row[ballCommentIndex],row[commentOwnerIndex],row[commentDateIndex])}>
                          <DeleteIcon /> 
                        </IconButton>
                        </div>
                    )}
                </div>
            ))}
            {permission && (
            <IconButton className="h-add-button" onClick={() => handleEditChange(-1)}>
                        <MessageIcon/>  <span className='new-comment'> 點擊這裡新增評論  </span>
                    </IconButton>
            )}
        </div>
      )}
    </div>
    );
  };

export default CommentDisplay ;
