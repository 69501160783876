import { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HeatMapForm = ({ playerList, hmOption, setHmOption }) => {
  const token = Cookies.get('token');

  const [error, setError] = useState('');
  // for select
  const [playerFormMatches, setPlayerFormMatches] = useState('');
  const [playerFormOpponents, setPlayerFormOpponents] = useState('');
  // hm data
  const [playerForm, setPlayerForm] = useState(hmOption.playerName);
  const [opponentFormOptions, setOpponentFormOptions] = useState(
    hmOption.opponentNames,
  );
  const [matchFormOptions, setMatchFormOptions] = useState(hmOption.videoNames);

  const handlePlayerChange = (event) => {
    const {
      target: { value },
    } = event;
    fetchPlayerMatches(value);
    fetchPlayerOpponents(value);
    setOpponentFormOptions([]);
    setMatchFormOptions([]);
    setPlayerForm(value);
  };

  const handleOpponentChange = (event) => {
    const {
      target: { value },
    } = event;
    setOpponentFormOptions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleMatchChange = (event) => {
    const {
      target: { value },
    } = event;
    setMatchFormOptions(typeof value === 'string' ? value.split(',') : value);
  };

  // get player matches/opponents

  const fetchPlayerMatches = async (value) => {
    try {
      const response = await axios.get(`/api/playerMatches`, {
        params: {
          player_name: value,
        },
        headers: {
          Authorization: `${token}`,
        },
      });
      const responseData = response.data;

      setPlayerFormMatches(responseData);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchPlayerOpponents = async (value) => {
    try {
      const response = await axios.get(`/api/playerOpponents`, {
        params: {
          player_name: value,
        },
        headers: {
          Authorization: `${token}`,
        },
      });
      const responseData = response.data;

      setPlayerFormOpponents(responseData);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchPlayerMatches(playerForm);
    fetchPlayerOpponents(playerForm);
  }, []);

  const handleSave = () => {
    // Make changes to hmOptions or perform any necessary validation
    const updatedOptions = {
      playerName: playerForm,
      videoNames: matchFormOptions,
      opponentNames: opponentFormOptions,
    };
    // Update hmOptions as needed
    setHmOption(updatedOptions);
  };

  return (
    <div>
      <Grid sx={{ backgroundColor: 'white' }}>
        <Grid item xs={12} sm={6}>
          <InputLabel id="hPlayer-simple-select-helper-label">球員:</InputLabel>
          <Select
            id="hPlayer"
            name="HomePlayer"
            value={playerForm || ''}
            onChange={handlePlayerChange}
            input={<OutlinedInput />}
            sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
          >
            <MenuItem value="" disabled>
              <em>請选择球員</em>
            </MenuItem>
            {playerList.map((player) => (
              <MenuItem key={player[0]} value={player[0]}>
                {player[0]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel id="hPlayerOpponent-simple-select-helper-label">
            球員對手:
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={opponentFormOptions}
            onChange={handleOpponentChange}
            input={<OutlinedInput />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
            sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
          >
            {playerFormOpponents &&
              playerFormOpponents.map((name) => (
                <MenuItem key={name} value={name[0]}>
                  {name[0]}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={1} sm={1}>
          <h2>或者</h2>
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel id="hPlayerOpponent-simple-select-helper-label">
            球員比賽:
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={matchFormOptions}
            onChange={handleMatchChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            disabled={opponentFormOptions && opponentFormOptions.length !== 0}
          >
            {playerFormMatches &&
              playerFormMatches.map((name) => (
                <MenuItem key={name} value={name[0]}>
                  {name[0]}
                </MenuItem>
              ))}
          </Select>
          {opponentFormOptions && opponentFormOptions.length !== 0 ? (
            <p style={{ color: 'red' }}>
              *使用對手選項進行篩選將覆蓋比賽選項。
            </p>
          ) : null}
        </Grid>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </div>
  );
};

export default HeatMapForm;
