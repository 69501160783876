import { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import POVEditorPlayer from './POVEditorPlayer/POVEditorPlayer';
import POVControlPanel from './POVControlPanel/POVControlPanel';
import EditPOVDisplay from './EditPOVDisplay/EditPOVDisplay';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import './POVEditor.scss';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  dateIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  roundDataIndex,
  roundStartTimeIndex,
  roundEndTimeIndex,
  highlightDataIndex,
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  transformationStartTimeIndex,
  transformationEndTimeIndex,
  transformationXIndex,
  transformationYIndex,
  transformationScaleIndex,
  highlightIdIndex,
  transformationHighlightIdIndex,
} from 'src/constants';

const POVEditor = () => {
  const token = Cookies.get('token');
  const { videoId } = useParams();
  const [error, setError] = useState('');
  const videoRef = useRef(null);

  const [matchResults, setMatchResults] = useState(null);
  const [roundResults, setRoundResults] = useState(null);

  const [currentRound, setCurrentRound] = useState(0);
  const [currentBall, setCurrentBall] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [POVIndex, setPOVIndex] = useState(0);
  const [tempResults, setTempResults] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(0);

  const [transformation, setTransformation] = useState({
    positionX: 0,
    positionY: 0,
    scale: 1,
  });
  const [progressBarValue, setProgressBarValue] = useState(0);

  const fetchVideoData = async () => {
    try {
      const response = await axios.get(`/api/videodata/${videoId}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      const responseData = response.data;
      // Destructure the response data
      const [matchResults, roundResults] = responseData;

      // change date and url
      let transformedList;
      transformedList = matchResults.map((videoData) => {
        const fileUrl = videoData[urlIndex];
        const urlWithoutSuffix = fileUrl.substring(0, fileUrl.lastIndexOf('.'));
        const url = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;

        const fetchedMatchDate = videoData[dateIndex];
        const dateWithoutTime = fetchedMatchDate.replace(
          /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
          '',
        );

        // Create a new array with the transformed data
        return [
          videoData[matchIdIndex], // Match ID
          videoData[videoNameIndex], // Video Name
          videoData[matchDescriptionIndex], // Video Description
          videoData[stageIndex], // Stage
          dateWithoutTime, // Transformed Match Date
          videoData[locationIndex], // Location
          videoData[homePlayerIndex], // Player 1
          videoData[awayPlayerIndex], // Player 2
          url, // Transformed File URL
        ];
      });

      setMatchResults(transformedList[0]);
      setRoundResults(roundResults);
    } catch (error) {
      setError(error.message);
    }
  };
  // load data
  useEffect(() => {
    fetchVideoData();
  }, []);

  // create copy for editing
  useEffect(() => {
    if (roundResults) {
      setTempResults(roundResults);
    }
  }, [roundResults]);

  // get current ball
  useEffect(() => {
    const updateRoundBall = () => {
      // find round once data is available
      if (roundResults && roundResults.length > 0) {
        let matchedRoundIndex = -1;
        // if time is before the first ball and current round is not 0
        if (
          currentTime <= roundResults[0][roundDataIndex][roundStartTimeIndex]
        ) {
          matchedRoundIndex = 0;
        }
        // if not, find round
        else {
          // loop all round start and end time
          for (
            let roundIndex = 0;
            roundIndex < roundResults.length;
            roundIndex++
          ) {
            const roundStartTime =
              roundResults[roundIndex][roundDataIndex][roundStartTimeIndex];
            const roundEndTime =
              roundResults[roundIndex][roundDataIndex][roundEndTimeIndex];
            // if time between start and end = found
            if (currentTime >= roundStartTime) {
              matchedRoundIndex = roundIndex;
              if (currentTime <= roundEndTime) {
                break;
              }
            }
          }
        }

        if (matchedRoundIndex !== -1) {
          if (currentRound !== matchedRoundIndex) {
            setCurrentRound(matchedRoundIndex);
          }
          // find highlight
          let matchHighlightIndex = -1;
          for (
            let hIndex = 0;
            hIndex < roundResults[matchedRoundIndex][highlightDataIndex].length;
            hIndex++
          ) {
            const highlightStartTime =
              roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
                highlightStartTimeIndex
              ];
            const highlightEndTime =
              roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
                highlightEndTimeIndex
              ];
            // if time between start and end = found
            if (currentTime >= highlightStartTime) {
              matchHighlightIndex = hIndex;
              setHighlightIndex(matchHighlightIndex);
              if (currentTime <= highlightEndTime) {
                let matchedPOVIndex = -1;
                for (
                  let POVIndex = 0;
                  POVIndex <
                  roundResults[matchedRoundIndex][highlightDataIndex][
                    matchHighlightIndex
                  ][1].length;
                  POVIndex++
                ) {
                  const POVStartTime =
                    roundResults[matchedRoundIndex][highlightDataIndex][
                      matchHighlightIndex
                    ][1][POVIndex][transformationStartTimeIndex];
                  const POVEndTime =
                    roundResults[matchedRoundIndex][highlightDataIndex][
                      matchHighlightIndex
                    ][1][POVIndex][transformationEndTimeIndex];
                  // if pov found
                  if (
                    currentTime >= POVStartTime &&
                    currentTime <= POVEndTime
                  ) {
                    matchedPOVIndex = POVIndex;
                    setPOVIndex(matchedPOVIndex);
                    break;
                  }
                }
              }
            }
          }
        } else {
          console.log('roundResults is null or empty.');
        }
      }
    };

    updateRoundBall();
    handleTimeBarUpdate();
  }, [currentTime]);

  // update the result
  useEffect(() => {
    if (
      tempResults.length > 0 &&
      isEditing &&
      !isPreview &&
      tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex]
    ) {
      setTempResults((prevTempResults) => {
        const updatedTempResults = [...prevTempResults];
        updatedTempResults[currentRound][highlightDataIndex][highlightIndex][1][
          POVIndex
        ][transformationXIndex] = transformation.positionX;
        updatedTempResults[currentRound][highlightDataIndex][highlightIndex][1][
          POVIndex
        ][transformationYIndex] = transformation.positionY;
        updatedTempResults[currentRound][highlightDataIndex][highlightIndex][1][
          POVIndex
        ][transformationScaleIndex] = transformation.scale;
        updatedTempResults[currentRound][highlightDataIndex][highlightIndex][1][
          POVIndex
        ][transformationHighlightIdIndex] =
          tempResults[currentRound][highlightDataIndex][highlightIndex][0][
            highlightIdIndex
          ];
        return updatedTempResults;
      });
    }
  }, [transformation]);

  // get progress bar value
  const handleTimeBarUpdate = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      const relativeTime =
        currentTime -
        tempResults[currentRound][highlightDataIndex][highlightIndex][0][
          highlightStartTimeIndex
        ];
      const highlightDuration =
        tempResults[currentRound][highlightDataIndex][highlightIndex][0][
          highlightEndTimeIndex
        ] -
        tempResults[currentRound][highlightDataIndex][highlightIndex][0][
          highlightStartTimeIndex
        ];

      if (isFinite(duration)) {
        setProgressBarValue((relativeTime / highlightDuration) * 100);
      } else {
        setProgressBarValue(0);
      }
    }
  };

  // update progress bar
  useEffect(() => {
    const progressBar = document.getElementById('progress-bar');
    if (progressBar) {
      progressBar.value = progressBarValue;
    }
  }, [progressBarValue]);

  return (
    <div className="main-pov-edit">
      {error && <p>Error: {error}</p>}
      {tempResults.length > 0 &&
      tempResults[currentRound][highlightDataIndex][highlightIndex] ? (
        <>
          <div className="edit-pov-video-interface">
            <div className="edit-pov-video-container">
              <POVEditorPlayer
                source={matchResults[urlIndex]}
                videoRef={videoRef}
                tempResults={tempResults}
                currentRound={currentRound}
                POVIndex={POVIndex}
                setTransformation={setTransformation}
                transformation={transformation}
                isPreview={isPreview}
                highlightIndex={highlightIndex}
                setIsPlaying={setIsPlaying}
                clickedIndex={clickedIndex}
                setCurrentTime={setCurrentTime}
              />
              <div className="edit-pov-control-interface">
                <div className="pov-control-container">
                  <progress
                    className="custom-progress-bar"
                    id="progress-bar"
                    max="100"
                    value="0"
                  />
                  <POVControlPanel
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    videoRef={videoRef}
                    clickedIndex={clickedIndex}
                    setClickedIndex={setClickedIndex}
                    isPreview={isPreview}
                    setIsPreview={setIsPreview}
                    tempResults={tempResults}
                    currentRound={currentRound}
                    highlightIndex={highlightIndex}
                    setTempResults={setTempResults}
                    setIsEditing={setIsEditing}
                    setPOVIndex={setPOVIndex}
                  />
                </div>
              </div>
            </div>
            <EditPOVDisplay
              tempResults={tempResults}
              videoRef={videoRef}
              fetchVideoData={fetchVideoData}
              currentBall={currentBall}
              currentRound={currentRound}
              setHighlightIndex={setHighlightIndex}
              setCurrentBall={setCurrentBall}
              setCurrentRound={setCurrentRound}
              setClickedIndex={setClickedIndex}
              videoId={videoId}
            />
          </div>
        </>
      ) : (
        <div className="loading-highlight">
          <CircularProgress color="primary" />
          <p className="error">
            <Link to={`/set-highlight/${videoId}`}>
              未找到球數據.......返回亮點编辑
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

export default POVEditor;
