import React from 'react';
import './table.scss';

import {
    ballStartTimeIndex,
    ballHomePlayerScoreIndex,
    ballAwayPlayerScoreIndex,
    ballHighlightIndex
  } from 'src/constants';

const Table = ({ matchData, roundData, roundIndex, videoRef, currentBall }) => {


    const renderTableRows = () => {
        let HomePlayerScore = 0;
        let AwayPlayerScore = 0;

        // jump to ball start time
        const handleColumnClick = (ballResult) => {
          videoRef.current.currentTime = ballResult[ballStartTimeIndex];
        };



        return (
            <>
            <td className='round'>
                <tr>回合 {roundIndex+1}</tr>
            </td>
            <td className='player-name'>
            <tr className='HomePlayer-name'>{matchData.homePlayer.length <= 15 ? matchData.homePlayer : matchData.homePlayer.slice(0, 15) + '...'}</tr>
            <tr>{matchData.awayPlayer.length <= 15 ? matchData.awayPlayer : matchData.awayPlayer.slice(0, 15) + '...'}</tr>
            </td>
            
            {roundData.map((row, index) => {
                const HomePlayerCurrentScore = row[ballHomePlayerScoreIndex];
                const AwayPlayerCurrentScore = row[ballAwayPlayerScoreIndex];
                const highlight = row[ballHighlightIndex];

                const HomePlayerScoreIncreased = HomePlayerCurrentScore > HomePlayerScore;
                const AwayPlayerScoreIncreased = AwayPlayerCurrentScore > AwayPlayerScore;

                HomePlayerScore = HomePlayerCurrentScore;
                AwayPlayerScore = AwayPlayerCurrentScore;

                const isCurrentBall = index === currentBall;

                let playerScoreClass = "player-score";

                if (highlight && isCurrentBall) {
                  playerScoreClass += " highlight current-ball";
                } else if (highlight) {
                  playerScoreClass += " highlight";
                } else if (isCurrentBall) {
                  playerScoreClass += " current-ball";
                }

                return (
                  <React.Fragment key={`${row[ballHomePlayerScoreIndex]}-${row[ballAwayPlayerScoreIndex]}`}>
                    <td className={playerScoreClass} onClick={() => handleColumnClick(row)}>
                      <tr style={{ color: HomePlayerScoreIncreased ? "red" : "black" }}>
                        <td>{HomePlayerCurrentScore}</td>
                      </tr>
                      <tr style={{ color: AwayPlayerScoreIncreased ? "red" : "black" }}>
                        <td>{AwayPlayerCurrentScore}</td>
                      </tr>
                    </td>
                  </React.Fragment>
                );
              })}
            </>
        );
        };

      return (
        <table className='score-table'>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>
      );
      }
export default Table;