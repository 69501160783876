import { useState } from 'react';

import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  ballCommentIndex,
  commentIdIndex,
  commentRatingIndex,
} from 'src/constants';

import axios from 'axios';

import './CommentEdit.scss';

const labels = {
  0: '極差',
  0.5: '非常差',
  1: '差',
  1.5: '稍差',
  2: '尚可',
  2.5: '良好',
  3: '相當好',
  3.5: '優秀',
  4: '非常優秀',
  4.5: '卓越',
  5: '極卓越',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const CommentEdit = ({
  commentData,
  currentBallIndex,
  setIsEditing,
  commentIndex,
  setCommentData,
  commentOwner,
  ballId,
  token,
  setVideoData,
}) => {
  const [commentText, setCommentText] = useState(
    commentIndex !== -1 &&
      commentData[currentBallIndex] &&
      commentData[currentBallIndex][commentIndex]
      ? commentData[currentBallIndex][commentIndex][ballCommentIndex]
      : '將您的評論輸入在這裡',
  );

  const [commentRating, setCommentRating] = useState(
    commentIndex !== -1 &&
      commentData[currentBallIndex] &&
      commentData[currentBallIndex][commentIndex]
      ? commentData[currentBallIndex][commentIndex][commentRatingIndex]
      : '0',
  );

  const now = new Date();
  const formattedDate = now.toISOString().split('T')[0];

  const handleRatingChange = (newValue) => {
    setCommentRating(newValue);
  };

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const updateCommentData = ({ newComment }) => {
    axios
      .put(
        '/api/editballcomment',
        { new_comment: newComment },
        {
          params: {
            primary_attribute: `new comment: ${newComment[3]}`,
          },
          headers: {
            Authorization: `${token}`,
          },
        },
      )
      .then((response) => {
        console.log('Comment updated successfully');
        setVideoData((prevState) => ({
          ...prevState,
          refresh: true,
        }));
      })
      .catch((error) => {
        console.error('Error updating comment:');
      });
  };

  const handleSave = () => {
    if (commentIndex !== -1) {
      // Edit existing comment
      const newData = [
        commentData[currentBallIndex][commentIndex][commentIdIndex],
        ballId,
        commentRating,
        commentText,
        commentOwner,
        formattedDate,
      ];
      const updatedComments = [...commentData];
      updatedComments[currentBallIndex][commentIndex] = newData;
      updateCommentData({ newComment: newData });
      setCommentData(updatedComments);
    } else {
      // Add new comment
      const newData = [
        -1,
        ballId,
        commentRating,
        commentText,
        commentOwner,
        formattedDate,
      ];
      const updatedComments = [...commentData];
      updatedComments[currentBallIndex].push(newData);
      updateCommentData({ newComment: newData, oldComment: '' });
      setCommentData(updatedComments);
    }
    setIsEditing(false);
  };

  return (
    <div className="ball-commentary-edit-panel">
      <div className="comment-rating">
        <Rating
          name="hover-feedback"
          className="stars"
          value={parseInt(commentRating)}
          precision={0.5}
          getLabelText={getLabelText}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          onChange={(e) => handleRatingChange(e.target.value)}
        />
        <Box sx={{ ml: 2 }}>{labels[parseInt(commentRating)]}</Box>
      </div>
      <div className="edit-comment-panel">
        <TextField
          id="outlined-controlled"
          multiline
          placeholder="亮點評述"
          color="secondary"
          focused
          value={commentText}
          onChange={handleCommentChange}
        />
        <div>
          <IconButton
            className="h-save-button"
            onClick={() => setIsEditing(false)}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton className="h-save-button" onClick={handleSave}>
            <SendIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CommentEdit;
