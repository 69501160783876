import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Chart from './Chart';
import RecentUpload from './RecentUpload';
import RecentActivities from './RecentActivities';
import ChartOption from './ChartOption';

import Cookies from 'js-cookie';

import { fetchUserLogAPI } from 'src/api/userService';
import './Dashboard.scss';

function HomePage(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Back to '}
      <Link color="inherit" href="/">
        Home Page
      </Link>{' '}
      {'.'}
    </Typography>
  );
}

const Dashboard = () => {
  const token = Cookies.get('token');

  const [userLog, setUserLog] = useState(null);
  const [xAxis, setXAxis] = useState(null);
  const [series, setSeries] = useState(null);
  const [latestUpload, setLatestUpload] = useState(null);
  const [chartWidth, setChartWidth] = useState(200);
  const [chartHeight, setChartHeight] = useState(200);

  const [DBFilterCriteria, setDBFilterCriteria] = useState(() => {
    // Retrieve the cached filter criteria from localStorage, or use the default values
    const cachedCriteria = sessionStorage.getItem('DBFilterCriteria');
    return cachedCriteria
      ? JSON.parse(cachedCriteria)
      : {
          userName: [''],
          dataDate: 'CMonth',
          metrics: ['update video views'],
        };
  });

  const handleResize = () => {
    const { innerWidth, innerHeight } = window;
    const isPortrait = innerHeight > innerWidth;
    const avg = (innerWidth + innerHeight) / 2;
    // Calculate the desired height based on orientation and screen size
    if (isPortrait) {
      setChartWidth(innerWidth * 0.8);
      setChartHeight(innerHeight * 0.3);
    } else {
      setChartWidth(avg * 0.8);
      setChartHeight(avg * 0.4);
    }
  };

  // get data
  useEffect(() => {
    const { userName, dataDate, metrics } = DBFilterCriteria;

    sessionStorage.setItem(
      'DBFilterCriteria',
      JSON.stringify(DBFilterCriteria),
    );

    const fetchUserLog = async () => {
      try {
        const response = await fetchUserLogAPI(
          {
            userName,
            dataDate,
            metrics,
          },
          token,
        );

        const { results, chart_result, latest_upload } = response;
        setUserLog(results);
        setXAxis(chart_result[0]);
        setSeries(chart_result.slice(1));
        setLatestUpload(latest_upload);
      } catch (error) {
        console.error('Error fetching user log:', error);
      }
    };

    fetchUserLog();
    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [DBFilterCriteria, token]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container className="dashboard-main-container" sx={{ mt: 4, mb: 4 }}>
        <Grid
          container
          spacing={3}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {/* Chart */}
          <Grid item xs={12} md={12} lg={9}>
            <Paper className="chart-paper">
              <Chart
                xAxis={xAxis}
                series={series}
                chartHeight={chartHeight}
                chartWidth={chartWidth}
                DBFilterCriteria={DBFilterCriteria}
              />
            </Paper>
          </Grid>
          <Grid
            container
            spacing={3}
            xs={12}
            md={12}
            lg={3}
            alignContent={'center'}
            justifyContent={'space-evenly'}
          >
            {/* Recent Upload */}
            <Grid
              item
              xs={11}
              md={11}
              lg={11}
              spacing={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <ChartOption
                  DBFilterCriteria={DBFilterCriteria}
                  setDBFilterCriteria={setDBFilterCriteria}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={11}
              md={11}
              lg={11}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <RecentUpload latestUpload={latestUpload} />
              </Paper>
            </Grid>
          </Grid>
          {/* Recent Activities */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <RecentActivities usersActivities={userLog} />
            </Paper>
          </Grid>
        </Grid>
        <HomePage sx={{ pt: 4 }} />
      </Container>
    </Box>
  );
};

export default Dashboard;
