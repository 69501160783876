import { useState } from 'react';

import './AnalysisMainPanel.scss';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import PlayerInfoPanel from '../PlayerInfoPanel/PlayerInfoPanel';

import HeatMapMultiPanel from '../AnalysisSecondaryPanel/HeatMapMultiPanel';
import PlayerVideos from '../PlayerVideos/PlayerVideos';
import { Opacity } from '@mui/icons-material';

const AnalysisMainPanel = ({ playerData, playerList, userInfo }) => {
  const [playerTab, setPlayerTab] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);

  const handlePlayerTabChange = (event, newValue) => {
    setPlayerTab(newValue);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="analysis-main-panel">
      {isExpanded && (
        <div className="analysis-options-side-panel">
          <Tabs
            className="analysis-main-tab-panel"
            value={playerTab}
            onChange={handlePlayerTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            indicatorColor="secondary"
            aria-label="player tag"
          >
            <Tab
              className="analysis-sec-tab"
              icon={<ScatterPlotIcon />}
              label="比賽紀錄"
            />
            {/* <Tab
                className="analysis-sec-tab"
                icon={<ScatterPlotIcon />}
                label="球員落點比較"
              /> */}
          </Tabs>

          <PlayerInfoPanel playerData={playerData} userInfo={userInfo} />
        </div>
      )}
      <div
        className="analysis-main-subpanel"
        style={{
          maxWidth: '100vw',
          opacity: isExpanded ? 0.5 : 1,
        }}
      >
        {playerTab === 0 && (
          <PlayerVideos
            playerData={playerData}
            accountType={userInfo.accountType}
          />
        )}
        {playerTab === 1 && <HeatMapMultiPanel playerList={playerList} />}
      </div>
      <button
        className="toggle-player-side-panel-button"
        onClick={toggleExpand}
      >
        {isExpanded ? (
          <CloseOutlinedIcon
            sx={{
              color: 'red',
              backgroundColor: 'white',
              fontSize: '4rem',
            }}
          />
        ) : (
          <AssignmentIndOutlinedIcon
            sx={{
              color: '#51a74e',
              backgroundColor: '#202325',
              fontSize: '2rem',
            }}
          />
        )}
      </button>
    </div>
  );
};

export default AnalysisMainPanel;
