import React, { useState } from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';
import Cookies from 'js-cookie';

import { MatchData } from 'types/videoTypes';

import './VideoFlag.scss';

interface FlagVideoProps {
  matchData: MatchData;
  setMatchData: React.Dispatch<React.SetStateAction<MatchData>>;
  defaultOpen: boolean;
}

const labelDict: { [key: string]: string } = {
  OCR: '分數/時間驗證',
  HUMAN: '手動驗證',
  DP: '落點驗證',
  DATA: '數據不準確',
  OTHER: '其他',
  ocr_need_validation: '分數/時間驗證',
  dp_need_validation: '落點驗證',
};
const FlagVideo: React.FC<FlagVideoProps> = ({
  matchData,
  setMatchData,
  defaultOpen = false,
}) => {
  const token = Cookies.get('token');
  const [open, setOpen] = useState(defaultOpen);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherValue, setOtherValue] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitReason = () => {
    const submitReason = async () => {
      try {
        const response = await axios.put(
          `/api/flagVideo`,
          {
            match_id: matchData.matchId,
            reason: selectedReason,
            customReason: otherValue,
          },
          {
            params: {
              primary_attribute: ` add video flag : ${matchData.videoName}`,
              secondary_attribute: `flagged reason : ${selectedReason}`,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        setMatchData((prevState) => ({
          ...prevState,
          flagComment: selectedReason,
        }));
      } catch (error) {
        // Handle error
        // console.log(error);
      }
    };
    handleClose();
    submitReason();
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(event.target.value);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOtherValue(event.target.value);
  };

  const handleRemoveFlag = () => {
    const submitReason = async () => {
      try {
        const response = await axios.put(
          `/api/flagVideo`,
          {
            match_id: matchData.matchId,
            reason: '',
            customReason: '',
            prev_reason: matchData.flagComment,
          },
          {
            params: {
              primary_attribute: `remove video flag : ${matchData.videoName}`,
              secondary_attribute: `removed reason : ${matchData.flagComment}`,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        setMatchData((prevState) => ({
          ...prevState,
          flagComment: '',
        }));
      } catch (error) {
        //console.log(error);
      }
    };
    submitReason();
  };

  return (
    <div className="flag-video" title="flag video">
      <button onClick={handleClickOpen}>
        <FlagOutlinedIcon
          className={matchData.flagComment === '' ? 'not-flagged' : 'flagged'}
        />
      </button>
      <Dialog open={open} onClose={handleClose}>
        {matchData.flagComment === '' ? (
          <>
            <DialogTitle>舉報視頻的原因</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={selectedReason}
                onChange={handleReasonChange}
                className="flag-option-panel"
              >
                <FormControlLabel
                  value="OCR"
                  control={<Radio />}
                  label="分數/時間不準確"
                />
                <FormControlLabel
                  value="HUMAN"
                  control={<Radio />}
                  label="手動驗證"
                />
                <FormControlLabel
                  value="DP"
                  control={<Radio />}
                  label="落點不準確"
                />
                <FormControlLabel
                  value="DATA"
                  control={<Radio />}
                  label="數據不準確"
                />
                <FormControlLabel
                  value="OTHER"
                  control={<Radio />}
                  label="其他"
                />
                <TextField
                  className="other-reasons"
                  disabled={selectedReason !== 'OTHER'}
                  id="outlined-disabled"
                  label="其他原因"
                  onChange={handleTextFieldChange}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>取消</Button>
              <Button
                onClick={() => {
                  handleSubmitReason();
                }}
              >
                確認
              </Button>
            </DialogActions>
          </>
        ) : (
          <div className="remove-flag-container">
            <span className="remove-flag-title">
              {labelDict[matchData.flagComment] || 'Unknown Label'} 問題已解決?
            </span>
            <button
              onClick={() => {
                handleRemoveFlag();
                handleClose();
              }}
              className="remove-flag-button"
            >
              <DoneOutlineOutlinedIcon />
            </button>
            <button onClick={handleClose} className="remove-flag-close-button">
              <CloseIcon />
            </button>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default FlagVideo;
