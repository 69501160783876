import { useEffect,useState} from 'react';
import Slider from '@mui/material/Slider'
import Cookies from 'js-cookie';

import './HighlightControlPanel.scss';

import {
  roundIdIndex,
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  highlightDataIndex,
  roundDataIndex,
  roundStartTimeIndex,
} from 'src/constants';

//indicate video current time
const HighlightTimeBar = ({ videoRef, roundStartTime, roundEndTime, lowerLimit, upperLimit, highlightIndex, currentRound, setTempResults, tempResults}) => {

  // set highlight start end time
  const [startTime, setStartTime] = useState(
    tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightStartTimeIndex]
  );
  const [endTime, setEndTime] = useState(
    tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightEndTimeIndex]
  );

  
  // jump to time of slider
  useEffect(() => {
    videoRef.current.currentTime = startTime;
  }, [startTime]);
  
  useEffect(() => {
    videoRef.current.currentTime = endTime;
  }, [endTime]);
  

  const handleChange = (event, newValue) => {
    const [newStartTime, newEndTime] = newValue;
    setStartTime(newStartTime);
    setEndTime(newEndTime);
  
    // Check if the new values are within the lower and upper limits
    if (
      newStartTime >= lowerLimit &&
      newEndTime <= upperLimit &&
      newStartTime !== newEndTime
    ) {
      setTempResults((prevTempResults) => {
        const updatedTempResults = [...prevTempResults];
        updatedTempResults[currentRound][highlightDataIndex][highlightIndex][0][
          highlightStartTimeIndex
        ] = newStartTime;
        updatedTempResults[currentRound][highlightDataIndex][highlightIndex][0][
          highlightEndTimeIndex
        ] = newEndTime;
        return updatedTempResults;
      });
    }
  };

  return (
    <div className='highlight-timebar-container'>
      <Slider
        min={roundStartTime}
        max={roundEndTime}
        step={0.01}
        value={[tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightStartTimeIndex],tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightEndTimeIndex]]}
        onChange={handleChange}
        valueLabelDisplay='auto'
        marks={[
          { value: lowerLimit, label: lowerLimit.toFixed(2) },
          { value: upperLimit, label: upperLimit.toFixed(2) }
        ]}
      />
    </div>
  );
};

// edit highlight bar
const VideoTimeBar = ({ roundStartTime, roundEndTime, roundId, tempResults,clickedIndex, setClickedIndex, setLowerLimit, setUpperLimit,
  setHighlightIndex, currentRound, setTempResults, matchData}) => {



  // different color for highlight section
  const generateColor = (index, isClicked) => {
    const hue = (index * 77.5) % 360;
    const saturation = 100; // Adjust the saturation value as desired
    const lightness = isClicked ? '60%' : '90%'; // Adjust the lightness value as desired
    return `hsl(${hue}, ${saturation}%, ${lightness})`;
  };

  // style of the highlight bar
  const getTimeBarStyle = (startTime, endTime, index, isClicked) => {
    const totalTime= roundEndTime - roundStartTime;
    const startPercentage = ((startTime-roundStartTime) / totalTime) * 100;
    const barWidth = ((endTime - startTime) / totalTime) * 100;
    const color = generateColor(index, isClicked);
    return {
      left: `${startPercentage}%`,
      width: `${barWidth}%`,
      background: color,
      position: 'absolute', // Set absolute positioning
      top: '0', // Align time bars on the same y-axis
      cursor: 'pointer',
    };
  };

  // set upper and lower bound of highlight
  const setLimits = (index) => {
    let lowerLimit;
    let upperLimit;
    
    if (index > 0) {
      // Get the end time of the previous index as the lower limit (0.001 SO THAT START != END)
      lowerLimit = tempResults[currentRound][highlightDataIndex][index - 1][0][highlightEndTimeIndex] + 0.001;
    } else {
      // No previous index, use ballStartTime as the lower limit
      lowerLimit = roundStartTime;
    }

    if (index < tempResults[currentRound][highlightDataIndex].length - 1) {
      // Get the start time of the next index as the upper limit
      upperLimit = tempResults[currentRound][highlightDataIndex][index + 1][0][highlightStartTimeIndex] -0.001;
    } else {
      // No next index, use ballEndTime as the upper limit
      upperLimit = roundEndTime;
    }
  
    setLowerLimit(lowerLimit);
    setUpperLimit(upperLimit);
    setHighlightIndex(index);
  
  };

  

  // add highlight or edit selected
  const handleTimeBarClick = (event, index) => {
    // Check if the clicked element is existing highlight  (already inside timebar)
    if (event.target.classList.contains('timebar')) {
      event.stopPropagation();
      // set limit first
      setLimits(index);
  
      if (clickedIndex === index) {
        setClickedIndex(null);
      } else {
        setClickedIndex(index);
      }
    } 

    else{
      // get the click time based on position in bar
      const owner = Cookies.get('identifier');
      const date = new Date();
      const formattedTimestamp = date.toUTCString();
      const rating = 0;
      const comment = '';
      const groupId = false;
      const viewCount = 0;
      const timeBarRect = event.currentTarget.getBoundingClientRect();
      const clickPositionX = event.clientX - timeBarRect.left;
      const totalTime = roundEndTime - roundStartTime;
      const clickTime = (clickPositionX / timeBarRect.width) * totalTime + roundStartTime;

      // get the largest section of highlight based on click time
      setTempResults((prevTempResults) => {
        const updatedTempResults = [...prevTempResults];
        const highlight = updatedTempResults[currentRound][highlightDataIndex];


        let minDifference = Infinity; // Initialize with a large value
        let largestEndTime = -Infinity;
        let newIndex = -1;
        
        // get a new index for new highlight by finding the current highlight with the closest end time
        for (let i = 0; i < highlight.length; i++) {
          const endTime = highlight[i][0][highlightEndTimeIndex];
          const difference = Math.abs(endTime - clickTime);
          // new index
          if (difference < minDifference) {
            minDifference = difference;
            newIndex = i;
          }
          // largest end time
          if (endTime > largestEndTime) {
            largestEndTime = endTime;
          }
        }
        
        // first highlight = -1 because it will +1 = 0
        if (newIndex === 0 && clickTime < highlight[newIndex][0][highlightStartTimeIndex]) {
          newIndex = -1;
        }
      
        // Set start time as the end time of the previous index or ballStartTime if index is 0
        let newStartTime;
        let newEndTime;
        
        // + a small number 0.001 beacuse of cases where start == end which is not allowed
        if (highlight[newIndex]) {
          newStartTime = highlight[newIndex][0][highlightEndTimeIndex] + 0.001;
        } else {
          newStartTime = roundStartTime;
        }
        // Set end time as the start time of the next index or ballEndTime if index is the last index
        if (clickTime < largestEndTime && highlight[newIndex + 1][0]) {
          newEndTime = highlight[newIndex + 1][0][highlightStartTimeIndex] - 0.001
        } else {
          newEndTime = roundEndTime;
        };
        console.log(matchData);

        // Append the new_Highlight at the appropriate index in the highlight array
        // [id, roundid,start,end,matchid,hpid,apid,hp,ap, datecreated, ownerid]
        const newHighlight = [[false, roundId, newStartTime, newEndTime
          , matchData.matchId, matchData.homePlayerId, matchData.awayPlayerId, 
          matchData.homePlayer, matchData.awayPlayer, rating, comment, 
          groupId, viewCount, formattedTimestamp, owner]];
        highlight.splice(newIndex + 1, 0, newHighlight);
        console.log('NEW:', newHighlight);

        return updatedTempResults;
      });
    }
  };


  return (
    <div
      className='timebar-container'
      style={{ position: 'relative' }}
      onClick={(event) => handleTimeBarClick(event, null, null)}
    > 
        {tempResults[currentRound][highlightDataIndex].map((highlightResult, highlightIndex) => (
          <div
            key={highlightIndex}
            className="timebar"
            style={getTimeBarStyle(
              highlightResult[0][highlightStartTimeIndex],
              highlightResult[0][highlightEndTimeIndex],
              highlightIndex,
              clickedIndex === highlightIndex
            )}
            onClick={(event) => handleTimeBarClick(event, highlightIndex, null)}
          ></div>
        ))}
  </div>
  );
};

const HighlightControlPanel = ({ roundEndTime, currentRound, tempResults, matchData, videoRef, highlightIndex, setHighlightIndex,
   setClickedIndex,clickedIndex ,setTempResults}) => {

  const roundStartTime = tempResults[currentRound][roundDataIndex][roundStartTimeIndex];
  const roundId = tempResults[currentRound][roundDataIndex][roundIdIndex];
  const [upperLimit, setUpperLimit] = useState(null);
  const [lowerLimit, setLowerLimit] = useState(null);

  return (
    <div className='highlight-control-panel'>
      { roundEndTime !== 0 ? (
      <div className='highlight-control-option'>
        <VideoTimeBar tempResults={tempResults}  setTempResults={setTempResults} currentRound={currentRound} roundId={roundId} roundStartTime={roundStartTime} roundEndTime={roundEndTime}
          clickedIndex={clickedIndex} setClickedIndex={setClickedIndex} setUpperLimit={setUpperLimit} setLowerLimit={setLowerLimit} setHighlightIndex={setHighlightIndex} matchData={matchData}/>
         { clickedIndex != null ? (
          <HighlightTimeBar videoRef={videoRef} roundStartTime={roundStartTime} roundEndTime={roundEndTime} lowerLimit={lowerLimit} upperLimit={upperLimit} highlightIndex={highlightIndex} tempResults={tempResults} currentRound={currentRound} setTempResults={setTempResults}/> 
         ):(
          null
         )}
         </div>
      ):(
        null
      )}
    </div>
  );
};

export default HighlightControlPanel;