import { useState } from 'react';
import Button from '@mui/material/Button';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import HeatMapForm from './HeatMapForm';

const HeatMapCustomDialog = ({ playerList, hmOptions, setHmOptions }) => {
  const [expanded, setExpanded] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <div className="multi-heatmap-setting-button">
      <Button sx={{ color: 'white' }} onClick={handleClickOpen}>
        <SettingsOutlinedIcon />
      </Button>
      <Dialog fullWidth="10vw" open={open} onClose={handleClose}>
        <DialogTitle>設定</DialogTitle>

        {hmOptions.map((hmOption, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{`第${index + 1}位球員`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DialogContent>
                <HeatMapForm
                  playerList={playerList}
                  hmOption={hmOption}
                  setHmOption={(updatedOption) => {
                    const newOptions = [...hmOptions];
                    newOptions[index] = updatedOption;
                    setHmOptions(newOptions);
                  }}
                />
              </DialogContent>
            </AccordionDetails>
          </Accordion>
        ))}

        <DialogActions>
          <Button onClick={handleClose}>關</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HeatMapCustomDialog;
