import React, { useState, useEffect, useCallback } from 'react';
import { fetchPlayerDataAPI } from 'src/api/playerService';
import Cookies from 'js-cookie';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
  FormLabel,
  Button,
  Drawer,
  IconButton,
  TextField,
  Chip,
  Autocomplete,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

interface PlayerData {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  countryName: string;
  countryCode: string;
  category: string;
  division: string;
  playCategory: string;
  year: number;
  ranking: number;
  worldRank: number;
  matchesPlayed: number;
  matchesWon: number;
  matchesLost: number;
  gender: string;
  age: number;
  birthDate: string;
  hand: string;
  grip: string;
  photoUrl400: string;
  photoUrl1080right: string;
  photoUrl1080left: string;
  isActive: boolean;
  isHallOfFame: boolean;
  isRetired: boolean;
}

interface FilterComponentProps {
  selectedDepth: number;
  setSelectedDepth: React.Dispatch<React.SetStateAction<number>>;
  customDepth: string;
  setCustomDepth: React.Dispatch<React.SetStateAction<string>>;
  handleCustomDepthSubmit: () => void;
  handleReset: () => void;
  onSelectedPlayersChange: (selectedPlayerIds: number[]) => void;
  userInfo: any;
  setToken: any;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  selectedDepth,
  setSelectedDepth,
  customDepth,
  setCustomDepth,
  handleCustomDepthSubmit,
  handleReset,
  onSelectedPlayersChange,
  userInfo,
  setToken,
}) => {
  const token = Cookies.get('token');

  const highlightNames_M = [
    'WONG CHUN TING',
    'HO KWAN KIT',
    'LAM SIU HANG',
    'CHAN BALDWIN',
  ];
  const highlightNames_F = [
    'LAM YEE LOK',
    'DOO HOI KEM',
    'ZHU CHENGZHU',
    'LEE HO CHING',
    'NG WING LAM',
  ];

  const dummyPlayers: PlayerData[] = [
    {
      id: 112620,
      fullName: 'WONG CHUN TING',
      firstName: 'CHUN TING',
      lastName: 'WONG',
      countryName: 'Hong Kong, China',
      countryCode: 'HKG',
      category: '',
      division: '',
      playCategory: '',
      year: 0,
      ranking: 0,
      worldRank: 0,
      matchesPlayed: 0,
      matchesWon: 0,
      matchesLost: 0,
      gender: 'M',
      age: 0,
      birthDate: '',
      hand: '',
      grip: '',
      photoUrl400: '',
      photoUrl1080right: '',
      photoUrl1080left: '',
      isActive: true,
      isHallOfFame: false,
      isRetired: false,
    },
  ];

  const [players, setPlayers] = useState<PlayerData[]>(dummyPlayers);
  const [countries, setCountries] = useState<{ name: string; code: string }[]>(
    [],
  );
  const [countryFilter, setCountryFilter] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [modeFilter, setModeFilter] = useState('all');
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<number[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [drawerSearchTerm, setDrawerSearchTerm] = useState('');

  const [filterCriteria, setFilterCriteria] = useState({
    playerName: '',
    ranking: '',
    country: [] as string[],
    gender: '',
    grip: '',
    ageCategory: '',
  });

  useEffect(() => {
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      playerName: searchTerm,
    }));
  }, [searchTerm]);

  useEffect(() => {
    const fetchPlayersData = async () => {
      try {
        const response = await fetchPlayerDataAPI(
          {
            player_name: filterCriteria.playerName as string,
            accountType: userInfo.accountType as string,
            player_id: null,
            categories: null,
            countries: null,
            existingMatch: null,
            sortCriteria: null,
          },
          token as string,
        );
        const data = response.data;

        const formattedData = data.map((item: any) => ({
          id: item.player_id,
          fullName: item.player_name,
          firstName: item.player_given_name,
          lastName: item.player_family_name,
          countryName: item.country,
          countryCode: item.country_code,
          category: item.category_code,
          division: item.subevent_code,
          playCategory: item.age_category_code,
          year: item.ranking_year,
          ranking: item.ranking_position,
          worldRank: item.current_rank,
          matchesPlayed: item.number_of_videos,
          gender: item.gender,
          age: item.age,
          birthDate: item.dob,
          hand: item.handedness,
          grip: item.grip,
          photoUrl400: item.head_shot,
          photoUrl1080right: item.headshot_r,
          photoUrl1080left: item.headshot_l,
        }));

        setPlayers(formattedData);

        const uniqueCountries = formattedData.reduce(
          (acc: any, player: PlayerData) => {
            if (
              !acc.some((country: any) => country.name === player.countryName)
            ) {
              acc.push({ name: player.countryName, code: player.countryCode });
            }
            return acc;
          },
          [],
        );
        uniqueCountries.sort((a: any, b: any) => {
          if (a.name === 'Hong Kong, China') return -1;
          if (b.name === 'Hong Kong, China') return 1;
          return a.name.localeCompare(b.name);
        });
        setCountries(uniqueCountries);
      } catch (error) {
        console.error('Failed to load player data:', error);
      }
    };

    fetchPlayersData();
  }, [token, userInfo.accountType, filterCriteria]);

  const handleCheckboxChange = (playerId: number, isChecked: boolean) => {
    let updatedSelectedPlayerIds = [...selectedPlayerIds];
    if (isChecked) {
      updatedSelectedPlayerIds.push(playerId);
    } else {
      updatedSelectedPlayerIds = updatedSelectedPlayerIds.filter(
        (id) => id !== playerId,
      );
    }
    setSelectedPlayerIds(updatedSelectedPlayerIds);
    onSelectedPlayersChange(updatedSelectedPlayerIds);
  };

  const handleRemoveSelectedPlayer = (playerId: number) => {
    const updatedSelectedPlayerIds = selectedPlayerIds.filter(
      (id) => id !== playerId,
    );
    setSelectedPlayerIds(updatedSelectedPlayerIds);
    onSelectedPlayersChange(updatedSelectedPlayerIds);
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: PlayerData[],
  ) => {
    const selectedIds = value.map((player) => player.id);
    setSelectedPlayerIds(selectedIds);
    onSelectedPlayersChange(selectedIds);
  };

  const handleDrawerSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDrawerSearchTerm(event.target.value);
  };

  const filteredPlayers = players.filter((player) => {
    const isCountryMatch =
      player.countryName === countryFilter || countryFilter === '';
    const isGenderMatch = genderFilter === '' || player.gender === genderFilter;
    const isModeMatch =
      modeFilter === 'all' ||
      (modeFilter === 'double' && player.fullName.includes('/')) ||
      (modeFilter === 'male' && player.gender === 'M') ||
      (modeFilter === 'female' && player.gender === 'F');
    const isSearchMatch = player?.fullName
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    return isCountryMatch && isGenderMatch && isModeMatch && isSearchMatch;
  });

  const drawerFilteredPlayers = players.filter((player) => {
    const isCountryMatch =
      player.countryName === countryFilter || countryFilter === '';
    const isGenderMatch = genderFilter === '' || player.gender === genderFilter;
    const isModeMatch =
      modeFilter === 'all' ||
      (modeFilter === 'double' && player.fullName.includes('/')) ||
      (modeFilter === 'male' && player.gender === 'M') ||
      (modeFilter === 'female' && player.gender === 'F');
    const isDrawerSearchMatch = player.fullName
      .toLowerCase()
      .includes(drawerSearchTerm.toLowerCase());
    return (
      isCountryMatch && isGenderMatch && isModeMatch && isDrawerSearchMatch
    );
  });

  const handleDepthChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        event.target.value === 'all' ? -1 : parseInt(event.target.value);
      setSelectedDepth(value);
    },
    [setSelectedDepth],
  );

  const handleCustomDepthChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCustomDepth(event.target.value);
    },
    [setCustomDepth],
  );

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 2,
          mb: 2,
        }}
      >
        <Autocomplete
          multiple
          freeSolo
          options={players}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.fullName
          }
          value={players.filter((player) =>
            selectedPlayerIds.includes(player.id),
          )}
          onChange={(
            event: React.ChangeEvent<unknown>,
            value: (string | PlayerData)[],
          ) =>
            handleSearchChange(
              event as React.ChangeEvent<HTMLInputElement>,
              value as PlayerData[],
            )
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={typeof option === 'string' ? option : option.fullName}
                {...getTagProps({ index })}
                key={typeof option === 'string' ? index : option.id}
                onDelete={() =>
                  handleRemoveSelectedPlayer(
                    typeof option === 'string' ? index : option.id,
                  )
                }
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="按名稱搜尋"
              variant="outlined"
              sx={{ flex: 1, minWidth: 200 }}
            />
          )}
          sx={{ flex: 1, minWidth: 200 }}
        />
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => setIsDrawerOpen(true)}
          sx={{ minWidth: 'fit-content' }}
        >
          打開篩選
        </Button>
      </Box>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box sx={{ width: 300, p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">篩選條件</Typography>
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="Search"
            variant="outlined"
            value={drawerSearchTerm}
            onChange={handleDrawerSearchChange}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="country-label">國家/地區</InputLabel>
            <Select
              labelId="country-label"
              value={countryFilter}
              label="Country"
              onChange={(e) => setCountryFilter(e.target.value as string)}
            >
              <MenuItem value="">
                <em>All Countries</em>
              </MenuItem>
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl component="fieldset" sx={{ mt: 3 }}>
            <FormLabel component="legend">模式</FormLabel>
            <RadioGroup
              row
              value={modeFilter}
              onChange={(e) => setModeFilter(e.target.value)}
            >
              {['all', 'male', 'female', 'double'].map((mode) => (
                <FormControlLabel
                  key={mode}
                  value={mode}
                  control={<Radio />}
                  label={mode.charAt(0).toUpperCase() + mode.slice(1)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <FormGroup
            sx={{
              mt: 2,
              maxHeight: 300,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {drawerFilteredPlayers.map((player) => (
              <FormControlLabel
                key={player.id}
                control={
                  <Checkbox
                    checked={selectedPlayerIds.includes(player.id)}
                    onChange={(e) =>
                      handleCheckboxChange(player.id, e.target.checked)
                    }
                  />
                }
                label={`${player.fullName} - ${player.countryName} - ${player.countryCode}`}
              />
            ))}
          </FormGroup>
          <Box sx={{ mt: 3 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Depth</FormLabel>
              <RadioGroup
                row
                value={selectedDepth}
                onChange={handleDepthChange}
              >
                <FormControlLabel value={1} control={<Radio />} label="1" />
                <FormControlLabel value={2} control={<Radio />} label="2" />
                <FormControlLabel value={-1} control={<Radio />} label="All" />
              </RadioGroup>
              <TextField
                label="Custom Depth"
                value={customDepth}
                onChange={handleCustomDepthChange}
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              />
              <Button
                onClick={handleCustomDepthSubmit}
                variant="contained"
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
              <Button onClick={handleReset} variant="outlined" sx={{ mt: 2 }}>
                Reset
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default FilterComponent;
