import { Fragment, useState } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { TablePagination, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import axios from 'axios';
import Cookies from 'js-cookie';

import Title from './Title';

import './UserManagement.scss';


export default function UserManagement({ userData,setRefreshData }) {

  const token = Cookies.get('token');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [user_account_type, setUserAccountType] = useState('');
  const [user_category, setUserCategory] = useState('');
  const [user_id ,setUserId] = useState('');
  const [username, setUsername] = useState('');


  const handleEditOpen = (username, category,account_type, user_id) => {
    setUsername(username);
    setUserCategory(category);
    setUserAccountType(account_type);
    setUserId(user_id);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };


  const handleDeleteOpen = (user_id) => {
    setUserId(user_id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteInputValue('');
    setErrorMessage('');
  };


  const handleDeleteInputChange = (event) => {
    setDeleteInputValue(event.target.value);
  };

  const handleDeleteUser = (user_id, username, accountType, accountCat) => {

    if (deleteInputValue === 'delete') { 
      axios.delete('/api/updateUserData', {
        params: {
          user_id: user_id,
          primary_attribute: `deleted username: ${username}`,
          secondary_attribute: `deleted account details: ${accountType}, ${accountCat}`
        },
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        handleDeleteClose();
        setRefreshData(true);
      })
    }
    else {
      setErrorMessage('Please enter "delete" to confirm the deletion.');
    };
  };



  const handleEditUser = (user_id, username, user_account_type,user_category) => {
    axios
    .post('/api/updateUserData', { 
    data: [user_id, username, user_account_type,user_category]}, 
    {
    params: {
      primary_attribute: `edited username: ${username}`,
      secondary_attribute: `edited account details: ${user_account_type}, ${user_category}`
    },
    headers: {
        Authorization: token
    }
    })
    .then(response => {
      handleEditClose();
      setRefreshData(true);
    })
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <Fragment>
        <div className='user-management-header'>
        <Title>Current Users</Title>
        <Link to="/admin-sign-up" variant="body2">
          添加用戶
        </Link>
        </div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Account Type</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Last Location</TableCell>
              <TableCell>Last log in</TableCell>
              <TableCell>Total log in</TableCell>
              <TableCell>Gross Video Watched (Count)</TableCell>
              <TableCell>Unique Video Watched</TableCell>
              <TableCell>Most Watched Video</TableCell>
              <TableCell>Video uploaded</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {slicedData.map((user) => (
              <TableRow key={user.user_id} className='user-table'>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  {user.account_type}
                </TableCell>
                <TableCell>
                  {user.category}
                </TableCell>
                <TableCell>{user.last_ip_address}</TableCell>
                <TableCell>{user.last_log_in ? new Date(user.last_log_in).toLocaleString('en-US', { timeZone: 'Asia/Singapore' }) : ''}</TableCell>
                <TableCell>{user.log_in_count}</TableCell>
                <TableCell>{user.total_video_views}</TableCell>
                <TableCell>{user.unique_video_viewed}</TableCell>
                <TableCell>{user.most_viewed_video}</TableCell>
                <TableCell>{user.video_uploaded}</TableCell>
                <TableCell> 
                  <button onClick={() => handleEditOpen(user.username, user.category, user.account_type, user.user_id)} title='編輯數據'>
                    <EditOutlinedIcon />
                  </button>
                </TableCell>
                <TableCell>
                  <button onClick={() => handleDeleteOpen(user.user_id)} title='刪除此球'>
                    <CloseOutlinedIcon />
                  </button>
                  <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                    <DialogTitle>請輸入「delete」以刪除视频</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="確認刪除"
                        type="text"
                        value={deleteInputValue}
                        onChange={handleDeleteInputChange}
                        error={errorMessage !== ''}
                        helperText={errorMessage}
                        fullWidth
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDeleteClose}>取消</Button>
                      <Button onClick={() => handleDeleteUser(user_id, user.username, user.account_type, user.category)} variant="contained" autoFocus>
                        刪除
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog open={editOpen} onClose={handleEditClose}>
                    <DialogTitle>在此編輯用戶</DialogTitle>
                    <DialogContent>
                      <TextField label="Username" variant="standard" defaultValue={username} onChange={(event) => setUsername(event.target.value)}/>
                      <InputLabel >Account type</InputLabel>
                      <Select
                      defaultValue={user_account_type}
                      onChange={(event) => setUserAccountType(event.target.value)} // Update user_account_type
                      >
                        <MenuItem value={'sub_admin'}>sub_admin</MenuItem>
                        <MenuItem value={'coach'}>coach</MenuItem>
                        <MenuItem value={'player'}>player</MenuItem>
                      </Select>
                      <InputLabel >Category</InputLabel>
                      <Select
                        defaultValue={user_category}
                        onChange={(event) => setUserCategory(event.target.value)} // Update user_category
                      >
                        <MenuItem value={'able'}>able</MenuItem>
                        <MenuItem value={'intellectual'}>intellectual</MenuItem>
                        <MenuItem value={'physical'}>physical</MenuItem>
                      </Select>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleEditClose}>取消</Button>
                      <Button onClick={() => handleEditUser(user_id, username, user_account_type,user_category)} variant="contained" autoFocus>
                        確認
                      </Button>
                    </DialogActions>
                  </Dialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={userData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
      </Paper>
      </Fragment>
    );
  }