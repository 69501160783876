import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { fetchDropPointsAPI, fetchIndMatchDataAPI } from 'src/api/videoService';
import DpInterface from './DpInterface';
import { MatchData } from 'types/videoTypes';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  dateIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  parentEventIndex,
  categoryIndex,
  viewCountIndex,
  uploadUserIndex,
  ballDataIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  homePlayerIdIndex,
  awayPlayerIdIndex,
  roundDataIndex,
  roundEndTimeIndex,
  FlagCommentIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  tableCoorIndex,
  scoreCoorIndex,
  startingPlayerIndex,
  analysisProgressIndex,
} from 'src/constants';

import './ManualInterface.scss';

interface UserInfo {
  identifier: string | null;
  isCoach: boolean | null;
  accountType: string | null;
  admin: boolean | null;
  subadmin: boolean | null;
}

interface ManualInterfaceProps {
  userInfo: UserInfo;
}

const ManualInterface: React.FC<ManualInterfaceProps> = ({ userInfo }) => {
  const { videoId } = useParams<{ videoId: string }>();
  const numericVideoId = videoId ? parseInt(videoId, 10) : 0;
  const token = Cookies.get('token') || '';

  const [dpResults, setDpResults] = useState<any[]>([]);
  const [matchData, setMatchData] = useState<MatchData>({
    matchId: '',
    videoName: '',
    videoDescription: '',
    stage: '',
    matchDate: '',
    location: '',
    homePlayer: '',
    awayPlayer: '',
    homePlayerId: '',
    awayPlayerId: '',
    fileUrl: '',
    thumbnailUrl: '',
    startEndTimeUrl: '',
    reportUrl: '',
    matchEvent: '',
    matchParentEvent: '',
    matchCategory: '',
    viewCount: '',
    uploadUser: '',
    flagComment: '',
    homePlayerScore: 0,
    awayPlayerScore: 0,
  });

  const [commentaryTimes, setCommentaryTimes] = useState({
    firstCommentaryStartTime: 0,
    firstCommentaryEndTime: 9999,
    lastCommentaryStartTime: 99999,
    lastCommentaryEndTime: 99999,
  });

  const [roundResults, setRoundResults] = useState<any[]>([]);
  const [apiError, setAPIError] = useState<string | null>(null);

  useEffect(() => {
    const transformResultsToArray = (matchDp: any[]): any[] => {
      const transformedArray: any[] = [];
      for (const ballDp of matchDp) {
        const [ball_num, drop_points, starting_player] = ballDp;
        const newData: (number | string | null)[][] = [
          ['x', '球的路徑 ', '落點', '最后落點', 'middleLine'],
        ];
        const middleLine = 1280 / 2;
        newData.push([0, null, null, null, middleLine]);
        newData.push([800, null, null, null, middleLine]);
        if (!drop_points) {
          newData.push([9999, 9999, 9999, 9999, null]);
          newData.push([9999, 9999, 9999, 9999, null]);
        } else {
          for (let i = 0; i < drop_points.length; i++) {
            const x = 800 * drop_points[i][0];
            const y = 1280 - 1280 * drop_points[i][1];

            const yScatterValue = i === drop_points.length - 1 ? null : y;
            const yLastValue = i === drop_points.length - 1 ? y : null;

            if (x < 0 && y < 0) {
              newData.push([9999, 9999, yScatterValue, yLastValue, null]);
            } else {
              newData.push([x, y, yScatterValue, yLastValue, null]);
            }
          }
        }
        if (newData.length === 4) {
          const secondArray = [
            newData[3][0],
            newData[3][1],
            newData[3][3],
            newData[3][2],
            null,
          ];
          newData.push(secondArray);
        }
        const resultArray = [ball_num, newData, starting_player];
        transformedArray.push(resultArray);
      }
      return transformedArray;
    };

    const fetchVideoData = async () => {
      try {
        const responseData = await fetchIndMatchDataAPI(numericVideoId, token);
        const [rawMatchData, rawRoundResults] = responseData;

        const transformedList = rawMatchData.map((videoData: any) => {
          const fileUrl = videoData[urlIndex];
          const urlWithoutSuffix = fileUrl.substring(
            0,
            fileUrl.lastIndexOf('.'),
          );
          const reportUrl = videoData[reportIndex];
          const url = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
          const publicReportUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/report/${reportUrl}`;
          const fetchedMatchDate = videoData[dateIndex];
          const dateWithoutTime = fetchedMatchDate.replace(
            /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
            '',
          );

          return {
            matchId: videoData[matchIdIndex],
            videoName: videoData[videoNameIndex],
            videoDescription: videoData[matchDescriptionIndex],
            stage: videoData[stageIndex],
            matchDate: dateWithoutTime,
            location: videoData[locationIndex],
            homePlayer: videoData[homePlayerIndex],
            awayPlayer: videoData[awayPlayerIndex],
            homePlayerId: videoData[homePlayerIdIndex],
            awayPlayerId: videoData[awayPlayerIdIndex],
            fileUrl: url,
            thumbnailUrl: videoData[thumbnailIndex],
            reportUrl: publicReportUrl,
            matchEvent: videoData[subEventIndex],
            matchParentEvent: videoData[parentEventIndex],
            matchCategory: videoData[categoryIndex],
            viewCount: videoData[viewCountIndex],
            uploadUser: videoData[uploadUserIndex],
            flagComment: videoData[FlagCommentIndex],
            homePlayerScore: videoData[homePlayerMatchScoreIndex],
            awayPlayerScore: videoData[awayPlayerMatchScoreIndex],
            tableCoor: videoData[tableCoorIndex],
            scoreCoor: videoData[scoreCoorIndex],
            startingPlayer: videoData[startingPlayerIndex],
            progress: videoData[analysisProgressIndex],
          };
        });

        if (
          rawRoundResults[0] &&
          rawRoundResults[0][roundDataIndex][roundEndTimeIndex] !== 9999
        ) {
          const firstBall = rawRoundResults[0][ballDataIndex][0];
          const lastBallArray =
            rawRoundResults[rawRoundResults.length - 1][ballDataIndex];
          const lastBall = lastBallArray[lastBallArray.length - 1];
          setCommentaryTimes({
            firstCommentaryStartTime:
              !firstBall || firstBall.length === 0
                ? 0
                : firstBall[ballStartTimeIndex],
            firstCommentaryEndTime:
              !firstBall || firstBall.length === 0
                ? 9999
                : firstBall[ballEndTimeIndex],
            lastCommentaryStartTime:
              !lastBall || lastBall.length === 0
                ? 99999
                : lastBall[ballStartTimeIndex],
            lastCommentaryEndTime:
              !lastBall || lastBall.length === 0
                ? 99999
                : lastBall[ballEndTimeIndex],
          });
        } else {
          setCommentaryTimes({
            firstCommentaryStartTime: 0,
            firstCommentaryEndTime: 9999,
            lastCommentaryStartTime: 99999,
            lastCommentaryEndTime: 99999,
          });
        }

        setMatchData(transformedList[0]);
        setRoundResults(rawRoundResults);
      } catch (error: any) {
        setAPIError(error.message);
      }
    };

    const fetchDpData = async () => {
      try {
        const responseData = await fetchDropPointsAPI(
          {
            videoId: numericVideoId,
            player_name: null,
            main_option: null,
            sub_option: null,
            multi_view: null,
            match_option: null,
            opponent_option: null,
          },
          token,
        );

        const transformedData = transformResultsToArray(responseData);

        setDpResults(transformedData);
      } catch (error: any) {
        setAPIError(error.message);
      }
    };

    fetchVideoData();
    fetchDpData();
  }, [numericVideoId, token]);

  return (
    <div className="manual-interface-main">
      {matchData.fileUrl && <DpInterface matchData={matchData} />}
      {apiError && <p>Error: {apiError}</p>}
    </div>
  );
};

export default ManualInterface;
