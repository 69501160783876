import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import './Review.scss';

export default function Review({ matchData }) {
  const translationDict = {
    videoName: '视频名称',
    videoDescription: '比赛详情',
    stage: '阶段',
    matchDate: '比赛日期',
    location: '比赛地点',
    HomePlayer: '主場球員',
    AwayPlayer: '客場球員',
    fileUrl: '视频檔案',
    thumbnailUrl: '缩略图',
    reportUrl: '報告檔案',
    dpFilename: '落点',
    matchEvent: '乒乓球賽事',
    matchParentEvent: '主辦機構',
    matchCategory: '比賽類型',
    isDouble: '雙打',
    customTag: '自訂標籤',
    dpFilename: '落点檔案',
    dpAnalyzedTime: '落点分析日期 ',
    LastOCR: 'OCR分析日期 ',
    OCRFilename: 'OCR 檔案',
    startingPlayer: '發球員',
  };

  return (
    <React.Fragment>
      <Typography className="review-title" variant="h6" gutterBottom>
        確認數據
      </Typography>
      <List className="review-list" disablePadding>
        {Object.entries(matchData)
          .filter(
            ([key]) =>
              key !== 'accountType' &&
              key !== 'matchId' &&
              key !== 'videoUrl' &&
              key !== 'scoreCoor' &&
              key !== 'tableCoor',
          )
          .map(([key, value]) => (
            <ListItem key={key} sx={{ py: 1, px: 0 }}>
              <ListItemText
                primary={
                  <Typography variant="body1" style={{ color: 'red' }}>
                    {translationDict[key]}
                  </Typography>
                }
              />
              <Typography variant="body2">
                {value ? value.toString() : ''}
              </Typography>
            </ListItem>
          ))}
      </List>
    </React.Fragment>
  );
}
