// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-upload-container {
  margin-top: var(--medium-font-size);
}
.main-upload-container .main-upload-panel {
  margin-top: var(--small-font-size);
}
.main-upload-container .main-upload-panel .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--apple-pink);
}
.main-upload-container .main-upload-panel .css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid black;
}
.main-upload-container .main-upload-panel button {
  color: #b81d24;
}
.main-upload-container .main-upload-panel span {
  color: var(--apple-blue);
}
.main-upload-container .file-info-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--large-font-size);
}
.main-upload-container .file-info-container h6 {
  color: var(--apple-lightgrey);
}`, "",{"version":3,"sources":["webpack://./src/videoPages/UploadVideo/VideoFileUploadForm.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF;AAAE;EACE,kCAAA;AAEJ;AADI;EACE,wBAAA;AAGN;AADI;EACE,8BAAA;AAGN;AADI;EACE,cAAA;AAGN;AADI;EACE,wBAAA;AAGN;AAAE;EACE,aAAA;EACA,sBAAA;EACA,kCAAA;AAEJ;AADI;EACE,6BAAA;AAGN","sourcesContent":[".main-upload-container {\n  margin-top: var(--medium-font-size);\n  .main-upload-panel {\n    margin-top: var(--small-font-size);\n    .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {\n      color: var(--apple-pink);\n    }\n    .css-l4u8b9-MuiInputBase-root-MuiInput-root:after {\n      border-bottom: 2px solid black;\n    }\n    button {\n      color: #b81d24;\n    }\n    span {\n      color: var(--apple-blue);\n    }\n  }\n  .file-info-container {\n    display: flex;\n    flex-direction: column;\n    margin-top: var(--large-font-size);\n    h6 {\n      color: var(--apple-lightgrey);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
