import { Fragment, useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { TablePagination } from '@mui/material';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import axios from 'axios';
import Cookies from 'js-cookie';

import Title from '../DashBoard/Title';
import OCRManagementPopUp from './utils/OCRManagementPopUp';
import HomeFilterOption from 'src/components/FilterPanel/HomeFilterOption';
import SearchBar from 'src/components/SearchBar/SearchBar';

import './OCRManagement.scss';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  dateIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  customTagIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  eventIndex,
  categoryIndex,
  isDoubleIndex,
  viewCountIndex,
  uploadUserIndex,
  parentEventIndex,
  dpFilenameIndex,
  OCRFilenameIndex,
  LastOCRIndex,
} from 'src/constants';

export default function OCRManagement({ userInfo }) {
  const token = Cookies.get('token');

  const [filterStats, setFilterStats] = useState({
    stageCounts: {},
    subEventCounts: {},
    customTagCounts: {},
    categoryCounts: {},
  });

  const [videoList, setVideoList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    matchName: '',
    matchId: [],
    matchDate: '',
    stages: [],
    player: '',
    location: '',
    events: [],
    categories: [],
    startDate: '',
    endDate: '',
    customTags: [],
    ocrOnly: '0',
  });
  const [sortCriteria, setSortCriteria] = useState(null);
  const [CustomTagList, setCustomTagList] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadScore = async (match_id, filename) => {
    try {
      const response = await axios.get('/api/downloadScore', {
        responseType: 'blob',
        params: {
          match_id: match_id,
        },
        headers: {
          Authorization: token,
        },
      });

      // Create a temporary URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename + '_score' + '.csv'); // Set the desired filename for the downloaded file
      document.body.appendChild(link);

      // Trigger the click event on the link to initiate the file download
      link.click();

      // Clean up the temporary link and URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadSummary = async () => {
    try {
      const response = await axios.get('/api/downloadSummary', {
        responseType: 'blob',
        headers: {
          Authorization: token,
        },
      });

      // Create a temporary URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'match_data.csv'); // Set the desired filename for the downloaded file
      document.body.appendChild(link);

      // Trigger the click event on the link to initiate the file download
      link.click();

      // Clean up the temporary link and URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  // get data
  useEffect(() => {
    const {
      matchName,
      matchId,
      matchDate,
      stages,
      player,
      location,
      events,
      categories,
      startDate,
      endDate,
      customTags,
      ocrOnly,
    } = filterCriteria;

    const fetchMatchData = async () => {
      try {
        // Change this if backend changed
        const response = await axios.get(`/api/videodata`, {
          params: {
            match_name: matchName,
            match_id: matchId.join(','),
            match_date: matchDate,
            location: location,
            stage: stages.join(','),
            player: player,
            accountType: 'all',
            events: events.join(','),
            categories: categories.join(','),
            startDate: startDate,
            endDate: endDate,
            sortCriteria: sortCriteria,
            customTags: customTags.join(','),
            admin: true,
            ocrOnly: ocrOnly,
            getOCRFiles: true,
          },
          headers: {
            Authorization: `${token}`,
          },
        });

        const responseData = response.data.videoData;
        const OCRFiles = response.data.OCRfiles;
        setFilterStats({
          stageCounts: response.data.stageCounts,
          subEventCounts: response.data.subEventCounts,
          customTagCounts: response.data.customTagCounts,
          categoryCounts: response.data.categoryCounts,
        });

        // transform data and url
        let transformedList;
        if (!responseData) {
          transformedList = [];
        } else {
          transformedList = responseData.map((videoData, index) => {
            const fetchedMatchDate = videoData[dateIndex];
            const dateWithoutTime = fetchedMatchDate.replace(
              /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
              '',
            );

            // Create a new array with the transformed data
            return {
              count: index + 1,
              matchId: videoData[matchIdIndex],
              videoName: videoData[videoNameIndex],
              matchDescription: videoData[matchDescriptionIndex],
              stage: videoData[stageIndex],
              date: dateWithoutTime,
              location: videoData[locationIndex],
              homePlayer: videoData[homePlayerIndex],
              awayPlayer: videoData[awayPlayerIndex],
              fileUrl: videoData[urlIndex],
              TNUrl: videoData[thumbnailIndex],
              report: videoData[reportIndex],
              parentEvent: videoData[parentEventIndex],
              event: videoData[eventIndex],
              subEvent: videoData[subEventIndex],
              category: videoData[categoryIndex],
              isDouble: videoData[isDoubleIndex],
              viewCount: videoData[viewCountIndex],
              uploadUser: videoData[uploadUserIndex],
              customTag: videoData[customTagIndex],
              homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
              awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],
              dp: videoData[dpFilenameIndex],
              ocr: videoData[OCRFilenameIndex],
              ocr_date: videoData[LastOCRIndex],
              ocr_files: OCRFiles[index],
            };
          });
        }

        setVideoList(transformedList);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMatchData();
    if (refresh === true) {
      setRefresh(false);
    }
  }, [filterCriteria, sortCriteria, refresh]);

  useEffect(() => {
    const fetchCustomTagList = async () => {
      try {
        const response = await axios.get('/api/customTagList', {
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;
        setCustomTagList(responseData);
      } catch (error) {
        //console.log(error);
      }
    };

    fetchCustomTagList();
  }, []);

  const slicedData = videoList.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <Fragment>
      <div className="ocr-management-header">
        <Title>Videos</Title>
        <div className="ocr-option-panel">
          <div className="ocr-search-panel">
            <SearchBar player={false} setFilterCriteria={setFilterCriteria} />
            <HomeFilterOption
              setFilterCriteria={setFilterCriteria}
              appliedFilterCriteria={filterCriteria}
              CustomTagList={CustomTagList}
              userInfo={userInfo}
              filterStats={filterStats}
            />
          </div>
          <div className="download-summary-button">
            <button onClick={() => handleDownloadSummary()}>
              下載 summary
            </button>
          </div>
        </div>
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Count</TableCell>
                <TableCell>Video name</TableCell>
                <TableCell>file name</TableCell>
                <TableCell>current ocr</TableCell>
                <TableCell sx={{ textAlign: 'center', width: '1%' }}>
                  ocr files
                </TableCell>
                <TableCell sx={{ textAlign: 'center', width: '1%' }}>
                  Save current Data as csv
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedData.map((video) => (
                <TableRow>
                  <TableCell sx={{ textAlign: 'flex-start' }}>
                    {video.count}
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: 'flex-start' }}
                    title={video.videoName}
                    className="truncate-ocr-cell"
                  >
                    {video.videoName}
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: 'flex-start' }}
                    title={video.fileUrl}
                    className="truncate-ocr-cell"
                  >
                    {video.fileUrl}
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: 'flex-start' }}
                    title={video.ocr}
                    className="truncate-ocr-cell"
                  >
                    {video.ocr}
                  </TableCell>
                  <TableCell>
                    <OCRManagementPopUp
                      videoId={video.matchId}
                      ocrList={video.ocr_files}
                      fileName={video.fileUrl}
                      currentOCR={video.ocr}
                      setRefresh={setRefresh}
                    />
                  </TableCell>
                  <TableCell className="button-ocr-cell">
                    <button>
                      <DownloadOutlinedIcon
                        onClick={() =>
                          handleDownloadScore(video.matchId, video.videoName)
                        }
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={videoList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </Fragment>
  );
}
