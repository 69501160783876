import React, { useState } from 'react';
import {
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import Cookies from 'js-cookie';
import axios from 'axios';

import './EditPlayerDialog.scss';

const EditPlayerDialog = ({ playerData, accountType }) => {
  const token = Cookies.get('token');

  const [open, setOpen] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [headshot, setHeadshot] = useState(null);
  const [headshot2, setHeadshot2] = useState(null);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const displayKeys = [
    'player_id',
    'player_name',
    'country',
    'country_code',
    'handedness',
    'grip',
    'head_shot_file',
    editedData['gender'] !== 'M' && editedData['gender'] !== 'F'
      ? ['player2_head_shot_file']
      : [],
    'bio',
    'win_rate',
  ];

  console.log(editedData);

  const handleClickOpen = () => {
    setOpen(true);
    setEditedData(playerData);
  };

  const handleClose = () => {
    window.location.reload();
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteInputValue('');
    setErrorMessage('');
  };

  const handleDeleteInputChange = (event) => {
    setDeleteInputValue(event.target.value);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    const { name, value } = event.target;
    if (name === 'head_shot_file') {
      setHeadshot(file);
    } else {
      setHeadshot2(file);
    }
    setEditedData((prevData) => ({
      ...prevData,
      [name]: file.name,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Handle saving the edited data
    const editPlayer = async () => {
      const formData = new FormData();
      try {
        if (headshot) {
          formData.append('headshotFile', headshot);
        }
        if (headshot2) {
          formData.append('headshotFile2', headshot2);
        }
        formData.append('player_data', JSON.stringify(editedData));
        formData.append('accountType', accountType);
        const response = await axios.put(`/api/updatePlayer`, formData, {
          params: {
            primary_attribute: ` player edited : ${playerData['player_name']}, id: ${playerData['player_id']} `,
          },
          headers: {
            Authorization: `${token}`,
          },
        });
        window.location.reload();
      } catch (error) {
        console.log(error.message);
      }
    };

    editPlayer();
  };

  const handleDelete = () => {
    // Handle delete
    const deletePlayer = async () => {
      try {
        const response = await axios.delete(`/api/deletePlayer`, {
          params: {
            player_id: playerData['player_id'],
            accountType: accountType,
            primary_attribute: ` player deleted : ${playerData['player_name']} , id: ${playerData['player_id']}`,
          },
          headers: {
            Authorization: `${token}`,
          },
        });
        console.log('deleted');
      } catch (error) {
        console.log(error.message);
      }
    };

    if (deleteInputValue === 'delete') {
      deletePlayer();
      window.location.reload();
    } else {
      setErrorMessage('Please enter "delete" to confirm the deletion.');
    }
  };

  return (
    <React.Fragment>
      <div className="edit-player-info">
        <Button
          className="delete-player-button"
          endIcon={<DeleteForeverIcon />}
          onClick={handleDeleteOpen}
        >
          刪除
        </Button>
        <Dialog open={deleteOpen} onClose={handleDeleteClose}>
          <DialogTitle>請輸入「delete」以刪除球員資訊</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="確認刪除"
              type="text"
              value={deleteInputValue}
              onChange={handleDeleteInputChange}
              error={errorMessage !== ''}
              helperText={errorMessage}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>取消</Button>
            <Button onClick={handleDelete} variant="contained" autoFocus>
              刪除
            </Button>
          </DialogActions>
        </Dialog>

        <Button
          className="edit-player-button"
          endIcon={<EditIcon />}
          onClick={handleClickOpen}
        >
          編輯
        </Button>
      </div>
      <Dialog
        className="edit-player-main-container"
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              資料
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              保存
            </Button>
          </Toolbar>
        </AppBar>
        <div className="edit-player-main-panel">
          <span>頭像(jpg,png)</span>
          <TextField
            required
            id="head_shot"
            name="head_shot_file"
            fullWidth
            variant="standard"
            type="file"
            accept=".jpg, .png"
            onChange={handlePhotoUpload}
          />
          {editedData['gender'] !== 'M' && editedData['gender'] !== 'F' ? (
            <>
              <span>選手2頭像 (jpg, png)</span>
              <TextField
                required
                id="player2_head_shot"
                name="player2_head_shot_file"
                fullWidth
                variant="standard"
                type="file"
                accept=".jpg, .png"
                onChange={handlePhotoUpload}
              />
            </>
          ) : null}
          {displayKeys.map((key) => (
            <TextField
              key={key}
              label={key}
              name={key}
              value={editedData[key] || ''}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          ))}
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">赛类</InputLabel>
            <Select
              name="gender"
              value={editedData['gender']}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={'M'}>男單打</MenuItem>
              <MenuItem value={'MM'}>男雙打</MenuItem>
              <MenuItem value={'F'}>女單打</MenuItem>
              <MenuItem value={'FF'}>女雙打</MenuItem>
              <MenuItem value={'MF'}>混合雙打</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="dob"
            name="dob"
            label="出生日期"
            fullWidth
            variant="standard"
            type="date"
            onChange={handleInputChange}
            value={editedData['dob']}
          />
          {accountType === 'm' && (
            <>
              <TextField
                id="rubber"
                name="rubber_type"
                label="正手膠"
                value={editedData['rubber_type'] || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                id="rubber"
                name="flipped_rubber_type"
                label="反手膠"
                value={editedData['flipped_rubber_type'] || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default EditPlayerDialog;
