import { useState } from 'react';
import CommentDisplay from './utils/CommentDisplay';
import CommentEdit from './utils/CommentEdit';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { TextField } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import axios from 'axios';

import {
  ballIdIndex,
  roundEndTimeIndex,
  roundIdIndex,
  roundNumberIndex,
  roundStartTimeIndex,
} from 'src/constants';

import BallEditDisplay from './utils/BallEditDisplay';
import BallDisplay from './utils/BallDisplay';
import { formatTime } from 'src/utils/utils';

import './VideoRoundCommentary.scss';

const VideoRoundCommentary = ({
  commentData,
  setCommentData,
  userInfo,
  token,
  ballData,
  setBallData,
  videoRef,
  videoData,
  setVideoData,
  matchId,
  setFixedRound,
  fixedRound,
  roundData,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingBall, setIsEditingBall] = useState(false);
  const [editingBallIndex, setEditingBallIndex] = useState(-1);
  const [commentIndex, setCommentIndex] = useState(-1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogInputValue, setDialogInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [expanded, setExpanded] = useState(false);

  const [isEditDialog, setIsEditDialog] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleFixedRoundChange = (event) => {
    setFixedRound(event.target.value);
  };

  const handleDialogOpen = (type) => {
    setDialogOpen(true);
    if (type === 'edit') {
      setIsEditDialog(true);
    } else {
      setIsEditDialog(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogInputValue('');
    setErrorMessage('');
  };

  const handleDialogInputChange = (event) => {
    setDialogInputValue(event.target.value);
  };

  const handleInsertRound = async () => {
    const insertRound = async () => {
      try {
        await axios.put(
          `/api/editrounddata`,
          {
            round_number: dialogInputValue,
            match_id: matchId,
          },
          {
            params: {
              primary_attribute: `video edited (id) : ${matchId}`,
              secondary_attribute: `add round : ${dialogInputValue}`,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        setVideoData((prevState) => ({
          ...prevState,
          refresh: true,
        }));
      } catch (error) {
        // Handle error case when inserting the round
      }
    };

    const checkRound = async () => {
      try {
        const response = await axios.get(`/api/editrounddata`, {
          params: {
            round_number: dialogInputValue,
            match_id: matchId,
            primary_attribute: `get video round ${dialogInputValue} data (id) : ${matchId}`,
          },
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.data.message === 'Round exists') {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    };

    try {
      const roundExist = await checkRound();

      if (!roundExist) {
        await insertRound();
        handleDialogClose();
      } else {
        setErrorMessage(
          'Please enter an integer or check if the round already exists',
        );
      }
    } catch (error) {}
  };

  const handleDeleteRound = () => {
    // Handle delete
    const deleteRound = async () => {
      await axios.delete(`/api/editrounddata`, {
        params: {
          round_number: roundData[0][roundNumberIndex],
          match_id: matchId,
          primary_attribute: `video edited (id) : ${matchId}`,
          secondary_attribute: `delete round : ${roundData[0][roundNumberIndex]}`,
        },
        headers: {
          Authorization: `${token}`,
        },
      });
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
      }));
    };

    if (dialogInputValue === 'delete') {
      deleteRound();
      handleDialogClose();
    } else {
      setErrorMessage('Please enter "delete" to confirm the deletion.');
    }
  };

  const handleInsertBall = () => {
    axios
      .put(
        `/api/editballdata`,
        {
          new_row: [
            -1,
            roundData[0][roundIdIndex],
            roundData[0][roundStartTimeIndex],
            roundData[0][roundEndTimeIndex],
            0,
            0,
          ],
        },
        {
          params: {
            match_id: matchId,
            primary_attribute: `video edited (id) : ${matchId}`,
            secondary_attribute: `new ball insert to round : ${roundData[0][roundNumberIndex]} ${roundData[0][roundStartTimeIndex]}s to ${roundData[0][roundEndTimeIndex]}s`,
          },
          headers: { Authorization: token },
        },
      )
      .then((response) => {
        setVideoData((prevState) => ({
          ...prevState,
          refresh: true,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Create an array of numbers from 1 to videoData.numberOfRound
  const roundNumbers = Array.from(
    { length: videoData.numberOfRound },
    (_, index) => index + 1,
  );

  const commentOwner = userInfo.identifier;
  const permission = userInfo.isCoach || userInfo.admin;

  return (
    <div className="ball-commentary-main-container">
      <div className="round-menu-panel">
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          选择回合
        </span>
        <div className="round-menu-option">
          {permission === true && (
            <div className="round-button-panel">
              <button onClick={() => handleDialogOpen('edit')} title="添加回合">
                <AddOutlinedIcon />
              </button>
              <button
                onClick={() => handleDialogOpen('delete')}
                title="刪除此回合"
              >
                <CloseOutlinedIcon />
              </button>
              <Dialog open={dialogOpen} onClose={handleDialogClose}>
                {isEditDialog === true ? (
                  <DialogTitle>請輸入round number</DialogTitle>
                ) : (
                  roundData[0] && (
                    <DialogTitle>
                      請輸入「delete」以刪除回合{roundData[0][roundNumberIndex]}
                    </DialogTitle>
                  )
                )}
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="確認"
                    type="text"
                    value={dialogInputValue}
                    onChange={handleDialogInputChange}
                    error={errorMessage !== ''}
                    helperText={errorMessage}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose}>取消</Button>
                  {isEditDialog === true ? (
                    <Button
                      onClick={() => handleInsertRound()}
                      variant="contained"
                      autoFocus
                    >
                      add
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleDeleteRound()}
                      variant="contained"
                      autoFocus
                    >
                      刪除
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </div>
          )}
          <Select
            id="fixedR"
            name="fixedR"
            onChange={handleFixedRoundChange}
            input={<OutlinedInput />}
            sx={{ width: '50%', backgroundColor: 'grey' }}
          >
            <MenuItem value="" disabled>
              <em>选择回合</em>
            </MenuItem>
            <MenuItem value={-1}>
              <em>自動</em>
            </MenuItem>
            {roundNumbers.map((round) => (
              <MenuItem key={round} value={round - 1}>
                回合:{round}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {ballData[0] ? (
        <div className="ball-commentary-main-panel">
          <div className="round-details-panel">
            <span>回合:{roundData[0][roundNumberIndex]} </span>
            <span>
              開始 : {formatTime(roundData[0][roundStartTimeIndex])} 結束:{' '}
              {formatTime(roundData[0][roundEndTimeIndex])}
            </span>
          </div>
          {ballData.map((row, index) => (
            <div className="ball-commentary-ind-container" key={index}>
              {isEditingBall === `ballPanel${index}` ? (
                <BallEditDisplay
                  index={index}
                  videoData={videoData}
                  setVideoData={setVideoData}
                  ballData={ballData}
                  setBallData={setBallData}
                  setIsEditingBall={setIsEditingBall}
                  videoRef={videoRef}
                  matchId={matchId}
                  fixedRound={fixedRound}
                />
              ) : (
                <BallDisplay
                  index={index}
                  videoData={videoData}
                  setVideoData={setVideoData}
                  ballData={ballData}
                  setBallData={setBallData}
                  commentData={commentData}
                  setIsEditingBall={setIsEditingBall}
                  permission={permission}
                  matchId={matchId}
                  fixedRound={fixedRound}
                />
              )}
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{ width: '100%' }}
              >
                <AccordionSummary
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                  sx={{ width: '100%' }}
                  title="顯示評論"
                >
                  {expanded === `panel${index}` ? (
                    <ExpandLessOutlinedIcon />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {isEditing && editingBallIndex === index ? (
                    <CommentEdit
                      commentData={commentData}
                      currentBallIndex={index}
                      setIsEditing={setIsEditing}
                      commentIndex={commentIndex}
                      setCommentData={setCommentData}
                      commentOwner={commentOwner}
                      ballId={row[ballIdIndex]}
                      token={token}
                      setVideoData={setVideoData}
                    />
                  ) : (
                    <CommentDisplay
                      setIsEditing={setIsEditing}
                      commentData={commentData}
                      setCommentData={setCommentData}
                      setCommentIndex={setCommentIndex}
                      setEditingBallIndex={setEditingBallIndex}
                      currentBallIndex={index}
                      userInfo={userInfo}
                      token={token}
                      setVideoData={setVideoData}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      ) : (
        roundData[0] && (
          <div className="add-first-ball-panel">
            <span>回合:{roundData[0][roundNumberIndex]} </span>
            <button onClick={() => handleInsertBall()} title="添加球">
              添加第一球
              <AddOutlinedIcon />
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default VideoRoundCommentary;
