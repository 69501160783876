import './MatchScoreBoard.scss';

import { roundDataIndex, roundStartTimeIndex } from 'src/constants';

const MatchScoreBoard = ({
  score2,
  score1,
  videoRef,
  HomePlayer,
  AwayPlayer,
  roundResults,
  videoData,
  setVideoData,
}) => {
  const renderTableRows = () => {
    const lastScore1 = score1.map((subarray) => subarray[subarray.length - 1]);
    const lastScore2 = score2.map((subarray) => subarray[subarray.length - 1]);

    let HomePlayerWins = 0;
    let AwayPlayerWins = 0;

    for (let i = 0; i < lastScore1.length; i++) {
      if (lastScore1[i] > lastScore2[i]) {
        HomePlayerWins++;
      } else if (lastScore1[i] < lastScore2[i]) {
        AwayPlayerWins++;
      }
    }

    const handleClick = (index) => {
      videoRef.current.currentTime =
        roundResults[index][roundDataIndex][roundStartTimeIndex] + 0.01;
      setVideoData((prevStatus) => ({
        ...prevStatus,
        skipped: true,
        currentRound: index,
        currentBall: 0,
      }));
    };

    return (
      <tbody>
        <tr>
          <th></th>
          <td>
            {HomePlayer.length <= 20
              ? HomePlayer
              : HomePlayer.slice(0, 20) + '...'}
          </td>
          <td>
            {AwayPlayer.length <= 20
              ? AwayPlayer
              : AwayPlayer.slice(0, 20) + '...'}
          </td>
        </tr>
        {lastScore1.map((score, index) => (
          <tr key={index}>
            <th>{index + 1}</th>
            <td
              className="HomePlayer-score"
              style={{
                color:
                  score === Math.max(score, lastScore2[index])
                    ? 'red'
                    : 'inherit',
                backgroundColor:
                  index === videoData.currentRound
                    ? 'rgb(131 174 255 / 45%)'
                    : 'inherit',
              }}
              onClick={() => handleClick(index)}
              title="跳到本回合"
            >
              {score}
            </td>
            <td
              className="AwayPlayer-score"
              style={{
                color:
                  lastScore2[index] === Math.max(score, lastScore2[index])
                    ? 'red'
                    : 'inherit',
                backgroundColor:
                  index === videoData.currentRound
                    ? 'rgb(131 174 255 / 45%)'
                    : 'inherit',
              }}
              onClick={() => handleClick(index)}
              title="跳到本回合"
            >
              {lastScore2[index]}
            </td>
          </tr>
        ))}
        <tr>
          <th>結果</th>
          <td
            style={{
              color: HomePlayerWins > AwayPlayerWins ? 'red' : 'inherit',
            }}
          >
            {HomePlayerWins}
          </td>
          <td
            style={{
              color: AwayPlayerWins > HomePlayerWins ? 'red' : 'inherit',
            }}
          >
            {AwayPlayerWins}
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <table className="match-score-table">
      <tbody>{renderTableRows()}</tbody>
    </table>
  );
};
export default MatchScoreBoard;
