import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

import './HighlightContent.scss';

import {
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  highlightDataIndex,
  highlightRatingIndex,
  highlightCommentIndex,
} from 'src/constants';

const labels = {
  0: '極差',
  0.5: '非常差',
  1: '差',
  1.5: '稍差',
  2: '尚可',
  2.5: '良好',
  3: '相當好',
  3.5: '優秀',
  4: '非常優秀',
  4.5: '卓越',
  5: '極卓越',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

function HighlightListComponent({
  tempResults,
  handleCommentChange,
  handleRatingChange,
  handleDeleteClick,
  handleGoRound,
  selectedRoundIndex,
}) {
  const [hover, setHover] = useState(-1);

  return (
    <>
      <div className="warning-container">
        <WarningIcon className="warning-icon" />
        <p>所有POV數據将从编辑的精彩集锦中删除。</p>
      </div>
      {tempResults
        ? tempResults.map((roundResult, roundIndex) => (
            <div className="edit-h-block" key={roundIndex}>
              <p
                className={`edit-h-round ${
                  selectedRoundIndex === roundIndex ? 'selected' : ''
                }`}
                onClick={() => handleGoRound(roundIndex)}
              >
                第{roundIndex + 1}回合{' '}
              </p>
              {roundResult[highlightDataIndex].map(
                (highlightResult, highlightIndex) => (
                  <div key={highlightIndex} className="edit-highlight">
                    <Box
                      sx={{
                        width: 200,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Rating
                        name="hover-feedback"
                        value={highlightResult[0][highlightRatingIndex]}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(e) =>
                          handleRatingChange(
                            roundIndex,
                            highlightIndex,
                            e.target.value,
                          )
                        }
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <Box sx={{ ml: 2 }}>
                        {labels[highlightResult[0][highlightRatingIndex]]}
                      </Box>
                    </Box>
                    <p className="highlight-time">
                      开始: {highlightResult[0][highlightStartTimeIndex]} 结束:{' '}
                      {highlightResult[0][highlightEndTimeIndex]}
                    </p>
                    <TextField
                      id="outlined-multiline-flexible"
                      label="亮點評述"
                      multiline
                      placeholder="亮點評述"
                      color="secondary"
                      focused
                      value={highlightResult[0][highlightCommentIndex]}
                      onChange={(e) =>
                        handleCommentChange(
                          roundIndex,
                          highlightIndex,
                          e.target.value,
                        )
                      }
                    />
                    <IconButton className="h-delete-button">
                      <DeleteIcon
                        onClick={() =>
                          handleDeleteClick(roundIndex, highlightIndex)
                        }
                      />
                    </IconButton>
                  </div>
                ),
              )}
            </div>
          ))
        : null}
    </>
  );
}

export default HighlightListComponent;
