import HeatMapCustomDialog from './HeatMapCustomDialog';
import Heatmap from '../HeatMap/HeatMap';

import { useState } from 'react';

import './HeatMapMultiPanel.scss';

const HeatMapMultiPanel = ({ playerList }) => {
  const [hmOptions, setHmOptions] = useState([
    {
      playerName: '',
      videoNames: [],
      opponentNames: [],
    },
    {
      playerName: '',
      videoNames: [],
      opponentNames: [],
    },
    {
      playerName: '',
      videoNames: [],
      opponentNames: [],
    },
  ]);

  return (
    <div className="multi-hm-container">
      <HeatMapCustomDialog
        playerList={playerList}
        hmOptions={hmOptions}
        setHmOptions={setHmOptions}
      />
      {hmOptions.every((option) => {
        return Object.values(option).every((value) => {
          if (Array.isArray(value)) {
            return value.length === 0;
          } else {
            return value === '';
          }
        });
      }) ? (
        <div className="no-heatmap-container">
          <p>請在設定中選擇球員進行落點分析。</p>
        </div>
      ) : (
        <div className="multi-hm-panel">
          {hmOptions.map(
            (hmOption, index) =>
              hmOption.playerName && (
                <div className="ind-hm-panel">
                  <Heatmap
                    landscape={true}
                    hmPlayerName={[hmOption.playerName]}
                    hnOpponentName={['opponent']}
                    match_option={hmOption.videoNames}
                    opponent_option={hmOption.opponentNames}
                    heatmapClass={`main-hm-plot-container_${index}`}
                    removeHmOption={() => {
                      const newOptions = [...hmOptions];
                      newOptions[index] = {
                        playerName: '',
                        videoNames: [],
                        opponentNames: [],
                      };
                      setHmOptions(newOptions);
                    }}
                  />
                </div>
              ),
          )}
        </div>
      )}
      <p className="padding"></p>
    </div>
  );
};

export default HeatMapMultiPanel;
