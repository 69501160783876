// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-panel {
  background-color: #141414 !important;
}
.login-panel h1 {
  color: var(--bright-red);
  font-weight: 300;
}
.login-panel label {
  color: var(--white);
}
.login-panel p {
  color: var(--white);
}
.login-panel button {
  background-color: var(--bright-red) !important;
  font-weight: 300;
}
.login-panel fieldset {
  border-color: var(--lightgrey);
}
.login-panel .MuiTextField-root > label {
  top: -8px;
}
.login-panel .MuiAvatar-circular {
  background-color: transparent;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
  background-color: var(--background-color) !important;
}

.login-custom-snackbar .MuiSnackbarContent-message {
  font-size: var(--large-font-size);
  color: var(--bright-red);
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.scss"],"names":[],"mappings":"AAEA;EACI,oCAAA;AADJ;AAEI;EACI,wBAAA;EACA,gBAAA;AAAR;AAEI;EACI,mBAAA;AAAR;AAEI;EACI,mBAAA;AAAR;AAEI;EACI,8CAAA;EACA,gBAAA;AAAR;AAEI;EACI,8BAAA;AAAR;AAEI;EACI,SAAA;AAAR;AAEI;EACI,6BAAA;AAAR;;AAIA;EACA,oDAAA;AADA;;AAKI;EACA,iCAAA;EACA,wBAAA;AAFJ","sourcesContent":["\n\n.login-panel{\n    background-color: #141414 !important;\n    h1{\n        color:var(--bright-red);\n        font-weight: 300;\n    }\n    label{\n        color:var(--white);\n    }\n    p{\n        color:var(--white);\n    }\n    button{\n        background-color:var(--bright-red) !important;\n        font-weight: 300;\n    }\n    fieldset{\n        border-color: var(--lightgrey);\n    }\n    .MuiTextField-root > label {\n        top:-8px;\n    }\n    .MuiAvatar-circular{\n        background-color: transparent;\n    }\n}\n\n.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {\nbackground-color: var(--background-color) !important;\n}\n\n.login-custom-snackbar{\n    .MuiSnackbarContent-message{\n    font-size: var(--large-font-size);\n    color:var(--bright-red);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
