// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.user-management-header h2 {
  font-size: var(--medium-font-size);
}
.user-management-header a {
  text-decoration: none;
  padding: var(--xsmall-font-size);
  border-radius: 14px;
  font-size: var(--medium-font-size);
  color: var(--dark-red);
  background-color: var(--apple-pink);
}

.MuiBackdrop-root {
  background-color: transparent !important;
}

.user-table .MuiTableCell-root button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: var(--medium-font-size);
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/Users/UserManagement.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,qBAAA;AAAJ;AACI;EACI,kCAAA;AACR;AACI;EACI,qBAAA;EACA,gCAAA;EACA,mBAAA;EACA,kCAAA;EACA,sBAAA;EACA,mCAAA;AACR;;AAGA;EACI,wCAAA;AAAJ;;AAMQ;EACI,eAAA;EACA,6BAAA;EACA,YAAA;EACA,kCAAA;AAHZ","sourcesContent":["\n.user-management-header{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-end;\n    h2{\n        font-size: var(--medium-font-size);\n    }\n    a{\n        text-decoration: none;\n        padding: var(--xsmall-font-size);\n        border-radius: 14px;\n        font-size: var(--medium-font-size);\n        color: var(--dark-red);\n        background-color: var(--apple-pink);\n    }\n}\n\n.MuiBackdrop-root{\n    background-color: transparent !important;\n  }\n\n\n.user-table{\n    .MuiTableCell-root{\n        button{\n            cursor: pointer;\n            background-color: transparent;\n            border: none;\n            font-size: var(--medium-font-size);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
