// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminpage-main {
  width: 100vw;
  display: flex;
}
.adminpage-main .MuiPaper-root {
  top: auto;
}
.adminpage-main .css-aq1gdx-MuiDrawer-docked .MuiDrawer-paper {
  width: 0px;
}
.adminpage-main .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/AdminPage.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;AAAE;EACE,SAAA;AAEJ;AAAE;EACE,UAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ","sourcesContent":[".adminpage-main {\n  width: 100vw;\n  display: flex;\n  .MuiPaper-root {\n    top: auto;\n  }\n  .css-aq1gdx-MuiDrawer-docked .MuiDrawer-paper {\n    width: 0px;\n  }\n  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
