// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-main-container h6 {
  text-align: center;
  border-bottom: 1px solid black;
}
.file-upload-main-container .data-input-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--medium-font-size);
  margin-bottom: var(--medium-font-size);
}
.file-upload-main-container .data-input-options button {
  font-size: var(--medium-button-size);
}
.file-upload-main-container .data-input-options .data-upload-panel {
  display: flex;
  flex-direction: column;
  border-left: 1px dashed black;
  padding-left: var(--large-font-size);
  margin-left: var(--large-font-size);
}
.file-upload-main-container .file-upload-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--medium-font-size);
}`, "",{"version":3,"sources":["webpack://./src/videoPages/EditVideo/FileUploadForm.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,8BAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mCAAA;EACA,sCAAA;AAAJ;AACI;EACE,oCAAA;AACN;AACI;EACE,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,oCAAA;EACA,mCAAA;AACN;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mCAAA;AAAJ","sourcesContent":[".file-upload-main-container {\n  h6 {\n    text-align: center;\n    border-bottom: 1px solid black;\n  }\n  .data-input-options {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: var(--medium-font-size);\n    margin-bottom: var(--medium-font-size);\n    button {\n      font-size: var(--medium-button-size);\n    }\n    .data-upload-panel {\n      display: flex;\n      flex-direction: column;\n      border-left: 1px dashed black;\n      padding-left: var(--large-font-size);\n      margin-left: var(--large-font-size);\n    }\n  }\n  .file-upload-container {\n    display: flex;\n    flex-direction: column;\n    margin-top: var(--medium-font-size);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
