import React, { useState, useEffect} from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import './POVEditorPlayer.scss';

import {
  transformationXIndex,
  transformationYIndex,
  transformationScaleIndex,
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  highlightDataIndex,
  transformationStartTimeIndex,
  transformationEndTimeIndex
} from 'src/constants';

const POVEditorPlayer = ({source, videoRef, highlightIndex, currentRound, tempResults, POVIndex, setTransformation, transformation, isPreview, setIsPlaying, clickedIndex, setCurrentTime}) => {

  // keep track of time in case of switch to/from preview mode
  const[previousTime,setPreviousTime] = useState(0);

  // dyanmic transformation
  const transformValue = (videoRef.current && transformation)
  ? `translate(${transformation.positionX * videoRef.current.clientWidth}px, ${transformation.positionY * (videoRef.current.clientWidth > videoRef.current.clientHeight ? videoRef.current.clientWidth : videoRef.current.clientHeight)}px) scale(${transformation.scale})`
  : '';
  
  const highlightStartTime = tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightStartTimeIndex];
  const highlightEndTime = tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightEndTimeIndex];

  // Set the initial start time
  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.currentTime = highlightStartTime ; 

  }, [videoRef, highlightStartTime ]);

  // time update
  const handleTimeUpdate = () => {
    // If click on new POV, loop POV
    if(clickedIndex !== null){
      setTransformation({positionX: tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationXIndex],positionY: tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationYIndex],
        scale: tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationScaleIndex]});
    const POVStartTime = tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationStartTimeIndex];
    const POVEndTime = tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationEndTimeIndex];
    // go back to start time
    if (videoRef.current.currentTime < POVStartTime || videoRef.current.currentTime > POVEndTime) {
      videoRef.current.currentTime = POVStartTime;
    }
  }

  // Not clicked on any, apply POV according to videotime, loop ball
  else if (tempResults[currentRound][highlightDataIndex][highlightIndex] && tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex]){
    // for pov
    const POVStartTime = tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationStartTimeIndex];
    const POVEndTime = tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationEndTimeIndex];
    // apply transformation if any
    if (videoRef.current.currentTime >= POVStartTime && videoRef.current.currentTime <= POVEndTime) {
      setTransformation({positionX: tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationXIndex],positionY: tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationYIndex],
        scale: tempResults[currentRound][highlightDataIndex][highlightIndex][1][POVIndex][transformationScaleIndex]});
    }
    else{ 
    setTransformation({positionX: 0,positionY: 0,scale: 1});
    };
    const startTime = tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightStartTimeIndex];
    const endTime = tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightEndTimeIndex];
    if (videoRef.current.currentTime < startTime || videoRef.current.currentTime > endTime) {
      videoRef.current.currentTime = startTime;
    }
  }
  // No POV, loop without transformation
  else{
    if (videoRef.current.currentTime < highlightStartTime || videoRef.current.currentTime > highlightEndTime) {
      videoRef.current.currentTime = highlightStartTime;
    };
    setTransformation({positionX: 0,positionY: 0,scale: 1});
  };
    setPreviousTime(videoRef.current.currentTime);
    setCurrentTime(videoRef.current.currentTime);
  };

  // record current transformation
  const handleZoom = (event) => {
    const { positionX, positionY, previousScale, scale } = event.state;
    // Get the width and height of the video element
    const videoWidth = videoRef.current.clientWidth;
    // Calculate the position as a percentage of the video display
    const positionXRatio = positionX/videoWidth;
    const positionYRatio = positionY/videoWidth;
    setTransformation({positionX: positionXRatio,positionY: positionYRatio,scale: scale});
  };
  
  // buttons for transformation control
  const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <>
    <div className="zoom-button-overlay">
      <button onClick={() => zoomIn()}>放大 +</button>
      <button onClick={() => zoomOut()}>缩小 -</button>
      <button onClick={() => resetTransform()}>恢复 x</button>
      </div>
    </>
  );
  
  // So that switching does not reset the video to start
  useEffect(() => {
    if (videoRef){
    videoRef.current.currentTime = previousTime;
    setIsPlaying(false);
    };
  }, [isPreview]);

    return (
      <div className='POV-edit-video-panel'>
        {(!isPreview) ? (
        <div className='POV-edit-container'>
          <TransformWrapper onTransformed={handleZoom} disablePadding={true} >
          {(utils) => (
            <React.Fragment>
            <TransformComponent>
              <video ref={videoRef} preload='metadata' playsInline className="POV-edit-player" onTimeUpdate={() => handleTimeUpdate()}>
                <source src={source+"#t=1.001"}  type="video/mp4" />
              </video> 
            </TransformComponent>
            </React.Fragment>
                  )}
          </TransformWrapper>
          </div>
        ) : (
          <div className="POV-edit-video-container">
          <video ref={videoRef} preload='metadata' className="POV-edit-player" playsInline onTimeUpdate={() => handleTimeUpdate()} style={{ transform: transformValue }}>
              <source src={source+"#t=1.001"}  type="video/mp4" />
          </video>
          </div>
          )} 
        </div>
    );
  };
  
  export default POVEditorPlayer;