import { Fragment } from 'react';
import './VerticalTable.scss';

import {
    ballStartTimeIndex,
    ballHomePlayerScoreIndex,
    ballAwayPlayerScoreIndex,
    ballHighlightIndex
  } from 'src/constants';

const VerticalTable = ({ matchData, roundData, roundIndex, videoRef, currentBall, setVideoData, deuce = false, cp = false }) => {


    const renderTableRows = () => {
        let HomePlayerScore = 0;
        let AwayPlayerScore = 0;

        // jump to ball start time
        const handleColumnClick = (ballResult) => {
          videoRef.current.currentTime = ballResult[ballStartTimeIndex] + 0.01;
          setVideoData(prevStatus => ({
            ...prevStatus,
            skipped: true, 
          }));
        };



        return (
            <>
            <tr className='round'>回合 {roundIndex+1}</tr>
            <tr>
            <td className='HomePlayer-name'>{matchData.homePlayer.length <= 15 ? matchData.homePlayer : matchData.homePlayer.slice(0, 15) + '...'}</td>
            <td className='AwayPlayer-name'>{matchData.awayPlayer.length <= 15 ? matchData.awayPlayer : matchData.awayPlayer.slice(0, 15) + '...'}</td>
            </tr>
            
            {roundData.map((row, index) => {
                const HomePlayerCurrentScore = row[ballHomePlayerScoreIndex];
                const AwayPlayerCurrentScore = row[ballAwayPlayerScoreIndex];
                if (cp === true && (HomePlayerCurrentScore < 8 || AwayPlayerCurrentScore < 8)){
                  return
                };
                if (deuce === true && (HomePlayerCurrentScore < 10 || AwayPlayerCurrentScore < 10)){
                  return
                };
                const highlight = row[ballHighlightIndex];

                const HomePlayerScoreIncreased = HomePlayerCurrentScore > HomePlayerScore;
                const AwayPlayerScoreIncreased = AwayPlayerCurrentScore > AwayPlayerScore;

                HomePlayerScore = HomePlayerCurrentScore;
                AwayPlayerScore = AwayPlayerCurrentScore;

                const isCurrentBall = index === currentBall;

                let playerScoreClass = "vertical-player-score";

                if (highlight && isCurrentBall) {
                  playerScoreClass += " highlight current-ball";
                } else if (highlight) {
                  playerScoreClass += " highlight";
                } else if (isCurrentBall) {
                  playerScoreClass += " current-ball";
                }

                return (
                  <Fragment key={`${row[ballHomePlayerScoreIndex]}-${row[ballAwayPlayerScoreIndex]}`}>
                    <tr className={playerScoreClass} onClick={() => handleColumnClick(row)}  title='跳到本球'>
                      <td style={{ color: HomePlayerScoreIncreased ? "red" : "black" }}>
                        <td>{HomePlayerCurrentScore}</td>
                      </td>
                      <td style={{ color: AwayPlayerScoreIncreased ? "red" : "black" }}>
                        <td>{AwayPlayerCurrentScore}</td>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </>
        );
        };

      return (
        <table className='vertical-score-table'>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>
      );
      }
export default VerticalTable;