// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.score-main-container {
  background: linear-gradient(to bottom, #2b2a2a, #1b2944, #000002);
  border-radius: var(--xxsmall-font-size);
  max-height: calc(90vh - var(--medium-font-size));
  overflow-y: auto;
}
.score-main-container .padding {
  height: 20vh;
  width: 1px;
}
.score-main-container .MuiCollapse-root {
  width: 100%;
}
.score-main-container .MuiTypography-root {
  color: white;
  font-size: var(--small-font-size);
}
.score-main-container .MuiAccordion-root {
  background-color: transparent;
  max-width: 100%;
  overflow: auto;
  color: white;
  box-shadow: 0px 2px 1px -1px rgba(12, 12, 12, 0.2), 0px 1px 1px 0px rgba(26, 1, 1, 0.14), 0px 1px 3px 0px rgba(27, 1, 1, 0.14);
}
.score-main-container .MuiAccordion-root .MuiAccordionSummary-expandIconWrapper svg {
  font-size: var(--medium-font-size);
}
.score-main-container .MuiAccordion-root tspan {
  fill: rgb(255, 255, 255);
  font-size: var(--small-font-size);
}
.score-main-container .MuiAccordion-root svg {
  color: white;
}

@media screen and (orientation: portrait) {
  .score-main-container .MuiTypography-root {
    font-size: var(--medium-font-size) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/videoPages/VideoInterface/VideoDynamicPanel/ScorePanel/ScorePanel.scss"],"names":[],"mappings":"AAAA;EACE,iEAAA;EACA,uCAAA;EACA,gDAAA;EACA,gBAAA;AACF;AAAE;EACE,YAAA;EACA,UAAA;AAEJ;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,YAAA;EACA,iCAAA;AAEJ;AAAE;EACE,6BAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EAcA,8HACE;AAZN;AADM;EACE,kCAAA;AAGR;AACI;EACE,wBAAA;EACA,iCAAA;AACN;AACI;EACE,YAAA;AACN;;AAQA;EAEI;IACE,6CAAA;EANJ;AACF","sourcesContent":[".score-main-container {\n  background: linear-gradient(to bottom, #2b2a2a, #1b2944, #000002);\n  border-radius: var(--xxsmall-font-size);\n  max-height: calc(90vh - var(--medium-font-size));\n  overflow-y: auto;\n  .padding {\n    height: 20vh;\n    width: 1px;\n  }\n  .MuiCollapse-root {\n    width: 100%;\n  }\n  .MuiTypography-root {\n    color: white;\n    font-size: var(--small-font-size);\n  }\n  .MuiAccordion-root {\n    background-color: transparent;\n    max-width: 100%;\n    overflow: auto;\n    color: white;\n    .MuiAccordionSummary-expandIconWrapper {\n      svg {\n        font-size: var(--medium-font-size);\n      }\n    }\n\n    tspan {\n      fill: rgb(255, 255, 255);\n      font-size: var(--small-font-size);\n    }\n    svg {\n      color: white;\n    }\n    box-shadow:\n      0px 2px 1px -1px rgba(12, 12, 12, 0.2),\n      0px 1px 1px 0px rgba(26, 1, 1, 0.14),\n      0px 1px 3px 0px rgba(27, 1, 1, 0.14);\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .score-main-container {\n    .MuiTypography-root {\n      font-size: var(--medium-font-size) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
