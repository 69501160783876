import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

const drawerWidth = 240;

const MiniDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: open ? drawerWidth : theme.spacing(5),
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '& .MuiDrawer-paper': {
    marginTop: 40,
    backgroundColor: '#f5f5f5',
    borderRight: '1px solid #e0e0e0',
    width: open ? drawerWidth : theme.spacing(5),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  ...(open && {
    width: drawerWidth,
  }),
  ...(!open && {
    width: theme.spacing(7) + 1,
  }),
}));

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 40,
  color: '#000',
  justifyContent: 'center',
});

const StyledListItemText = styled(ListItemText)(({ open }) => ({
  padding: '0 8px',
  fontSize: 12,
  textAlign: 'center',
  color: '#000',
  display: open ? 'block' : 'none',
}));

const StyledIconButton = styled(IconButton)({
  marginLeft: 4,
  color: '#000',
});

const StyledListItem = styled(ListItem)(({ open }) => ({
  padding: '8px 16px',
  display: 'flex',
  flexDirection: open ? 'row' : 'column',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  textAlign: open ? 'left' : 'center',
}));

const HomeSidePanel = ({ isCollapsed, setIsCollapsed, isCoach, accountType }) => {
  const togglePanel = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <MiniDrawer variant="permanent" open={!isCollapsed}>
      <StyledIconButton onClick={togglePanel}>
        <MenuIcon />
      </StyledIconButton>
      <List>
        {isCoach && (
          <StyledListItem button component={Link} to="/video-upload" open={!isCollapsed}>
            <StyledListItemIcon>
              <UploadFileOutlinedIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="上傳視頻" open={!isCollapsed} />
          </StyledListItem>
        )}
        <StyledListItem button component={Link} to="/player-analysis" open={!isCollapsed}>
          <StyledListItemIcon>
            <Groups2Icon />
          </StyledListItemIcon>
          <StyledListItemText primary="球員" open={!isCollapsed} />
        </StyledListItem>
        {accountType === 'a' && (
          <StyledListItem button component={Link} to="/player-graph" open={!isCollapsed}>
            <StyledListItemIcon>
              <SettingsEthernetIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="關係圖" open={!isCollapsed} />
          </StyledListItem>
        )}
      </List>
    </MiniDrawer>
  );
};

export default HomeSidePanel;
