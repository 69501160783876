import { useState, useEffect } from 'react';
import { Button, IconButton} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Table from './table';
import './ScoreBoard.scss';


import {
    ballDataIndex,
  } from 'src/constants';


  const ScoreBoard = ({ matchData, roundResults, currentRound, videoRef, currentBall }) => {
    const [isScoreBoardOpen, setIsScoreBoardOpen] = useState(false);
    const [roundData, setRoundData] = useState([]);
  
    const toggleScoreBoard = () => {
      setIsScoreBoardOpen(!isScoreBoardOpen);
    };
  
    const scoreBoardClass = !isScoreBoardOpen ? 'scoreboard minimized' : 'scoreboard';
    
    //update scoreboard data
    useEffect(() => {
      if (roundResults && roundResults[currentRound]) {
        setRoundData(roundResults[currentRound][ballDataIndex]);
      }
    }, [roundResults, currentRound]);
  
    return (
      <div className={scoreBoardClass}>
        {!isScoreBoardOpen ? (
          <div className="minimized-icon" onClick={toggleScoreBoard}>
            <DateRangeIcon />
          </div>
        ) : (
          <>
            <div className='scoreboard-container'>
              <Table matchData={matchData} roundData={roundData} roundIndex={currentRound} videoRef={videoRef} currentBall={currentBall} />
            </div>
            <Button variant="contained" className="close" onClick={toggleScoreBoard}>
            <IconButton>
              <CloseOutlinedIcon />
            </IconButton>
          </Button>
          </>
        )}
      </div>
    );
  };
  
  export default ScoreBoard;