import EditPlayerDialog from '../AnalysisMainPanel/EditPlayerDialog';
import PlayerProfileCard from '../PlayerProfileCard/PlayerProfileCard';

import './PlayerInfoPanel.scss';

const PlayerInfoPanel = ({ playerData, userInfo }) => {
  return (
    <div className="block-player-control">
      <div className="img-player-analysis-panel">
        {userInfo.isCoach === true && (
          <EditPlayerDialog
            playerData={playerData}
            accountType={userInfo.accountType}
          />
        )}
        <PlayerProfileCard
          playerData={playerData}
          accountType={userInfo.accountType}
        />
      </div>
      <div className="padding"></div>
    </div>
  );
};

export default PlayerInfoPanel;
