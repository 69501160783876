import { useEffect, useState, useRef } from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import Cookies from 'js-cookie';

import './EditPOVDisplay.scss';

import { Link } from 'react-router-dom';

import {
  transformationYIndex,
  transformationScaleIndex,
  highlightStartTimeIndex,
  highlightDataIndex,
  roundDataIndex,
  roundDescriptionIndex,
  transformationStartTimeIndex,
  transformationEndTimeIndex,
  transformationIdIndex,
} from 'src/constants';

const EditPOVDisplay = ({
  tempResults,
  videoRef,
  currentRound,
  fetchVideoData,
  currentBall,
  videoId,
  setClickedIndex,
  setCurrentRound,
  setHighlightIndex,
}) => {
  const token = Cookies.get('token');
  const editPanelRefs = useRef([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedHighlightIndex, setSelectedHighlightIndex] = useState(null);

  // autoscroll
  useEffect(() => {
    if (currentRound !== null && currentBall !== null) {
      const editPanel = editPanelRefs.current[currentRound];
      if (editPanel) {
        const editScore = editPanel.querySelector(
          `[data-ball-index="${currentBall}"]`,
        );
        if (editScore) {
          editScore.scrollIntoView({ behavior: 'smooth' });
          handleClick(currentBall, currentRound);
        }
      }
    }
  }, [currentBall, currentRound]);

  // update round, highlight, index when clicked
  const handleClick = (highlightIndex, roundIndex) => {
    setCurrentRound(roundIndex);
    setSelectedHighlightIndex(highlightIndex);
    setHighlightIndex(highlightIndex);
    setClickedIndex(null);
    videoRef.current.currentTime =
      tempResults[currentRound][highlightDataIndex][highlightIndex][0][
        highlightStartTimeIndex
      ];
  };

  const handleSubmitClick = () => {
    axios
      .put(
        `/api/editPOV`,
        { new_results: tempResults },
        {
          params: {
            primary_attribute: `video edited (id): ${videoId}`,
          },
          headers: {
            Authorization: `${token}`,
          },
        },
      )
      .then((response) => {
        //console.log(response.data);
        setSnackbarMessage('數據更新成功');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // undo to reset temp data
  const handleUndoClick = () => {
    window.location.reload();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteClick = (roundIndex, highlightIndex, POVIndex) => {
    setClickedIndex(null);
    const transformationId =
      tempResults[roundIndex][highlightDataIndex][highlightIndex][1][POVIndex][
        transformationIdIndex
      ];
    tempResults[roundIndex][highlightDataIndex][highlightIndex][1].splice(
      POVIndex,
      1,
    );
    axios
      .delete(`/api/editPOV`, {
        params: {
          delete_transformation_id: transformationId,
          primary_attribute: `video edited (id): ${videoId}`,
        },
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        // Handle the successful response
        fetchVideoData();
        setSnackbarMessage('數據已删除!');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  return (
    <div className="edit-POV-panel-container">
      <div className="edit-POV-container">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          className="POV-custom-snackbar"
          open={snackbarOpen}
          autoHideDuration={1000000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContent
            className="POV-custom-snackbar"
            message={snackbarMessage}
          />
        </Snackbar>
        {tempResults ? (
          <div className="all-POV-display">
            {tempResults.map((roundResult, roundIndex) => (
              <div
                className="edit-POV-panel"
                key={roundIndex}
                ref={(ref) => (editPanelRefs.current[roundIndex] = ref)}
              >
                <p className="edit-POV-round">第{roundIndex + 1}回合</p>
                <p className="POV-round-description">
                  {' '}
                  回合亮點: {roundResult[roundDataIndex][roundDescriptionIndex]}
                </p>
                {roundResult[highlightDataIndex].map(
                  (highlightResult, highlightIndex) => (
                    <div data-ball-index={highlightIndex}>
                      <p
                        className={`edit-highlight ${
                          currentRound === roundIndex &&
                          selectedHighlightIndex === highlightIndex
                            ? 'selected'
                            : ''
                        }`}
                        onClick={() => handleClick(highlightIndex, roundIndex)}
                      >
                        亮點 {highlightIndex + 1}{' '}
                      </p>
                      {highlightResult[1].map((POVResult, POVIndex) => (
                        <div className="edit-POV" key={POVIndex}>
                          <p className="POV-time">
                            开始: {POVResult[transformationStartTimeIndex]}{' '}
                            结束: {POVResult[transformationEndTimeIndex]}
                          </p>
                          <p className="POV-transformation">
                            位置X: {POVResult[transformationYIndex]} 位置Y:{' '}
                            {POVResult[transformationYIndex]} 比例:{' '}
                            {POVResult[transformationScaleIndex]}{' '}
                          </p>
                          <IconButton
                            onClick={() =>
                              handleDeleteClick(
                                roundIndex,
                                highlightIndex,
                                POVIndex,
                              )
                            }
                            className="POV-delete-button"
                          >
                            <DeleteIcon className="POV-delete-button" />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                  ),
                )}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="edit-POV-buttons">
        <button onClick={handleSubmitClick}>保存</button>
        <button onClick={handleUndoClick}>撤销</button>
        <Link to={`/video-player/${videoId}`}>返回视频</Link>
        <Link to={`/set-highlight/${videoId}`}>亮點编辑</Link>
      </div>
    </div>
  );
};

export default EditPOVDisplay;
