import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';
import Cookies from 'js-cookie';

import './InputVideoPanel.scss';

export default function InputVideoPanel({ matchData }) {
  const token = Cookies.get('token');

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('success');
  const [triggerPlot, setTriggerPlot] = useState(false);

  const [isScoreCoordinates, setIsScoreCoordinates] = useState(false);

  const [analysisData, setAnalysisData] = useState({
    matchid: matchData.matchId,
    tableCoordinates: matchData.tableCoor ? matchData.tableCoor : [],
    scoreCoordinates: matchData.scoreCoor ? matchData.scoreCoor : [],
    startingPlayer: matchData.startingPlayer,
  });
  console.log(matchData);

  const handlePlayerChange = (event) => {
    setAnalysisData((prevData) => ({
      ...prevData,
      startingPlayer: event.target.value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleSave = () => {
    if (
      analysisData.tableCoordinates.length === 4 &&
      analysisData.scoreCoordinates.length === 4
    ) {
      axios
        .put(
          '/api/updateAnalysisVideo',
          { data: analysisData },
          {
            params: {
              primary_attribute: ` video edited : ${matchData.videoName}`,
            },
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response) => {
          //console.log(response.data);
          setOpen(false);
          setSnackBarMessage('成功');
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSnackBarMessage('失敗,確保兩組座標都有4對');
    }
    setOpenSnackBar(true);
  };

  const handleCoordinateSelection = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;

    // Add the selected coordinates to the state
    if (isScoreCoordinates) {
      setAnalysisData((prevData) => ({
        ...prevData,
        scoreCoordinates: [...prevData.scoreCoordinates, { x, y }],
      }));
    } else {
      setAnalysisData((prevData) => ({
        ...prevData,
        tableCoordinates: [...prevData.tableCoordinates, { x, y }],
      }));
    }
  };

  const handleCaptureImage = () => {
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    const context = canvasElement.getContext('2d');

    // Set the canvas size to match the video's width and height
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;

    // Draw the current video frame on the canvas
    context.drawImage(
      videoElement,
      0,
      0,
      canvasElement.width,
      canvasElement.height,
    );
    setTriggerPlot(!triggerPlot);
  };

  const handleStartOver = () => {
    // Clear the coordinates and canvas
    setAnalysisData((prevData) => ({
      ...prevData,
      tableCoordinates: [],
      scoreCoordinates: [],
    }));

    const canvasElement = canvasRef.current;
    const context = canvasElement.getContext('2d');
    context.clearRect(0, 0, canvasElement.width, canvasElement.height);

    const videoElement = videoRef.current;
    context.drawImage(
      videoElement,
      0,
      0,
      canvasElement.width,
      canvasElement.height,
    );
  };

  const handleTableToggle = () => {
    setIsScoreCoordinates((prevValue) => !prevValue);
  };

  useEffect(() => {
    const canvasElement = canvasRef.current;
    const context = canvasElement ? canvasElement.getContext('2d') : null;

    if (context) {
      // Clear the canvas
      context.clearRect(0, 0, canvasElement.width, canvasElement.height);

      // Draw the current video frame on the canvas
      const videoElement = videoRef.current;
      context.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height,
      );

      // Display the selected coordinates as red dots on the canvas
      context.fillStyle = 'red';
      analysisData.tableCoordinates.forEach(({ x, y }) => {
        context.beginPath();
        context.arc(
          x * canvasElement.width,
          y * canvasElement.height,
          5,
          0,
          Math.PI * 2,
        );
        context.fill();
      });
      context.fillStyle = 'green';
      analysisData.scoreCoordinates.forEach(({ x, y }) => {
        context.beginPath();
        context.arc(
          x * canvasElement.width,
          y * canvasElement.height,
          5,
          0,
          Math.PI * 2,
        );
        context.fill();
      });
    }
  }, [
    analysisData.tableCoordinates,
    analysisData.scoreCoordinates,
    triggerPlot,
  ]);

  // load data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/updateAnalysisVideo', {
          params: {
            match_id: matchData.matchId,
            primary_attribute: `fetched data for video : ${matchData.videoName}`,
          },
          headers: {
            Authorization: token,
          },
        });

        const data = response.data;
        const tableCoordinates = data[0].map((coord) => ({
          x: coord[0] / 1000,
          y: coord[1] / 1000,
        }));
        const scoreCoordinates = data[1].map((coord) => ({
          x: coord[0] / 1000,
          y: coord[1] / 1000,
        }));

        setAnalysisData((prevData) => ({
          ...prevData,
          startingPlayer: data[2],
          tableCoordinates: [...tableCoordinates],
          scoreCoordinates: [...scoreCoordinates],
        }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [matchData.matchId]);

  return (
    <div className="input-analysis-main-container">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        {open === false ? (
          <Alert
            onClose={handleCloseSnackBar}
            variant="filled"
            severity="success"
            sx={{ width: '100%' }}
          >
            {snackBarMessage}
          </Alert>
        ) : (
          <Alert
            onClose={handleCloseSnackBar}
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
          >
            {snackBarMessage}
          </Alert>
        )}
      </Snackbar>
      <Button
        className="video-input-button"
        variant="outlined"
        onClick={handleClickOpen}
      >
        輸入分析數據
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className="video-input-headbar" sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              className="video-input-close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className="video-input-title"
              sx={{ ml: 2, flex: 1 }}
              component="div"
            >
              分析數據
            </Typography>
            <Button
              className="video-input-save"
              autoFocus
              color="inherit"
              onClick={handleSave}
            >
              保存
            </Button>
          </Toolbar>
        </AppBar>
        <div className="input-analysis-container">
          <div className="input-analysis-video-container">
            <video
              ref={videoRef}
              className="video-player"
              playsInline
              controls
              preload="auto"
            >
              <source
                src={
                  matchData.videoUrl
                    ? `${matchData.videoUrl}#t=0.001`
                    : `${matchData.fileUrl}#t=0.001`
                }
                type="video/mp4"
              />
            </video>
            <div className="input-analysis-player-panel">
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  開球選手
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={analysisData.startingPlayer}
                  onChange={handlePlayerChange}
                >
                  <FormControlLabel
                    value="top"
                    control={<Radio />}
                    label="top"
                  />
                  <FormControlLabel
                    value="bottom"
                    control={<Radio />}
                    label="bottom"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="input-canvas-container">
            <canvas
              ref={canvasRef}
              className="canvas-player"
              onClick={handleCoordinateSelection}
            />
            <div className="input-canvas-options">
              <h1>
                {isScoreCoordinates ? '現在設置記分板' : '現在設置記乒乓球桌'}
              </h1>
              <Button variant="outlined" onClick={handleCaptureImage}>
                使用當前畫面設置桌面座標
              </Button>
              <span>
                乒乓球桌: {JSON.stringify(analysisData.tableCoordinates)}
                <p>Length: {analysisData.tableCoordinates.length}</p>
              </span>
              <span>
                記分板: {JSON.stringify(analysisData.scoreCoordinates)}
                <p>Length: {analysisData.scoreCoordinates.length}</p>
              </span>
              <Button variant="outlined" onClick={handleTableToggle}>
                {isScoreCoordinates ? 'Set 乒乓球桌 ' : 'Set 分板'}
              </Button>
              <Button variant="outlined" onClick={handleStartOver}>
                刪除選擇
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
