/* eslint-disable */
import React from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';

interface DrawerProps {
  drawerOpen: boolean;
  handleDrawerClose: () => void;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}
const DrawerComponent: React.FC<DrawerProps> = ({
  drawerOpen,
  handleDrawerClose,
  handleTabChange,
}) => {
  return (
    <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
      <Box sx={{ width: 250 }}>
        <List>
          {/* 全部雙打 ,  全部單打, 男單打 , 女單打, 男雙打,女雙打,混合雙打 */}
          {[
            '全部雙打',
            '全部單打',
            '男單打',
            '女單打',
            '男雙打',
            '女雙打',
            '混合雙打',
          ].map((text, index) => (
            <ListItem
              button
              key={text}
              onClick={(event) => handleTabChange(event, index)}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
