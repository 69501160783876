import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MatchData } from 'types/videoTypes';

const DropPointAnalysisPanel: React.FC<{ matchData: MatchData }> = ({
  matchData,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/edit-metadata/${matchData.matchId}/2`);
  }, [navigate, matchData.matchId]);

  return null;
};

export default DropPointAnalysisPanel;
