// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-info-panel {
  overflow-x: hidden;
  display: flex;
  color: var(--main-text-color);
  flex-direction: column;
  align-items: center;
  max-height: calc(90vh - var(--medium-font-size));
  border-radius: var(--small-font-size);
  background: linear-gradient(to bottom, #2b2a2a, #1b2944, #000002);
  overflow-y: auto;
}
.video-info-panel .matches-container {
  min-width: 90%;
}
.video-info-panel .video-info-analysis-container {
  display: flex;
  justify-content: center;
  width: 90%;
}
.video-info-panel .video-info-analysis-container .match-description {
  padding: var(--medium-font-size);
  font-size: var(--small-font-size);
}
.video-info-panel .video-info-analysis-container .match-description p {
  max-width: 100%;
  word-wrap: break-word;
  overflow-y: auto;
}
.video-info-panel .video-info-analysis-container .main-hm-plot-container {
  min-width: 100%;
  width: -moz-fit-content;
  width: fit-content;
}
.video-info-panel .padding {
  min-height: 20vh;
  width: 1px;
}

@media screen and (orientation: portrait) {
  .video-info-panel .video-info-analysis-container {
    display: flex;
    position: relative;
  }
}`, "",{"version":3,"sources":["webpack://./src/videoPages/VideoInterface/VideoDynamicPanel/VideoBasicInfoPanel/VideoBasicInfoPanel.scss"],"names":[],"mappings":"AAAA;EAIE,kBAAA;EACA,aAAA;EACA,6BAAA;EACA,sBAAA;EACA,mBAAA;EACA,gDAAA;EACA,qCAAA;EACA,iEAAA;EACA,gBAAA;AAFF;AATE;EACE,cAAA;AAWJ;AAAE;EACE,aAAA;EACA,uBAAA;EACA,UAAA;AAEJ;AADI;EAME,gCAAA;EACA,iCAAA;AAFN;AAJM;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;AAMR;AADI;EACE,eAAA;EACA,uBAAA;EAAA,kBAAA;AAGN;AAAE;EACE,gBAAA;EACA,UAAA;AAEJ;;AAEA;EAEI;IACE,aAAA;IACA,kBAAA;EAAJ;AACF","sourcesContent":[".video-info-panel {\n  .matches-container {\n    min-width: 90%;\n  }\n  overflow-x: hidden;\n  display: flex;\n  color: var(--main-text-color);\n  flex-direction: column;\n  align-items: center;\n  max-height: calc(90vh - var(--medium-font-size));\n  border-radius: var(--small-font-size);\n  background: linear-gradient(to bottom, #2b2a2a, #1b2944, #000002);\n  overflow-y: auto;\n  .video-info-analysis-container {\n    display: flex;\n    justify-content: center;\n    width: 90%;\n    .match-description {\n      p {\n        max-width: 100%;\n        word-wrap: break-word;\n        overflow-y: auto;\n      }\n      padding: var(--medium-font-size);\n      font-size: var(--small-font-size);\n    }\n    .main-hm-plot-container {\n      min-width: 100%;\n      width: fit-content;\n    }\n  }\n  .padding {\n    min-height: 20vh;\n    width: 1px;\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .video-info-panel {\n    .video-info-analysis-container {\n      display: flex;\n      position: relative;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
