import { useState } from 'react';

import { Snackbar, SnackbarContent, TextField } from '@mui/material';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { formatTime } from 'src/utils/utils';
import axios from 'axios';
import Cookies from 'js-cookie';

import {
  ballAwayPlayerScoreIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballIdIndex,
  ballRoundIdIndex,
  ballStartTimeIndex,
  roundIdIndex,
} from 'src/constants';

import './BallDisplay.scss';

const BallDisplay = ({
  ballData,
  index,
  commentData,
  setIsEditingBall,
  setBallData,
  permission,
  videoData,
  setVideoData,
  matchId,
  fixedRound,
}) => {
  const token = Cookies.get('token');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteInputValue('');
    setErrorMessage('');
  };

  const handleDeleteInputChange = (event) => {
    setDeleteInputValue(event.target.value);
  };

  const handleChange = (editingBall) => {
    setIsEditingBall(editingBall !== null ? `ballPanel${editingBall}` : null);
  };

  const insertRow = (index) => {
    const newData = [...ballData];
    const newDataRow = [
      -1,
      ballData[index][ballRoundIdIndex],
      0,
      0,
      0,
      0,
      false,
      false,
      false,
    ];
    newData.splice(index, 0, newDataRow);
    setBallData(newData);
    handleChange(index);
  };

  const handleDeleteRow = (index) => {
    // Handle delete
    const deleteRow = async (index) => {
      const newData = [...ballData];
      newData.splice(index, 1);
      setBallData(newData);

      axios
        .delete(`/api/editballdata`, {
          params: {
            ball_id: ballData[index][ballIdIndex],
            round_id: ballData[index][ballRoundIdIndex],
            match_id: matchId,
            primary_attribute: `video edited (id) : ${matchId}`,
            secondary_attribute: `delete ball from round id : ${ballData[index][ballRoundIdIndex]} ${ballData[index][ballStartTimeIndex]} to ${ballData[index][ballEndTimeIndex]}`,
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          // Handle the successful response
          setSnackbarMessage('數據已删除!');
          setSnackbarOpen(true);
          setVideoData((prevState) => ({
            ...prevState,
            refresh: true,
          }));
        })
        .catch((error) => {});
    };

    if (deleteInputValue === 'delete') {
      deleteRow(index);
      handleDeleteClose();
    } else {
      setErrorMessage('Please enter "delete" to confirm the deletion.');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="video-ball-container">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="h-custom-snackbar"
        open={snackbarOpen}
        autoHideDuration={1000000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          className="h-custom-snackbar"
          message={snackbarMessage}
        />
      </Snackbar>
      <div className="ball-score-container">
        <div className="ball-score-panel">
          {ballData[index][ballHomePlayerScoreIndex]} :{' '}
          {ballData[index][ballAwayPlayerScoreIndex]}
          {index === videoData.currentBall &&
            (fixedRound === -1 || fixedRound === videoData.currentRound) && (
              <span className="current-ball">
                <ReportGmailerrorredIcon className="is-playing" />
              </span>
            )}
        </div>
        {permission === true && (
          <div className="ball-button-panel">
            <button onClick={() => handleChange(index)} title="編輯數據">
              <EditOutlinedIcon />
            </button>
            <button onClick={() => insertRow(index)} title="添加球">
              <AddOutlinedIcon />
            </button>
            <button onClick={() => handleDeleteOpen()} title="刪除此球">
              <CloseOutlinedIcon />
            </button>
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
              <DialogTitle>請輸入「delete」以刪除球</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="確認刪除"
                  type="text"
                  value={deleteInputValue}
                  onChange={handleDeleteInputChange}
                  error={errorMessage !== ''}
                  helperText={errorMessage}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteClose}>取消</Button>
                <Button
                  onClick={() => handleDeleteRow(index)}
                  variant="contained"
                  autoFocus
                >
                  刪除
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
      <div className="ball-info-panel">
        <div className="highlight-time">
          <span>开始: {formatTime(ballData[index][ballStartTimeIndex])}</span>
          <span>结束: {formatTime(ballData[index][ballEndTimeIndex])}</span>
        </div>
        <span className="comment-count">
          <CommentOutlinedIcon />{' '}
          {commentData[index] ? commentData[index].length : 0}
        </span>
      </div>
    </div>
  );
};

export default BallDisplay;
