import React from 'react';
import './VideoAnalysisControl.scss';

const subNames = ['全部', '直接得分', '該球得分', '下拍失分', '該球失分'];

interface VideoAnalysisControlProps {
  option: number;
  setOption: React.Dispatch<React.SetStateAction<number>>;
  subOption: number;
  setSubOption: React.Dispatch<React.SetStateAction<number>>;
  selectedPlayer: string;
  setSelectedPlayer: React.Dispatch<React.SetStateAction<string>>;
  homePlayer: string;
  awayPlayer: string;
  coachComment?: boolean;
}

const VideoAnalysisControl: React.FC<VideoAnalysisControlProps> = ({
  option,
  setOption,
  subOption,
  setSubOption,
  selectedPlayer,
  setSelectedPlayer,
  homePlayer,
  awayPlayer,
  coachComment = false,
}) => {
  const names = coachComment
    ? ['教練評論', '全部', '發球', '接發球', '第三拍']
    : ['全部', '發球', '接發球', '第三拍'];

  const handleOptionChange = (selectedOption: number): void => {
    setOption(selectedOption);
  };

  const handleSubOptionChange = (selectedSubOption: number): void => {
    setSubOption(selectedSubOption);
  };

  const handlePlayerSelection = (player: string): void => {
    setSelectedPlayer(player);
  };

  return (
    <div className="video-analysis-control">
      <span className="video-analysis-control-title">教練評論/熱度圖</span>
      {option !== 0 && (
        <div className="player-selection">
          <button
            className={`player-button ${
              selectedPlayer === homePlayer ? 'selected' : ''
            }`}
            onClick={() => handlePlayerSelection(homePlayer)}
          >
            {homePlayer}
          </button>
          <button
            className={`player-button ${
              selectedPlayer === awayPlayer ? 'selected' : ''
            }`}
            onClick={() => handlePlayerSelection(awayPlayer)}
          >
            {awayPlayer}
          </button>
        </div>
      )}
      <div className="control-bar">
        <div className="control-group">
          <div className="main-options">
            {names.map((name, index) => (
              <button
                key={index}
                className={`control-button ${
                  option === index ? 'selected' : ''
                }`}
                onClick={() => handleOptionChange(index)}
              >
                {name}
              </button>
            ))}
          </div>
          {option !== 0 && (
            <div className="sub-options">
              {subNames.map((subName, index) => (
                <button
                  key={index}
                  className={`sub-control-button ${
                    subOption === index ? 'selected' : ''
                  }`}
                  onClick={() => handleSubOptionChange(index)}
                >
                  {subName}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoAnalysisControl;
