import { useEffect, useState } from 'react';
import h337 from 'heatmap.js';

import StraightIcon from '@mui/icons-material/Straight';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { fetchDropPointsAPI } from 'src/api/videoService';
import VideoAnalysisControl from 'src/components/VideoAnalysis/VideoAnalysisControl';

import Cookies from 'js-cookie';

import './HeatMap.scss';

const names = {
  0: '全部',
  1: '發球',
  2: '接發球',
  3: '第三拍',
  4: '全部',
  5: '發球',
  6: '接發球',
  7: '第三拍',
};

const hmOptions_dict = {
  0: 'all',
  1: '1',
  2: '2',
  3: '3',
  4: 'all',
  5: '1',
  6: '2',
  7: '3',
};

const subOptions_dict = {
  0: 'all',
  1: 'winning',
  2: 'losing',
  3: 'all',
  4: 'winning',
  5: 'losing',
};

function findMaxSumWithinArea(points, area) {
  let maxSum = -Infinity;

  // Iterate over each point
  for (let i = 0; i < points.length; i++) {
    const currentPoint = points[i];
    let sum = currentPoint.value;

    // Check if other points fall within the area
    for (let j = 0; j < points.length; j++) {
      if (i !== j) {
        const otherPoint = points[j];
        if (
          otherPoint.x >= currentPoint.x - area &&
          otherPoint.x <= currentPoint.x + area &&
          otherPoint.y >= currentPoint.y - area &&
          otherPoint.y <= currentPoint.y + area
        ) {
          sum += otherPoint.value;
        }
      }
    }

    // Update the maximum sum if a higher sum is found
    if (sum > maxSum) {
      maxSum = sum;
    }
  }

  // Return the maximum sum
  return maxSum;
}

function convertToLandscapeCoordinates(x, y, value, width, height) {
  return {
    x: Math.floor((1 - y) * width),
    y: Math.floor(x * height),
    value: value,
  };
}

function calculatePercentageSpread(data, landscape) {
  var boxes;
  boxes = [
    [0.333, 0.5],
    [0.667, 0.5],
    [1, 0.5],
    [0.333, 1],
    [0.667, 1],
    [1, 1],
  ];

  let counts = [0, 0, 0, 0, 0, 0];

  for (const [x, y] of data) {
    for (let i = 0; i < boxes.length; i++) {
      const [boxX, boxY] = boxes[i];
      if (x < boxX && y < boxY && x != -1) {
        counts[i]++;
        break;
      }
    }
  }

  const totalPoints = data.length;
  if (totalPoints === 0) {
    return { counts: [0, 0, 0, 0, 0, 0], dpPercentages: [0, 0, 0, 0, 0, 0] };
  }
  let dpPercentages = counts.map((count) =>
    count !== 0 ? (count / totalPoints) * 100 : 0,
  );

  if (landscape) {
    const newOrder = [3, 0, 4, 1, 5, 2];

    counts = newOrder.map((index) => counts[index]);
    dpPercentages = newOrder.map((index) => dpPercentages[index]);
  }

  return { counts, dpPercentages };
}

const Heatmap = ({
  videoId,
  hmPlayerName,
  hmOpponentName,
  match_option,
  opponent_option,
  heatmapClass,
  removeHmOption,
  homePlayer,
  awayPlayer,
  mini = false,
  giant = false,
  landscape = false,
  disableClose = false,
}) => {
  const [mainAnalysisOption, setMainAnalysisOption] = useState(0);
  const [subAnalysisOption, setSubAnalysisOption] = useState(0);

  const token = Cookies.get('token');

  const [allHmData, setAllHmData] = useState([
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
    [[-1, -1]],
  ]);
  const [hmData, setHmData] = useState([[-1, -1]]);
  const [percentages, setPercentages] = useState([0, 0, 0, 0, 0, 0]);
  const [rPercentages, setRPercentages] = useState([0, 0, 0, 0, 0, 0]);
  const [count, setCount] = useState([0, 0, 0, 0, 0, 0]);
  const [rCount, setRCount] = useState([0, 0, 0, 0, 0, 0]);

  const [selectedRegion, setSelectedRegion] = useState(1);
  const [selectedRegions, setSelectedRegions] = useState([1]);
  const [regionData, setRegionData] = useState([[-1, -1]]);

  const playerName =
    hmPlayerName ||
    (mainAnalysisOption < 4 ? [homePlayer, 'home'] : [awayPlayer, 'away']);
  const opponentName =
    hmOpponentName ||
    (mainAnalysisOption >= 4 ? [homePlayer, 'home'] : [awayPlayer, 'away']);

  const numberToLetter = ['a', 'b', 'c', 'd', 'e', 'f'];

  let prevInnerWidth = window.innerWidth;
  let prevInnerHeight = window.innerHeight;

  const plotHM = (isResized = false) => {
    const { innerWidth, innerHeight } = window;

    // Check if it was triggered by a resize event
    if (isResized) {
      // Calculate the percentage difference between previous and current dimensions
      const widthDiff = Math.abs(innerWidth - prevInnerWidth) / prevInnerWidth;
      const heightDiff =
        Math.abs(innerHeight - prevInnerHeight) / prevInnerHeight;

      // Check if the difference is less than 3%, because weird mobile interaction
      if (widthDiff < 0.03 && heightDiff < 0.03) {
        return;
      }

      // Save the current dimensions as the new previous values
      prevInnerWidth = innerWidth;
      prevInnerHeight = innerHeight;
    }

    let regionPlotData;
    let hmPlotData;
    if (regionData.length === 0) {
      regionPlotData = [[-1, -1]];
    } else {
      regionPlotData = regionData;
    }

    if (hmData.length === 0) {
      hmPlotData = [[-1, -1]];
    } else {
      hmPlotData = hmData;
    }

    // Rest of your code
    const average = (innerWidth + innerHeight) / 2;
    const HMRadius = average / 50;
    const regionRadius = average / 60;

    // Destroy the previous heatmap instance
    const heatmapContainer = document.querySelector(
      `.${heatmapClass} .player-heatmap`,
    );
    const regionContainer = document.querySelector(
      `.${heatmapClass} .opponent-heatmap`,
    );

    if (heatmapContainer && heatmapContainer.firstChild) {
      heatmapContainer.removeChild(heatmapContainer.firstChild);
    }
    if (regionContainer && regionContainer.firstChild) {
      regionContainer.removeChild(regionContainer.firstChild);
    }

    var width, height;
    var plot_height, plot_width;

    // set dimension here
    // landscape
    if (innerWidth > innerHeight) {
      if (mini) {
        plot_height = 0.56;
        plot_width = 0.32;
      } else if (giant) {
        plot_height = 0.49;
        plot_width = 0.28;
      } else {
        plot_height = 0.63;
        plot_width = 0.36;
      }
      width = plot_width * innerHeight;
      height = (plot_height / 2) * innerHeight;

      if (landscape === true) {
        [width, height] = [height, width];
      }
    }
    //portrait
    else {
      if (mini) {
        plot_height = 0.42;
        plot_width = 0.24;
      } else if (giant) {
        plot_height = 0.875;
        plot_width = 0.5;
      } else {
        plot_height = 0.49;
        plot_width = 0.28;
      }
      width = plot_width * innerWidth;
      height = (plot_height / 2) * innerWidth;
      if (landscape === true) {
        [width, height] = [height, width];
      }
    }
    const area = HMRadius * 0.1;

    // heatmap
    if (heatmapContainer) {
      var heatmapInstance = h337.create({
        container: heatmapContainer,
        backgroundColor: '#cbf3f0',
        radius: HMRadius,
      });

      var points = hmPlotData.flatMap((coord) => {
        var x = coord[0];
        var y = coord[1];
        var value = 1;

        if (landscape === true) {
          return convertToLandscapeCoordinates(x, y, value, width, height);
        }

        return {
          x: Math.floor(x * width),
          y: Math.floor(y * height), //
          value: value,
        };
      });

      var data = {
        data: points,
      };

      const maxSum = findMaxSumWithinArea(points, area);
      heatmapInstance.setData(data);
      heatmapInstance.setDataMax(maxSum);
    }

    // region hm

    if (regionContainer) {
      var regionHeatmapInstance = h337.create({
        container: regionContainer,
        backgroundColor: '#fbf8cc',
        radius: HMRadius,
      });

      var regionConfig = {
        maxOpacity: 1,
        minOpacity: 0,
        blur: 0.95,
      };

      var regionPoints = regionPlotData.flatMap((coord) => {
        var x = coord[0];
        var y = coord[1];
        var value = 1;

        if (landscape === true) {
          return convertToLandscapeCoordinates(x, y, value, width, height);
        }

        return {
          x: Math.floor(x * width),
          y: Math.floor(y * height),
          value: value,
        };
      });

      var regionHMData = {
        data: regionPoints,
      };

      const regionMaxSum = findMaxSumWithinArea(points, area);
      regionHeatmapInstance.setData(regionHMData);

      regionHeatmapInstance.setDataMax(regionMaxSum);
    }

    // Create the event listener function
    const resizeListener = () => {
      plotHM(true);
    };

    // Add the event listener
    window.addEventListener('resize', resizeListener);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', resizeListener);
  };

  const handleRegionClick = (region) => {
    //console.log(`Clicked region ${region}`);
    const regionIndex = region - 1;
    const hmIndex = region + 5;
    var repeat = false;
    setSelectedRegion(region);

    // if clicked on the same region
    if (selectedRegion === region) {
      setSelectedRegion(-region);
      repeat = true;
    }

    if (!repeat && !selectedRegions.includes(region)) {
      // Add the region to the selected regions array
      setSelectedRegions((prevSelectedRegions) => [
        ...prevSelectedRegions,
        region,
      ]);
      setHmData((prevData) => [...prevData, ...allHmData[hmIndex]]);
      setRegionData((prevData) => [...prevData, ...allHmData[regionIndex]]);
    } else {
      const newSelectedRegions = selectedRegions.filter((r) => r !== region);
      const newHmData = newSelectedRegions.flatMap((r) => allHmData[r + 5]);
      const newRegionData = newSelectedRegions.flatMap((r) => allHmData[r - 1]);

      // Destroy the previous heatmap instance
      const regionHeatmapContainer = document.querySelector(
        `.${heatmapClass}  .region.${numberToLetter[region - 1]}`,
      );

      while (regionHeatmapContainer && regionHeatmapContainer.firstChild) {
        regionHeatmapContainer.removeChild(regionHeatmapContainer.firstChild);
      }

      // Remove the region from the selected regions array
      setSelectedRegions(newSelectedRegions);
      // Update the hmData state
      setHmData(newHmData);
      setRegionData(newRegionData);
    }
  };

  const handleRemoveClick = () => {
    // Destroy the previous heatmap instance
    const heatmapContainer = document.querySelector(
      `.${heatmapClass} .player-heatmap`,
    );
    const regionContainer = document.querySelector(
      `.${heatmapClass} .opponent-heatmap`,
    );

    if (heatmapContainer && heatmapContainer.firstChild) {
      heatmapContainer.removeChild(heatmapContainer.firstChild);
    }
    if (regionContainer && regionContainer.firstChild) {
      regionContainer.removeChild(regionContainer.firstChild);
    }
    removeHmOption();
  };

  useEffect(() => {
    const fetchDpData = async () => {
      try {
        const responseData = await fetchDropPointsAPI(
          {
            videoId: videoId,
            match_option: match_option ? match_option.join(',') : null,
            opponent_option: opponent_option ? opponent_option.join(',') : null,
            player_name: playerName[0],
            M_option: hmOptions_dict[mainAnalysisOption],
            S_option: subOptions_dict[subAnalysisOption],
            multi_view: true,
          },
          token,
        );

        const dataArray = Object.values(responseData);
        setAllHmData(dataArray);
        setHmData(dataArray[6]);
        setRegionData(dataArray[0]);

        setSelectedRegion(1);
        setSelectedRegions([1]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDpData();
  }, [
    mainAnalysisOption,
    subAnalysisOption,
    playerName[0],
    opponent_option,
    match_option,
  ]);

  useEffect(() => {
    if (hmData && hmData.length >= 0) {
      const { counts: hmCounts, dpPercentages: HmPercentages } =
        calculatePercentageSpread(hmData, landscape);
      setCount(hmCounts);
      setPercentages(HmPercentages);

      const { counts: regionCounts, dpPercentages: regionPercentages } =
        calculatePercentageSpread(regionData, landscape);
      setRCount(regionCounts);
      setRPercentages(regionPercentages);

      plotHM();
    }
  }, [hmData]);

  return (
    <div className="heatmap-container">
      <div className="hm-options-panel">
        {!disableClose === true && (
          <button className="close-hm-button" onClick={handleRemoveClick}>
            <CloseOutlinedIcon />
          </button>
        )}
        <VideoAnalysisControl
          single={landscape}
          option={mainAnalysisOption}
          setOption={setMainAnalysisOption}
          subOption={subAnalysisOption}
          setSubOption={setSubAnalysisOption}
        />
      </div>
      {landscape === true ? (
        <div
          className={`hm-main-container-landscape ${mini ? 'mini' : ''} ${
            giant ? 'giant' : ''
          }`}
        >
          {' '}
          <span className="hm-option">{names[mainAnalysisOption]}</span>
          <div className="heatmap-legend">
            <StraightIcon className="heatmap-arrow" />

            <p className="player-name">球員</p>
            <StraightIcon className="heatmap-arrow" />
          </div>
          {hmData ? (
            <div className={heatmapClass}>
              <div className="opponent-heatmap"></div>
              <div className="player-heatmap"></div>
              <div className="dp-percent">
                {percentages.map((percentage, index) => (
                  <span key={index}>
                    {percentage && Math.round(percentage)}%(
                    {count && count[index]})
                  </span>
                ))}
              </div>
              <div className="heatmap-region">
                <div
                  className={`region ${
                    selectedRegions.includes(4) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(4)}
                >
                  {selectedRegions.includes(4) && (
                    <span>
                      {rPercentages[0] && Math.round(rPercentages[0])}%(
                      {rCount && rCount[0]})
                    </span>
                  )}
                </div>

                <div
                  className={`region ${
                    selectedRegions.includes(1) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(1)}
                >
                  {selectedRegions.includes(1) && (
                    <span>
                      {rPercentages[1] && Math.round(rPercentages[1])}%(
                      {rCount && rCount[1]})
                    </span>
                  )}
                </div>

                <div
                  className={`region ${
                    selectedRegions.includes(5) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(5)}
                >
                  {selectedRegions.includes(5) && (
                    <span>
                      {rPercentages[2] && Math.round(rPercentages[2])}%(
                      {rCount && rCount[2]})
                    </span>
                  )}
                </div>

                <div
                  className={`region ${
                    selectedRegions.includes(2) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(2)}
                >
                  {selectedRegions.includes(2) && (
                    <span>
                      {rPercentages[3] && Math.round(rPercentages[3])}%(
                      {rCount && rCount[3]})
                    </span>
                  )}
                </div>
                <div
                  className={`region ${
                    selectedRegions.includes(6) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(6)}
                >
                  {' '}
                  {selectedRegions.includes(6) && (
                    <span>
                      {rPercentages[4] && Math.round(rPercentages[4])}%(
                      {rCount && rCount[4]})
                    </span>
                  )}
                </div>
                <div
                  className={`region ${
                    selectedRegions.includes(3) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(3)}
                >
                  {' '}
                  {selectedRegions.includes(3) && (
                    <span>
                      {rPercentages[5] && Math.round(rPercentages[5])}
                      %(
                      {rCount && rCount[5]})
                    </span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p style={{ color: 'white' }}>no data</p>
          )}
          <p className="opponent-name">對手</p>
        </div>
      ) : (
        <div
          className={`hm-main-container ${mini ? 'mini' : ''} ${
            giant ? 'giant' : ''
          }`}
        >
          <p className="opponent-name">{opponentName[0]}</p>
          {hmData ? (
            <div className={heatmapClass}>
              <div className="player-heatmap"></div>
              <div className="opponent-heatmap"></div>
              <div className="dp-percent">
                {percentages.map((percentage, index) => (
                  <span key={index}>
                    {percentage && Math.round(percentage)}%(
                    {count && count[index]})
                  </span>
                ))}
              </div>
              <div className="heatmap-region">
                <div
                  className={`region ${
                    selectedRegions.includes(1) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(1)}
                >
                  {selectedRegions.includes(1) && (
                    <span>
                      {rPercentages[0] && Math.round(rPercentages[0])}%(
                      {rCount && rCount[0]})
                    </span>
                  )}
                </div>

                <div
                  className={`region ${
                    selectedRegions.includes(2) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(2)}
                >
                  {selectedRegions.includes(2) && (
                    <span>
                      {rPercentages[1] && Math.round(rPercentages[1])}%(
                      {rCount && rCount[1]})
                    </span>
                  )}
                </div>

                <div
                  className={`region ${
                    selectedRegions.includes(3) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(3)}
                >
                  {selectedRegions.includes(3) && (
                    <span>
                      {rPercentages[2] && Math.round(rPercentages[2])}%(
                      {rCount && rCount[2]})
                    </span>
                  )}
                </div>

                <div
                  className={`region ${
                    selectedRegions.includes(4) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(4)}
                >
                  {selectedRegions.includes(4) && (
                    <span>
                      {rPercentages[3] && Math.round(rPercentages[3])}%(
                      {rCount && rCount[3]})
                    </span>
                  )}
                </div>
                <div
                  className={`region ${
                    selectedRegions.includes(5) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(5)}
                >
                  {' '}
                  {selectedRegions.includes(5) && (
                    <span>
                      {rPercentages[4] && Math.round(rPercentages[4])}%(
                      {rCount && rCount[4]})
                    </span>
                  )}
                </div>
                <div
                  className={`region ${
                    selectedRegions.includes(6) ? 'selected' : ''
                  }`}
                  onClick={() => handleRegionClick(6)}
                >
                  {' '}
                  {selectedRegions.includes(6) && (
                    <span>
                      {rPercentages[5] && Math.round(rPercentages[5])}
                      %(
                      {rCount && rCount[5]})
                    </span>
                  )}
                </div>
              </div>
              <div className="heatmap-legend">
                <StraightIcon className="heatmap-arrow" />
                <span>{names[mainAnalysisOption]}</span>
                <StraightIcon className="heatmap-arrow" />
              </div>
            </div>
          ) : (
            <p style={{ color: 'white' }}>no data</p>
          )}
          <p className="player-name">{playerName[0]}</p>
        </div>
      )}
    </div>
  );
};
export default Heatmap;
