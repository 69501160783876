import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import { fetchPlayerDataAPI } from 'src/api/playerService';
import Cookies from 'js-cookie';

import PlayerCard from './PlayerPanel/PlayerCard/PlayerCard';
import PlayerSearchBar from 'src/components/PlayerSearchBar/PlayerSearchBar';

import { countryToCodeMapping } from 'src/constants';

import Flag from 'react-world-flags';

import './PlayerHome.scss';

import { countryCodeMapping, countries } from 'src/constants';

const translationDict = {
  男單打: 'm',
  男雙打: 'mm',
  女單打: 'f',
  女雙打: 'ff',
  混合雙打: 'mf',
};

const translationRDict = {
  m: '男單打',
  mm: '男雙打',
  f: '女單打',
  ff: '女雙打',
  mf: '混合雙打',
};

const PlayerHome = ({ userInfo, setToken }) => {
  const token = Cookies.get('token');
  const [error, setError] = useState('');
  const [expanded, setExpanded] = useState(true);

  const [selectedCats, setSelectedCats] = useState(() => {
    // Retrieve the cached filter criteria from localStorage, or use the default values
    const cachedCats = localStorage.getItem('playerFilterCriteria');
    const translatedCats = cachedCats
      ? JSON.parse(cachedCats).category.map((item) => translationRDict[item])
      : [];
    return translatedCats;
  });
  const [playerList, setPlayerList] = useState([]);

  // filter/sort criteria
  const [filterCriteria, setFilterCriteria] = useState(() => {
    // Retrieve the cached filter criteria from localStorage, or use the default values
    const cachedCriteria = localStorage.getItem('playerFilterCriteria');
    return cachedCriteria
      ? JSON.parse(cachedCriteria)
      : {
          playerName: '',
          country: countries,
          category: [],
          existingMatch: true,
        };
  });
  const [sortCriteria, setSortCriteria] = useState(() => {
    const cachedSortCriteria = localStorage.getItem('playerSortCriteria');
    return cachedSortCriteria ? JSON.parse(cachedSortCriteria) : [];
  });

  const handleClick = (item) => {
    const translatedItem = translationDict[item];

    if (selectedCats.includes(item)) {
      setSelectedCats(
        selectedCats.filter((selectedCat) => selectedCat !== item),
      );
      setFilterCriteria({
        ...filterCriteria,
        category: filterCriteria.category.filter(
          (cat) => cat !== translatedItem,
        ),
      });
    } else {
      setSelectedCats([...selectedCats, item]);
      setFilterCriteria({
        ...filterCriteria,
        category: [...filterCriteria.category, translatedItem],
      });
    }
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const isCatSelected = (item) => selectedCats.includes(item);

  const handleClearFilter = (key, value) => {
    setFilterCriteria((prevCriteria) => {
      const updatedCriteria = { ...prevCriteria };
      if (value === 'all') {
        updatedCriteria['country'] = countries;
      } else if (Array.isArray(updatedCriteria[key])) {
        updatedCriteria[key] = updatedCriteria[key].filter(
          (item) => item !== value,
        );

        if (key === 'category') {
          setSelectedCats(
            selectedCats.filter(
              (selectedCat) => selectedCat !== translationRDict[value],
            ),
          );
        }
      } else {
        updatedCriteria[key] = '';
      }
      return updatedCriteria;
    });
  };

  const handleClearAllFilter = () => {
    const updatedFilterCriteria = {
      playerName: '',
      country: countries,
      category: [],
      existingMatch: true,
    };

    setFilterCriteria(updatedFilterCriteria);
  };

  const fetchPlayersData = async (filterCriteria, sortCriteria) => {
    const { playerName, category, existingMatch, country } = filterCriteria;

    localStorage.setItem(
      'playerFilterCriteria',
      JSON.stringify(filterCriteria),
    );
    localStorage.setItem('playerSortCriteria', JSON.stringify(sortCriteria));

    try {
      const response = await fetchPlayerDataAPI(
        {
          player_name: playerName,
          categories: category.join(','),
          countries: country.join(','),
          existingMatch: existingMatch,
          sortCriteria: sortCriteria,
          accountType: userInfo.accountType,
        },
        token,
      );
      const responseData = response.data;

      if (responseData === undefined) {
        setPlayerList([]);
        return;
      }

      const playerDict = responseData.map((playerData) => {
        const birthDate = playerData.dob
          ? new Date(playerData.dob)
          : new Date('2000-01-01');
        const currentDate = new Date();
        let actual_age = currentDate.getFullYear() - birthDate.getFullYear();

        const currentMonth = currentDate.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDay = currentDate.getDate();
        const birthDay = birthDate.getDate();
        const countryCode = countryCodeMapping[playerData.country_code] || '';

        if (
          currentMonth < birthMonth ||
          (currentMonth === birthMonth && currentDay < birthDay)
        ) {
          actual_age--;
        }

        let head_shot_path = playerData.head_shot;
        let player2_head_shot_path = playerData.player2_head_shot;

        if (head_shot_path && !head_shot_path.startsWith('https')) {
          head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${head_shot_path}`;
        }

        if (
          player2_head_shot_path &&
          !player2_head_shot_path.startsWith('https')
        ) {
          player2_head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${player2_head_shot_path}`;
        }

        const playerDict = {
          ...playerData,
          countryCode: countryCode,
          actual_age: actual_age,
          head_shot: head_shot_path,
          player2_head_shot: player2_head_shot_path,
        };

        return playerDict;
      });
      setPlayerList(playerDict);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchPlayersData(filterCriteria, sortCriteria);
  }, [filterCriteria, sortCriteria]);

  return (
    <div className="players-home-container">
      {error && <p style={{ color: 'white' }}>Error: {error}</p>}
      <div className="player-home-main">
        {expanded && (
          <div className="player-home-option-panel">
            <PlayerSearchBar
              setFilterCriteria={setFilterCriteria}
              appliedFilterCriteria={filterCriteria}
              setSortCriteria={setSortCriteria}
              appliedSortCriteria={sortCriteria}
              searchName={filterCriteria.playerName}
            />
            <div className="player-home-filter-container">
              <div className="player-home-filter-panel">
                {Object.keys(filterCriteria).map((key) => {
                  const arrayValue = filterCriteria[key];

                  // Check if the value is an array
                  if (Array.isArray(arrayValue) && key !== 'category') {
                    const displayItems = arrayValue.slice(0, 5); // Get up to 5 elements

                    return (
                      <>
                        {arrayValue.length > 5 ? (
                          <Button
                            key="5+"
                            className="filter-button"
                            variant="contained"
                            endIcon={<CloseOutlinedIcon />}
                            onClick={() => handleClearFilter(key, 'all')}
                          >
                            5+
                          </Button>
                        ) : (
                          displayItems.map((value, index) => {
                            const code = countryToCodeMapping[value];
                            return (
                              <Button
                                key={index}
                                className="filter-button"
                                variant="contained"
                                endIcon={<CloseOutlinedIcon />}
                                onClick={() => handleClearFilter(key, value)}
                              >
                                {code ? (
                                  <Flag
                                    code={code}
                                    className="player-smallflag"
                                  />
                                ) : (
                                  <p>{value}</p>
                                )}
                              </Button>
                            );
                          })
                        )}
                      </>
                    );
                  }

                  return null; // Render nothing if the value is not an array
                })}
              </div>

              <Button
                className="clear-filter-button"
                variant="outlined"
                onClick={() => handleClearAllFilter()}
              >
                全世界
              </Button>
            </div>
          </div>
        )}
        <div className="players-category">
          <button
            className={isCatSelected('男單打') ? 'selected' : ''}
            onClick={() => handleClick('男單打')}
          >
            男單打
          </button>
          <button
            className={isCatSelected('男雙打') ? 'selected' : ''}
            onClick={() => handleClick('男雙打')}
          >
            男雙打
          </button>
          <button
            className={isCatSelected('女單打') ? 'selected' : ''}
            onClick={() => handleClick('女單打')}
          >
            女單打
          </button>
          <button
            className={isCatSelected('女雙打') ? 'selected' : ''}
            onClick={() => handleClick('女雙打')}
          >
            女雙打
          </button>
          <button
            className={isCatSelected('混合雙打') ? 'selected' : ''}
            onClick={() => handleClick('混合雙打')}
          >
            混合雙打
          </button>
          <Button
            className="expand-filter-button"
            variant="outlined"
            endIcon={
              expanded ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />
            }
            onClick={() => handleExpand()}
          ></Button>
        </div>
        <div className="player-grid">
          {playerList.length > 0 ? (
            playerList.map((playerData, index) => (
              <PlayerCard playerData={playerData} />
            ))
          ) : (
            <p className="error">正在載入運動員資訊...</p>
          )}
          <div className="padding"></div>
        </div>
      </div>
    </div>
  );
};

export default PlayerHome;
