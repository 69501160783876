// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-hm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 95vh;
  overflow-y: auto;
}
.multi-hm-container .no-heatmap-container {
  padding: var(--small-font-size);
  border-radius: var(--xxsmall-font-size);
  background-color: rgba(31, 29, 28, 0.568627451);
}
.multi-hm-container .no-heatmap-container p {
  margin: 0;
  color: white;
}
.multi-hm-container .multi-hm-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
}
.multi-hm-container .ind-hm-panel {
  padding-right: var(--large-font-size);
  padding-left: var(--large-font-size);
  border-radius: var(--small-font-size);
  background-color: #1f1d1c;
}
.multi-hm-container .padding {
  min-width: 20vw;
  min-height: 20vh;
}
.multi-hm-container .multi-heatmap-setting-button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.multi-hm-container .multi-heatmap-setting-button svg {
  font-size: var(--large-font-size);
  color: var(--bright-red);
}`, "",{"version":3,"sources":["webpack://./src/playerPages/AnalysisSecondaryPanel/HeatMapMultiPanel.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EACE,+BAAA;EACA,uCAAA;EACA,+CAAA;AACJ;AAAI;EACE,SAAA;EACA,YAAA;AAEN;AAEE;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,6BAAA;EACA,SAAA;AAAJ;AAEE;EACE,qCAAA;EACA,oCAAA;EACA,qCAAA;EACA,yBAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;AAAJ;AAEE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AAAJ;AACI;EACE,iCAAA;EACA,wBAAA;AACN","sourcesContent":[".multi-hm-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-height: 95vh;\n  overflow-y: auto;\n\n  .no-heatmap-container {\n    padding: var(--small-font-size);\n    border-radius: var(--xxsmall-font-size);\n    background-color: #1f1d1c91;\n    p {\n      margin: 0;\n      color: white;\n    }\n  }\n\n  .multi-hm-panel {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    justify-content: space-evenly;\n    gap: 10px;\n  }\n  .ind-hm-panel {\n    padding-right: var(--large-font-size);\n    padding-left: var(--large-font-size);\n    border-radius: var(--small-font-size);\n    background-color: #1f1d1c;\n  }\n  .padding {\n    min-width: 20vw;\n    min-height: 20vh;\n  }\n  .multi-heatmap-setting-button {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    svg {\n      font-size: var(--large-font-size);\n      color: var(--bright-red);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
