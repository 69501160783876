import TextField from '@mui/material/TextField';


import {
    roundDataIndex,
    roundDescriptionIndex
  } from 'src/constants';

import './RoundContent.scss';



function RoundContentComponent({ matchData, tempResults, selectedRoundIndex, handleGoRound, handleRoundDescriptionChange  }) {
  return (
    <div className='highlight-match-container'>
      {matchData ? (
        <>
        <span className="players-info"> ({matchData.homePlayer} 對 {matchData.awayPlayer})</span>
          <div className="edit-highlight-video-data">
            <span className="match-info">{matchData.videoName} {matchData.stage}  </span>
            <p className="date-location">{matchData.matchDate} ({matchData.location})</p>
          </div>
        </>
      ) : null}
      {tempResults &&
        tempResults.map((roundResult, roundIndex) => (
          <div className='edit-h-block' key={roundIndex}>
            <p className={`edit-h-round ${selectedRoundIndex === roundIndex ? 'selected' : ''}`} 
            onClick={() => handleGoRound(roundIndex)}>第{roundIndex + 1}回合 </p>
            <TextField
                id="outlined-multiline-flexible"
                label="回合評述"
                multiline
                placeholder="回合評述"
                color="secondary" 
                focused
                value={roundResult[roundDataIndex][roundDescriptionIndex]}
                onChange={(e) => handleRoundDescriptionChange (roundIndex, e.target.value)}
                />
          </div>
        ))}
    </div>
  );
}

export default RoundContentComponent;