// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-ind-commentary-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  border-bottom: 1px solid var(--white);
  margin-bottom: var(--large-font-size);
}
.video-ind-commentary-display .comment-rating {
  display: flex;
}
.video-ind-commentary-display .comment-rating .stars svg {
  color: #faaf00 !important;
}
.video-ind-commentary-display .comment {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.video-ind-commentary-display .comment .avatar-owner-span {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: var(--bright-red);
}
.video-ind-commentary-display .comment .avatar-owner-span .MuiAvatar-circular {
  font-size: var(--medium-font-size);
  width: var(--large-font-size);
  height: var(--large-font-size);
}
.video-ind-commentary-display .comment-date {
  margin: 0;
  width: 95%;
  text-align: right;
}

.h-add-button .new-comment {
  font-size: var(--small-font-size);
  color: var(--main-text-color);
}

@media screen and (orientation: portrait) {
  .video-ind-commentary-display {
    font-size: var(--medium-font-size);
  }
  .video-ind-commentary-display .comment .avatar-owner-span .MuiAvatar-circular {
    font-size: var(--medium-button-size);
  }
}`, "",{"version":3,"sources":["webpack://./src/videoPages/VideoInterface/VideoDynamicPanel/VideoHighlightPanel/utils/CommentDisplay.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iCAAA;EACA,qCAAA;EACA,qCAAA;AACF;AACE;EACE,aAAA;AACJ;AACM;EACE,yBAAA;AACR;AAIE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAFJ;AAGI;EACE,aAAA;EACA,2BAAA;EACA,qBAAA;EACA,wBAAA;AADN;AAEM;EACE,kCAAA;EACA,6BAAA;EACA,8BAAA;AAAR;AAIE;EACE,SAAA;EACA,UAAA;EACA,iBAAA;AAFJ;;AAOE;EACE,iCAAA;EACA,6BAAA;AAJJ;;AAQA;EACE;IACE,kCAAA;EALF;EASM;IACE,oCAAA;EAPR;AACF","sourcesContent":[".video-ind-commentary-display {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: var(--small-font-size);\n  border-bottom: 1px solid var(--white);\n  margin-bottom: var(--large-font-size);\n\n  .comment-rating {\n    display: flex;\n    .stars {\n      svg {\n        color: #faaf00 !important;\n      }\n    }\n  }\n\n  .comment {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    .avatar-owner-span {\n      display: flex;\n      justify-content: flex-start;\n      align-items: flex-end;\n      color: var(--bright-red);\n      .MuiAvatar-circular {\n        font-size: var(--medium-font-size);\n        width: var(--large-font-size);\n        height: var(--large-font-size);\n      }\n    }\n  }\n  .comment-date {\n    margin: 0;\n    width: 95%;\n    text-align: right;\n  }\n}\n\n.h-add-button {\n  .new-comment {\n    font-size: var(--small-font-size);\n    color: var(--main-text-color);\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .video-ind-commentary-display {\n    font-size: var(--medium-font-size);\n\n    .comment {\n      .avatar-owner-span {\n        .MuiAvatar-circular {\n          font-size: var(--medium-button-size);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
