import { useEffect,useState} from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Slider from '@mui/material/Slider'
import { handlePlayPause,handleSeekBackward,handleSeekForward } from 'src/utils/videoControl';


import './POVControlPanel.scss';

import {
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  highlightIdIndex,
  highlightDataIndex,
  transformationStartTimeIndex,
  transformationEndTimeIndex,
} from 'src/constants';

//indicate video current time
const POVTimeBar = ({ videoRef,lowerLimit, upperLimit, clickedIndex,highlightIndex,  currentRound,setTempResults, tempResults, highlightStartTime, highlightEndTime}) => {

  // set highlight start end time
  const [startTime, setStartTime] = useState(
    tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightStartTimeIndex]
  );
  const [endTime, setEndTime] = useState(
    tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightEndTimeIndex]
  );

  // jump to time of slider
  useEffect(() => {
    videoRef.current.currentTime = startTime;
  }, [startTime]);
  
  useEffect(() => {
    videoRef.current.currentTime = endTime;
  }, [endTime]);

  const handleChange = (event, newValue) => {
    const [newStartTime, newEndTime] = newValue;
    setStartTime(newStartTime);
    setEndTime(newEndTime);
    
    // Check if the new values are within the lower and upper limits
    if (newStartTime >= lowerLimit && newEndTime <= upperLimit && newStartTime !== newEndTime) {
      setTempResults((prevTempResults) => {
          const updatedTempResults = [...prevTempResults];
            updatedTempResults[currentRound][highlightDataIndex][highlightIndex][1][clickedIndex][transformationStartTimeIndex] = newStartTime;
            updatedTempResults[currentRound][highlightDataIndex][highlightIndex][1][clickedIndex][transformationEndTimeIndex] = newEndTime;
          return updatedTempResults;
        });
    }
  };
  
  return (
    <div className='POV-timebar-container'>
      <Slider
        min={highlightStartTime}
        max={highlightEndTime}
        step={0.01}
        value={[tempResults[currentRound][highlightDataIndex][highlightIndex][1][clickedIndex][transformationStartTimeIndex],tempResults[currentRound][highlightDataIndex][highlightIndex][1][clickedIndex][transformationEndTimeIndex]]}
        onChange={handleChange}
        valueLabelDisplay='auto'
        marks={[
          { value: lowerLimit, label: lowerLimit.toFixed(2) },
          { value: upperLimit, label: upperLimit.toFixed(2) }
        ]}
      />
    </div>
  );
};

// edit POV bar
const VideoTimeBar = ({tempResults,clickedIndex, setClickedIndex, setLowerLimit, setUpperLimit,
 currentRound, setTempResults, setIsEditing, isPreview, highlightIndex, setPOVIndex,highlightStartTime ,highlightEndTime}) => {

  const highlight_Id= tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightIdIndex];

  // different color for transformation section
  const generateColor = (index, isClicked) => {
    const hue = (index * 77.5) % 360;
    const saturation = 100; // Adjust the saturation value as desired
    const lightness = isClicked ? '60%' : '90%'; // Adjust the lightness value as desired
    return `hsl(${hue}, ${saturation}%, ${lightness})`;
  };

  // style of the transformation bar
  const getTimeBarStyle = (startTime, endTime, index, isClicked) => {
    const totalTime= highlightEndTime- highlightStartTime;
    const startPercentage = ((startTime-highlightStartTime) / totalTime) * 100;
    const barWidth = ((endTime - startTime) / totalTime) * 100;
    const color = generateColor(index, isClicked);
    return {
      left: `${startPercentage}%`,
      width: `${barWidth}%`,
      background: color,
      position: 'absolute', // Set absolute positioning
      top: '0', // Align time bars on the same y-axis
      cursor: 'pointer',
    };
  };

  // set upper and lower bound of transformation
  const setLimits = (index) => {
    let lowerLimit;
    let upperLimit;
  
    if (index > 0) {
      // Get the end time of the previous index as the lower limit (0.001 SO THAT START != END)
      lowerLimit = tempResults[currentRound][highlightDataIndex][highlightIndex][1][index - 1][transformationEndTimeIndex] + 0.001;
    } else {
      // No previous index, use highlightStartTime as the lower limit
      lowerLimit = highlightStartTime;
    }
    if (index < tempResults[currentRound][highlightDataIndex][highlightIndex][1].length - 1) {
      // Get the start time of the next index as the upper limit
      upperLimit = tempResults[currentRound][highlightDataIndex][highlightIndex][1][index + 1][transformationStartTimeIndex] -0.001;
    } else {
      // No next index, use highlightEndTime as the upper limit
      upperLimit = highlightEndTime;
    }
    setLowerLimit(lowerLimit);
    setUpperLimit(upperLimit);
    setPOVIndex(index);
  };

  // add transformation or edit selected
  const handleTimeBarClick = (event, index) => {
    // Check if the clicked element is existing transformation (already inside timebar)
    if (event.target.classList.contains('timebar')) {
      event.stopPropagation();
      console.log('Clicked on time bar with index:', index);
      // set limit first
      setLimits(index);
  
      if (clickedIndex === index) {
        setClickedIndex(null);
        setIsEditing(false);
      } else {
        setIsEditing(true);
        setClickedIndex(index);
      };
    } 
    // add highlight if not in preview mode
    else if (!isPreview){
      // get the click time based on position in bar
      const timeBarRect = event.currentTarget.getBoundingClientRect();
      const clickPositionX = event.clientX - timeBarRect.left;
      const totalTime = highlightEndTime- highlightStartTime;
      const clickTime = (clickPositionX / timeBarRect.width) * totalTime + highlightStartTime;

      // get the largest section of transformation based on click time
      setTempResults((prevTempResults) => {
        const updatedTempResults = [...prevTempResults];
        const transformations = updatedTempResults[currentRound][highlightDataIndex][highlightIndex][1];

        let minDifference = Infinity; // Initialize with a large value
        let largestEndTime = -Infinity;
        let newIndex = -1;
        
        // get a new index for new transformation by finding the current transformation with the closest end time
        for (let i = 0; i < transformations.length; i++) {
          const endTime = transformations[i][transformationEndTimeIndex];
          const difference = Math.abs(endTime- clickTime);
          // new index
          if (difference < minDifference) {
            minDifference = difference;
            newIndex = i;
          }
          // largest end time
          if (endTime> largestEndTime) {
            largestEndTime = endTime;
          }
        }
        // first transformation = -1 because it will +1 = 0
        if (newIndex === 0 && clickTime < transformations[newIndex][transformationStartTimeIndex]) {
          newIndex = -1;
        }
      
        // Set start time as the end time of the previous index or highlightStartTime if index is 0
        let newStartTime;
        let newEndTime;
      
        if (transformations[newIndex]) {
          newStartTime = transformations[newIndex][transformationEndTimeIndex] + 0.001;
        } else {
          newStartTime = highlightStartTime;
        }
      
        // Set end time as the start time of the next index or highlightEndTime if index is the last index
        if (clickTime < largestEndTime && transformations[newIndex + 1]) {
          newEndTime = transformations[newIndex + 1][transformationStartTimeIndex] - 0.001
        } else {
          newEndTime = highlightEndTime;
        }
        

        // Append the new_transformation at the appropriate index in the transformations array
        const newTransformation = [false, highlight_Id, 0, 0, 1, newStartTime, newEndTime];
        transformations.splice(newIndex + 1, 0, newTransformation);
        console.log('NEW:', newTransformation);

        return updatedTempResults;
      });
    }
  };

  return ( 
    <div
      className='timebar-container'
      style={{ position: 'relative' }}
      onClick={(event) => handleTimeBarClick(event, null, null)}
    >
      {tempResults[currentRound][highlightDataIndex][highlightIndex][1].map((POVResult, POVIndex) => (
        <div
          key={POVIndex}
          className="timebar"
          style={getTimeBarStyle(
            POVResult[transformationStartTimeIndex],
            POVResult[transformationEndTimeIndex],
            POVIndex,
            clickedIndex === POVIndex
          )}
          onClick={(event) => handleTimeBarClick(event, POVIndex, null)}
        ></div>
      ))}
  </div>
  );
};

const POVControlPanel = ({setPOVIndex, currentRound, tempResults, isPlaying, videoRef, setIsPlaying, highlightIndex,setClickedIndex,clickedIndex ,setTempResults,isPreview,setIsPreview,setIsEditing}) => {

  const highlightStartTime = tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightStartTimeIndex];
  const highlightEndTime =  tempResults[currentRound][highlightDataIndex][highlightIndex][0][highlightEndTimeIndex];
  const [upperLimit, setUpperLimit] = useState(null);
  const [lowerLimit, setLowerLimit] = useState(null);

  const handlePreview = () => {
    setIsPreview(!isPreview);
  };

  
  return (
    <div className='POV-control-panel'>
      <div className='POV-control-option'>
      { tempResults[currentRound][highlightDataIndex][highlightIndex] ? (
        <>
        <VideoTimeBar tempResults={tempResults}  setTempResults={setTempResults} currentRound={currentRound} highlightStartTime={highlightStartTime} highlightEndTime={highlightEndTime}
            clickedIndex={clickedIndex} setClickedIndex={setClickedIndex} setUpperLimit={setUpperLimit} setPOVIndex={setPOVIndex}
            setLowerLimit={setLowerLimit} highlightIndex={highlightIndex} setIsEditing={setIsEditing} isPreview={isPreview}/>
          { clickedIndex != null  && !isPreview ? (
            <POVTimeBar   highlightIndex={highlightIndex} videoRef={videoRef} highlightStartTime={highlightStartTime} highlightEndTime={highlightEndTime}
            lowerLimit={lowerLimit} upperLimit={upperLimit} clickedIndex={clickedIndex} tempResults={tempResults} currentRound={currentRound} setTempResults={setTempResults}/>
          ):(
            null
          )}
          </>
      ):(
        null
      )}
      </div>
        <div className='POV-buttons'>
            <span>回合: {currentRound + 1}</span>
            <IconButton onClick={() => handleSeekBackward(videoRef)}>
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton className="overlay-button" onClick={() => handlePlayPause(videoRef, isPlaying, setIsPlaying)}>
                  {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
            <IconButton onClick={() => handleSeekForward(videoRef)}>
              <NavigateNextIcon />
            </IconButton>
            <button
              onClick={() => handlePreview()}
              className={isPreview ? 'preview-button active' : 'preview-button'}
            >
              预览
            </button>
        </div>
    </div>
  );
};

export default POVControlPanel;