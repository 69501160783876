import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import MiniVideoCard from './MiniVideoCard';
import {
  fetchPlaylistDataAPI,
  uploadPlaylistDataAPI,
} from '../../../api/videoService';

const categories = ['全部', '本地', '國際', '參考', '重點']; // , '已分析' removed

const EditVideoDialog = ({
  open,
  onClose,
  videoData,
  onSave,
  setToken,
  accountType,
}) => {
  const [videoDetails, setVideoDetails] = useState([]);
  const [topVideos, setTopVideos] = useState([]);
  const [normalVideos, setNormalVideos] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [tabIndex, setTabIndex] = useState(0);
  const [enabledTabs, setEnabledTabs] = useState({
    全部: true,
    本地: false,
    國際: false,
    參考: false,
    重點: false,
    // 已分析: false,
  });
  const [changes, setChanges] = useState([]);

  const [dataFetchPlaylistDataAPI, setDataFetchPlaylistDataAPI] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchAndMergeData = async () => {
      try {
        const params = { accountType };
        const data = await fetchPlaylistDataAPI(params, setToken);
        setDataFetchPlaylistDataAPI(data);
        // Debug: Log fetched data
        console.log('Fetched playlist data:', data);

        // Merge fetched playlist data with existing videoData
        const mergedData = videoData.map((video) => {
          const videoCategory = [];
          data.forEach((playlist) => {
            if (playlist.match_ids.includes(video.matchId)) {
              videoCategory.push(playlist.playlist_name);
            }
          });

          const top = data.some((playlist) =>
            playlist.ispin.includes(video.matchId),
          );
          return { ...video, video_category: videoCategory, top };
        });

        // Debug: Log merged data
        console.log('Merged data:', mergedData);

        setVideoDetails(mergedData);
        setNormalVideos(mergedData.filter((video) => !video.top));
        setTopVideos(mergedData.filter((video) => video.top));
        updateEnabledTabs(mergedData);
      } catch (error) {
        console.error('Error fetching playlist data:', error);
      }
    };

    // Only fetch data if videoData is not empty
    if (videoData && videoData.length > 0) {
      fetchAndMergeData();
    }
  }, [videoData, setToken, accountType]);

  const updateEnabledTabs = (videos) => {
    const newEnabledTabs = {
      全部: true,
      本地: videos.some((video) => video.video_category.includes('本地')),
      國際: videos.some((video) => video.video_category.includes('國際')),
      參考: videos.some((video) => video.video_category.includes('參考')),
      重點: videos.some((video) => video.video_category.includes('重點')),
      // 已分析: videos.some((video) => video.video_category.includes('已分析')),
    };
    setEnabledTabs(newEnabledTabs);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query) {
      const filtered = videoDetails.filter((video) =>
        video.videoName.toLowerCase().includes(query),
      );
      setNormalVideos(filtered.filter((video) => !video.top));
      setTopVideos(filtered.filter((video) => video.top));
    } else {
      setNormalVideos(videoDetails.filter((video) => !video.top));
      setTopVideos(videoDetails.filter((video) => video.top));
    }
  };

  const handleCardClick = (video) => {
    console.log('Clicked video:', video);
  };

  const handleCategoryChange = (matchId, newCategories) => {
    const updatedVideos = videoDetails.map((video) => {
      if (video.matchId === matchId) {
        console.log(
          `Categories changed for video ${
            video.videoName
          } to ${newCategories.join(', ')}`,
        );
        return { ...video, video_category: newCategories, top: newCategories.length > 0 ? video.top : false };
      }
      return video;
    });

    setVideoDetails(updatedVideos);
    setNormalVideos(updatedVideos.filter((video) => !video.top));
    setTopVideos(updatedVideos.filter((video) => video.top));
    updateEnabledTabs(updatedVideos);

    setChanges((prevChanges) => {
      const newChanges = { ...prevChanges };

      newCategories.forEach((category) => {
        let categoryObj = newChanges[category] || {
          custom_tags: [],
          ispin: [],
          match_ids: [],
          playlist_id: dataFetchPlaylistDataAPI.length + Object.keys(newChanges).length + 1,
          playlist_name: category,
        };

        if (!categoryObj.match_ids.includes(matchId)) {
          categoryObj.match_ids.push(matchId);
        }
        newChanges[category] = categoryObj;
      });

      // Remove matchId from categories no longer assigned
      Object.keys(newChanges).forEach((category) => {
        if (!newCategories.includes(category)) {
          newChanges[category].match_ids = newChanges[category].match_ids.filter((id) => id !== matchId);
          if (newChanges[category].match_ids.length === 0) {
            delete newChanges[category];
          }
        }
      });

      return newChanges;
    });

    // Update dataFetchPlaylistDataAPI
    setDataFetchPlaylistDataAPI((prevData) => {
      const newData = [...prevData];

      newCategories.forEach((category) => {
        let playlist = newData.find((p) => p.playlist_name === category);
        if (!playlist) {
          playlist = {
            custom_tags: [],
            ispin: [],
            match_ids: [],
            playlist_id: newData.length + 1,
            playlist_name: category,
          };
          newData.push(playlist);
        }

        if (!playlist.match_ids.includes(matchId)) {
          playlist.match_ids.push(matchId);
        }
      });

      // Remove matchId from playlists no longer assigned
      newData.forEach((playlist) => {
        if (!newCategories.includes(playlist.playlist_name)) {
          playlist.match_ids = playlist.match_ids.filter((id) => id !== matchId);
        }
      });
      // remove ispin if no match_ids
      newData.forEach((playlist) => {
        if (playlist.ispin.length > 0) {
          playlist.ispin = playlist.ispin.filter((id) => playlist.match_ids.includes(id));
        }
      });

      return newData;
    });
  };

  const handlePinToTop = (matchId) => {
    const updatedVideos = videoDetails.map((video) => {
      if (video.matchId === matchId) {
        const isTop = !video.top;
        console.log(`Pin status changed for video ${video.videoName} to ${isTop}`);
        return { ...video, top: isTop };
      }
      return video;
    });

    setVideoDetails(updatedVideos);
    setNormalVideos(updatedVideos.filter((video) => !video.top));
    setTopVideos(updatedVideos.filter((video) => video.top));

    // Update ispin in dataFetchPlaylistDataAPI
    setDataFetchPlaylistDataAPI((prevData) => {
      const newData = [...prevData];
      newData.forEach((playlist) => {
        if (playlist.match_ids.includes(matchId)) {
          const video = updatedVideos.find((video) => video.matchId === matchId);
          if (video.top) {
            if (!playlist.ispin.includes(matchId)) {
              playlist.ispin.push(matchId);
            }
          } else {
            playlist.ispin = playlist.ispin.filter((id) => id !== matchId);
          }
        }
      });
      return newData;
    });
  };

  const handleSave = async () => {
    console.log('Changes to be saved:', changes);
    console.log('Updated Playlist Data API:', dataFetchPlaylistDataAPI);

    try {
      await uploadPlaylistDataAPI(dataFetchPlaylistDataAPI, setToken);
      onSave(videoDetails);
      setOpenSnackbar(true); // Show Snackbar after saving
    } catch (error) {
      console.error('Error updating playlist data:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getFilteredVideos = (videos, category) => {
    if (category === '全部') {
      return videos;
    }
    return videos.filter((video) => video.video_category.includes(category));
  };

  if (!videoDetails) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Videos</DialogTitle>
      <DialogContent>
        <TextField
          label="Search Videos"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
        />
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {categories.map((category, index) => (
            <Tab
              label={category}
              key={index}
              disabled={!enabledTabs[category]}
            />
          ))}
        </Tabs>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          {/* <Typography variant="h6" sx={{ margin: '16px 0' }}>
            Top Videos
          </Typography> */}
          {getFilteredVideos(topVideos, categories[tabIndex]).map((video) => (
            <div key={video.matchId} style={{ marginBottom: '8px' }}>
              <MiniVideoCard
                videoData={video}
                handleCardClick={handleCardClick}
                onCategoryChange={handleCategoryChange}
                onPinToTop={handlePinToTop}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          {/* <Typography variant="h6" sx={{ margin: '16px 0' }}>
            Normal Videos
          </Typography> */}
          {getFilteredVideos(normalVideos, categories[tabIndex]).map(
            (video) => (
              <div key={video.matchId} style={{ marginBottom: '8px' }}>
                <MiniVideoCard
                  videoData={video}
                  handleCardClick={handleCardClick}
                  onCategoryChange={handleCategoryChange}
                  onPinToTop={handlePinToTop}
                />
              </div>
            ),
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          需要重新整理才能看到更新。
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default EditVideoDialog;




// drag function did not fix
// ---------------------------------------------------
// import React, { useState, useEffect } from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   TextField,
//   Tabs,
//   Tab,
//   Typography,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import MiniVideoCard from './MiniVideoCard';
// import {
//   fetchPlaylistDataAPI,
//   uploadPlaylistDataAPI,
// } from '../../../api/videoService';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// const categories = ['全部', '本地', '國際', '參考', '重點']; // , '已分析' removed

// const EditVideoDialog = ({
//   open,
//   onClose,
//   videoData,
//   onSave,
//   setToken,
//   accountType,
// }) => {
//   const [videoDetails, setVideoDetails] = useState([]);
//   const [topVideos, setTopVideos] = useState([]);
//   const [normalVideos, setNormalVideos] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');

//   const [tabIndex, setTabIndex] = useState(0);
//   const [enabledTabs, setEnabledTabs] = useState({
//     全部: true,
//     本地: false,
//     國際: false,
//     參考: false,
//     重點: false,
//     // 已分析: false,
//   });
//   const [changes, setChanges] = useState([]);

//   const [dataFetchPlaylistDataAPI, setDataFetchPlaylistDataAPI] = useState([]);
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   useEffect(() => {
//     const fetchAndMergeData = async () => {
//       try {
//         const params = { accountType };
//         const data = await fetchPlaylistDataAPI(params, setToken);
//         setDataFetchPlaylistDataAPI(data);
//         // Debug: Log fetched data
//         console.log('Fetched playlist data:', data);

//         // Merge fetched playlist data with existing videoData
//         const mergedData = videoData.map((video) => {
//           const videoCategory = [];
//           data.forEach((playlist) => {
//             if (playlist.match_ids.includes(video.matchId)) {
//               videoCategory.push(playlist.playlist_name);
//             }
//           });

//           const top = data.some((playlist) =>
//             playlist.ispin.includes(video.matchId),
//           );
//           return { ...video, video_category: videoCategory, top };
//         });

//         // Debug: Log merged data
//         console.log('Merged data:', mergedData);

//         setVideoDetails(mergedData);
//         setNormalVideos(mergedData.filter((video) => !video.top));
//         setTopVideos(mergedData.filter((video) => video.top));
//         updateEnabledTabs(mergedData);
//       } catch (error) {
//         console.error('Error fetching playlist data:', error);
//       }
//     };

//     // Only fetch data if videoData is not empty
//     if (videoData && videoData.length > 0) {
//       fetchAndMergeData();
//     }
//   }, [videoData, setToken, accountType]);

//   const updateEnabledTabs = (videos) => {
//     const newEnabledTabs = {
//       全部: true,
//       本地: videos.some((video) => video.video_category.includes('本地')),
//       國際: videos.some((video) => video.video_category.includes('國際')),
//       參考: videos.some((video) => video.video_category.includes('參考')),
//       重點: videos.some((video) => video.video_category.includes('重點')),
//       // 已分析: videos.some((video) => video.video_category.includes('已分析')),
//     };
//     setEnabledTabs(newEnabledTabs);
//   };

//   const handleSearchChange = (e) => {
//     const query = e.target.value.toLowerCase();
//     setSearchQuery(query);
//     if (query) {
//       const filtered = videoDetails.filter((video) =>
//         video.videoName.toLowerCase().includes(query),
//       );
//       setNormalVideos(filtered.filter((video) => !video.top));
//       setTopVideos(filtered.filter((video) => video.top));
//     } else {
//       setNormalVideos(videoDetails.filter((video) => !video.top));
//       setTopVideos(videoDetails.filter((video) => video.top));
//     }
//   };

//   const handleCardClick = (video) => {
//     console.log('Clicked video:', video);
//   };

//   const handleCategoryChange = (matchId, newCategories) => {
//     const updatedVideos = videoDetails.map((video) => {
//       if (video.matchId === matchId) {
//         console.log(
//           `Categories changed for video ${
//             video.videoName
//           } to ${newCategories.join(', ')}`,
//         );
//         return { ...video, video_category: newCategories, top: newCategories.length > 0 ? video.top : false };
//       }
//       return video;
//     });

//     setVideoDetails(updatedVideos);
//     setNormalVideos(updatedVideos.filter((video) => !video.top));
//     setTopVideos(updatedVideos.filter((video) => video.top));
//     updateEnabledTabs(updatedVideos);

//     setChanges((prevChanges) => {
//       const newChanges = { ...prevChanges };

//       newCategories.forEach((category) => {
//         let categoryObj = newChanges[category] || {
//           custom_tags: [],
//           ispin: [],
//           match_ids: [],
//           playlist_id: dataFetchPlaylistDataAPI.length + Object.keys(newChanges).length + 1,
//           playlist_name: category,
//         };

//         if (!categoryObj.match_ids.includes(matchId)) {
//           categoryObj.match_ids.push(matchId);
//         }
//         newChanges[category] = categoryObj;
//       });

//       // Remove matchId from categories no longer assigned
//       Object.keys(newChanges).forEach((category) => {
//         if (!newCategories.includes(category)) {
//           newChanges[category].match_ids = newChanges[category].match_ids.filter((id) => id !== matchId);
//           if (newChanges[category].match_ids.length === 0) {
//             delete newChanges[category];
//           }
//         }
//       });

//       return newChanges;
//     });

//     // Update dataFetchPlaylistDataAPI
//     setDataFetchPlaylistDataAPI((prevData) => {
//       const newData = [...prevData];

//       newCategories.forEach((category) => {
//         let playlist = newData.find((p) => p.playlist_name === category);
//         if (!playlist) {
//           playlist = {
//             custom_tags: [],
//             ispin: [],
//             match_ids: [],
//             playlist_id: newData.length + 1,
//             playlist_name: category,
//           };
//           newData.push(playlist);
//         }

//         if (!playlist.match_ids.includes(matchId)) {
//           playlist.match_ids.push(matchId);
//         }
//       });

//       // Remove matchId from playlists no longer assigned
//       newData.forEach((playlist) => {
//         if (!newCategories.includes(playlist.playlist_name)) {
//           playlist.match_ids = playlist.match_ids.filter((id) => id !== matchId);
//         }
//       });
//       // remove ispin if no match_ids
//       newData.forEach((playlist) => {
//         if (playlist.ispin.length > 0) {
//           playlist.ispin = playlist.ispin.filter((id) => playlist.match_ids.includes(id));
//         }
//       });

//       return newData;
//     });
//   };

//   const handlePinToTop = (matchId) => {
//     const updatedVideos = videoDetails.map((video) => {
//       if (video.matchId === matchId) {
//         const isTop = !video.top;
//         console.log(`Pin status changed for video ${video.videoName} to ${isTop}`);
//         return { ...video, top: isTop };
//       }
//       return video;
//     });

//     setVideoDetails(updatedVideos);
//     setNormalVideos(updatedVideos.filter((video) => !video.top));
//     setTopVideos(updatedVideos.filter((video) => video.top));

//     // Update ispin in dataFetchPlaylistDataAPI
//     setDataFetchPlaylistDataAPI((prevData) => {
//       const newData = [...prevData];
//       newData.forEach((playlist) => {
//         if (playlist.match_ids.includes(matchId)) {
//           const video = updatedVideos.find((video) => video.matchId === matchId);
//           if (video.top) {
//             if (!playlist.ispin.includes(matchId)) {
//               playlist.ispin.push(matchId);
//             }
//           } else {
//             playlist.ispin = playlist.ispin.filter((id) => id !== matchId);
//           }
//         }
//       });
//       return newData;
//     });
//   };

//   const handleDragEnd = (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const { source, destination } = result;

//     const updatedVideos = [...videoDetails];
//     const [movedVideo] = updatedVideos.splice(source.index, 1);
//     updatedVideos.splice(destination.index, 0, movedVideo);

//     setVideoDetails(updatedVideos);
//     setNormalVideos(updatedVideos.filter((video) => !video.top));
//     setTopVideos(updatedVideos.filter((video) => video.top));
//   };

//   const handleSave = async () => {
//     console.log('Changes to be saved:', changes);
//     console.log('Updated Playlist Data API:', dataFetchPlaylistDataAPI);

//     try {
//       await uploadPlaylistDataAPI(dataFetchPlaylistDataAPI, setToken);
//       onSave(videoDetails);
//       setOpenSnackbar(true); // Show Snackbar after saving
//     } catch (error) {
//       console.error('Error updating playlist data:', error);
//     }
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabIndex(newValue);
//   };

//   const getFilteredVideos = (videos, category) => {
//     if (category === '全部') {
//       return videos;
//     }
//     return videos.filter((video) => video.video_category.includes(category));
//   };

//   if (!videoDetails) return null;

//   return (
//     <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
//       <DialogTitle>Edit Videos</DialogTitle>
//       <DialogContent>
//         <TextField
//           label="Search Videos"
//           value={searchQuery}
//           onChange={handleSearchChange}
//           fullWidth
//           margin="normal"
//         />
//         <Tabs
//           value={tabIndex}
//           onChange={handleTabChange}
//           variant="scrollable"
//           scrollButtons="auto"
//         >
//           {categories.map((category, index) => (
//             <Tab
//               label={category}
//               key={index}
//               disabled={!enabledTabs[category]}
//             />
//           ))}
//         </Tabs>
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             overflowY: 'auto',
//           }}
//         >
//           <DragDropContext onDragEnd={handleDragEnd}>
//             <Droppable droppableId="topVideos">
//               {(provided) => (
//                 <div
//                   ref={provided.innerRef}
//                   {...provided.droppableProps}
//                   style={{ marginBottom: '16px' }}
//                 >
//                   {getFilteredVideos(topVideos, categories[tabIndex]).map((video, index) => (
//                     <MiniVideoCard
//                       key={video.matchId}
//                       videoData={video}
//                       handleCardClick={handleCardClick}
//                       onCategoryChange={handleCategoryChange}
//                       onPinToTop={handlePinToTop}
//                       index={index}
//                     />
//                   ))}
//                   {provided.placeholder}
//                 </div>
//               )}
//             </Droppable>
//             <Droppable droppableId="normalVideos">
//               {(provided) => (
//                 <div
//                   ref={provided.innerRef}
//                   {...provided.droppableProps}
//                 >
//                   {getFilteredVideos(normalVideos, categories[tabIndex]).map((video, index) => (
//                     <MiniVideoCard
//                       key={video.matchId}
//                       videoData={video}
//                       handleCardClick={handleCardClick}
//                       onCategoryChange={handleCategoryChange}
//                       onPinToTop={handlePinToTop}
//                       index={index}
//                     />
//                   ))}
//                   {provided.placeholder}
//                 </div>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </div>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSave} color="primary">
//           Save
//         </Button>
//       </DialogActions>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//       >
//         <Alert onClose={() => setOpenSnackbar(false)} severity="success">
//           需要重新整理才能看到更新。
//         </Alert>
//       </Snackbar>
//     </Dialog>
//   );
// };

// export default EditVideoDialog;
