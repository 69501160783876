import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { VideoData } from '../types/GraphTypes';

const useFetchVideoData = (
  setVideoData: React.Dispatch<React.SetStateAction<VideoData[] | null>>,
  userInfo: any,
) => {
  const token = Cookies.get('token');
  const [playerName, setPlayerName] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({
    playerName: playerName || '',
    ranking: '',
    country: [],
    gender: '',
    grip: '',
    ageCategory: '',
  });
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get('/api/videodata', {
          params: {
            player_name: filterCriteria.playerName,
            accountType: userInfo.accountType,
            admin: true,
          },
          headers: {
            Authorization: `${token}`,
          },
        });
        const jsonData = response.data;
        const formattedData = jsonData['videoData'].map(
          (item: any): VideoData => ({
            id: item[0],
            fullName: item[1],
            matchRound: item[3],
            matchDate: item[4],
            location: item[5],
            player1: item[6],
            player2: item[7],
            videoUrl: item[8],
            thumbnailUrl: item[9],
            player1Id: item[11],
            player2Id: item[12],
            isActive: item[13],
            isHallOfFame: item[14],
            isRetired: item[15],
            event: item[16],
            series: item[17],
            category: item[18],
            playCategory: item[19],
            isOngoing: item[20],
            ranking: item[21],
            createdBy: item[22],
            modifiedBy: item[23],
            isFlagged: item[24],
            isManuallyUpdated: item[25],
            combinedCsv: item[26],
            createdAt: item[27],
            topTag: item[35],
            lastModified: item[38],
          }),
        );

        setVideoData(formattedData);
      } catch (error) {
        if (
          (error as any).response &&
          ((error as any).response.status === 404 ||
            (error as any).response.status === 500)
        ) {
          fetch('/data.json')
            .then((response) => {
              if (!response.ok)
                throw new Error(`HTTP error! status: ${response.status}`);
              return response.json();
            })
            .then((jsonData) => {
              const formattedData = jsonData.map(
                (item: any): VideoData => ({
                  id: item[0],
                  fullName: item[1],
                  matchRound: item[3],
                  matchDate: item[4],
                  location: item[5],
                  player1: item[6],
                  player2: item[7],
                  videoUrl: item[8],
                  thumbnailUrl: item[9],
                  player1Id: item[11],
                  player2Id: item[12],
                  isActive: item[13],
                  isHallOfFame: item[14],
                  isRetired: item[15],
                  event: item[16],
                  series: item[17],
                  category: item[18],
                  playCategory: item[19],
                  isOngoing: item[20],
                  ranking: item[21],
                  createdBy: item[22],
                  modifiedBy: item[23],
                  isFlagged: item[24],
                  isManuallyUpdated: item[25],
                  combinedCsv: item[26],
                  createdAt: item[27],
                  topTag: item[35],
                  lastModified: item[38],
                }),
              );

              setVideoData(formattedData);
            })
            .catch((e) => {
              console.error('Error loading video data:', e);
            });
        }
      }
    };

    fetchVideoData();
  }, [token, setVideoData]);
};

export default useFetchVideoData;
