import { useEffect, useState } from 'react';
import h337 from 'heatmap.js';
import StraightIcon from '@mui/icons-material/Straight';

import { fetchDropPointsAPI } from 'src/api/videoService';

import { formatTime } from 'src/utils/utils';

import Cookies from 'js-cookie';

import './SimpleHeatmap.scss';
import {
  ballAwayPlayerScoreIndex,
  ballDataIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballStartTimeIndex,
} from 'src/constants';

const region_dict = {
  0: '左長枱',
  1: '中長枱',
  2: '右長枱',
  3: '左短枱',
  4: '中短枱',
  5: '右短枱',
};

function findMaxSumWithinArea(points, area) {
  let maxSum = -Infinity;

  // Iterate over each point
  for (let i = 0; i < points.length; i++) {
    const currentPoint = points[i];
    let sum = currentPoint.value;

    // Check if other points fall within the area
    for (let j = 0; j < points.length; j++) {
      if (i !== j) {
        const otherPoint = points[j];
        if (
          otherPoint.x >= currentPoint.x - area &&
          otherPoint.x <= currentPoint.x + area &&
          otherPoint.y >= currentPoint.y - area &&
          otherPoint.y <= currentPoint.y + area
        ) {
          sum += otherPoint.value;
        }
      }
    }

    // Update the maximum sum if a higher sum is found
    if (sum > maxSum) {
      maxSum = sum;
    }
  }

  // Return the maximum sum
  return maxSum * 0.6;
}

const SimpleHeatmap = ({
  videoId,
  playerName,
  opponentName,
  hmOptions,
  subOptions,
  heatmapClass,
  roundResults,
  videoRef,
  mini = false,
  giant = false,
}) => {
  const token = Cookies.get('token');

  const [hmData, setHmData] = useState([[-1, -1]]);
  const [percentages, setPercentages] = useState([0, 0, 0, 0, 0, 0]);
  const [count, setCounts] = useState([0, 0, 0, 0, 0, 0]);
  const [boxDetails, setBoxDetails] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  let prevInnerWidth = window.innerWidth;
  let prevInnerHeight = window.innerHeight;

  function calculatePercentageSpread(hmData, roundResults) {
    const boxes = [
      [0.333, 0.5],
      [0.667, 0.5],
      [1, 0.5],
      [0.333, 1],
      [0.667, 1],
      [1, 1],
    ];
    const counts = [0, 0, 0, 0, 0, 0];
    const boxDetails = [[], [], [], [], [], []];

    for (const entry of hmData) {
      if (entry.length >= 3) {
        const coordinates = entry.slice(0, -2);
        const roundIndex = entry[entry.length - 2];
        const ballIndex = entry[entry.length - 1];

        for (let i = 0; i < coordinates.length; i++) {
          const [x, y] = coordinates[i];
          for (let j = 0; j < boxes.length; j++) {
            const [boxX, boxY] = boxes[j];

            if (x <= boxX && y <= boxY) {
              counts[j]++;
              const ballData =
                roundResults[roundIndex]?.[ballDataIndex]?.[ballIndex];
              boxDetails[j].push({
                x,
                y,
                roundIndex,
                ballIndex,
                homePlayerScore: ballData?.[ballHomePlayerScoreIndex],
                awayPlayerScore: ballData?.[ballAwayPlayerScoreIndex],
                startTime: ballData?.[ballStartTimeIndex],
                endTime: ballData?.[ballEndTimeIndex],
              });
              break;
            }
          }
        }
      }
    }

    const totalPoints = counts.reduce((a, b) => a + b, 0);
    const percentages = counts.map((count) =>
      count !== 0 ? (count / totalPoints) * 100 : 0,
    );

    return { counts, percentages, boxDetails };
  }

  const plotHM = (hmData, isResized = false) => {
    const { innerWidth, innerHeight } = window;

    // Check if it was triggered by a resize event
    if (isResized) {
      // Calculate the percentage difference between previous and current dimensions
      const widthDiff = Math.abs(innerWidth - prevInnerWidth) / prevInnerWidth;
      const heightDiff =
        Math.abs(innerHeight - prevInnerHeight) / prevInnerHeight;

      // Check if the difference is less than 3%, because weird mobile interaction
      if (widthDiff < 0.03 && heightDiff < 0.03) {
        return;
      }

      // Save the current dimensions as the new previous values
      prevInnerWidth = innerWidth;
      prevInnerHeight = innerHeight;
    }

    const average = (innerWidth + innerHeight) / 2;
    const HMRadius = average / 50;

    // Destroy the previous heatmap instance
    const heatmapContainer = document.querySelector(
      `.${heatmapClass} .simple-heatmap`,
    );
    if (heatmapContainer && heatmapContainer.firstChild) {
      heatmapContainer.removeChild(heatmapContainer.firstChild);
    }

    // heatmap
    const HMContainer = document.querySelector(
      `.${heatmapClass} .simple-heatmap`,
    );
    if (HMContainer) {
      try {
        var heatmapInstance = h337.create({
          container: HMContainer,
          backgroundColor: '#FAFAFA',
          radius: HMRadius,
        });
        var width, height;
        var plot_height, plot_width;

        // set dimension here
        // landscape
        if (innerWidth > innerHeight) {
          if (mini) {
            plot_height = 0.56;
            plot_width = 0.32;
            width = plot_width * innerHeight;
            height = plot_height * innerHeight;
          } else if (giant) {
            plot_height = 0.245;
            plot_width = 0.28;
            width = plot_width * innerWidth;
            height = plot_height * innerWidth;
          } else {
            plot_height = 0.63;
            plot_width = 0.36;
            width = plot_width * innerHeight;
            height = plot_height * innerHeight;
          }
        }
        //portrait
        else {
          if (mini) {
            plot_height = 0.42;
            plot_width = 0.24;
          } else if (giant) {
            plot_height = 0.4375;
            plot_width = 0.5;
          } else {
            plot_height = 0.49;
            plot_width = 0.28;
          }
          width = plot_width * innerWidth;
          height = plot_height * innerWidth;
        }

        var points = hmData.flatMap((entry) => {
          if (entry.length >= 3) {
            const coordinates = entry.slice(0, -2);
            const roundIndex = entry[entry.length - 2];
            const ballIndex = entry[entry.length - 1];

            return coordinates.map(([x, y]) => ({
              x: Math.floor(x * width),
              y: Math.floor(y * height),
              value: 1,
              roundIndex,
              ballIndex,
            }));
          }
          return [];
        });

        var data = {
          data: points,
        };

        const area = HMRadius / 30;
        const maxSum = findMaxSumWithinArea(points, area);
        heatmapInstance.setData(data);
        heatmapInstance.setDataMax(maxSum);
      } catch (error) {
        console.error(error);
      }
    }

    // Create the event listener function
    const resizeListener = () => {
      plotHM(hmData, true);
    };

    // Add the event listener
    window.addEventListener('resize', resizeListener);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', resizeListener);
  };

  const removeExistingHm = () => {
    const heatmapContainer = document.querySelector(
      `.${heatmapClass} .simple-heatmap`,
    );
    if (heatmapContainer && heatmapContainer.firstChild) {
      heatmapContainer.removeChild(heatmapContainer.firstChild);
    }
  };

  useEffect(() => {
    const fetchDpData = async () => {
      try {
        const responseData = await fetchDropPointsAPI(
          {
            videoId: videoId,
            player_name: playerName[0],
            M_option: hmOptions,
            S_option: subOptions,
          },
          token,
        );
        setHmData(responseData[0]);
      } catch (error) {
        // setError(error.message);
      }
    };
    fetchDpData();
  }, [hmOptions, subOptions, playerName]);

  useEffect(() => {
    // Initial plot
    if (hmData.length > 0 && roundResults.length > 0) {
      removeExistingHm();
      const { counts, percentages, boxDetails } = calculatePercentageSpread(
        hmData,
        roundResults,
      );
      setCounts(counts);
      setPercentages(percentages);
      setBoxDetails(boxDetails);
      plotHM(hmData);
    }
  }, [hmData]);

  const handleRegionClick = (index) => {
    setSelectedRegion(index);
  };

  const handleTimeClick = (startTime) => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
    }
  };

  const getScoreColor = (score, opponentScore) => {
    if (score > opponentScore) return 'score-higher';
    if (score < opponentScore) return 'score-lower';
    return 'score-tie';
  };

  const renderDetailsTable = () => {
    if (selectedRegion === null) return null;

    const details = boxDetails[selectedRegion];

    return (
      <div className="details-table">
        <h3 className="details-title">
          {' '}
          {region_dict[selectedRegion]}(
          <span className="player-name" title={playerName[0]}>
            {playerName[0].length > 15
              ? playerName[0].slice(0, 15) + '...'
              : playerName[0]}
          </span>
          面向枱)
        </h3>
        <table>
          <thead>
            <tr>
              <th>回合</th>
              <th>比分</th>
              <th>開始時間</th>
              <th>結束時間</th>
            </tr>
          </thead>
          <tbody>
            {details.map((detail, index) => (
              <tr key={index} className="detail-row">
                <td>{detail.roundIndex + 1}</td>
                <td>
                  <div className="score-container">
                    <span
                      className={getScoreColor(
                        detail.homePlayerScore,
                        detail.awayPlayerScore,
                      )}
                    >
                      {detail.homePlayerScore}
                    </span>
                    <span className="score-separator">:</span>
                    <span
                      className={getScoreColor(
                        detail.awayPlayerScore,
                        detail.homePlayerScore,
                      )}
                    >
                      {detail.awayPlayerScore}
                    </span>
                  </div>
                </td>
                <td
                  className="start-time"
                  onClick={() => handleTimeClick(detail.startTime)}
                >
                  {formatTime(detail.startTime)}
                </td>
                <td>{formatTime(detail.endTime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div
      className={`sim-hm-main-container ${mini ? 'mini' : ''} ${
        giant ? 'giant' : ''
      }`}
    >
      <p className="video-info-heatmap-opponent-name"> {opponentName[0]}</p>
      <div className={heatmapClass}>
        <div
          className="simple-heatmap"
          style={{ width: '100%', height: '100%' }}
        ></div>
        <div className="dp-percent">
          {percentages.map((percentage, index) => (
            <span
              key={index}
              className={`region ${selectedRegion === index ? 'selected' : ''}`}
              onClick={() => handleRegionClick(index)}
            >
              <span className="region-percentage">
                {percentage && Math.round(percentage)}%({count[index]})
              </span>
            </span>
          ))}
        </div>
      </div>
      <div className="simple-hm-bottom-panel">
        <StraightIcon />
        <p className="video-info-heatmap-player-name">{playerName[0]}</p>
        <StraightIcon />
      </div>
      {renderDetailsTable()}
    </div>
  );
};
export default SimpleHeatmap;
