import { Fragment } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id, time, name, location, activities, accountType) {
  return { id, time, name, location, activities, accountType };
}

const rows = [
  createData(
    0,
    '16 Mar, 2019 10:30 AM',
    'Doo Hoi Kem',
    'Shatin',
    'Log in',
    'Athlete',
  ),
  createData(
    1,
    '16 Mar, 2019 12:45 PM',
    'Coach Chen',
    'London, UK',
    'Delete Video',
    'Sub admin',
  ),
  createData(2, '16 Mar, 2019 3:15 PM', 'CpiiAdmin', 'Shatin', 'create user', 'Admin'),
  createData(
    3,
    '16 Mar, 2019 6:00 PM',
    'Coach test',
    'Hong Kong',
    'Sign out',
    'Coach',
  ),
  createData(
    4,
    '15 Mar, 2019 9:20 AM',
    'Coach test',
    'Yuen Long',
    'Upload Video',
    'Coach',
  ),
];


export default function UserActivities() {
  return (
    <Fragment>
      <Title>Recent Users Activities</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Activity</TableCell>
            <TableCell align="right">Account Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.time}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.location}</TableCell>
              <TableCell>{row.activities}</TableCell>
              <TableCell align="right">{row.accountType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
}