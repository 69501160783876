import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import './VideoUploadOptions.scss';

const VideoUploadOptions = ({
  matchData,
  file,
  index,
  handleRemoveFile,
  handleModifyMatchData,
  playlists,
  selectedPlaylists,
  handlePlaylistSelect,
}) => {
  return (
    <li className="video-upload-block" key={index}>
      <div className="video-upload-content">
        <span>{file.name}</span>
        <button onClick={() => handleRemoveFile(index)} aria-label="Refresh">
          <CloseIcon />
        </button>
      </div>
      <div className="video-upload-info">
        <TextField
          className="video-name-input"
          key={index}
          value={matchData.videoName[index]}
          onChange={(e) => handleModifyMatchData(index, e.target.value)}
          label={'影片名 : '}
          variant="outlined"
          fullWidth
        />
        <FormControl className="playlist-select">
          <Select
            multiple
            value={
              selectedPlaylists[file['name']]
                ? selectedPlaylists[file['name']]
                : []
            }
            renderValue={(selected) => selected.join(', ')}
            onChange={(event) => handlePlaylistSelect(event, file['name'])}
          >
            {!playlists.message &&
              playlists.map((playlist) => (
                <MenuItem
                  key={playlist['playlist_id']}
                  value={playlist['playlist_name']}
                >
                  <Checkbox
                    checked={
                      selectedPlaylists[file['name']]
                        ? selectedPlaylists[file['name']].includes(
                            playlist['playlist_name'],
                          )
                        : false
                    }
                  />
                  <ListItemText primary={playlist['playlist_name']} />
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>播放清單</FormHelperText>
        </FormControl>
      </div>
    </li>
  );
};

export default VideoUploadOptions;
