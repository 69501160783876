import React, { RefObject, useState } from 'react';
import TextField from '@mui/material/TextField';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

import { updateHighlightDataAPI } from 'src/api/videoService';

import './VideoHighlightEditingPanel.scss';

import { formatTime } from 'src/utils/utils';

import Cookies from 'js-cookie';

const labels: { [key: number]: string } = {
  0: '極差',
  0.5: '非常差',
  1: '差',
  1.5: '稍差',
  2: '尚可',
  2.5: '良好',
  3: '相當好',
  3.5: '優秀',
  4: '非常優秀',
  4.5: '卓越',
  5: '極卓越',
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

interface HighlightProps {
  highlight_id: number;
  match_id: number;
  round_id: number;
  start_time: number;
  end_time: number;
  description: string;
  owner: string;
  hl_rating: number;
  transformation: { [key: string]: any };
  videoRef: RefObject<HTMLVideoElement>;
  setVideoData: React.Dispatch<React.SetStateAction<HighlightProps>>;
  setHighlightIndexToEdit: React.Dispatch<React.SetStateAction<number>>;
  setIsAddNew: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoHighlightEditingPanel: React.FC<HighlightProps> = ({
  highlight_id,
  match_id,
  round_id,
  start_time,
  end_time,
  description,
  owner,
  hl_rating,
  videoRef,
  transformation,
  setVideoData,
  setHighlightIndexToEdit,
  setIsAddNew,
}) => {
  const token = Cookies.get('token');
  const [newStartTime, setNewStartTime] = useState(start_time);
  const [newEndTime, setNewEndTime] = useState(end_time);
  const [modifiedDescription, setModifiedDescription] = useState(description);
  const [commentRating, setCommentRating] = useState(hl_rating);

  const handleRatingChange = (newValue: number) => {
    setCommentRating(newValue);
  };

  const handleSubmit = async () => {
    try {
      const response = await updateHighlightDataAPI(
        {
          highlight_id: highlight_id,
          match_id: match_id,
          round_id: round_id,
          owner: owner,
          highlight_rating: commentRating,
          highlight_description: modifiedDescription,
          highlight_start_time: newStartTime,
          highlight_end_time: newEndTime,
          x: transformation.positionX,
          y: transformation.positionY,
          scale: transformation.scale,
        },
        token as string,
      );
      setIsAddNew(false);
      setHighlightIndexToEdit(-1);
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="edit-highlight-container">
      <div className="edit-highlight-info-panel">
        <Rating
          name="hover-feedback"
          className="stars"
          value={commentRating}
          precision={0.5}
          getLabelText={getLabelText}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          onChange={(e, newValue) => handleRatingChange(newValue || 0)}
        />
        <Box sx={{ ml: 2 }}>{labels[commentRating]}</Box>
      </div>

      <TextField
        id="outlined-textarea"
        label="教練評論"
        placeholder="教練評論"
        multiline
        value={modifiedDescription}
        onChange={(event) => {
          setModifiedDescription(event.target.value);
        }}
      />
      <div className="edit-highlight-time-panel">
        <button
          className="highlight-start-button"
          onClick={() =>
            setNewStartTime(videoRef.current ? videoRef.current.currentTime : 0)
          }
        >
          开始: {formatTime(newStartTime)}
        </button>
        <button
          className="highlight-end-button"
          onClick={() =>
            setNewEndTime(videoRef.current ? videoRef.current.currentTime : 0)
          }
        >
          结束: {formatTime(newEndTime)}
        </button>
      </div>

      <div className="edit-highlight-pov-panel">
        {Object.entries(transformation).map(([key, value]) => (
          <span key={key}>
            {key}: {Number(value).toFixed(3)}
          </span>
        ))}
      </div>
      <button className="save-edit-button" onClick={() => handleSubmit()}>
        保存
      </button>
    </div>
  );
};

export default VideoHighlightEditingPanel;
