import { useState, useEffect } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoRoundCommentary from './VideoRoundCommentary';
import VideoHighlightCommentary from './VideoHighlightCommentary';

import './VideoHighlightPanel.scss';

import {
  ballDataIndex,
  ballEndTimeIndex,
  ballStartTimeIndex,
  commentsDataIndex,
} from 'src/constants';

const VideoHighlightPanel = ({
  roundResults,
  userInfo,
  token,
  videoRef,
  videoData,
  setVideoData,
  matchId,
  currentTransformation,
}) => {
  const [commentData, setCommentData] = useState([]);
  const [ballData, setBallData] = useState([]);
  const [roundData, setRoundData] = useState([]);
  const [fixedRound, setFixedRound] = useState(-1);

  //update scoreboard data
  useEffect(() => {
    if (
      fixedRound === -1 &&
      roundResults &&
      roundResults[videoData.currentRound]
    ) {
      setCommentData(roundResults[videoData.currentRound][commentsDataIndex]);
      setBallData(roundResults[videoData.currentRound][ballDataIndex]);
      setRoundData(roundResults[videoData.currentRound]);
    } else if (fixedRound !== -1 && roundResults && roundResults[fixedRound]) {
      setCommentData(roundResults[fixedRound][commentsDataIndex]);
      setBallData(roundResults[fixedRound][ballDataIndex]);
      setRoundData(roundResults[fixedRound]);
    } else {
      setCommentData([]);
      setBallData([]);
      setRoundData([]);
    }
  }, [roundResults, videoData.currentRound, fixedRound]);

  const ballStartTime =
    roundResults[videoData.currentRound] &&
    roundResults[videoData.currentRound][ballDataIndex][videoData.currentBall]
      ? roundResults[videoData.currentRound][ballDataIndex][
          videoData.currentBall
        ][ballStartTimeIndex]
      : 0;
  const ballEndTime =
    roundResults[videoData.currentRound] &&
    roundResults[videoData.currentRound][ballDataIndex][videoData.currentBall]
      ? roundResults[videoData.currentRound][ballDataIndex][
          videoData.currentBall
        ][ballEndTimeIndex]
      : 0;

  return (
    <div className="highlight-main-container">
      {roundResults && (
        <div style={{ width: '100%' }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>回合評述</Typography>
            </AccordionSummary>
            <VideoRoundCommentary
              commentData={commentData}
              setCommentData={setCommentData}
              videoData={videoData}
              setVideoData={setVideoData}
              fixedRound={fixedRound}
              userInfo={userInfo}
              token={token}
              ballData={ballData}
              setBallData={setBallData}
              videoRef={videoRef}
              matchId={matchId}
              setFixedRound={setFixedRound}
              roundData={roundData}
            />
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>亮點評述</Typography>
            </AccordionSummary>
            <VideoHighlightCommentary
              userInfo={userInfo}
              roundData={roundResults[videoData.currentRound]}
              ballStartTime={ballStartTime}
              ballEndTime={ballEndTime}
              videoRef={videoRef}
              currentTransformation={currentTransformation}
              setVideoData={setVideoData}
            />
          </Accordion>
        </div>
      )}
      <p className="padding"></p>
    </div>
  );
};

export default VideoHighlightPanel;
