// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-title {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid black;
  font-size: var(--medium-font-size) !important;
  color: var(--dark-red) !important;
}

.review-list .MuiListItem-root {
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/videoPages/EditVideo/Review.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,6CAAA;EACA,iCAAA;AAAJ;;AAII;EACI,sBAAA;AADR","sourcesContent":["\n.review-title{\n    display: flex;\n    justify-content: center;\n    border-bottom: 1px solid black;\n    font-size: var(--medium-font-size) !important;\n    color: var(--dark-red) !important;\n}\n\n.review-list{\n    .MuiListItem-root{\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
