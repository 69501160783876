import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import { MatchData } from 'types/videoTypes';

interface HumanVerificationPanelProps {
  matchData: MatchData;
  setMatchData: React.Dispatch<React.SetStateAction<MatchData>>;
}

const HumanVerificationPanel: React.FC<HumanVerificationPanelProps> = ({
  matchData,
  setMatchData,
}) => {
  return (
    <div>
      <FlagVideo
        matchData={matchData}
        setMatchData={setMatchData}
        defaultOpen={false}
      />
    </div>
  );
};

export default HumanVerificationPanel;
