import React, { useState, useRef } from 'react';
import { updateDPDataAPI } from 'src/api/videoService';
import { UpdateDPDataParams } from 'types/videoTypes';
import InputPanel from '../InputPanel/InputPanel';

import Cookies from 'js-cookie';

import './EditDpPanel.scss';

interface Position {
  x: string;
  y: string;
}

interface EditDpPanelProps {
  matchId: number;
  currentRound: number;
  dpIndex: number;
  setVideoData: React.Dispatch<React.SetStateAction<any>>;
  ballData: any[][];
  videoRef: React.RefObject<HTMLVideoElement>;
}

const EditDpPanel: React.FC<EditDpPanelProps> = ({
  matchId,
  currentRound,
  dpIndex,
  setVideoData,
  ballData,
  videoRef,
}) => {
  const token = Cookies.get('token') as string;

  const [clickPositions, setClickPositions] = useState<Position[]>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  const handleChartClick = (indexToDelete: number) => {
    setClickPositions((prevPositions) =>
      prevPositions.filter((_, idx) => idx !== indexToDelete),
    );
  };

  const handleClearAll = () => {
    setClickPositions([]);
  };

  const handleSaveDp = async () => {
    const convertedArray: number[] = clickPositions.flatMap((pos) => [
      Math.round((parseFloat(pos.x) / 100) * 800),
      Math.round((parseFloat(pos.y) / 100) * 1280),
    ]);

    try {
      const dpData: UpdateDPDataParams = {
        match_id: matchId,
        round_number: currentRound,
        dp_index: dpIndex,
        dp: convertedArray,
      };

      const responseData = await updateDPDataAPI(dpData, token);
      setVideoData((prevState: any) => ({
        ...prevState,
        refresh: true,
      }));
    } catch (error) {
      console.error('Error saving DP data:', error);
    }
  };

  const handleAddClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (chartRef.current) {
      const chartRect = chartRef.current.getBoundingClientRect();
      console.log(chartRect);
      const xPos = `${
        ((event.clientX - chartRect.left) / chartRect.width) * 100
      }%`;
      const yPos = `${
        ((event.clientY - chartRect.top) / chartRect.height) * 100
      }%`;

      setClickPositions((prevPositions) => [
        ...prevPositions,
        { x: xPos, y: yPos },
      ]);
    }
  };

  return (
    <div className="edit-dp-container">
      <div className="input-dp-container">
        <span className="table-label top-right">上/右球員</span>
        <div
          className="table-tennis-table"
          ref={chartRef}
          onClick={handleAddClick}
        >
          <div className="middle-line"></div>
          {[...Array(4)].map((_, rowIndex) => (
            <div key={rowIndex} className="table-row">
              {[...Array(3)].map((__, cellIndex) => (
                <div key={cellIndex} className="table-cell"></div>
              ))}
            </div>
          ))}
          {clickPositions.map((position, index) => (
            <div
              key={index}
              className="position-marker"
              style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
              }}
            >
              <div className="dot" style={{ color: 'red' }}>
                •
              </div>
              <div className="index-label" style={{ color: 'black' }}>
                {index + 1}
                <span
                  className="delete-marker"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleChartClick(index);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
          ))}
        </div>
        <span className="table-label bottom-left">下/左球員</span>
      </div>
      <div className="edit-dp-options-container">
        <span style={{ textAlign: 'center' }}>分數/時間</span>
        <InputPanel
          ballData={ballData}
          videoRef={videoRef}
          setVideoData={setVideoData}
        />
        <button className="clear-button" onClick={handleClearAll}>
          清除所有落點
        </button>
        <button className="save-button" onClick={handleSaveDp}>
          儲存
        </button>
      </div>
    </div>
  );
};

export default EditDpPanel;
