// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dp-management-header {
  display: flex;
  flex-direction: column;
}
.dp-management-header .dp-option-panel {
  display: grid;
  grid-template-columns: 80% 20%;
}
.dp-management-header .dp-option-panel .dp-search-panel {
  display: flex;
  align-items: center;
}
.dp-management-header .dp-option-panel .dp-search-panel .search-container {
  min-width: 80%;
}
.dp-management-header .dp-option-panel .download-summary-button {
  display: flex;
  justify-content: flex-end;
}
.dp-management-header .dp-option-panel .download-summary-button button {
  width: -moz-fit-content;
  width: fit-content;
  padding: var(--xsmall-font-size);
}

.truncate-dp-cell {
  max-width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: var(--xsmall-font-size);
}

.button-dp-cell {
  text-align: center;
}

@media screen and (orientation: portrait) {
  .dp-management-header .dp-option-panel .dp-search-panel {
    justify-content: space-around;
  }
  .dp-management-header .dp-option-panel .dp-search-panel .search-container {
    margin-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/Files/DPManagement.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;AAAJ;AACI;EACI,aAAA;EACA,8BAAA;AACR;AAAQ;EACI,aAAA;EACA,mBAAA;AAEZ;AADY;EACI,cAAA;AAGhB;AAAQ;EACI,aAAA;EACA,yBAAA;AAEZ;AADY;EACI,uBAAA;EAAA,kBAAA;EACA,gCAAA;AAGhB;;AAIA;EACI,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gCAAA;AADJ;;AAGA;EACI,kBAAA;AAAJ;;AAIA;EAIY;IACI,6BAAA;EAJd;EAKc;IACI,eAAA;EAHlB;AACF","sourcesContent":["\n.dp-management-header{\n    display: flex;\n    flex-direction: column;\n    .dp-option-panel{\n        display: grid;\n        grid-template-columns: 80% 20%;\n        .dp-search-panel{\n            display: flex;\n            align-items: center;\n            .search-container{\n                min-width: 80%;\n            }\n        }\n        .download-summary-button{\n            display: flex;\n            justify-content: flex-end;\n            button{\n                width: fit-content;\n                padding: var(--xsmall-font-size);\n            }\n        }\n    }\n}\n\n\n.truncate-dp-cell {\n    max-width: 10vw;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    padding: var(--xsmall-font-size);\n  }\n.button-dp-cell{\n    text-align: center;\n}\n\n\n@media screen and (orientation: portrait) {\n\n    .dp-management-header{\n        .dp-option-panel{\n            .dp-search-panel{\n                justify-content: space-around;\n                .search-container{\n                    margin-right: 0;\n                }\n            }\n        }\n    }\n    \n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
