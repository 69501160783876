// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-main-container {
  max-width: 100% !important;
}
.dashboard-main-container .chart-paper {
  background-color: var(--apple-vlightgrey);
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/DashBoard/Dashboard.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AAAE;EACE,yCAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".dashboard-main-container {\n  max-width: 100% !important;\n  .chart-paper {\n    background-color: var(--apple-vlightgrey);\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 2vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
