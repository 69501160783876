// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-player-control {
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: var(--xsmall-font-size);
  padding: var(--xxsmall-font-size);
}
@media (orientation: portrait) {
  .block-player-control {
    padding-bottom: 0;
  }
}
.block-player-control .img-player-analysis-panel {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(215, 71, 71, 0.8588235294));
  border-radius: var(--small-font-size);
}
@media (orientation: portrait) {
  .block-player-control .img-player-analysis-panel {
    padding: var(--small-font-size);
  }
}
@media (orientation: portrait) {
  .block-player-control .padding {
    min-width: 60vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/playerPages/PlayerInfoPanel/PlayerInfoPanel.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,sCAAA;EACA,iCAAA;AACF;AAAE;EARF;IASI,iBAAA;EAGF;AACF;AADE;EACE,WAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,yFAAA;EACA,qCAAA;AAGJ;AAFI;EAPF;IAQI,+BAAA;EAKJ;AACF;AAFI;EADF;IAEI,eAAA;EAKJ;AACF","sourcesContent":[".block-player-control {\n  max-height: 80vh;\n  overflow-y: auto;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  border-radius: var(--xsmall-font-size);\n  padding: var(--xxsmall-font-size);\n  @media (orientation: portrait) {\n    padding-bottom: 0;\n  }\n\n  .img-player-analysis-panel {\n    width: 100%;\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    background: linear-gradient(to bottom, #00000000, #d74747db);\n    border-radius: var(--small-font-size);\n    @media (orientation: portrait) {\n      padding: var(--small-font-size);\n    }\n  }\n  .padding {\n    @media (orientation: portrait) {\n      min-width: 60vw;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
